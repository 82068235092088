import React, { useMemo } from 'react';

import { HydraDeviceDetails } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { formatCalendarDate } from '../../../helpers/dateFormats';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import LinkButton from '../../../ui/Button/LinkButton';
import { copyToClipboard } from '../../../ui/utils';
import { getUtcDate } from '../helpers';

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type OuterProps = { kiosk: HydraDeviceDetails };
type InnerProps = MappedState;
type Props = InnerProps & OuterProps;

const KioskListDetails: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { AppId, dtUtils, kiosk, translate } = props;
  const classes = useStyles();

  const localUserTime = useMemo(() => {
    if (kiosk.LastPollUtc) {
      const utcDate = getUtcDate(kiosk.LastPollUtc);
      return dtUtils.utcToZonedTime(utcDate, dtUtils.userIanaTimeZone);
    }
  }, [kiosk.LastPollUtc, dtUtils.userIanaTimeZone]);

  return (
    <ListItemText
      primary={
        <Typography variant="subtitle1">
          <Translate id={'Kiosk'} />
          {`, ${kiosk.DeviceName}`}
        </Typography>
      }
      secondary={
        <>
          {kiosk.StoreNames &&
            kiosk.StoreNames.map((store, index) => {
              if (store.StoreName !== null) {
                return (
                  <Typography key={index} variant="caption">
                    {`${store.StoreName}, `}
                  </Typography>
                );
              } else {
                return null;
              }
            })}
          {kiosk.Status && (
            <Typography variant="caption">
              <Translate id={`${kiosk.Status}` as 'Online'} />
            </Typography>
          )}
          {localUserTime && (
            <Typography variant="caption">
              {', '}
              <Translate id={'Last_polled'} />
              {formatCalendarDate(dtUtils, localUserTime, translate)}
            </Typography>
          )}
          <Box pt={2}>
            <Grid container m={-1.5} width={`calc(100% + 24px)`}>
              {kiosk.DeviceId && (
                <Grid item className={classes.gridItem}>
                  <Typography variant="body2">
                    <Translate id={'Device_ID'} />: {kiosk.DeviceId}
                  </Typography>
                </Grid>
              )}
              {kiosk.SerialNumber && (
                <Grid item className={classes.gridItem}>
                  <Typography variant="body2">
                    <Translate id={'Serial_number'} />: {kiosk.SerialNumber}
                  </Typography>
                </Grid>
              )}
              {kiosk.Version && (
                <Grid item className={classes.gridItem}>
                  <Typography variant="body2">
                    <Translate id={'Version'} />: {kiosk.Version}
                  </Typography>
                </Grid>
              )}

              <InputBase
                id={`kiosk-info-${kiosk.DeviceId}`}
                type="hidden"
                value={`Device ID:${kiosk.DeviceId} Serial Number:${kiosk.SerialNumber} Version:${kiosk.Version} WLID:${AppId}`}
              />
              <LinkButton
                to={`/${AppId}/kiosks`}
                size="medium"
                color="primary"
                fdKey="copy-to-clipboard-Button"
                onClick={() => {
                  copyToClipboard(null, `kiosk-info-${kiosk.DeviceId}`);
                }}
                style={{ color: '#05149E' }}
              >
                <Translate id={'Copy_info'} />
              </LinkButton>
            </Grid>
          </Box>
        </>
      }
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  AppId: state.currentApp.AppId,
  dtUtils: dateTimeUtils(state),
  translate: getTranslate(state.locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, null))(KioskListDetails);
