import React from 'react';

import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { StoreGroupEvents } from '../../signalr/hub.actions';
import Stores from '../Stores/Stores.routes';
import StoreGroups from './StoreGroups';

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps: Props) => {
  return (state: AppState, ownProps: Props) => {
    return {
      currentApp: state.currentApp,
    };
  };
};
type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps) => {
  return (dispatch: ThunkDispatch, ownProps: RouteComponentProps) => {
    return {
      subscribe: () => {
        dispatch(StoreGroupEvents.Subscribe.Created);
        dispatch(StoreGroupEvents.Subscribe.Updated);
        dispatch(StoreGroupEvents.Subscribe.Deleted);
      },
      unsubscribe: () => {
        dispatch(StoreGroupEvents.Unsubscribe.Created);
        dispatch(StoreGroupEvents.Unsubscribe.Updated);
        dispatch(StoreGroupEvents.Unsubscribe.Deleted);
      },
    };
  };
};

type Props = RouteComponentProps;
type State = {} & { loaded: boolean };
const StoreGroupRoutes = compose<Props, RouteComponentProps>(
  setDisplayName('StoreGroupRoutes'),
  connect(mapStateToPropsFactory, mapDispatchToPropsFactory)
)(
  class extends React.Component<Props & MappedState & MappedDispatch, State> {
    public async componentDidMount() {
      if (!this.props.currentApp.AppId) {
        return;
      }

      this.props.subscribe();
    }
    public componentWillUnmount() {
      this.props.unsubscribe();
    }

    public render() {
      const { match, currentApp } = this.props;
      return (
        <ErrorBoundary identifier="store-group">
          <Switch>
            <Route
              exact
              path={match.path}
              render={(props) => <StoreGroups {...props} key={currentApp.AppId} />}
            />

            <Route path={`${match.path}/:storeGroupId/stores`} component={Stores} />
            <Redirect to={match.path} />
          </Switch>
        </ErrorBoundary>
      );
    }
  }
);

export default StoreGroupRoutes;
