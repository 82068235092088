import React, { useEffect } from 'react';

import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import CodeView from '../../../ui/CodeView';
import PageLayout from '../../../ui/Layout';
import FullWidthContainer from '../../../ui/Layout/FullWidthContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { Loader } from '../../common/Loader';
import { getInteractionLog } from '../actions';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: '12px',
  },
}));

const mapStateToProps = (state: AppState) => {
  const {
    locale,
    developers: { interactionLog, isLoading },
  } = state;

  return {
    isLoading,
    data: interactionLog,
    translate: getTranslate(locale),
    currentApp: state.currentApp,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps: InnerProps) => ({
  getLog: () => dispatch(getInteractionLog(ownProps.match.params.id)),
});

type MappedProps = ReturnType<typeof mapStateToProps>;
type DispatchedProps = ReturnType<typeof mapDispatchToProps>;

type InnerProps = MappedProps & RouteComponentProps<{ id: string }>;

type OuterProps = {};

type Props = InnerProps & OuterProps & DispatchedProps;

export default compose<InnerProps, OuterProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(function ({ translate, data, isLoading, getLog, currentApp }: Props) {
  const classes = useStyles();

  useEffect(() => {
    if (!data) {
      getLog();
    }
  }, []);

  return (
    <PageLayout
      documentTitle="Log_details"
      title={translate('Log_details')}
      toParent={`/${currentApp.AppId}/developers/logs`}
    >
      {isLoading ? (
        <Loader />
      ) : data ? (
        <PaperContainer>
          {data.RequestHeaders && (
            <div className={classes.section}>
              <Typography className={classes.title} variant="subtitle2">
                Request Headers
              </Typography>
              <FullWidthContainer>
                <CodeView
                  inputId="request-header-0000"
                  copyToClipboard
                  name="Request Headers"
                  data={data.RequestHeaders}
                />
              </FullWidthContainer>
            </div>
          )}
          {data.RequestBody && (
            <div className={classes.section}>
              <Typography className={classes.title} variant="subtitle2">
                Request Body
              </Typography>
              <FullWidthContainer>
                <CodeView
                  inputId="request-body-0001"
                  copyToClipboard
                  name="Request Body"
                  data={data.RequestBody}
                />
              </FullWidthContainer>
            </div>
          )}
          {data.ResponseHeaders && (
            <div className={classes.section}>
              <Typography className={classes.title} variant="subtitle2">
                Response Headers
              </Typography>
              <FullWidthContainer>
                <CodeView
                  inputId="response-header-0002"
                  copyToClipboard
                  name="Response Headers"
                  data={data.ResponseHeaders}
                />
              </FullWidthContainer>
            </div>
          )}
          {data.ResponseBody && (
            <div className={classes.section}>
              <Typography className={classes.title} variant="subtitle2">
                Response Body
              </Typography>
              <FullWidthContainer>
                <CodeView
                  inputId="response-body-0003"
                  copyToClipboard
                  name="Response Body"
                  data={data.ResponseBody}
                />
              </FullWidthContainer>
            </div>
          )}
        </PaperContainer>
      ) : null}
    </PageLayout>
  );
});
