import * as React from 'react';

import { SignupStep } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';

import { CreateSubscription } from '../../components/Auth/Onboarding/CreateSubscription';
import StoreLocation from '../../components/Auth/Onboarding/StoreLocation';
import StoreName from '../../components/Auth/Onboarding/StoreName';
import { UserSurvey } from '../../components/Auth/Onboarding/UserSurvey';

class AuthComponent extends React.Component<Props, {}> {
  public render() {
    const { account } = this.props;
    if (account.SignupSteps && account.SignupSteps.length > 0) {
      switch (account.SignupSteps[0].Action) {
        case SignupStep.ActionEnum.StoreName:
          return <StoreName />;
        case SignupStep.ActionEnum.Question:
          return <UserSurvey />;
        case SignupStep.ActionEnum.StoreLocation:
          return <StoreLocation />;
        case SignupStep.ActionEnum.PaymentSubscription:
          return <CreateSubscription />;
      }
    }

    return null;
  }
}

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { account, currentApp } = state;
  return {
    account,
    currentApp,
  };
}

type Props = { dispatch: ThunkDispatch } & RouteComponentProps & MappedState;
const ConnectedAuth = compose(withRouter, connect(mapStateToProps))(AuthComponent);

export { ConnectedAuth as AuthGate };
