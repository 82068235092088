import React from 'react';

import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import ModalButtons from '@fd/ui/Modal/ModalButtons';
import { ModalContent } from '@fd/ui/Modal/ModalContent';

type Props = {
  fdKey?: string;
};

const DeleteMappingModal = ({ fdKey }: Props) => {
  return (
    <Modal open={true} onClose={() => null} titleTextId={'Delete_mapping'} fdKey={fdKey}>
      <ModalContent>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore
      </ModalContent>
      <ModalActions>
        <ModalButtons
          leftButtonTranslationId="Cancel"
          leftButtonAction={() => null}
          rightButtonTranslationId="Delete"
          rightButtonAction={() => null}
          fdKey={'delete-mapping-modal-buttons'}
        />
      </ModalActions>
    </Modal>
  );
};

export default DeleteMappingModal;
