export const STORE_CREATE = 'STORE_CREATE';
export const STORE_CREATE_REQUEST = 'STORE_CREATE_REQUEST';
export const STORE_CREATE_SUCCESS = 'STORE_CREATE_SUCCESS';
export const STORE_CREATE_FAILURE = 'STORE_CREATE_FAILURE';

export const STORE_CREATE_CLONE = 'STORE_CREATE_CLONE';
export const STORE_CREATE_CLONE_REQUEST = 'STORE_CREATE_CLONE_REQUEST';
export const STORE_CREATE_CLONE_SUCCESS = 'STORE_CREATE_CLONE_SUCCESS';
export const STORE_CREATE_CLONE_FAILURE = 'STORE_CREATE_CLONE_FAILURE';

export const STORE_LOAD = 'STORE_LOAD';
export const STORE_LOAD_REQUEST = 'STORE_LOAD_REQUEST';
export const STORE_LOAD_SUCCESS = 'STORE_LOAD_SUCCESS';
export const STORE_LOAD_FAILURE = 'STORE_LOAD_FAILURE';

export const STORE_UPDATE = 'STORE_UPDATE';
export const STORE_UPDATE_REQUEST = 'STORE_UPDATE_REQUEST';
export const STORE_UPDATE_SUCCESS = 'STORE_UPDATE_SUCCESS';
export const STORE_UPDATE_FAILURE = 'STORE_UPDATE_FAILURE';

export const STORE_UPDATE_ADDRESS = 'STORE_UPDATE_ADDRESS';
export const STORE_UPDATE_ADDRESS_REQUEST = 'STORE_UPDATE_ADDRESS_REQUEST';
export const STORE_UPDATE_ADDRESS_SUCCESS = 'STORE_UPDATE_ADDRESS_SUCCESS';
export const STORE_UPDATE_ADDRESS_FAILURE = 'STORE_UPDATE_ADDRESS_FAILURE';

export const STORE_UPDATE_ADDRESS_COORDINATES = 'STORE_UPDATE_ADDRESS_COORDINATES';
export const STORE_UPDATE_ADDRESS_COORDINATES_REQUEST = 'STORE_UPDATE_ADDRESS_COORDINATES_REQUEST';
export const STORE_UPDATE_ADDRESS_COORDINATES_SUCCESS = 'STORE_UPDATE_ADDRESS_COORDINATES_SUCCESS';
export const STORE_UPDATE_ADDRESS_COORDINATES_FAILURE = 'STORE_UPDATE_ADDRESS_COORDINATES_FAILURE';

export const STORE_GET_TIPS = 'STORE_GET_TIPS';
export const STORE_GET_TIPS_REQUEST = 'STORE_GET_TIPS_REQUEST';
export const STORE_GET_TIPS_SUCCESS = 'STORE_GET_TIPS_SUCCESS';
export const STORE_GET_TIPS_FAILURE = 'STORE_GET_TIPS_FAILURE';

export const STORE_UPDATE_TIPS = 'STORE_UPDATE_TIPS';
export const STORE_UPDATE_TIPS_REQUEST = 'STORE_UPDATE_TIPS_REQUEST';
export const STORE_UPDATE_TIPS_SUCCESS = 'STORE_UPDATE_TIPS_SUCCESS';
export const STORE_UPDATE_TIPS_FAILURE = 'STORE_UPDATE_TIPS_FAILURE';

export const STORE_GET_SERVICE_CHARGE = 'STORE_GET_SERVICE_CHARGE';
export const STORE_GET_SERVICE_CHARGE_REQUEST = `${STORE_GET_SERVICE_CHARGE}_REQUEST`;
export const STORE_GET_SERVICE_CHARGE_SUCCESS = `${STORE_GET_SERVICE_CHARGE}_SUCCESS`;
export const STORE_GET_SERVICE_CHARGE_FAILURE = `${STORE_GET_SERVICE_CHARGE}_FAILURE`;

export const STORE_UPDATE_SERVICE_CHARGE = 'STORE_UPDATE_SERVICE_CHARGE';
export const STORE_UPDATE_SERVICE_CHARGE_REQUEST = `${STORE_UPDATE_SERVICE_CHARGE}_REQUEST`;
export const STORE_UPDATE_SERVICE_CHARGE_SUCCESS = `${STORE_UPDATE_SERVICE_CHARGE}_SUCCESS`;
export const STORE_UPDATE_SERVICE_CHARGE_FAILURE = `${STORE_UPDATE_SERVICE_CHARGE}_FAILURE`;

export const STORE_GET_ORDER_BATCH_CONFIG = 'STORE_GET_ORDER_BATCH_CONFIG';
export const STORE_GET_ORDER_BATCH_CONFIG_REQUEST = `${STORE_GET_ORDER_BATCH_CONFIG}_REQUEST`;
export const STORE_GET_ORDER_BATCH_CONFIG_SUCCESS = `${STORE_GET_ORDER_BATCH_CONFIG}_SUCCESS`;
export const STORE_GET_ORDER_BATCH_CONFIG_FAILURE = `${STORE_GET_ORDER_BATCH_CONFIG}_FAILURE`;

export const STORE_UPDATE_ORDER_BATCH_CONFIG = 'STORE_UPDATE_ORDER_BATCH_CONFIG';
export const STORE_UPDATE_ORDER_BATCH_CONFIG_REQUEST = `${STORE_UPDATE_ORDER_BATCH_CONFIG}_REQUEST`;
export const STORE_UPDATE_ORDER_BATCH_CONFIG_SUCCESS = `${STORE_UPDATE_ORDER_BATCH_CONFIG}_SUCCESS`;
export const STORE_UPDATE_ORDER_BATCH_CONFIG_FAILURE = `${STORE_UPDATE_ORDER_BATCH_CONFIG}_FAILURE`;

export const STORE_LOAD_ALL = 'STORE_LOAD_ALL';
export const STORE_LOAD_ALL_REQUEST = 'STORE_LOAD_ALL_REQUEST';
export const STORE_LOAD_ALL_SUCCESS = 'STORE_LOAD_ALL_SUCCESS';
export const STORE_LOAD_ALL_FAILURE = 'STORE_LOAD_ALL_FAILURE';

export const STORE_LOAD_BY_APP = 'STORE_LOAD_BY_APP';
export const STORE_LOAD_BY_APP_REQUEST = 'STORE_LOAD_BY_APP_REQUEST';
export const STORE_LOAD_BY_APP_SUCCESS = 'STORE_LOAD_BY_APP_SUCCESS';
export const STORE_LOAD_BY_APP_FAILURE = 'STORE_LOAD_BY_APP_FAILURE';

export const STORE_SET_SELECTED = 'STORE_SET_SELECTED';

export const STORES_SET_SEARCH = 'STORES_SET_SEARCH';

export const STORES_RESET = 'STORES_RESET';

export const STORES_EMPTY_STATE = 'STORES_EMPTY_STATE';

export const STORE_ARCHIVE = 'STORE_ARCHIVE';
export const STORE_PUBLISH = 'STORE_PUBLISH';
export const STORE_UNPUBLISH = 'STORE_UNPUBLISH';

export const STORE_PREORDER_ENABLE_OPENING_HOURS_OVERRIDES =
  'STORE_PREORDER_ENABLE_OPENING_HOURS_OVERRIDES';

export const GET_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST =
  'GET_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST';
export const GET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS =
  'GET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS';
export const GET_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE =
  'GET_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE';
export const SET_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST =
  'SET_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST';
export const SET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS =
  'SET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS';
export const SET_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE =
  'SET_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE';
export const DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST =
  'DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST';
export const DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS =
  'DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS';
export const DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE =
  'DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE';

export const STORE_GET_LEAD_TIMES_SUCCESS = 'STORE_GET_LEAD_TIMES_SUCCESS';
export const STORE_UPDATE_LEAD_TIMES_SUCCESS = 'STORE_UPDATE_LEAD_TIMES_SUCCESS';
