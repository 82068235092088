import { getSubscriptionsApiEndPoint } from '../helpers/apibase';
import { createApi } from '../helpers/utilities';
import {
  CheckoutApiFactory,
  CheckoutUpdateSubscriptionRequest,
  CreateCheckoutSessionRequest,
} from '../overrides/@flipdish/subscriptions-api-client-typescript/api';
import { mapServerError } from './utils/serverErrorMapper';

const checkoutApi = createApi(CheckoutApiFactory, getSubscriptionsApiEndPoint());

export const createCheckoutSession = async (
  request: CreateCheckoutSessionRequest,
  appId: string | undefined = undefined
) => {
  try {
    if (appId) {
      return await checkoutApi.createCheckoutSessionForApp(appId, request);
    }

    return await checkoutApi.createCheckoutSession(request);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const getCheckoutSummary = async (
  appId: string,
  subscriptionId: string,
  priceId: string,
  addOns: string[] | undefined,
  promoCode: string | undefined
) => {
  try {
    return await checkoutApi.getCheckoutSummary(
      appId,
      subscriptionId,
      priceId,
      addOns?.join(','),
      promoCode
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const checkout = async (
  appId: string,
  subscriptionId: string,
  request: CheckoutUpdateSubscriptionRequest
) => {
  try {
    return await checkoutApi.checkoutUpdateSubscription(appId, subscriptionId, request);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const getCheckoutSession = async (sessionId: string) => {
  try {
    return await checkoutApi.getCheckoutSession(sessionId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const checkoutService = {
  createCheckoutSession,
  getCheckoutSummary,
  checkout,
  getCheckoutSession,
};
