import * as React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import kitchenJpg from '../../../assets/images/kitchen.jpg';
import KitchenSvgData from '../../../assets/images/KitchenSvgData';
import LogoWhite from '../../../assets/images/LogoWhite';
import { LanguageBar } from '../LanguageBar';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: '#ffffff',
    },
    header: {
      backgroundColor: '#11146E',
      backgroundImage: 'url("' + KitchenSvgData + '")',
      fallbacks: {
        backgroundImage: 'url(' + kitchenJpg + ')',
      },
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'right top',
      padding: theme.spacing(4),

      [theme.breakpoints.down('md')]: {
        minHeight: 'calc(100vh * 0.3875)',
        maxHeight: 'calc(100vh * 0.43)',
      },
      [theme.breakpoints.up('md')]: {
        height: '100vh',
        padding: '96px 96px 196px 96px',
      },
      // transition: 'all 0.3s ease'
    },
    title: {},
    logo: {
      [theme.breakpoints.down('md')]: {
        transform: 'scale(0.75)',
        transformOrigin: 'left top',
        marginLeft: theme.spacing(0.875),
      },
    },
    contentWrapper: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
    contentContainer: {
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
      },
    },
    content: {
      // [theme.breakpoints.up('sm')]: {},
    },
    languageBarNoMargin: {
      margin: 0,
    },
    hiddenLink: {
      cursor: 'default',
      outline: 0,
    },
  });

export interface IAuthWrapperProps {
  title: React.ElementType;
  children: React.ReactNode;
}
type Props = WithStyles<typeof styles> & IAuthWrapperProps;
const AuthLayout = compose<Props, IAuthWrapperProps>(
  setDisplayName('AuthLayout'),
  withStyles(styles, {
    name: 'FdAuthLayout',
    withTheme: true,
  })
)(({ classes, children, title: Title }) => {
  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={9}
        className={classes.header}
        container
        direction="column"
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Grid item>
          <Link to="/login" className={classes.hiddenLink} data-fd="link-back-to-login">
            <LogoWhite className={classes.logo} />
          </Link>
        </Grid>
        <Grid item className={classes.title}>
          {Title && <Title />}
        </Grid>
      </Grid>

      <Grid item container xs={12} sm={12} md={4} lg={3} className={classes.contentWrapper}>
        <Grid item container justifyContent="flex-end">
          <LanguageBar wrapperClasses={classes.languageBarNoMargin} />
        </Grid>

        <Grid item xs={12} container className={classes.content}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default AuthLayout;
