const signupPath = '/signup';

export const signup = {
  steps: ['Account_created', 'Store_location', 'About_you'],
  paths: {
    signup: signupPath,
    storename: `${signupPath}/store`,
    location: `${signupPath}/location`,
    locationConfirm: `${signupPath}/location/confirm`,
    survey: `${signupPath}/survey`,
  },
};
