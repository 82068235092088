import axios from 'axios';

import { mapServerError } from '../../services/utils/serverErrorMapper';

const isProd = window.location?.host?.includes('portal.flipdish.com');
const rmsBaseUrl = isProd
  ? 'https://rms-core-api.portal.flipdish.com/api/core'
  : 'https://rms-core-api.portal.flipdishdev.com/api/core';

const rmsApi = axios.create({
  baseURL: rmsBaseUrl,
  withCredentials: true,
});
type RMSExhangeResponse = {
  data: {
    token: string;
  };
};

// #region getRMSTokenFromFDAuthorization
export const getRMSTokenFromFDAuthorizationKey = 'getRMSTokenFromFDAuthorization';
export async function getRMSTokenFromFDAuthorization(): Promise<RMSExhangeResponse> {
  try {
    return await rmsApi.get('/system/security/authenticateCookie');
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getAssociations
export const getAssociationsKey = 'getAssociations';
// TODO: Define a response type when I see what that is/ if they have a client library
export async function getAssociations(appId: string, token: string): Promise<any> {
  try {
    if (!token) {
      throw new Error('No token provided');
    }
    return await rmsApi.get(`/associations/flipdish/appId/${appId}`, {
      headers: {
        'x-token': token,
      },
    });
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getAssociations
export const getStorefrontsKey = 'getStorefronts';
// TODO: Define a response type when I see what that is/ if they have a client library
export async function getStorefronts(values: {
  token: string;
  franchisorId: string;
  franchiseeId: string;
  propertyId: string;
}): Promise<any> {
  const { franchiseeId, franchisorId, propertyId, token } = values;
  try {
    if (!franchiseeId) {
      throw new Error('No franchiseeId provided');
    } else if (!franchisorId) {
      throw new Error('No franchisorId provided');
    } else if (!propertyId) {
      throw new Error('No propertyId provided');
    } else if (!token) {
      throw new Error('No token provided');
    }
    return await rmsApi.get(
      `/organizations/${franchisorId}/${franchiseeId}/${propertyId}/storefronts`,
      {
        headers: {
          'x-token': token,
        },
      }
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion
