import React, { useMemo, useState } from 'react';

import { StoreValidationConfig } from '@flipdish/api-client-typescript/private/api';
import Switch from '@mui/material/Switch';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import LinkButton from '@fd/ui/Button/LinkButton';
import Select, { OptionType } from '@fd/ui/Select/Select';

import { notify, notifyError } from '../../../../layouts/Notify/actions';
import { menusService } from '../../../../services/menus.service';
import { assignMenu, storeService } from '../../../../services/store.service';
import { kioskServices } from '../../Kiosks.services';

type RenderLinkButtonProps = { fdKey: string; link: string; linkText: TranslationId };
const RenderLinkButton: React.FC<React.PropsWithChildren<RenderLinkButtonProps>> = ({
  fdKey,
  link,
  linkText,
}) => {
  return (
    <LinkButton
      color="primary"
      fdKey={fdKey}
      style={{
        letterSpacing: '0.4px',
        textTransform: 'initial',
        fontSize: '12px',
        fontWeight: 400,
      }}
      to={link}
      type="button"
      variant="text"
    >
      <Translate id={linkText} />
    </LinkButton>
  );
};

export type InvalidStoreActionItemProps = {
  action: keyof NonNullable<StoreValidationConfig['ConfigValidation']>;
  storeGroupId: number;
  storeId: number;
};

const InvalidStoreActionItem: React.FC<
  React.PropsWithChildren<InvalidStoreActionItemProps & MappedDispatch & MapStateToProps>
> = ({
  action,
  appId,
  showNotificationError,
  showNotificationSuccess,
  storeGroupId,
  storeId,
  translate,
}) => {
  const [pickupEnabled, setPickupEnabled] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const menus = useQuery({
    queryKey: ['getMenus', 'storeValidation', appId],

    queryFn: () => {
      return menusService.getMenus(appId!);
    },

    enabled: action === 'MenuAssigned' && !!appId,
  });
  const menuOptionsForSelect = useMemo(
    () =>
      menus.data?.map((m) => ({
        label: m.Name ? m.Name : `${m.MenuId}`,
        value: m.MenuId!,
      })),
    [menus]
  );

  const enablePickup = useMutation({
    mutationFn: ({ checked }: { checked: boolean }) => {
      setPickupEnabled(true);
      return storeService.update(storeId, { PickupEnabled: checked });
    },

    onSuccess: () => {
      showNotificationSuccess('Success');
      queryClient.invalidateQueries({ queryKey: [kioskServices.getStoreValidationsQueryKey] });
    },

    onError: () => {
      setPickupEnabled(false);
      showNotificationError();
    },
  });

  const setMenu = useMutation({
    mutationFn: ({ menuId }: { menuId: number }) => {
      return assignMenu(storeId, menuId);
    },

    onSuccess: () => {
      showNotificationSuccess('Success');
      queryClient.invalidateQueries({ queryKey: [kioskServices.getStoreValidationsQueryKey] });
    },

    onError: () => {
      showNotificationError();
    },
  });

  const handleInputChange = (selectedMenu: OptionType) => {
    setMenu.mutate({ menuId: selectedMenu.value as number });
  };

  switch (action) {
    case 'PickupEnabled':
      return (
        <Switch
          color="default"
          inputProps={
            {
              'data-fd': `${action}_switch`,
              'data-fd-value': !!pickupEnabled,
            } as React.InputHTMLAttributes<HTMLInputElement>
          }
          name={action}
          onChange={(e) => enablePickup.mutate({ checked: e.target.checked })}
          checked={pickupEnabled}
        />
      );
    case 'BankAccountAttached':
      return (
        <RenderLinkButton
          fdKey="Go_to_banking"
          link={`/${appId}/storegroups/${storeGroupId}/stores/${storeId}/bankingsettings`}
          linkText="Go_to_banking"
        />
      );
    case 'HasFullAddress':
      return (
        <RenderLinkButton
          fdKey="Go_to_store_general_settings"
          link={`/${appId}/storegroups/${storeGroupId}/stores/${storeId}/general`}
          linkText="Kiosk_add_store_address"
        />
      );
    case 'PickupHours':
      return (
        <RenderLinkButton
          fdKey="Go_to_openinghours"
          link={`/${appId}/storegroups/${storeGroupId}/stores/${storeId}/openinghours`}
          linkText="Kiosk_set_opening_hours"
        />
      );
    case 'IsPublished':
      return (
        <RenderLinkButton
          fdKey="Go_to_store_settings"
          link={`/${appId}/storegroups/${storeGroupId}/stores/${storeId}`}
          linkText="Go_to_setup"
        />
      );
    case 'MenuAssigned':
      return (
        <Select
          dataFd="Assign_menu_to_storeddd"
          onChange={handleInputChange}
          options={menuOptionsForSelect}
          placeholder={`${translate('Kiosk_select_menu')}`}
          TextFieldProps={{
            fdKey: 'Assign_menu_to_store',
            variant: 'standard',
            inputProps: { style: { paddingLeft: 0 } },
          }}
        />
      );
    default:
      return <></>;
  }
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state.currentApp.AppId,
    translate: getTranslate(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  showNotificationError: (message?: TranslationId) =>
    dispatch(
      notifyError({
        message: message || 'Error_please_try_again_later',
        translate: true,
      })
    ),
  showNotificationSuccess: (message: TranslationId) => {
    dispatch(
      notify({
        message,
        variant: 'success',
        translate: true,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvalidStoreActionItem);
