import React, { useState } from 'react';

import Add from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps } from 'react-router';

import { Button } from '@fd/ui/atoms/Button';
import StoreFilter from '@fd/ui/Filter/StoreFilter';

import { useQuerySalesChannelsHook } from '../../../custom-hooks/useQuerySalesChannelsHook';
import { channelsService } from '../../../services/channels.service';
import PageLayout from '../../../ui/Layout';
import DeleteMappingModal from '../components/DeleteMappingModal/DeleteMappingModal';
import StoreMappingModal from '../components/StoreMappingModal/StoreMappingModal';

const useStyles = makeStyles((theme: Theme) => ({
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    width: 196,
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  fitler: {
    width: 481,
  },
}));

type Props = RouteComponentProps<{ channel: string }>;

export const StoreMapping = ({ appId, channelParam, translate }: Props & MappedState) => {
  const classes = useStyles();

  const [showStoreMappingModal, setShowStoreMappingModal] = useState(false);

  const [showEditStoreMappingModal, setshowEdittoreMappingModal] = useState(false);

  const [showDeleteMappingModal, setShowDeleteMappingModal] = useState(false);

  const { data: channelData } = useQuerySalesChannelsHook(
    () => channelsService.getChannel(parseInt(channelParam), appId),
    'channel',
    channelParam !== undefined && appId !== undefined,
    true
  );

  return (
    <PageLayout
      documentTitle="Store_mapping"
      title={<Translate id="Store_mapping" />}
      toParent={`/${appId}/sales-channels/${channelParam}`}
    >
      <div className={classes.pageContent}>
        <Typography variant="caption">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in volupt
        </Typography>
        <Button
          className={classes.button}
          fdKey="add-new-mapping-button"
          onClick={() => setShowStoreMappingModal(true)}
        >
          <Add />
          <Translate id="Add_new_mapping" />
        </Button>
        <Divider className={classes.divider} />
        <div className={classes.fitler} data-fd="store-mapping-filter">
          <StoreFilter isMulti />
        </div>
      </div>

      {showStoreMappingModal && channelData?.Data && (
        <StoreMappingModal
          leftButtonTranslationId="Cancel"
          leftButtonAction={() => setShowStoreMappingModal(false)}
          rightButtonTranslationId="Add"
          rightButtonAction={() => null}
          titleId="New_mapping"
          subTitleId={'Map_a_flipdish_store_to'}
          channel={channelData.Data}
          translate={translate}
        />
      )}

      {showEditStoreMappingModal && channelData?.Data && (
        <StoreMappingModal
          leftButtonTranslationId="Cancel"
          leftButtonAction={() => setshowEdittoreMappingModal(false)}
          rightButtonTranslationId="Save"
          rightButtonAction={() => null}
          titleId="Edit_mapping"
          subTitleId="Change_store_or_external_id"
          channel={channelData.Data}
          translate={translate}
        />
      )}

      {showDeleteMappingModal && <DeleteMappingModal />}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  return {
    channelParam: props.match.params.channel,
    appId: state.currentApp.AppId as string,
    translate: getTranslate(state),
  };
};

export default connect(mapStateToProps)(StoreMapping);
