import React, { useCallback, useMemo } from 'react';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PageLayout, { HORIZONTAL_SPACE_CLASSNAME } from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import ListItemLink from '@fd/ui/List/ListItemLink';

import defaultDashboardIcon from '../../../assets/images/ico_custom_dashboard.svg';
import orderReportIcon from '../../../assets/images/ico_reports_orders.svg';
import reportOverviewIcon from '../../../assets/images/ico_reports_overview.svg';
import reportsEndOfDayIcon from '../../../assets/images/reports-end-of-day.svg';
import { type IAppState } from '../../../reducers/root.reducer';
import { REPORT_ICONS_URL_PREFIX } from '../constants';
import { getLookerDashboards } from '../OrderReport.actions';

const useStyles = makeStyles((theme: Theme) => ({
  dashboardGroupContainer: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    margin: '16px',
    marginLeft: '0',
    padding: '0px',
    minWidth: '0',
  },
}));

type DashboardLink = {
  title: TranslationId | string;
  summary?: TranslationId | string;
  path: string;
  icon?: JSX.Element;
  isCustom?: boolean;
};

type Props = MappedState & MappedDispatch;
const Dashboards = (props: Props) => {
  const { appId, getLookerDashboards } = props;
  const classes = useStyles();

  const getReportIcon = useCallback((dashboard: any) => {
    const src = dashboard.IconFileName
      ? REPORT_ICONS_URL_PREFIX + dashboard.IconFileName
      : defaultDashboardIcon;
    return (
      <img
        style={{ opacity: 0.54, color: 'rgba(0, 0, 0, 0.54)', width: 24, height: 24 }}
        src={src}
      />
    );
  }, []);

  const { data } = useQuery({
    queryKey: ['lookerDashboards'],

    queryFn: async () => {
      const res = await getLookerDashboards();
      return res
        .map((dashboard) => ({
          title: dashboard.Name,
          path: dashboard.IsWhiteLabelSpecific
            ? `dashboards/${dashboard.DashboardLink}`
            : `dashboards/global/${dashboard.DashboardLink}`,
          summary: dashboard.Summary,
          isCustom: dashboard.IsWhiteLabelSpecific,
          icon: getReportIcon(dashboard),
        }))
        .filter((dashboard) => dashboard.title !== 'Flipdish_phone_marketing_report_title');
    },

    initialData: () => [],
  });

  const dashboardLinks = useMemo<DashboardLink[]>(
    () => [
      {
        title: 'Overview',
        path: `/${appId}/reports/overview`,
        icon: <img style={{ opacity: 0.54, width: 24, height: 24 }} src={reportOverviewIcon} />,
        summary: 'Overview_report_description',
      },
      {
        title: 'Orders',
        path: `/${appId}/reports/orders`,
        icon: (
          <img
            style={{ opacity: 0.54, color: 'rgba(0, 0, 0, 0.54)', width: 24, height: 24 }}
            src={orderReportIcon}
          />
        ),
        summary: 'Orders_report_description',
      },
      {
        title: 'End_of_day_report',
        path: `/${appId}/reports/endofday`,
        icon: <img src={reportsEndOfDayIcon} />,
        summary: 'End_of_day_report_description',
      },
      ...(data || []),
    ],
    [data]
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageLayout title={<Translate id="All_dashboards" />} documentTitle="All_dashboards">
      <PaperContainer fluid className={classes.dashboardGroupContainer}>
        <List component="nav">
          {dashboardLinks.map((link, index) => (
            <ListItemLink
              button
              key={link.path}
              fdKey={`All_reports_${link.title}`}
              to={link.path}
              className={HORIZONTAL_SPACE_CLASSNAME}
              alignItems="center"
              divider={index !== dashboardLinks.length - 1 || isMobile}
            >
              {link.icon && <ListItemIcon className={classes.icon}>{link.icon}</ListItemIcon>}
              <ListItemText>
                <Typography variant="subtitle1">
                  {link.isCustom ? link.title : <Translate id={link.title as TranslationId} />}
                </Typography>
                {link.summary && (
                  <Typography variant="caption">
                    {link.isCustom ? (
                      link.summary
                    ) : (
                      <Translate id={link.summary as TranslationId} />
                    )}
                  </Typography>
                )}
              </ListItemText>
              <ListItemIcon>
                <KeyboardArrowRight />
              </ListItemIcon>
            </ListItemLink>
          ))}
        </List>
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: IAppState) => ({
  appId: state.currentApp.AppId,
  translate: getTranslate(state.locale),
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getLookerDashboards: () => dispatch(getLookerDashboards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);
