import * as React from 'react';

import { reduce } from 'lodash';

export function ellipsis(str: string, n: number): string {
  return str.length > n - 3 ? str.substring(0, n - 3) + '...' : str;
}

export const replaceNewlines = (text) => {
  if (!text) {
    return null;
  }
  const items = text.split('\n');
  return reduce(items, (result, value) => {
    return (
      <>
        {result}
        <br />
        {value}
      </>
    );
  });
};

export const toKebabCase = (str: string): string => {
  return str
    ?.replace(/([a-z])([A-Z])/g, '$1-$2')
    ?.replace(/\s+/g, '-')
    ?.toLowerCase();
};
