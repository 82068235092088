import React, { useState } from 'react';

import { OrderBatchingConfiguration } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

import { EnableSwitch } from '@fd/ui/atoms';

import GridContainer from '../../../ui/Layout/GridContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import OrderBatchingIcon from '../components/icons/OrderBatchingIcon';

const useStyles = makeStyles((theme: Theme) => ({
  infoRoot: {
    display: 'flex',
    padding: `${theme.spacing(4.5)}!important`,
    paddingTop: `${theme.spacing(2.5)}!important`,
    paddingBottom: `${theme.spacing(3.5)}!important`,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: `${theme.spacing(3)}!important`,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
    },
  },
  infoIcon: {
    backgroundColor: '#EAF2FF',
    borderRadius: '100%',
    height: 96,
    width: 96,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: 60,
      color: 'rgba(5, 20, 158, 0.8)',
    },
  },
  info: {
    paddingLeft: `${theme.spacing(4.5)}!important`,
    flex: 1,
    '& h1': {
      fontSize: 24,
      fontWeight: 500,
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    '& p': {
      lineHeight: 1.33,
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
  },
  root: {
    paddingBottom: `${theme.spacing(5)}!important`,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      paddingTop: `${theme.spacing(3)}!important`,
      paddingLeft: `${theme.spacing(3)}!important`,
      paddingRight: `${theme.spacing(3)}!important`,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
    },
  },
  sectionDivider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  },
  sectionTitleGrid: {
    '& label': {
      lineHeight: 1.5,
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0!important',
      '& label': {
        color: 'black',
      },
    },
  },
  sectionTitleGridNoDesc: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: `${theme.spacing(2)}!important`,
    },
  },
  formHelpText: {
    lineHeight: 1.33,
    marginTop: 0,
    marginBottom: theme.spacing(3),
  },
  timerLengthField: {
    width: 200,
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type Props = {
  orderBatchConfig?: OrderBatchingConfiguration;
  onOrderBatchConfigChange: (
    newOrderBatchConfig: OrderBatchingConfiguration,
    enableChanged: boolean
  ) => void;
};

const OrderBatchingForm = ({ orderBatchConfig, onOrderBatchConfigChange }: Props) => {
  const classes = useStyles();

  const [stateOrderBatchConfig, setStateOrderBatchConfig] = useState<OrderBatchingConfiguration>(
    () =>
      orderBatchConfig || {
        IsEnabled: false,
        BatchIntervalInSeconds: 60,
      }
  );

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    const newOrderBatchConfig = {
      ...stateOrderBatchConfig,
      [name]: checked,
    };

    setStateOrderBatchConfig(newOrderBatchConfig);
    onOrderBatchConfigChange(newOrderBatchConfig, true);
  };

  const handleTimerLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTimerLength = parseInt(event.target.value);
    const timerLength = stateOrderBatchConfig.BatchIntervalInSeconds;

    if (newTimerLength === timerLength) {
      return;
    }

    const newOrderBatchConfig = {
      ...stateOrderBatchConfig,
      BatchIntervalInSeconds: newTimerLength,
    };

    setStateOrderBatchConfig(newOrderBatchConfig);
    onOrderBatchConfigChange(newOrderBatchConfig, false);
  };

  return (
    <>
      <PaperContainer className={classes.infoRoot}>
        <div className={classes.infoIcon}>
          <OrderBatchingIcon />
        </div>
        <div className={classes.info}>
          <Typography variant="h1">
            <Translate id="Order_batching_info_title" />
          </Typography>
          <FormHelperText id="batch-info-desc">
            <Translate id="Order_batching_info_desc" />
          </FormHelperText>
          <Button color="primary" data-fd="order-batching-learn-more-button" type="submit">
            <Translate id="Learn_more" />
          </Button>
        </div>
      </PaperContainer>
      <PaperContainer className={classes.root}>
        <GridContainer
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            sm={5}
            className={clsx(
              classes.sectionTitleGrid,
              classes.sectionTitleGridNoDesc,
              classes.gridItem
            )}
          >
            <InputLabel>
              <Translate id="Order_batching_enable" />
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={7} className={classes.gridItem}>
            <EnableSwitch
              name="IsEnabled"
              checked={stateOrderBatchConfig.IsEnabled}
              onChange={handleSwitchChange}
              fdKey="orderbatching_enabled"
              autoFocus
            />
          </Grid>
        </GridContainer>
        {stateOrderBatchConfig.IsEnabled && (
          <>
            <Divider className={classes.sectionDivider} />
            <GridContainer
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                sm={5}
                className={clsx(classes.sectionTitleGrid, classes.gridItem)}
              >
                <InputLabel>
                  <Translate id="Batch_timer" />
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={7} className={classes.gridItem}>
                <FormHelperText className={classes.formHelpText} id="batch-timer-desc">
                  <Translate id="Batch_timer_desc" />
                </FormHelperText>
                <TextField
                  select
                  variant="outlined"
                  label={<Translate id="Timer_length" />}
                  value={stateOrderBatchConfig.BatchIntervalInSeconds}
                  className={classes.timerLengthField}
                  onChange={handleTimerLengthChange}
                >
                  {[1, 2, 3, 4, 5].map((val) => (
                    <MenuItem key={val} value={val * 60}>
                      <Translate
                        id={val === 1 ? 'Order_batching_minute' : 'Order_batching_minutes'}
                        data={{ value: val }}
                      />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </GridContainer>
          </>
        )}
      </PaperContainer>
    </>
  );
};

export default OrderBatchingForm;
