export default function copyToClipbboard(e, elementId) {
  const field = document.getElementById(elementId) as HTMLInputElement;
  if (field) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(field.value);
    } else {
      field.select();
      document.execCommand('copy');
    }
  }
}
