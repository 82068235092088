import React, { useState } from 'react';

import { useQuerySalesChannelsHook } from '@fd/customHooks/useQuerySalesChannelsHook';
import {
  App,
  CreateLocation,
  CreateLocationArea,
  LocationAreaWithLocations,
} from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import { PageHeader } from '@fd/ui/molecules/PageHeader';

import TableSettingsIcon from '../../../assets/images/tableSettings.svg';
import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { getSelectedStore } from '../../../selectors/store.selector';
import { flagService } from '../../../services';
import { storeService } from '../../../services/store.service';
import PageLayout from '../../../ui/Layout';
import AddSectionCard from './components/AddSectionCard';
import AddSectionModal from './components/AddSectionModal';
import SectionDisplay from './components/SectionDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  headerGrid: {
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  headerItems: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  loading: {
    margin: '10px',
  },
  icon: {
    width: 70,
    height: 70,
  },
  sectionHeaderText: {
    fontWeight: 400,
    fontSize: 16,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  sectionText: {
    fontWeight: 500,
    fontSize: 16,
    paddingBottom: theme.spacing(2),
  },
  buttonIcon: {
    marginRight: theme.spacing(1.5),
  },
  sectionBox: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',
    padding: theme.spacing(3),
    alignItems: 'center',
  },
}));

type Props = MappedStateToProps & MappedDispatch;

const TableSettings = (props: Props) => {
  const {
    appId,
    storeId,
    store,
    canEdit,
    translate,
    notifyError,
    notifySaving,
    notifySaved,
    closeNotifySaving,
    isTablePrefixEnabled,
  } = props;

  const [isOpenAddSectionModal, setIsOpenAddSectionModal] = useState<boolean>(false);
  const styles = useStyles();

  const refetchLocationAreas = () => {
    refetch();
  };

  const { data, isLoading, isError, refetch } = useQuerySalesChannelsHook(
    () => storeService.getLocationAreasForStore(appId, storeId),
    'locationAreas',
    appId !== undefined && storeId !== undefined,
    true
  );

  const handleAddSectionModal = () => {
    setIsOpenAddSectionModal(!isOpenAddSectionModal);
  };

  const createSection = async (
    locationArea: CreateLocationArea,
    numberOfLocations: number,
    startingNumber?: number,
    tablePrefix?: string
  ) => {
    try {
      notifySaving();
      const result = await storeService.createLocationArea(appId, storeId, locationArea);
      closeNotifySaving();

      if (numberOfLocations > 0) {
        createLocations(result, numberOfLocations, startingNumber, tablePrefix);
      } else {
        notifySaved();
        refetchLocationAreas();
      }

      handleAddSectionModal();
    } catch (e) {
      closeNotifySaving();
      const errorNotificatiion: NotifyProps = { message: e.message, translate: false };
      notifyError(errorNotificatiion);
    }
  };

  const createLocations = async (
    locationArea: LocationAreaWithLocations,
    numberOfLocations: number,
    startingNumber?: number,
    tablePrefix?: string
  ) => {
    const locations: CreateLocation[] = generateLocationArray(
      locationArea,
      numberOfLocations,
      startingNumber,
      tablePrefix
    );
    try {
      notifySaving();
      await storeService.createLocations(locationArea.LocationAreaId, appId, storeId, locations);
      closeNotifySaving();
      notifySaved();
      refetchLocationAreas();
    } catch (e) {
      closeNotifySaving();
      const errorNotificatiion: NotifyProps = { message: e.message, translate: false };
      notifyError(errorNotificatiion);
    }
  };

  const generateLocationArray = (
    locationArea: LocationAreaWithLocations,
    numberOfLocations: number,
    startingNumber?: number,
    tablePrefix?: string
  ) => {
    const locations: CreateLocation[] = [];
    let displayOrderIndex = 1;
    if (locationArea.Locations?.length) {
      displayOrderIndex =
        locationArea.Locations[locationArea.Locations.length - 1].DisplayOrder + 1;
    }

    if (locationArea.LocationAreaId) {
      for (let i = 0; i < numberOfLocations; i++) {
        let tableName = displayOrderIndex.toString();

        if (isTablePrefixEnabled && startingNumber) {
          const tableNumber = (startingNumber + i).toString();
          tableName = tablePrefix ? `${tablePrefix} ${tableNumber}` : tableNumber;
        }

        locations.push({
          DisplayOrder: displayOrderIndex,
          LocationName: tableName,
        });
        displayOrderIndex++;
      }
    }
    return locations;
  };

  if (isError) {
    return <Redirect to={'/' + appId + '/storegroups'} />;
  }

  return (
    <PageLayout
      toParent={store ? `/${appId}/storegroups/${store.StoreGroupId}/stores/${storeId}` : ''}
      caption={(store && store.Name) || ''}
      documentTitle="Table_settings_title"
      title={<Translate id="Table_settings_title" />}
    >
      {isLoading ? (
        <CircularProgress className={styles.loading} size={50} />
      ) : (
        <Grid container className={styles.grid}>
          <Grid item container className={styles.headerGrid} spacing={2}>
            <Grid item className={styles.headerItems}>
              <PageHeader
                heading={translate('Table_settings_title')}
                description={translate('Table_settings_page_description')}
                icon={<img src={TableSettingsIcon} className={styles.icon} />}
              />
            </Grid>

            <Grid item className={styles.headerItems}>
              <AddSectionCard onClick={handleAddSectionModal} canEdit={canEdit} />
            </Grid>
          </Grid>

          <Typography className={styles.sectionHeaderText}>
            <Translate id="Sections" />
          </Typography>

          {data && data.length ? (
            data.map((locationArea: LocationAreaWithLocations) => (
              <SectionDisplay
                key={locationArea.LocationAreaId}
                locationArea={locationArea}
                appId={appId}
                storeId={storeId}
                canEdit={canEdit}
                refetchLocationAreas={refetchLocationAreas}
                generateLocationArray={generateLocationArray}
                translate={translate}
                isTablePrefixEnabled={isTablePrefixEnabled}
              />
            ))
          ) : (
            <Grid container direction="column" className={styles.sectionBox}>
              <Typography className={styles.sectionText}>
                <Translate id="No_sections" />
              </Typography>

              <Button
                color="primary"
                variant="outlined"
                id="add-section-button"
                onClick={handleAddSectionModal}
                disabled={!canEdit}
              >
                <AddIcon className={styles.buttonIcon} />
                <Translate id="Add_new_section" />
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {isOpenAddSectionModal && (
        <AddSectionModal
          onClose={handleAddSectionModal}
          translate={translate}
          createSection={createSection}
          isTablePrefixEnabled={isTablePrefixEnabled}
        />
      )}
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySaving()),
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving({ persist: true })),
  notifySaved: () => dispatch(notifySaved()),
});

type MappedStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, ownProps) => {
  const { storeId } = ownProps.match.params;
  const store = getSelectedStore(state);
  const canEdit = state.permissions.some((p) => p === App.AppResourceSetEnum.EditStores.toString());
  const isTablePrefixEnabled = flagService.isFlagOn(state, 'tablePrefix');
  return {
    store,
    storeId,
    canEdit,
    appId: state.currentApp.AppId as string,
    translate: getTranslate(state.locale),
    isTablePrefixEnabled,
  };
};

const EnhancedComponent = compose<Props, {}>(
  setDisplayName('TableSettings'),
  connect(mapStateToProps, mapDispatchToProps)
)(TableSettings);

export default EnhancedComponent;
