import React, { useEffect } from 'react';

import { HomeAction } from '@flipdish/api-client-typescript';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Button } from '../../../ui/Button';
import LinkButton from '../../../ui/Button/LinkButton';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import ProfilePassword from '../../Profile/ProfilePassword';
import { dismiss as dismissAction, dismissSetupSuccess, loadSetupConfigs } from '../actions';
import { getSetupConfigs } from '../selectors';
import WebsiteCard from '../Website/WebsiteCard';
import ListItem from './ListItem';
import ListItemLoading from './ListItemLoading';
import UnsupportedConfigListItem from './UnsupportedConfigListItem';

const ActionLinkButton = ({ to, title, dataFd, disabled = false }) => (
  <LinkButton
    to={to}
    fdKey={dataFd}
    variant="contained"
    color="primary"
    fullWidth
    disabled={disabled}
  >
    <Translate id={title} />
  </LinkButton>
);

const LinkListItem = ({ data, isLast, onDismiss, to, dataFd }) => {
  const action =
    data.TitleKey === 'Set_your_password' ? (
      <ProfilePassword>
        {(onClick) => (
          <Button fullWidth fdKey={dataFd} color="primary" variant="contained" onClick={onClick}>
            <Translate id={data.TitleKey} />
          </Button>
        )}
      </ProfilePassword>
    ) : (
      <ActionLinkButton to={to} title={data.ActionKey} dataFd={dataFd} />
    );

  return (
    <ListItem dataFd={dataFd} data={data} isLast={isLast} onDismiss={onDismiss} action={action} />
  );
};

const ExternalLinkItem = ({ data, isLast, onDismiss, dataFd, ...other }) => (
  <ListItem
    dataFd={dataFd}
    data={data}
    isLast={isLast}
    onDismiss={onDismiss}
    action={
      <a href={data.Action} target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>
        <Button fullWidth color="primary" variant="contained" fdKey={dataFd}>
          <Translate id={data.ActionKey} />
        </Button>
      </a>
    }
    {...other}
  />
);

const StoreListItem = ({ data, isLast, onDismiss, to, dataFd }) => {
  return (
    <ListItem
      dataFd={`${dataFd}-${data.StoreName}`}
      data={data}
      titleData={{ name: data.StoreName }}
      isLast={isLast}
      onDismiss={onDismiss}
      action={
        <ActionLinkButton
          to={to}
          title={data.ActionKey}
          dataFd={`${dataFd}-${data.StoreName}`}
          // tslint:disable-next-line
          disabled={!data['StoreGroupId'] || !data['StoreId']}
        />
      }
    />
  );
};

const SetupConfigList = (props: Props) => {
  const { isEmpty, isOpen, setupConfigs, dismiss, loadSetup, appId, localDismiss } = props;

  useEffect(() => {
    if (isOpen) {
      loadSetup();
    }
  }, [isOpen]);

  return (
    <>
      {setupConfigs.topCard && (
        <WebsiteCard
          homeActionId={setupConfigs.topCard.HomeActionId ? setupConfigs.topCard.HomeActionId : 0}
          to={`/${appId}/website/live-editor`}
          key={'website'}
        />
      )}
      <Collapse in={isOpen}>
        <PaperContainer fluid>
          <List disablePadding>
            {isEmpty ? (
              <ListItemLoading key={'loading'} />
            ) : (
              setupConfigs.cards.map((cfg, idx) => {
                const isLast = idx + 1 === setupConfigs.cards.length;

                switch (cfg.Action) {
                  case '/bankaccount/create': {
                    const to = `/${appId}/finance/bank-accounts/new`;
                    return (
                      <LinkListItem
                        key={idx}
                        data={cfg}
                        dataFd="setup-bank-account"
                        isLast={isLast}
                        onDismiss={dismiss}
                        to={to}
                      />
                    );
                  }
                  case '/teammate/create': {
                    const to = `/${appId}/teammates/new`;
                    return (
                      <LinkListItem
                        key={idx}
                        data={cfg}
                        dataFd="invite-teammate"
                        isLast={isLast}
                        onDismiss={dismiss}
                        to={to}
                      />
                    );
                  }
                  case '/user/update/password': {
                    const to = `/${appId}/profile`;
                    return (
                      <LinkListItem
                        key={idx}
                        data={cfg}
                        dataFd="set-password"
                        isLast={isLast}
                        onDismiss={localDismiss}
                        to={to}
                      />
                    );
                  }

                  // store routes
                  case '/store/create/businesshours': {
                    // tslint:disable-next-line
                    const sgId = cfg['StoreGroupId'];
                    // tslint:disable-next-line
                    const sId = cfg['StoreId'];
                    const to = `/${appId}/storegroups/${sgId}/stores/${sId}/openinghours`;
                    return (
                      <StoreListItem
                        key={idx}
                        data={cfg}
                        dataFd="setup-opening-hours"
                        isLast={isLast}
                        onDismiss={dismiss}
                        to={to}
                      />
                    );
                  }
                  case '/store/create/deliveryzones': {
                    // tslint:disable-next-line
                    const sgId = cfg['StoreGroupId'];
                    // tslint:disable-next-line
                    const sId = cfg['StoreId'];
                    const to = `/${appId}/storegroups/${sgId}/stores/${sId}/deliveryzones`;
                    return (
                      <StoreListItem
                        key={idx}
                        data={cfg}
                        dataFd="create-delivery-zones"
                        isLast={isLast}
                        onDismiss={dismiss}
                        to={to}
                      />
                    );
                  }

                  default:
                    break;
                }

                if (
                  cfg.HomeActionType === HomeAction.HomeActionTypeEnum.External &&
                  cfg.TitleKey !== 'Website_link_title'
                ) {
                  return (
                    <ExternalLinkItem
                      key={idx}
                      data={cfg}
                      dataFd="set-password"
                      isLast={isLast}
                      onDismiss={dismiss}
                    />
                  );
                }

                return <UnsupportedConfigListItem key={idx} data={cfg} />;
              })
            )}
          </List>
        </PaperContainer>
      </Collapse>
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> & MappedDispatch;
const mapStateToProps = (state: AppState) => {
  const isEmpty = state.home.setupConfigs.isEmpty;
  const appId = state.currentApp.AppId;

  return {
    appId,
    setupConfigs: getSetupConfigs(state),
    isOpen: !isEmpty && appId !== 'flipdish-global',
    isEmpty,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dismiss: (homeActionId: number) => dispatch(dismissAction(homeActionId)),
  localDismiss: (homeAction: string) => dispatch(dismissSetupSuccess(homeAction)),
  loadSetup: () => {
    dispatch(loadSetupConfigs());
  },
});

export default compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(SetupConfigList);
