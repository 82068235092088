import React from 'react';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles(() => ({
  textTitle: {
    fontSize: '36px',
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
}));

const BankAccount = () => {
  const classes = useStyles();
  return (
    <div className={classes.topContent}>
      <Typography color="primary" className={classes.textTitle}>
        <Translate id="Bank_accounts" />
      </Typography>
      <Typography variant="body1">
        <Translate id="Press_next_in_order_to_add_and_update_your_banking" />
      </Typography>
    </div>
  );
};

export default BankAccount;
