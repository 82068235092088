export const domainTag = 'frontend_portal_';

export const frontEndFlags = [
  'addChannel',
  'addStoresButton',
  'alertBannerInvoice',
  'campaignForceDiscount',
  'createCampaignAndVoucherBtn',
  'deliveryLeadTime',
  'firstSetupV2',
  'freeDeliveryFeeVoucher',
  'hideSetPasswordButton',
  'internationalisedAddress',
  'isAuth0Enabled',
  'loginRedirectToAuth0Login',
  'loyaltyCampaignVoucherSwitch',
  'menuItemVoucher',
  'nestedModifiers',
  'orderFulfillmentStatusConfigurations',
  'orderFulfillmentStatusOrderDetails',
  'paygreenFeatures',
  'refreshFDAuthorizationToken',
  'showBannerAlertForIntegratedMenus',
  'showMenuEditor',
  'showPayoutsStoreOptions',
  'showPosInPayoutReport',
  'showRMS',
  'showRMSAccountsAddOn',
  'showRMSDocumentsAddOn',
  'showRMSInventoryAddOn',
  'showRMSPayrollAddOn',
  'showRMSFoodSafetyAddOn',
  'showAddOnsBasedOnRMSSubscriptions',
  'storeOrderBatching',
  'tablePrefix',
  'tippingCustom',
  'tippingReduceValues',
  'tippingRoundUp',
  'useInjectedRecaptchaKey',
  'useNewPayoutV3Report',
  'voucherChannel',
  'voucherDiscoverable',
  'voucherLimitAvailability',
  'voucherValidPeriod',
] as const;

export type FrontEndFlag = (typeof frontEndFlags)[number];
