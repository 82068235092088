import React, { CSSProperties, useMemo, useState } from 'react';

import { Coordinates, Store } from '@flipdish/api-client-typescript';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import NoSsr from '@mui/material/NoSsr';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { Translate } from 'react-localize-redux';
import RsSelect, { components as rsComponents } from 'react-select';
import { SelectComponents } from 'react-select/lib/components';
import { ValueContainerProps } from 'react-select/lib/components/containers';
import { ControlProps } from 'react-select/lib/components/Control';
import { IndicatorProps } from 'react-select/lib/components/indicators';
import { NoticeProps } from 'react-select/lib/components/Menu';
import { MultiValueProps } from 'react-select/lib/components/MultiValue';
import { OptionProps } from 'react-select/lib/components/Option';
import { PlaceholderProps } from 'react-select/lib/components/Placeholder';
import { SingleValueProps } from 'react-select/lib/components/SingleValue';
import { Props as RsProps } from 'react-select/lib/Select';
import {
  InputActionMeta as rsInputActionMeta,
  ValueType as rsValueType,
} from 'react-select/lib/types';

import { ellipsis } from '../../helpers/strings';
import Loading from '../Loading';
import TextField, { TextFieldProps } from '../TextField/TextField';
import Tooltip from '../Tooltip/Tooltip';

export type OptionType = {
  coordinates?: Coordinates;
  currency?: Store.CurrencyEnum;
  isDisabled?: boolean;
  isGroup?: boolean;
  isHidden?: boolean;
  isNonClearable?: boolean;
  label: string;
  value: string | number;
  valueEndAdornment?: Store.CurrencyEnum | string;
};

export type SingeValueType = OptionType | undefined;
export type MultipleValueType = OptionType[] | undefined;
export type ValueType = rsValueType<OptionType>;
export type InputActionMeta = rsInputActionMeta;

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid',
      borderColor: theme.palette.action.disabled,
    },
  },
  input: {
    display: 'flex',
    height: 'auto',
    padding: theme.spacing(1, 0, 1, 2),
  },
  multiInput: {
    padding: theme.spacing(1, 0, 1, 1),
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    height: '32px',
    borderRadius: '16px',
    backgroundColor: theme.palette.grey[300],
    zIndex: 32,
  },
  chipFocused: {
    // TODO: emphasize is not available in MUI v5
    // backgroundColor: emphasize(
    //   theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
    //   0.08
    // ),
    backgroundColor: theme.palette.grey[300],
  },
  chipDisabled: {
    '&.MuiChip-root.Mui-disabled': {
      opacity: 0.76,
    },
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    padding: theme.spacing(1, 0, 1, 0),
    left: theme.spacing(2),
  },
  placeholderMulti: {
    padding: theme.spacing(1, 0, 1, 1),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  clearIcon: {
    paddingTop: theme.spacing(0.5),
    color: 'rgba(0,0,0,0.54)',
    cursor: 'pointer',
  },
  cancelIcon: {
    color: 'rgba(0,0,0,0.54)',
  },
  dropdownIcon: {
    color: 'rgba(0,0,0,0.54)',
    cursor: 'pointer',
  },
  inputStandard: {
    padding: 0,
    marginTop: 0,
    marginBottom: theme.spacing(0.5),
  },
  placeholderStandard: {
    position: 'absolute',
    padding: 0,
    left: 0,
    bottom: theme.spacing(1),
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.38)',
    },
  },
}));

export const ClearIndicator = (props) => {
  const classes = useStyles();
  const { isFocused, innerProps } = props;

  return (
    isFocused && (
      <div {...innerProps}>
        <CloseIcon className={classes.clearIcon} />
      </div>
    )
  );
};

export const DropdownIndicator = (props: IndicatorProps<OptionType>) => {
  const classes = useStyles();
  const {
    selectProps: { isDisabled, dropdownIcon },
  } = props;

  if (isDisabled) {
    return null;
  }

  return (
    rsComponents.DropdownIndicator && (
      <rsComponents.DropdownIndicator {...props}>
        {dropdownIcon === 'search' ? (
          <SearchIcon className={classes.dropdownIcon} data-fd="select-searchIcon" />
        ) : (
          <ArrowDropDownIcon className={classes.dropdownIcon} data-fd="select-dropdownIcon" />
        )}
      </rsComponents.DropdownIndicator>
    )
  );
};

export const LoadingIndicator = () => <Loading size={20} />;

export const LoadingMessage = (props) => {
  const { children } = props;

  return (
    <Typography align="center">
      {children === 'Loading...' ? (
        <Translate>{(translate) => `${translate('Loading')}...`}</Translate>
      ) : (
        children
      )}
    </Typography>
  );
};

export const NoOptionsMessage = (props: NoticeProps<OptionType>) => {
  const classes = useStyles();
  const { innerProps, children } = props;

  return (
    <Typography variant="body1" className={classes.noOptionsMessage} {...innerProps}>
      {children === 'No options' ? (
        <Translate>{(translate) => `${translate('No_options')}...`}</Translate>
      ) : (
        children
      )}
    </Typography>
  );
};

export const inputComponent = React.forwardRef<HTMLDivElement, any>((props, ref) => {
  return <div ref={ref} {...props} />;
});

export const Control = (props: ControlProps<OptionType>) => {
  const {
    children,
    innerProps,
    innerRef,
    isDisabled,
    isMulti,
    selectProps: {
      variant,
      setSearchQuery,
      textFieldClassName,
      TextFieldProps,
      fieldError,
      noErrorTranslate,
    },
  } = props;
  const classes = useStyles({ hasLabel: !!TextFieldProps.label });

  const isStandardVariant = variant === 'standard';
  const isFieldError = !!fieldError;

  const controlStyle = clsx(
    classes.input,
    {
      [classes.multiInput]: isMulti,
      [classes.inputStandard]: isStandardVariant,
    },
    textFieldClassName
  );

  return (
    <TextField
      aria-label={TextFieldProps.label}
      className={classes.textField}
      data-fd={TextFieldProps.name}
      disabled={isDisabled}
      error={isFieldError}
      fullWidth={TextFieldProps.isFullWidth}
      helperText={
        isFieldError ? (
          noErrorTranslate === true ? (
            fieldError
          ) : (
            <Translate id={typeof fieldError === 'string' ? fieldError : fieldError.props.id} />
          )
        ) : undefined
      }
      minWidth={TextFieldProps.minWidth}
      InputProps={{
        inputComponent,
        inputRef: innerRef,
        inputProps: {
          className: controlStyle,
          children,
          ...innerProps,
        },
      }}
      onChange={setSearchQuery ? (e) => setSearchQuery(e.target.value) : null}
      {...TextFieldProps}
    />
  );
};

export const Option = (props: OptionProps<OptionType>) => {
  const {
    children,
    data: { label, isDisabled, isGroup },
    innerProps,
    innerRef,
    isFocused,
    selectProps: { dataFd, options },
  } = props;
  const firstGroup = options.find((option: OptionType) => option.isGroup === true) as OptionType;
  const isFirstGroup = firstGroup ? firstGroup.label === children : false;

  return (
    <MenuItem
      //@ts-ignore
      component="div"
      data-fd={`${dataFd}-${label}`}
      disabled={isDisabled}
      ref={innerRef as any}
      selected={isFocused}
      style={{
        borderTop: isGroup && !isFirstGroup ? '1px solid #e0e0e0' : 'none',
        fontWeight: isGroup ? 'bold' : 'normal',
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
};

export const Placeholder = (props: PlaceholderProps<OptionType>) => {
  const classes = useStyles();
  const {
    children,
    innerProps,
    selectProps: { variant, isDisabled, dropdownIcon, isMulti },
  } = props;
  const isStandardVariant = variant === 'standard';

  return (
    <Typography
      variant="subtitle1"
      component="span"
      className={clsx(classes.placeholder, {
        [classes.placeholderMulti]: isMulti,
        [classes.placeholderStandard]: isStandardVariant,
        [classes.disabled]: isDisabled || dropdownIcon === 'search',
      })}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

export const SingleValue = (props: SingleValueProps<OptionType>) => {
  const classes = useStyles();
  const {
    children,
    data: { valueEndAdornment },
    innerProps,
    isDisabled,
  } = props;

  const classNames = isDisabled ? clsx(classes.disabled, classes.singleValue) : classes.singleValue;

  return (
    <Typography className={classNames} {...innerProps}>
      {valueEndAdornment ? `${children} (${valueEndAdornment})` : children}
    </Typography>
  );
};

export const ValueContainer = (props: ValueContainerProps<OptionType>) => {
  const classes = useStyles();
  const {
    isMulti,
    hasValue,
    getValue,
    children,
    selectProps: { menuIsOpen, maxChips, dataFd },
  } = props;

  const values = getValue() as OptionType[];
  const chipsLength = values.length;
  const chipsLimit = maxChips ? maxChips : 2;
  const showReducedChips =
    !menuIsOpen &&
    children &&
    children[0].length > chipsLimit &&
    children[1] &&
    hasValue &&
    isMulti &&
    chipsLength > chipsLimit;

  const getReducedChips = (children) => {
    const reducedChips = [children[0].slice(0, chipsLimit), children[1]];
    return reducedChips;
  };

  return (
    <div className={classes.valueContainer} data-fd={dataFd}>
      {showReducedChips ? getReducedChips(children) : children}
      {showReducedChips && '+' + (chipsLength - chipsLimit)}
    </div>
  );
};

export const MultiValue = (props: MultiValueProps<OptionType>) => {
  const classes = useStyles();
  const {
    children,
    isDisabled,
    isFocused,
    removeProps,
    data: { valueEndAdornment, isNonClearable },
  } = props;
  const chipLabel = children as string;
  const maxChipWidth = 26;
  const isMaxChipWidth = chipLabel && chipLabel.length > maxChipWidth - 3; // -3 for chars '...' added to end of chip
  const showDeleteIcon = isNonClearable || isDisabled;

  const getLabel = () => {
    if (valueEndAdornment) {
      return isMaxChipWidth
        ? `${ellipsis(chipLabel, maxChipWidth)} (${valueEndAdornment})`
        : `${chipLabel} (${valueEndAdornment})`;
    }

    return isMaxChipWidth ? ellipsis(chipLabel, maxChipWidth) : chipLabel;
  };

  return (
    <Tooltip
      disableHoverListener={!isMaxChipWidth}
      disableTouchListener={!isMaxChipWidth}
      fdKey={`${chipLabel}-tooltip`}
      labelText={chipLabel}
    >
      <Chip
        className={clsx(classes.chip, {
          [classes.chipFocused]: isFocused,
          [classes.chipDisabled]: isDisabled,
        })}
        deleteIcon={
          <CancelIcon
            {...removeProps}
            className={classes.cancelIcon}
            data-fd={`${chipLabel}-delete`}
          />
        }
        disabled={isDisabled}
        label={getLabel()}
        onDelete={showDeleteIcon ? undefined : removeProps.onClick}
        tabIndex={-1}
      />
    </Tooltip>
  );
};

export const components: SelectComponents<OptionType> = {
  ...rsComponents,
  ClearIndicator,
  Control,
  DropdownIndicator,
  IndicatorSeparator: () => null,
  LoadingIndicator,
  LoadingMessage,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

export type SelectProps = RsProps<OptionType> & {
  dataFd?: string;
  dropdownIcon?: 'search' | 'default';
  fieldError?: {} | string | undefined;
  isClearable?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  label?: string;
  maxChips?: number;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  minWidth?: TextFieldProps['minWidth'];
  options?: OptionType[];
  placeholder?: string;
  setSearchQuery?: (eventValue: string) => void;
  textFieldClassName?: string;
  TextFieldProps: TextFieldProps;
  variant?: 'outlined' | 'standard';
};

const Select = (props: SelectProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isFullWidth = true, minWidth = 'auto', TextFieldProps, value, ...rest } = props;
  const {
    dataFd,
    isClearable,
    isDisabled,
    isLoading,
    isMulti,
    label,
    menuPlacement,
    options,
    placeholder,
    variant,
  } = rest;

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    container: (base: CSSProperties) => ({
      ...base,
      '& fieldset': {
        borderColor: isDisabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.54)',
      },
    }),
    menu: (base: CSSProperties) => ({
      ...base,
      zIndex: 10,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    }),
  };

  const [inputQuery, setInputQuery] = useState('');

  const handleInputChange = useMemo(
    () =>
      debounce((query: string) => {
        setInputQuery(query);
      }, 1000),
    []
  );

  const onInputChange = (inputValue: string, { action, prevInputValue }) => {
    if (action === 'input-change') {
      handleInputChange(inputValue);
    } else if (action === 'menu-close') {
      setInputQuery('');
    } else {
      return inputQuery;
    }
  };

  return (
    <NoSsr>
      <RsSelect
        classes={classes}
        closeMenuOnSelect={!isMulti}
        components={components}
        dataFd={dataFd}
        inputId="react-select"
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        menuPlacement={menuPlacement ? menuPlacement : 'bottom'}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder}
        styles={selectStyles}
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: 'react-select',
            shrink: true,
            className: isDisabled ? classes.disabled : '',
          },
          isFullWidth,
          label: label,
          minWidth,
          style: { zIndex: 0 },
          variant: variant ? variant : 'outlined',
          ...TextFieldProps,
        }}
        value={value}
        {...rest}
      />
    </NoSsr>
  );
};

export default Select;
