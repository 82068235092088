const INTERCOM_ID = process.env.VITE_INTERCOM_ID;

let _disabled_ = false;
let _initialized_: boolean;
function initialize(disable = false) {
  if (document.cookie.indexOf('intercom-id') === -1 && disable) {
    return;
  }

  if (!INTERCOM_ID) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `[Intercom] to enable Intercom in dev time add 'VITE_INTERCOM_ID='ca9u6xd0'' to '.env.development.local' file`
      );
    }
    return;
  }

  _disabled_ = disable;
  if (disable && _initialized_ && window.Intercom) {
    window.Intercom('shutdown'); // NOT TESTED, presuming this is this kill switch
  }

  if (_initialized_) {
    return;
  }
  _initialized_ = false;

  // @ts-ignore
  window.intercomSettings = {
    app_id: INTERCOM_ID,
    // alignment: 'left',
    // hide_default_launcher: true,
    horizontal_padding: 25,
    vertical_padding: 25,
  };

  /* eslint-disable */
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      // @ts-ignore
      ic('reattach_activator');
      // @ts-ignore
      ic('update', w.intercomSettings);
    } else {
      var d = document;
    }
    var i =
      i ||
      function () {
        i.c(arguments);
      };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${INTERCOM_ID}`;
      var x = x || d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);

      _initialized_ = true;
    };
    w.addEventListener('load', l, false);
  })();
  /* eslint-enable */
}

type BootProps = Pick<
  Required<Flipdish.AccountDetail>,
  'AccountId' | 'Email' | 'Name' | 'IsSelfServeUser'
> &
  Pick<Required<Flipdish.IntercomUserHash>, 'UserHash'> &
  Pick<Required<Flipdish.App>, 'AppId'>;
function boot(props: BootProps) {
  if (INTERCOM_ID && !_disabled_) {
    window.Intercom('boot', {
      app_id: INTERCOM_ID,
      name: props.Name,
      email: props.Email,
      user_id: props.AccountId.toString(),
      is_self_serve_user: props.IsSelfServeUser,
      AppNameId: props.AppId,
      // created_at: '123456789',
      user_hash: props.UserHash,
      utm_campaign:
        'https://app.intercom.io/a/apps/' + INTERCOM_ID + '/messages/messages/auto/336662276',
      // custom_launcher_selector: element ID to open Intercom from anywhere (ie '#my_custom_link')
      // ie: <a id="my_custom_link" href="mailto:q0102c9w@intercom-mail.com">Support</a>
    });
  }
}

export const intercom = { initialize, boot };
