import React, { useState } from 'react';

import { AddressFormResponse, SupportedCountry } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FormikErrors, type FormikHandlers, type FormikTouched } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { addressService } from '../../../services/address.service';
import { flagService } from '../../../services/flagService';
import { storeService } from '../../../services/store.service';
import { DynamicAddressForm } from '../../DynamicAddress';
import ActionButtonsOnMapForm from './ActionButtonsOnMapForm';
import AddressFields from './AddressFields';
import { mapFormDataAddress } from './helper';
import { type OnboardingFormValues } from './StoreLocation';

type OuterProps = {
  coordinates: { Latitude: number; Longitude: number };
  errors: FormikErrors<OnboardingFormValues>;
  getAccount: () => any;
  goBack: () => void;
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  isSubmitting: boolean;
  isValid?: boolean;
  storeId?: number;
  storeName: string;
  supportedCountry: SupportedCountry | null;
  touched: FormikTouched<OnboardingFormValues>;
  translate: TranslateFunction;
  values: OnboardingFormValues;
};

type Props = OuterProps & MappedState & MappedDispatch;

export const ConfirmationForm = (props: Props) => {
  const {
    dispatchCloseNotifySaving,
    dispatchNotifyError,
    dispatchNotifySaved,
    dispatchNotifySaving,
    errors,
    getAccount,
    goBack,
    handleBlur,
    handleChange,
    coordinates,
    internationalisedAddressConfig,
    isSubmitting,
    isValid,
    storeId,
    storeName,
    supportedCountry,
    touched,
    translate,
    values,
  } = props;
  const queryClient = useQueryClient();

  const data = queryClient.getQueryData<AddressFormResponse>([
    addressService.getGoogleAddressQueryKey,
  ]);
  const countryCode = supportedCountry?.CountryCode || '';

  const useDynamicAddressFields =
    internationalisedAddressConfig?.affectedCountries?.includes(countryCode);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const menuPosition = isMobile ? 'bottom' : 'top';

  const { mutateAsync: updateAddressForm, isError: updateAddressFormError } = useMutation({
    mutationFn: (addressFields: { [key: string]: any }) => {
      if (!storeId) {
        throw new Error('Store ID is required');
      }

      dispatchNotifySaving();
      return storeService.updateAddressForm(storeId, {
        AddressFields: addressFields,
        CountryCode: countryCode,
      });
    },

    onError: () => {
      dispatchCloseNotifySaving();
      setIsLoading(false);
      dispatchNotifyError({ message: 'Something_went_wrong' });
    },
  });

  const { mutateAsync: updateCoordinates, isError: updateCoordinatesError } = useMutation({
    mutationFn: (coordinates: { [key: string]: any }) => {
      if (!storeId) {
        throw new Error('Store ID is required');
      }

      return storeService.updateStoreAddressCoordinates(storeId, coordinates);
    },

    onSuccess: async () => {
      dispatchCloseNotifySaving();
      dispatchNotifySaved();
    },

    onError: () => {
      dispatchCloseNotifySaving();
      setIsLoading(false);
      dispatchNotifyError({ message: 'Something_went_wrong' });
    },
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      setIsLoading(true);
      await updateAddressForm(values);
      if (!updateAddressFormError) {
        await updateCoordinates(coordinates);
        if (!updateCoordinatesError) {
          await getAccount();
        }
      }
    } catch (error) {
      // error is "caught" here first and then the onError code in the useMutation hook is executed
    } finally {
      if (updateAddressFormError || updateCoordinatesError) {
        setSubmitting(false);
      }
    }
  };

  return (
    <Grid container m={-1.5} width={`calc(100% + 24px)`}>
      <Grid item xs={12} p={1.5}>
        <Typography variant="subtitle1">
          <Translate id="Store" />: {storeName}
        </Typography>
      </Grid>
      {useDynamicAddressFields ? (
        <DynamicAddressForm
          disabled={isSubmitting || isLoading}
          displayFullWidthForm={true}
          error={!data}
          form={data?.FormData}
          goBack={goBack}
          handleSubmit={handleSubmit}
          initialValues={mapFormDataAddress(data || {})}
          isValid={isValid}
          menuPlacement={menuPosition}
          showActionButtonsOnMapForm={true}
          translate={translate}
        />
      ) : (
        <>
          <AddressFields
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            supportedCountry={supportedCountry}
            touched={touched}
            values={values}
          />
          <ActionButtonsOnMapForm isSubmitting={isSubmitting} goBack={goBack} isValid={isValid} />
        </>
      )}
    </Grid>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  const internationalisedAddressConfig = flagService.getSplitValueConfig(
    state,
    'internationalisedAddress'
  )?.config;
  return {
    appId: currentApp.AppId!,
    internationalisedAddressConfig,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchCloseNotifySaving: () => dispatch(closeNotifySaving()),
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaving: () => dispatch(notifySaving({ persist: true })),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationForm);
