import * as React from 'react';

import { type StyledComponentProps } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';

import { LoadingButton } from '../../../ui/LoadingButton';

const styles = (theme: any): any => ({
  buttonWrapper: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    backgroundColor: '#ff0046',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#b20031',
    },
    '&:active': {
      backgroundColor: '#b20031',
    },
    letterSpacing: '1.4px',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      letterSpacing: '1.3px',
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(6),
    },
  },
});

const buttonProps = {
  color: 'primary',
  variant: 'contained',
  type: 'submit',
  fullWidth: true,
  className: '',
  'data-fd': '',
  size: 'large',
};

export interface IProps {
  resource: string;
  loading?: boolean;
  disabled?: boolean;
  'data-fd'?: string;
  children?: React.ReactNode;
}

export type IntroButtonProps = StyledComponentProps & IProps;

class IntroButton extends React.Component<IntroButtonProps, any> {
  public constructor(props) {
    super(props);
    this.state = {
      buttonProps: {
        ...buttonProps,
        'data-fd': props['data-fd'],
        className: props.classes.button || '',
      },
    };
  }

  public render() {
    const { children, classes, loading, resource, disabled } = this.props;

    if (!classes) {
      return null;
    }

    return (
      <LoadingButton
        buttonProps={this.state.buttonProps}
        wrapperClassName={classes.buttonWrapper}
        loadingResources={[resource]}
        loading={loading}
        disabled={disabled}
      >
        {children}
      </LoadingButton>
    );
  }
}

const EnhancedComponent = compose<any, IProps>(
  withStyles(styles, {
    name: 'IntroButton',
    withTheme: true,
  })
)(IntroButton);

export { EnhancedComponent as IntroButton };
