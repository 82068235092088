import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';
import { useParams } from 'react-router';

import LinkButton from '../../../../ui/Button/LinkButton';

export type Props = { isEmpty: boolean };

const NoDataAddKioskLink = (props: Props) => {
  const { isEmpty } = props;
  const { appId } = useParams<{ appId: string }>();

  return (
    <Collapse in={isEmpty} mountOnEnter unmountOnExit>
      <AppBar enableColorOnDark position="relative" color="secondary">
        <Box color="#fff" pt={1} pr={2} pb={1} pl={2}>
          <Grid container alignItems="center">
            <Grid item xs={6} sm>
              <Typography color="inherit">
                <Translate id="Add_a_kiosk" />
              </Typography>
            </Grid>
            <Grid item xs={6} sm={2} container justifyContent="flex-end">
              <LinkButton to={`/${appId}/kiosks/new`} fdKey="go_to_add_new_kiosk" color="inherit">
                <Translate id="Add_kiosk" />
              </LinkButton>
            </Grid>
          </Grid>
        </Box>
      </AppBar>
    </Collapse>
  );
};

export default NoDataAddKioskLink;
