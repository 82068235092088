import {
  type AccountDetailBase,
  type ChangePasswordModel,
  type CreateAccountModel,
  type LoginModel,
  type PasswordResetModel,
  type RequestPasswordResetModel,
  type SetPasswordWithPinModel,
  type SignupStep,
  AccountsApiFactory,
  AppsApiFactory,
} from '@flipdish/api-client-typescript';
import { AccountsApiFactory as AccountsPrivateApiFactory } from '@flipdish/api-client-typescript/private/api';
import axios from 'axios';

import { GMAP_API_KEY } from '../constants/map.constant';
import { getApiEndPoint } from '../helpers/apibase';
import { patch } from '../helpers/patch';
import { localStorage, sessionStorage } from '../helpers/storage';
import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const accountsApi = createApi(AccountsApiFactory);
const accountsPrivateApi = createApi(AccountsPrivateApiFactory);

const newAccountsApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

const appsApi = createApi(AppsApiFactory);

export const accountService = {
  answerSignupQuestion,
  create,
  createPassword: loginCreatePassword,
  createPasswordWithPin,
  getAccountDetails,
  getIanaTimeZoneFromGmaps,
  getLocalisedTimeZones,
  getSupCountries,
  login,
  loginSso,
  logout,
  requestPasswordChangePin,
  requestPasswordReset: loginPasswordReset,
  skipSignupStep,
  updateAccount,
  updateAccountPassword,
  verifyRecaptchaToken,
  getAuth0Config,
};

// #region getSupportCountry
export async function getSupCountries() {
  try {
    const incomingMessage = await appsApi.getSupportedCountries();
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region create
export async function create(props: {
  email: string;
  storeName: string;
  languageCode: string;
  Rid?: number;
  Cid?: string;
  RecaptchaToken?: string;
}) {
  try {
    const payload: CreateAccountModel = {
      Email: props.email,
      StoreName: props.storeName,
      LanguageId: props.languageCode,
      Cid: props.Cid,
      Rid: props.Rid,
      RecaptchaToken: props.RecaptchaToken,
    };
    await accountsApi.createAccount(payload);
    const account = {
      email: props.email,
      authorized: true,
    };
    return account;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region getAccountDetails
export async function getAccountDetails() {
  try {
    const incomingMessage = await accountsApi.getAccountDetails();
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region login
export async function login(email: string, password: string) {
  try {
    const loginModel: LoginModel = {
      Email: email,
      Password: password,
    };

    await accountsApi.login(loginModel);
    const account = {
      email,
      authorized: true,
    };
    return account;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region loginSSO
async function loginSso(email: string, token?: string) {
  try {
    const originHeader = window?.location?.host?.includes('portal.flipdish.com')
      ? 'portal.flipdish.com'
      : 'portal.flipdishdev.com';

    await newAccountsApi.post(`/accounts/login/sso`, undefined, {
      headers: { Authorization: `Bearer ${token}`, 'X-Flipdish-Source-Origin': originHeader },
    });
    return {
      email,
      authorized: true,
    };
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region loginCreatePassword
export async function loginCreatePassword(password: string, token: string, tokenId: string) {
  try {
    const payload: PasswordResetModel = {
      Password: password,
      PasswordConfirmation: password,
      Token: token,
      TokenId: tokenId,
    };

    await accountsApi.passwordResetWithToken(payload);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

function createPasswordWithPin(pin, password, token?): any {
  const passwordResetWithToken: SetPasswordWithPinModel = {
    Pin: pin,
    NewPassword: password,
    RecaptchaToken: token,
  };

  return accountsApi
    .changePasswordWithPin(passwordResetWithToken)
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

// #region requestPasswordChangePin
export async function requestPasswordChangePin(email: string) {
  try {
    const payload: RequestPasswordResetModel = {
      Email: email,
    };
    await accountsApi.sendPinForPasswordReset(payload);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region loginWithPin
export async function loginPasswordReset(email: string, token?: string) {
  try {
    const payload: RequestPasswordResetModel = {
      Email: email,
      RecaptchaToken: token,
    };

    await accountsApi.requestPasswordReset(payload);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region updateAccount
export async function updateAccount(model: AccountDetailBase) {
  try {
    await accountsApi.updateAccount(model);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

function updateAccountPassword(updateModel: ChangePasswordModel): any {
  return accountsApi
    .changePassword(patch(updateModel, {}))
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

// #region updateAccount
export async function logout() {
  try {
    await accountsApi.logout();
    sessionStorage.clear();
    localStorage.clear();
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region verifyRecaptchaToken
export async function verifyRecaptchaToken(token: string) {
  try {
    await accountsApi.recaptchaValidate(token);
    return 200;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

function skipSignupStep(stepEnum: SignupStep.ActionEnum) {
  return accountsApi
    .skipSignupStep(stepEnum.toString() as any)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error) => Promise.reject(error));
}

function answerSignupQuestion(answerId: number) {
  return accountsApi
    .answerSignUpQuestion('Question', answerId.toString() as any)
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
}

function getIanaTimeZoneFromGmaps(lat: number, lng: number) {
  return axios
    .get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&key=${GMAP_API_KEY}&timestamp=${Math.round(
        new Date().getTime() / 1000
      )}`
    )
    .then((response: any) => Promise.resolve(response.data.timeZoneId))
    .catch((error) => Promise.reject(error));
}

function getLocalisedTimeZones() {
  return accountsApi
    .getLocalisedTimeZones()
    .then((response) => {
      const localizedTimeZones = response.Data;
      return Promise.resolve(localizedTimeZones);
    })
    .catch((error) => Promise.reject(error));
}

// #region getAuth0Config
export async function getAuth0Config() {
  try {
    const response = await accountsPrivateApi.getLoginConfig();
    return response.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion
