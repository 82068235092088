import React from 'react';

import { SupportedCountry } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { type FormikErrors, type FormikHandlers, type FormikTouched } from 'formik';
import { Translate } from 'react-localize-redux';

import { type OnboardingFormValues } from './StoreLocation';

type Props = {
  errors: FormikErrors<OnboardingFormValues>;
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  supportedCountry: SupportedCountry | null;
  touched: FormikTouched<OnboardingFormValues>;
  values: OnboardingFormValues;
};

export default function AddressFields(props: Props) {
  const { errors, handleBlur, handleChange, supportedCountry, touched, values } = props;

  return (
    <>
      <Grid item xs={12} p={1.5}>
        <TextField
          variant="standard"
          name="addressLine1"
          label={<Translate id="Address" />}
          autoComplete="address-line1"
          value={values.addressLine1}
          fullWidth
          inputProps={{
            'data-fd': 'address-line-1',
          }}
          error={touched.addressLine1 && !!errors.addressLine1}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.addressLine1 && errors.addressLine1}
        />
      </Grid>
      <Grid item xs={12} p={1.5}>
        <TextField
          variant="standard"
          name="addressCity"
          label={<Translate id="TownCity" />}
          autoComplete="address-level2"
          value={values.addressCity}
          fullWidth
          inputProps={{
            'data-fd': 'town-city',
          }}
          error={touched.addressCity && !!errors.addressCity}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.addressCity && errors.addressCity}
        />
      </Grid>
      <Grid item xs={12} p={1.5}>
        <TextField
          variant="standard"
          name="postCode"
          label={
            supportedCountry && supportedCountry.PostCodeType! <= 1 ? (
              <Translate id="Postal_code" />
            ) : (
              <Translate id="ZIP" />
            )
          }
          autoComplete="postCode"
          value={values.postCode}
          fullWidth
          inputProps={{
            'data-fd': 'postCode',
          }}
          error={touched.postCode && !!errors.postCode}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.postCode && errors.postCode}
        />
      </Grid>
    </>
  );
}
