import {
  AppsApiFactory,
  StoreGroup,
  StoreGroupsApiFactory,
  StoresApiFactory,
} from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const storesApi = createApi(StoresApiFactory);
const storeGroupApi = createApi(StoreGroupsApiFactory);
const appApi = createApi(AppsApiFactory);

async function createInitialStore(storeName: string) {
  try {
    const app = await appApi.createApp({ Name: storeName });
    const storeGroup = await storeGroupApi.createStoreGroup(app.Data, {
      Name: storeName,
      Currency: StoreGroup.CurrencyEnum.EUR,
    });
    const { StoreGroupId } = storeGroup.Data;
    if (!StoreGroupId) {
      throw new Error(`Error creating new store group for ${storeName}-  StoreGroupId is null`);
    }
    await storesApi.createStore(StoreGroupId, { Name: storeName });
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const onboardingService = {
  createInitialStore,
};
