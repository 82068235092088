import * as React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { type FormikProps, Form, withFormik } from 'formik';
import { type WithSnackbarProps, withSnackbar } from 'notistack';
import { parse as parseQueryString } from 'qs';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import { accountActions } from '../../actions/account.actions';
import { accountConstants } from '../../constants/account.constants';
import { passwordValidationApiErrors, validatePassword } from '../../helpers/validation';
import { accountRoutesConst as routes } from '../../routes/account.routes';
import LinkButton from '../../ui/Button/LinkButton';
import { IntroButton } from './Common/IntroButton';
import AuthLayout from './Common/Layout';
import LoginTitle from './Login/LoginTitle';

export interface IFormValues {
  email?: string;
  password: string;
  password_confirmation: string;
  tokenId: string;
  token: string;
}

const styles = (theme: Theme) =>
  createStyles({
    formFields: {
      // remove chrome autofill color
      '& input:-webkit-autofill': {
        boxShadow: '0 0 0 30px white inset !important',
      },
    },
    textField: {
      // prevent jumping screen
      minHeight: '68px',
    },
    extras: {
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
      },
    },
    linkBtn: {
      textTransform: 'none',
    },
  });

const Title = () => (
  <Translate>
    {(translate) => (
      <LoginTitle
        titlePrefix={translate('Create_your') as string}
        title={(translate('Password') as string).toLowerCase()}
      />
    )}
  </Translate>
);

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps) => {
  return (state: AppState, ownProps) => {
    const { account } = state;
    return {
      account,
      translate: getTranslate(state.locale),
    };
  };
};

type Props = WithStyles<typeof styles> &
  MappedState &
  WithSnackbarProps &
  RouteComponentProps & { dispatch: ThunkDispatch } & FormikProps<IFormValues>;
const CreatePassword = compose<Props, {}>(
  setDisplayName('CreatePassword'),
  withStyles(styles, {
    name: 'FdCreatePassword',
    withTheme: true,
  }),
  withRouter,
  withSnackbar,
  connect(mapStateToPropsFactory),
  withFormik<Props, IFormValues>({
    displayName: 'LoginForm',
    mapPropsToValues: (props) => {
      const { email, token, tokenId } = parseQueryString(props.location.search.substring(1)) as any;
      return {
        email: email || '',
        password: '',
        password_confirmation: '',
        token,
        tokenId,
      };
    },
    handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
      const { dispatch } = props;
      try {
        await dispatch(
          accountActions.createPassword(values.password, values.token, values.tokenId)
        );
        await dispatch(accountActions.getAccountDetails());
        setSubmitting(false);
        props.enqueueSnackbar(props.translate('Your_password_has_been_set'), {
          ariaAttributes: { 'aria-describedby': 'client-snackbar' },
          variant: 'success',
        });
        setTimeout(() => {
          props.history.replace('/');
        }, 1000 * 2);
      } catch (error) {
        setSubmitting(false);
        const containsPasswordErrorMessage = passwordValidationApiErrors.includes(error.message);
        if (containsPasswordErrorMessage) {
          setFieldError('password_confirmation', props.translate(error.message) as string);
        } else {
          props.enqueueSnackbar(props.translate('Something_went_wrong'), {
            variant: 'error',
            ariaAttributes: { 'aria-describedby': 'client-snackbar' },
          });
        }
      }
    },
    validate: (values, props) => {
      const errors: Partial<IFormValues> = {};
      const passwordError = validatePassword(values.password, props.translate);

      if (passwordError) errors.password = passwordError;

      if (values.password !== values.password_confirmation) {
        errors.password_confirmation = props.translate('Does_not_match') as string;
      }

      if (!values.token || values.token === '') {
        errors.token = props.translate('Required') as string;
      }
      if (!values.tokenId || values.tokenId === '') {
        errors.tokenId = props.translate('Required') as string;
      }

      return errors;
    },
  })
)(
  class extends React.Component<Props, {}> {
    private inputRef = React.createRef<HTMLInputElement>();

    public componentDidMount() {
      this.focus();
    }

    public render() {
      const {
        classes,
        translate,
        isValid,
        isSubmitting,
        touched,
        errors,
        handleBlur,
        handleChange,
        values,
      } = this.props;

      return (
        <AuthLayout title={Title}>
          <Grid container alignItems="stretch" direction="column" wrap="nowrap" spacing={2}>
            <Grid item>
              <Form>
                <Grid container spacing={0} className={classes.formFields}>
                  {values.email && (
                    <Grid item xs={12} lg={11}>
                      <TextField
                        variant="standard"
                        name="email"
                        label={translate('Email')}
                        placeholder={translate('Email') as string}
                        type="email"
                        autoComplete="email"
                        fullWidth
                        inputProps={{
                          'data-fd': 'email',
                        }}
                        className={classes.textField}
                        disabled={true}
                        value={values.email}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} lg={11}>
                    <TextField
                      variant="standard"
                      inputRef={this.inputRef}
                      name="password"
                      label={translate('Password')}
                      placeholder={translate('At_least_12_characters') as string}
                      type="password"
                      autoComplete="off"
                      fullWidth
                      inputProps={{
                        'data-fd': 'password',
                      }}
                      className={classes.textField}
                      error={touched.password && !!errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        (touched.password && errors.password) ??
                        translate('Password_must_contain_two_of')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={11}>
                    <TextField
                      variant="standard"
                      name="password_confirmation"
                      label={translate('Confirm_password')}
                      placeholder={translate('Confirm_password') as string}
                      type="password"
                      autoComplete="off"
                      fullWidth
                      inputProps={{
                        'data-fd': 'password-confirm',
                      }}
                      className={classes.textField}
                      error={touched.password_confirmation && !!errors.password_confirmation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.password_confirmation && errors.password_confirmation}
                    />
                  </Grid>
                  <Grid item xs={12} lg={11}>
                    <IntroButton
                      resource={accountConstants.CREATE_PASSWORD}
                      loading={isSubmitting}
                      disabled={!isValid || isSubmitting}
                      data-fd="create-password"
                    >
                      <Translate id={'Create_password'} />
                    </IntroButton>
                  </Grid>
                </Grid>
              </Form>
            </Grid>

            <Grid item container direction="column" className={classes.extras}>
              <Grid item xs={12}>
                <LinkButton
                  color="primary"
                  to={routes.Login}
                  type="button"
                  disabled={isSubmitting}
                  fdKey="login-instead"
                  className={classes.linkBtn}
                >
                  <Translate id="Login_instead" />
                </LinkButton>
              </Grid>
            </Grid>
          </Grid>
        </AuthLayout>
      );
    }

    public focus = () => {
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    };
  }
);

export default CreatePassword;
