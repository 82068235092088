import React from 'react';

import { SupportedCountry } from '@flipdish/api-client-typescript/private/api';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { type FormikErrors, type FormikHandlers, type FormikTouched } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { MessageBanner, SkeletonLoader } from '@fd/ui/atoms';

import { notifyError } from '../../../layouts/Notify/actions';
import { getIsStoresLoading } from '../../../selectors/store.selector';
import { flagService } from '../../../services';
import { addressService } from '../../../services/address.service';
import { type OnboardingFormValues } from './StoreLocation';

const useStyles = makeStyles((theme: Theme) => ({
  buttonDefault: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '48px',
    },
  },
  buttonPrimary: {
    backgroundColor: '#ff0046',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#b20031',
    },
    '&:active': {
      backgroundColor: '#b20031',
    },
    letterSpacing: '1.4px',
    [theme.breakpoints.down('md')]: {
      letterSpacing: '1.3px',
    },
  },
  searchBox: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  banner: {
    marginTop: theme.spacing(2),
    borderRadius: '5px',
    backgroundColor: 'rgb(202, 0, 48)',
  },
  bannerContent: {
    color: 'rgb(255, 255, 255)',
  },
}));

type OuterProps = {
  addressEntered: boolean;
  errors: FormikErrors<OnboardingFormValues>;
  googleObjectAddress?: {
    Results: google.maps.places.PlaceResult;
    Status: google.maps.GeocoderStatus;
  };
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  isSubmitting: boolean;
  isValid: boolean;
  onSearchBoxPlaceChange: () => void;
  onSBLoad: (ref: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<OnboardingFormValues>>;
  setStepToConfirmLocation: () => void;
  storeName: string;
  supportedCountry?: SupportedCountry | null;
  touched: FormikTouched<OnboardingFormValues>;
  translate: TranslateFunction;
  values: OnboardingFormValues;
};

type Props = OuterProps & MappedState;

export function LocatorForm(props: Props) {
  const {
    addressEntered,
    errors,
    googleObjectAddress,
    handleBlur,
    handleChange,
    internationalisedAddressConfig,
    isSubmitting,
    isValid,
    onSearchBoxPlaceChange,
    onSBLoad,
    setFieldValue,
    setStepToConfirmLocation,
    storeName,
    supportedCountry,
    touched,
    translate,
    values,
    language,
    isLoading,
  } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();

  const useDynamicAddressFields = internationalisedAddressConfig?.affectedCountries?.includes(
    supportedCountry?.CountryCode
  );

  const getGoogleAddress = useMutation({
    mutationKey: [
      addressService.getGoogleAddressQueryKey,
      googleObjectAddress?.Results,
      googleObjectAddress?.Status,
    ],

    mutationFn: () => {
      if (!googleObjectAddress) {
        throw new Error('googleObjectAddress is required');
      }

      return addressService.getFormattedGoogleAddress(
        googleObjectAddress.Results,
        googleObjectAddress.Status,
        language || 'en'
      );
    },

    onSuccess: (data) => {
      queryClient.setQueryData([addressService.getGoogleAddressQueryKey], data);
      setStepToConfirmLocation();
    },

    onError: () => {
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  const handleAddress = async () => {
    try {
      //This is to handle non supportedCountry
      if (supportedCountry === undefined || !useDynamicAddressFields) {
        setStepToConfirmLocation();
      }
      if (googleObjectAddress && supportedCountry?.CountryCode && useDynamicAddressFields) {
        await getGoogleAddress.mutateAsync();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container m={-1.5} width={`calc(100% + 24px)`}>
      {!storeName && !isLoading ? (
        <Grid item xs={12} p={1.5}>
          <MessageBanner
            titleClassName={classes.bannerContent}
            className={classes.banner}
            title={translate('User_creation_failed') as string}
            icon={<ErrorOutlineIcon className={classes.bannerContent} />}
            fdKey="store-load-error"
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} p={1.5}>
            {isLoading ? (
              <SkeletonLoader fdKey="skeleton-store" rows={[{ height: '30px', width: '50%' }]} />
            ) : (
              <Typography variant="subtitle1">
                <Translate id="Store" />: {storeName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} p={1.5}>
            {isLoading ? (
              <SkeletonLoader
                fdKey="skeleton-address-search"
                rows={[{ height: '50px', width: '100%' }]}
              />
            ) : (
              <StandaloneSearchBox onLoad={onSBLoad} onPlacesChanged={onSearchBoxPlaceChange}>
                <TextField
                  variant="standard"
                  name="addressFormatted"
                  label={<Translate id="Address" />}
                  placeholder={translate('type_store_address') as string}
                  value={values.addressFormatted}
                  fullWidth
                  inputProps={{
                    className: classes.searchBox,
                    'data-fd': 'search-box',
                  }}
                  autoComplete="street-address"
                  error={touched.addressFormatted && !!errors.addressFormatted}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue('addressLine1', '');
                  }}
                  onBlur={handleBlur}
                  helperText={touched.addressFormatted && errors.addressFormatted}
                />
              </StandaloneSearchBox>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12} p={1.5}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item xs={12} sm={5}>
            <Button
              color="primary"
              variant="contained"
              // type="submit"
              fullWidth
              disabled={!isValid || isSubmitting || !addressEntered}
              className={clsx(classes.buttonDefault, classes.buttonPrimary)}
              onClick={() => {
                handleAddress();
              }}
              size="large"
              data-fd="set-location"
            >
              <Translate id="Set_Location" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const internationalisedAddressConfig = flagService.getSplitValueConfig(
    state,
    'internationalisedAddress'
  )?.config;
  return {
    internationalisedAddressConfig,
    language: state.account?.Language,
    isLoading: getIsStoresLoading(state) || !state?.currentApp?.AppId,
  };
};

export default connect(mapStateToProps)(LocatorForm);
