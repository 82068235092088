import React from 'react';

import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import { store } from '../../helpers/store';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { flagService } from '../../services/flagService/flagService';
import RMSPage from '../RMS/components/RMSPage';
import { RMSModules } from '../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../RMS/utils/rmsSubscriptions';
import WebsiteDomainSetup from '../WebsiteAndApp/components/SetupDomain';
import CookieSettings from '../WebsiteAndApp/CookieSettings/CookieSettings';
import WebsiteSettings from '../WebsiteAndApp/WebSiteSettings/WebsiteSettings';
import AddChannel from './AddChannel/AddChannel';
import ChannelSettings from './ChannelSettings/ChannelSettings';
import { MobileApps } from './MobileApps/pages/MobileApps';
import POSPage from './POS/POSPage';
import SalesChannelsGeneralSettings from './Settings/SalesChannelsGeneralSettings';
import StoreMapping from './StoreMapping/StoreMapping';
import StoreVisibilityPage from './Website/StoreVisibilityPage';
import SalesChannels from './Website/WebsiteSettings';

type RouteParams = {
  appId?: string;
};

type Props = RouteComponentProps<RouteParams>;
const SalesChannelsRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path, params },
  } = props;

  const addChannelFlag = flagService.isFlagOn(store.getState(), 'addChannel');

  return (
    <ErrorBoundary identifier="sales-channels">
      <Switch>
        <>
          <Permissions allowed={['ViewSalesChannels', 'EditSalesChannels']}>
            <Route
              exact
              path={`${path}/general-settings`}
              component={SalesChannelsGeneralSettings}
            />

            <Route exact path={`${path}/mobile-apps`} component={MobileApps} />

            <Route path={`${path}/website-settings`} component={SalesChannels} />

            <Route
              exact
              path={`${path}/website/analytics-and-social`}
              component={WebsiteSettings}
            />
            <Route exact path={`${path}/website/domain-setup`} component={WebsiteDomainSetup} />
            <Route exact path={`${path}/website/cookie-management`} component={CookieSettings} />
            <Route
              exact
              path={`${path}/website/store-visibility`}
              component={StoreVisibilityPage}
            />

            {addChannelFlag && <Route exact path={`${path}/add-channel`} component={AddChannel} />}

            {addChannelFlag && (
              <Route exact path={`${path}/:channel`} component={ChannelSettings} />
            )}
            {addChannelFlag && (
              <Route exact path={`${path}/:channel/store-mapping`} component={StoreMapping} />
            )}
          </Permissions>
          <Permissions allowed={['Owner', 'ManagedOwner', 'FlipdishStaff']}>
            <Route exact path={`${path}/point-of-sale`} component={POSPage} />
            <Route
              exact
              path={`${path}/point-of-sale/settings/network-diagram`}
              render={() => (
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Network_Diagram" />}
                  module={RMSModules.NETWORK_DIAGRAM}
                  url={`configurations/system/${RMSModules.NETWORK_DIAGRAM}`}
                  includePropertyIdInUrl
                  toParent={`/${params?.appId}/sales-channels/point-of-sale?tab=settings`}
                />
              )}
            />
          </Permissions>
        </>
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(SalesChannelsRoutes);
