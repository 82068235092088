import {
  Accept,
  OrderDeliveryInformationBase,
  OrderFulfillmentStatusUpdate,
  OrdersApiFactory,
  OrderSummary,
  Refund,
  Reject,
} from '@flipdish/api-client-typescript';
import axios from 'axios';

import {
  getApiEndPoint,
  tryFixApiWrongDateParsing,
  tryFixApiWrongDateParsingPaginationResult,
} from '../helpers/apibase';
import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const orderApi = createApi(OrdersApiFactory);

type OrderStateEnum = Required<Flipdish.OrderSummary>['OrderState'];
type AppTypeEnum = Required<Flipdish.OrderSummary>['AppType'];

// #region getOrderById
export async function getOrderById(orderId: number) {
  try {
    const response = await orderApi.getOrderById(orderId);

    return tryFixApiWrongDateParsing(
      { body: response },
      ['RequestedForTime', 'PlacedTime', 'AcceptedFor'] as any,
      response.Data as any
    );
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region getOrdersSummary
export type GetOrdersSummary = {} & {
  appId: string;
  query?: string;
  storeIds?: number[];
  orderStates?: OrderStateEnum[];
  page?: number;
  limit?: number;
  physicalRestaurantId?: number[];
  channels?: AppTypeEnum[];
  from?: Date;
  to?: Date;
};
export async function getOrdersSummary({
  appId,
  query,
  orderStates,
  page,
  limit,
  physicalRestaurantId,
  channels,
  from,
  to,
}: GetOrdersSummary) {
  try {
    const response = await orderApi.getOrdersSummary(
      appId,
      query,
      physicalRestaurantId,
      orderStates,
      page,
      limit,
      false,
      channels,
      undefined,
      from as unknown as string,
      to as unknown as string
    );

    return tryFixApiWrongDateParsingPaginationResult<OrderSummary>(
      { body: response },
      ['RequestedForTime'] as any,
      response as any
    );
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

export type GetOrders = {} & {
  physicalRestaurantId?: number[];
  orderStates?: OrderStateEnum[];
  page?: number;
  limit?: number;
};

export async function getOrders({
  physicalRestaurantId,
  orderStates,
  page,
  limit,
}: GetOrdersSummary) {
  try {
    const incomingMessage = await orderApi.getOrders(
      physicalRestaurantId,
      orderStates,
      undefined,
      undefined,
      page,
      limit
    );
    // UTC format is OK here
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region acceptOrder
export type AcceptOrderProps = {} & {
  orderId: number;
  estimatedMinutesForDelivery?: number;
};
export async function acceptOrder({ orderId, estimatedMinutesForDelivery }: AcceptOrderProps) {
  try {
    const payload: Accept = {
      EstimatedMinutesForDelivery: estimatedMinutesForDelivery,
    };
    await orderApi.acceptOrder(orderId, payload);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region rejectOrder
export type RejectOrderProps = {} & {
  orderId: number;
  rejectReason: Reject.RejectReasonEnum;
  doNotNotifyCustomer: boolean;
};
export async function rejectOrder({
  orderId,
  rejectReason,
  doNotNotifyCustomer,
}: RejectOrderProps) {
  try {
    const payload: Reject = {
      RejectReason: rejectReason,
      DoNotNotifyCustomer: doNotNotifyCustomer,
    };
    await orderApi.rejectOrder(orderId, payload);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region refundOrder
export type RefundOrderProps = {} & {
  orderId: number;
  refundAmount: number;
  notifyCustomer: boolean;
  refundReason?: string;
};
export async function refundOrder({
  orderId,
  refundAmount,
  notifyCustomer,
  refundReason,
}: RefundOrderProps) {
  try {
    const payload: Refund = {
      NotifyCustomer: notifyCustomer,
      RefundAmount: refundAmount,
      RefundReason: refundReason,
    };
    await orderApi.refundOrder(orderId, payload);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region getRefundStatus
const baseURL = getApiEndPoint();
const portalApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});
export const getRefundStatus = async (orderId: number) => {
  try {
    const response = await portalApi.get<{ Data: { OrderId: number; PaymentRefundable: boolean } }>(
      `/payments/payment/${orderId}/refundable`
    );

    return response.data.Data;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
};
//#endregion

// #region getOrderDeliveryInfo
export async function getOrderDeliveryInfo(orderId: number) {
  try {
    const { Data } = await orderApi.getDeliveryInformation(orderId);

    return Data as OrderDeliveryInformationBase;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
}
// #endregion

// #region getOrderFulfillmentState
export async function getOrderFulfillmentState(orderId: number) {
  try {
    const { Data } = await orderApi.getFulfillmentStatusWithDetailsAndActions(orderId);

    return Data;
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
}
// #endregion

// #region updateOrderFulfillmentState
export async function updateOrderFulfillmentState(
  orderId: number,
  fulfillmentStatusRequest: OrderFulfillmentStatusUpdate
) {
  try {
    await orderApi.updateFulfillmentState(orderId, fulfillmentStatusRequest);
  } catch (error) {
    const err = await mapServerError(error);
    throw err;
  }
}
// #endregion

export const orderFulfillmentService = {
  updateOrderFulfillmentState,
  getOrderFulfillmentState,
};
