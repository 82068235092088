import * as React from 'react';

import { AccountDetail, SignupStep } from '@flipdish/api-client-typescript';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { withSnackbar } from 'notistack';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Field as RField, Form, initialize as reduxFormInitialize, reduxForm } from 'redux-form';

import { accountActions } from '../../../actions/account.actions';
import aboutYouMobileSvg from '../../../assets/images/about_you_mobile.svg';
import aboutYouWideSvg from '../../../assets/images/about_you_wide.svg';
import { accountConstants } from '../../../constants/account.constants';
import { signup } from '../../../constants/signup.constants';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import withAmplitude from '../../../services/amplitude/withAmplitude';
import { LoadingButton } from '../../../ui/LoadingButton';
import { StepsHeader } from './StepsHeader';

const processingSelector = createLoadingSelector([accountConstants.SKIP_SIGNUP_STEP]);
class Field extends RField<any> {}

const renderTextField = ({
  input,
  label,
  meta: { active, dirty, error, submitFailed, touched },
  children,
  ...custom
}) => (
  <TextField
    variant="standard"
    label={label}
    error={!active && ((dirty && touched) || submitFailed) && !!error}
    helperText={error && !active ? error : ` `}
    onFocus={input.onFocus}
    {...input}
    {...custom}
    data-fd={'q-orders-online'}
    SelectProps={{
      'data-fd': 'select-question1',
    }}
  >
    {children}
  </TextField>
);

const styles = (theme: Theme) => {
  return createStyles({
    surveyBackground: {
      position: 'absolute',
      backgroundImage: 'url(' + aboutYouWideSvg + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'url(' + aboutYouMobileSvg + ')',
        position: 'static',
        height: '38.75vh',
        minHeight: 200,
      },
    },
    card: {
      position: 'absolute',
      zIndex: 2,
      flex: 1,
      right: theme.spacing(8),
      bottom: theme.spacing(8),
      minWidth: 600,
      borderRadius: '2px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500,
      },
      [theme.breakpoints.down('lg')]: {
        minWidth: 500,
      },
      [theme.breakpoints.down('sm')]: {
        position: 'static',
        width: '100%',
        right: 0,
        bottom: 0,
        height: '61.25vh',
        minHeight: 300,
        minWidth: 0,
        borderRadius: 0,
      },
    },
    cardContent: {
      padding: theme.spacing(4),
    },
    form: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    formActions: {
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'row-reverse',
      },
    },
    formFields: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    link: {
      position: 'relative',
      color: '#05149e',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
      },
    },
    centerCaption: {
      textAlign: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    stepsShort: {
      color: 'rgba(0, 0, 0, 0.38)',
      marginBottom: 6,
    },
    buttonContainer: {
      minWidth: 140,
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    button: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      backgroundColor: '#ff0046',
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(6),
        width: theme.spacing(28),
      },
      color: '#fff',
      '&:hover': {
        backgroundColor: '#b20031',
      },
      '&:active': {
        backgroundColor: '#b20031',
      },
      letterSpacing: '1.4px',
      [theme.breakpoints.down('lg')]: {
        letterSpacing: '1.3px',
      },
    },
  });
};

class UserSurvey extends React.Component<any, any> {
  private redirect: any = false;

  public constructor(props) {
    super(props);
    this.state = {
      question1: 1,
      submitting: false,
    };
  }

  public handleInputChangeRX = (e, value) => {
    this.setState({ question1: value });
  };

  public submitSurvey = () => {
    const { dispatch } = this.props;
    const q = this.state.question1;
    dispatch(accountActions.answerSignupQuestion(q));
    this.setState({ submitting: true });
  };

  public skipSurvey = () => {
    const { dispatch } = this.props;

    dispatch(accountActions.skipSignupStep(SignupStep.ActionEnum.Question));
  };

  public componentDidMount() {
    const { account, dispatch, trackEvent } = this.props;
    trackEvent('portal_signup_survey', {
      action: 'logged_in',
    });
    if (account) {
      dispatch(reduxFormInitialize('UserSurvey', account));
    }
    if (this.props.location.pathname !== signup.paths.survey) {
      this.props.history.push(signup.paths.survey);
    }
  }

  public componentDidUpdate() {
    const { dispatch, submitError, errorNotification } = this.props;
    if (submitError) {
      errorNotification(submitError.body.Message);
      dispatch({ type: accountConstants.ANSWER_SIGNUP_QUESTION_RESET });
      this.setState({ submitting: false });
    }
  }

  public render() {
    const { account, classes, handleSubmit, translate, processing } = this.props;

    const stepIndex = signup.steps.indexOf('About_you');

    const typedAccount: AccountDetail = account;

    if (this.redirect) {
      console.log('User survey redirecting');
      return this.redirect;
    }

    if (!typedAccount.SignupSteps) {
      return <div />;
    }

    const question: SignupStep | undefined = typedAccount.SignupSteps.find(
      (step) => step.Action == SignupStep.ActionEnum.Question
    );

    if (!question) {
      return <div />;
    }

    return (
      <>
        <div className={classes.surveyBackground} />
        <Hidden smDown>
          <StepsHeader steps={signup.steps} stepIndex={stepIndex} />
        </Hidden>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Hidden smUp>
              <Typography variant="caption" className={classes.stepsShort}>
                <Translate id="Step" /> {stepIndex + 1}/{signup.steps.length}
              </Typography>
            </Hidden>
            <Typography variant="h4">
              <Translate id="Little_about_you" />
            </Typography>
            <Form onSubmit={handleSubmit(this.submitSurvey)}>
              <div className={classes.form}>
                <div className={classes.formFields}>
                  <Field
                    label={translate(question.Question)}
                    select
                    name="question1"
                    type="text"
                    component={renderTextField}
                    onChange={this.handleInputChangeRX}
                    fullWidth
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                        'data-fd': 'q-orders-online-select',
                      },
                    }}
                    className={classes.formField}
                  >
                    {question.PredefinedAnswers
                      ? question.PredefinedAnswers.map((answer) => (
                          <MenuItem key={answer.Id} value={answer.Id}>
                            <Translate id={answer.Text as any} />
                          </MenuItem>
                        ))
                      : null}
                  </Field>
                </div>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.formActions}
                  >
                    <Grid item xs={12} sm={5}>
                      <LoadingButton
                        wrapperClassName={classes.buttonContainer}
                        loading={this.state.submitting}
                        fullWidth
                        // disabled={!isValid || isSubmitting}
                        color={'primary'}
                        variant={'contained'}
                        type={'submit'}
                        fdKey="finish"
                        size="large"
                        className={clsx(classes.formField, classes.button)}
                        loadingResources={[accountConstants.ANSWER_SIGNUP_QUESTION]}
                      >
                        <Translate id="Finish" />
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <LoadingButton
                        onClick={this.skipSurvey}
                        loading={processing}
                        className={classes.link}
                        type="button"
                        fdKey="skip-this"
                        fullWidth
                      >
                        <Translate id="Skip_this" />
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Form>
          </CardContent>
        </Card>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { account, loading, locale } = state;
  return {
    account,
    processing: processingSelector(state),
    submitError: loading[accountConstants.ANSWER_SIGNUP_QUESTION_ERROR],
    translate: getTranslate(locale),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    errorNotification: (msg) =>
      ownProps.enqueueSnackbar(msg, {
        variant: 'error',
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
      }),
  };
}

const EnhancedComponent = compose(
  withRouter,
  withSnackbar,
  withAmplitude,
  withStyles(styles, {
    name: 'UserSurvey',
    withTheme: true,
  }),
  reduxForm({
    form: 'UserSurvey',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(UserSurvey);

export { EnhancedComponent as UserSurvey };
