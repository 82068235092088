import React, { useEffect } from 'react';

import List from '@mui/material/List';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { compose } from 'recompose';

import PageLayout from '../../ui/Layout';
import PaperContainer from '../../ui/Layout/PaperContainer';
import WindowInfiniteScroll from '../../ui/WindowInfiniteScroll';
import LoadingListItem from '../Finance/Banking/List/LoadingListItem';
import DeviceListItem from './Components/DeviceListItem';
import NoDevices from './Components/NoDevices';
import { getDevices } from './Devices.actions';

type Props = MappedDispatch & MappedProps;
const DeviceList = (props: Props) => {
  const { deviceList, getDeviceList, hasMore, isDevicesEmpty, isLoading, translate } = props;
  const location = useLocation();
  const history = useHistory();

  const loadMore = (page: number) => {
    return getDeviceList(page);
  };

  useEffect(() => {
    getDeviceList(1, true);
  }, []);

  const navigateToAddTerminal = () => {
    const url = `${location.pathname}/new`;
    history.push(url);
  };

  return (
    <PageLayout
      title={translate('Terminals')}
      documentTitle="Terminals"
      showActionButton
      showAddIcon
      actionBtnTitle={'Add_terminal'}
      userPermissions={'UpdateHydraConfigManage'}
      onClick={navigateToAddTerminal}
    >
      {!isDevicesEmpty ? (
        <PaperContainer fluid>
          <WindowInfiniteScroll
            pageStart={1}
            loadMore={loadMore}
            hasMore={hasMore && !isLoading}
            loader={isLoading ? LoadingListItem : undefined}
            initialLoad={false}
          >
            <List component="nav">
              {deviceList.map((device, index) => {
                return (
                  <DeviceListItem
                    isLast={index + 1 === deviceList.length}
                    key={`${device.DeviceId}`}
                    data-fd={device.DeviceId}
                    device={device}
                    deviceId={device.DeviceId}
                    deviceType={device.DeviceType}
                  />
                );
              })}
              {isLoading && <LoadingListItem />}
            </List>
          </WindowInfiniteScroll>
        </PaperContainer>
      ) : (
        <NoDevices />
      )}
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const {
    devices: {
      terminals,
      terminalsTotalCount,
      printers,
      printersTotalCount,
      isDevicesEmpty,
      isLoading,
    },
    locale,
  } = state;

  return {
    isLoading,
    isDevicesEmpty,
    translate: getTranslate(locale),
    deviceList: [...terminals, ...printers],
    hasMore: terminals.length < terminalsTotalCount || printers.length < printersTotalCount,
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    getDeviceList: (page: number, isInitial?: boolean) => dispatch(getDevices(page, 20, isInitial)),
  };
}

export default compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(DeviceList);
