import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Typography } from '@fd/ui/atoms';
import LinkButton from '@fd/ui/Button/LinkButton';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import { FormSection } from '@fd/ui/molecules';

import { permissionsSelector } from '../../../../../selectors/permissions.selector';
import AssignStoresVisibility from '../../../components/AssignStoresVisibility';

const useStyles = makeStyles((theme: Theme) => ({
  linkButton: {
    marginTop: theme.spacing(2),
  },
  settingContainer: {
    marginBottom: theme.spacing(3),
  },
  androidStoreVisibility: {
    position: 'relative',
    zIndex: 10,
  },
}));

//This will be replaced by app self serve in the future
const LegacyMobileSalesChannelsConfig = (props: MappedState) => {
  const { appId, hasAndroidApp, hasEditSalesChannelsPermission, hasIosApp, translate } = props;
  const classes = useStyles();

  const renderNoAppMessage = (appType: 'iOS' | 'Android') => {
    const messageId =
      appType == 'iOS'
        ? 'You_dont_have_an_ios_mobile_application_with'
        : 'You_dont_have_an_Android_mobile_application_with';
    return (
      <>
        <Typography variant="subtitle1">
          <Translate id={appType} /> <Translate id="Stores_visibility" />
        </Typography>
        <Typography variant="caption">
          <Translate id={messageId} />
        </Typography>
        <div>
          {hasEditSalesChannelsPermission && (
            <LinkButton
              className={classes.linkButton}
              fdKey={`${appType}-get-in-touch-button`}
              color="primary"
              variant="contained"
              to="#"
              onClick={(e) => {
                window.location.href = `mailto:help@flipdish.com?subject=Request for ${appType} App`;
                e.preventDefault();
              }}
            >
              <Translate id="Get_in_touch" />
            </LinkButton>
          )}
        </div>
      </>
    );
  };

  const renderAppSalesChannelConfig = (appType: 'iOS' | 'Android', hasApp: boolean) => {
    const channelId = appType === 'iOS' ? 1 : 2;
    const storeVisibilityStyle = appType === 'Android' ? classes.androidStoreVisibility : undefined;
    if (hasApp) {
      return (
        <div className={storeVisibilityStyle}>
          <FormSection sectionTitle={translate('Stores_visibility') as string} showDivider={false}>
            <AssignStoresVisibility
              description={
                <Translate
                  id="Change_the_visibility_of_the_stores_on_{appType}_app"
                  data={{ AppType: appType }}
                />
              }
              appId={appId}
              appType={appType}
              channelId={channelId}
              isDisabled={!hasEditSalesChannelsPermission}
              translate={translate}
            />
          </FormSection>
        </div>
      );
    }
    return renderNoAppMessage(appType);
  };

  return (
    <PaperContainer>
      <div className={classes.settingContainer}>
        {renderAppSalesChannelConfig('Android', hasAndroidApp)}
      </div>
      <div className={classes.settingContainer}>
        {renderAppSalesChannelConfig('iOS', hasIosApp)}
      </div>
    </PaperContainer>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['EditSalesChannels']);
  return {
    appId: currentApp.AppId as string,
    hasEditSalesChannelsPermission: getPermissionsSelector(state),
    hasAndroidApp: currentApp.HasAndroidApp || false,
    hasIosApp: currentApp.HasIosApp || false,
    translate: getTranslate(state.locale),
  };
};

const EnhancedComponent = connect(mapStateToProps)(LegacyMobileSalesChannelsConfig);

export { EnhancedComponent as LegacyMobileSalesChannelsConfig };
