import React, { useEffect } from 'react';

import { App } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { checkVisibility } from 'react-redux-permissions/dist/core';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { compose } from 'recompose';

import LazyComponent from '@fd/ui/LazyComponent';

import { lazyWithRetry } from '../../helpers/utilities';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import { isFlipdishGlobal } from '../../selectors/app.selector';
import {
  reset,
  subscribeBankingUpdates,
  unSubscribeBankingUpdates,
} from './Banking/banking.actions';
import BankingCreate from './Banking/Create/BankingCreate';
import BankingDetails from './Banking/Details/BankingDetails';
import BankingEdit from './Banking/Edit/BankingEdit';
import BankingList from './Banking/List/BankingList';
import PayoutDetails from './Payouts/components/PayoutDetails/PayoutDetails';
import PayoutReports from './Payouts/components/PayoutReports';
import RestaurantVoucherCreate from './RestaurantVouchers/Create/RestaurantVoucherCreate';
import RestaurantVouchersList from './RestaurantVouchers/List/RestaurantVouchersList';

const PayoutReportV3 = lazyWithRetry(
  () => import('./Payouts/components/PayoutDetailsV3/PayoutReportV3')
);
type Props = MappedProps & MappedDispatch & RouteComponentProps;

const FinanceRoutes: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    isFlipdishGlobal,
    match: { path },
    canCreate,
    reset,
    unSubscribeBankingUpdates,
    subscribeBankingUpdates,
  } = props;

  useEffect(() => {
    subscribeBankingUpdates();
    return () => {
      unSubscribeBankingUpdates();
      reset();
    };
  }, []);

  return (
    <ErrorBoundary identifier="bank-accounts">
      <Switch>
        {canCreate && !isFlipdishGlobal && (
          <Route exact path={`${path}/bank-accounts/new`} component={BankingCreate} />
        )}

        <Route exact path={`${path}/bank-accounts/:accountId`} component={BankingDetails} />

        {canCreate && (
          <Route exact path={`${path}/bank-accounts/:accountId/edit`} component={BankingEdit} />
        )}

        {canCreate && !isFlipdishGlobal && (
          <Route
            exact
            path={`${path}/restaurant-vouchers/new`}
            component={RestaurantVoucherCreate}
          />
        )}

        <Route exact path={`${path}/payouts`} component={PayoutReports} />
        <Route exact path={`${path}/payouts/:payoutId`} component={PayoutDetails} />
        <Route
          exact
          path={`${path}/payoutsv3/:payoutId`}
          render={() => (
            <LazyComponent>
              <PayoutReportV3 />
            </LazyComponent>
          )}
        />
        <Route path={`${path}/bank-accounts`} component={BankingList} />
        <Redirect exact from={`${path}/banking`} to={`${path}/bank-accounts`} />
        <Redirect exact from={`${path}/banking/`} to={`${path}/bank-accounts`} />
        <Redirect
          exact
          from={`${path}/bankaccounts/:bankAccountId/payouts/:payoutId`}
          to={`${path}/payouts/:payoutId/?bankAccountId=:bankAccountId`}
        />
        <Route path={`${path}/restaurant-vouchers`} component={RestaurantVouchersList} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const canCreate = checkVisibility(
    state.permissions,
    [App.AppResourceSetEnum.CreateBankAccounts],
    []
  );
  return {
    canCreate,
    isFlipdishGlobal: isFlipdishGlobal(state),
  };
}
type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  subscribeBankingUpdates: () => dispatch(subscribeBankingUpdates()),
  unSubscribeBankingUpdates: () => dispatch(unSubscribeBankingUpdates()),
  reset: () => {
    dispatch(reset());
  },
});

export { FinanceRoutes };
export default compose<Props, RouteComponentProps>(connect(mapStateToProps, mapDispatchToProps))(
  FinanceRoutes
);
