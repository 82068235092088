import * as React from 'react';
import { useState } from 'react';

import AddAPhoto from '@mui/icons-material/AddAPhotoOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Md5 } from 'ts-md5';

const useStyles = makeStyles((theme: Theme) => ({
  mainDiv: {
    width: '100%',
    paddingRight: 32,
    [theme.breakpoints.only('md')]: {
      paddingRight: 24,
    },
    [theme.breakpoints.only('sm')]: {
      paddingRight: 20,
    },
    [theme.breakpoints.only('xs')]: {
      paddingRight: 0,
    },
  },
  gravatarDiv: {
    position: 'relative',
    width: '100%',
    maxWidth: '223px',
    height: 'auto',
    borderRadius: '223px',
    '&:hover': {
      '& > div:nth-child(2)': {
        opacity: 0.6,
      },
      '& > div:nth-child(3)': {
        opacity: 1,
      },
    },
  },
  gravatarImage: {
    display: 'block',
    width: '100%',
    height: 'auto',
    borderRadius: '180px',
  },
  gravatarOverlay: {
    position: 'absolute',
    width: '100%',
    height: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '180px',
    opacity: 0,
    transition: '.5s ease',
    backgroundColor: '#b9d4fc',
    fontSize: '0px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  gravatarInsideOverlay: {
    position: 'absolute',
    margin: 'auto',
    width: '56px',
    height: '56px',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '28px',
    boxShadow: '0 2px 4px 0',
    opacity: 0,
    backgroundColor: '#ffffff',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const ProfileGravatar = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isWidthDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  const account = useSelector((state: AppState) => state.account);
  const translate = useSelector((state: AppState) => getTranslate(state.locale));

  const avatarImageUrl = account.Email
    ? 'https://www.gravatar.com/avatar/' +
      Md5.hashStr(account.Email) +
      '?d=https%3A%2F%2Fflipdish.imgix.net%2Fdefault-avatar.jpg' +
      (isWidthDownMd ? '' : '&s=200')
    : '';

  const gravatarClick = () => {
    if (!isWidthDownMd) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.mainDiv}>
        <div
          className={classes.gravatarDiv}
          data-fd="btn-gravatar-change-open"
          onClick={gravatarClick}
        >
          <img src={avatarImageUrl} className={classes.gravatarImage} />
          <div className={classes.gravatarOverlay} />
          <div className={classes.gravatarInsideOverlay}>
            <AddAPhoto style={{ margin: '16px' }} />
          </div>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{translate('Change_avatar')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translate('Would_you_like_to_change_your_avatar_on_gravatar')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" data-fd="btn-gravatar-change-cancel">
              {translate('Cancel')}
            </Button>
            <Button
              onClick={handleChange}
              color="primary"
              autoFocus
              data-fd="btn-gravatar-change-agree"
            >
              {translate('Change')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ProfileGravatar;
