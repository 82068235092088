import React, { useEffect, useState } from 'react';

import { useInfiniteQueryAllInvoicesHook } from '@fd/customHooks/useInfiniteQueryAllInvoices';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { CheckboxField } from '@fd/ui/atoms';
import EmptyComponent from '@fd/ui/EmptyComponent';

import { notifyError } from '../../../layouts/Notify/actions';
import { showPausedPayouts } from '../../../selectors/app.selector';
import { flagService } from '../../../services/flagService';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import DynamicAlertBanner from '../../../ui/molecules/DynamicAlertBanner/DynamicAlertBanner';
import LoadingListItem from '../../Finance/Banking/List/LoadingListItem';
import { billingService } from '../billing.service';
import SubscriptionsListItem from './SubscriptionListItem';

const useStyles = makeStyles((theme: Theme) => ({
  mobileFilters: {
    padding: theme.spacing(1),
  },
  subscriptionsList: {
    marginTop: theme.spacing(2),
  },
  mySubscriptions: {
    marginTop: theme.spacing(1),
    display: 'flex',
    columnGap: theme.spacing(3),
  },
  desktopContainer: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  mobileContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const SubscriptionsList = (props: MappedState & MappedDispatch) => {
  const {
    appId,
    dispatchNotifyError,
    isAlertBannerInvoiceOn,
    showPausedPayouts = false,
    translate,
  } = props;
  const classes = useStyles();
  const [showMySubscriptions, setShowMySubscriptions] = useState(false);
  const [showCancelledSubscriptions, setShowCancelledSubscriptions] = useState(false);

  const {
    data: subscriptions,
    isPending,
    isFetching,
    isError,
  } = useQuery({
    queryKey: [
      billingService.getSubscriptionsForAppQueryKey,
      appId,
      showMySubscriptions,
      showCancelledSubscriptions,
    ],

    queryFn: () =>
      billingService.getSubscriptionsForApp(
        appId!,
        showMySubscriptions,
        undefined,
        showCancelledSubscriptions
      ),
    enabled: !!appId,
  });

  useEffect(() => {
    if (isError) {
      dispatchNotifyError();
    }
  }, [isError]);

  const { overdueInvoices } = useInfiniteQueryAllInvoicesHook({
    appId: appId,
    isEnabled: !!subscriptions,
  });
  const showBanner = isAlertBannerInvoiceOn && !showPausedPayouts && overdueInvoices?.length > 0;

  const loadingSubscriptions = isPending || isFetching;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageLayout title={<Translate id="Subscriptions" />} documentTitle="Subscriptions">
      {showBanner && (
        <DynamicAlertBanner
          backgroundColor="yellow"
          bannerText={'You_have_1_or_more_invoices_overdue_payment'}
          buttonText={'Go_to_invoices'}
          showButton={true}
          toUrl={`/${appId}/billing/invoices`}
        />
      )}
      <Grid container className={isMobile ? classes.mobileContainer : classes.desktopContainer}>
        <Grid
          item
          xs={12}
          className={
            isMobile
              ? clsx(classes.mobileFilters, classes.mySubscriptions)
              : classes.mySubscriptions
          }
        >
          <CheckboxField
            ariaLabel={translate('Checkbox_for_only_showing_subscriptions_that_you_o') as string}
            checked={showMySubscriptions}
            fdKey="my-subscriptions-filter"
            label={translate('Show_only_my_subscriptions') as string}
            onChange={() => setShowMySubscriptions(!showMySubscriptions)}
          />
          <CheckboxField
            ariaLabel={translate('Checkbox_for_showing_cancelled_subscriptions') as string}
            checked={showCancelledSubscriptions}
            fdKey="show-cancelled-filter"
            label={translate('Show_cancelled') as string}
            onChange={() => setShowCancelledSubscriptions(!showCancelledSubscriptions)}
          />
        </Grid>
      </Grid>

      <PaperContainer fluid className={classes.subscriptionsList}>
        <List component="nav">
          {loadingSubscriptions ? (
            <LoadingListItem />
          ) : (
            subscriptions?.map((subscription, index: number) => {
              return (
                <SubscriptionsListItem
                  appId={appId}
                  subscription={subscription}
                  isLast={index + 1 === subscriptions.length}
                  key={subscription.SubscriptionId}
                  overdue={overdueInvoices?.includes(subscription?.SubscriptionId)}
                />
              );
            })
          )}
          {isMobile && !!subscriptions?.length && <Divider />}
          {!loadingSubscriptions && !subscriptions?.length ? (
            <EmptyComponent title="No_subscriptions_found" />
          ) : null}
        </List>
      </PaperContainer>
    </PageLayout>
  );
};
type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp.AppId,
    isAlertBannerInvoiceOn: flagService.isFlagOn(state, 'alertBannerInvoice'),
    translate: getTranslate(state),
    showPausedPayouts: showPausedPayouts(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: () =>
    dispatch(
      notifyError({
        message: 'Error_please_try_again_later',
        translate: true,
      })
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsList);
