import { EmvTerminalWithAssignments, HydraStoreData, Store } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

const currentAppSelector = (state: AppState) => state.currentApp;

export const getKioskListUrl = createSelector([currentAppSelector], (app) =>
  app ? `/${app.AppId}/kiosks` : undefined
);

export const getAddNewKioskUrl = createSelector([currentAppSelector], (app) =>
  app ? `/${app.AppId}/kiosks/new` : undefined
);

const kioskStores = (state: AppState) => state.kiosks.stores;
export const getStoreList = createSelector([kioskStores], (stores: Store[]) => {
  if (stores) {
    return stores.map((store) => {
      return { value: store.StoreId, label: store.Name };
    });
  } else {
    return [];
  }
});

export const getEMVsList = createSelector(
  [
    (terminals: EmvTerminalWithAssignments[]) => terminals,
    (terminals, deviceId: string) => deviceId,
  ],
  (emvsList, deviceId) => {
    const isAssigned = emvsList.some((e) => e.HydraDeviceId === deviceId);
    if (isAssigned) {
      return emvsList.filter((e) => e.HydraDeviceId === deviceId);
    } else {
      return emvsList.filter((e) => !e.IsAssignedToHydraDevice);
    }
  }
);

export const getKioskDetailsUrlFactory = (deviceId: string | undefined) =>
  createSelector([currentAppSelector], (app) =>
    app ? `/${app.AppId}/kiosks/${deviceId}` : undefined
  );

export const getStoresAttachedToKioskFactory = (deviceId: string | undefined) =>
  createSelector([kioskSelector], (kiosks) => {
    const currentKiosk = kiosks && kiosks.find((k) => k.DeviceId === deviceId);
    return currentKiosk?.StoreNames;
  });

export const isAppLocatedInUSA = createSelector(
  [currentAppSelector],
  (app) => app?.CountryId === 'US'
);

const getKioskBluetoothTerminalUpdated = (state: AppState) =>
  state.kiosks.kioskBluetoothTerminalUpdated;

const getKioskBluetoothTerminalUnpaired = (state: AppState) =>
  state.kiosks.kioskBluetoothTerminalUnpaired;

type KioskSelector = ReturnType<typeof kioskSelector>;
const kioskSelector = (state: AppState) => state.kiosks.kiosks;

export const getKioskIds = createSelector([kioskSelector], (kioskList) =>
  kioskList.reduce<(string | undefined)[]>((acc, cur) => {
    return [...acc, cur.DeviceId];
  }, [])
);

export const kioskSelectors = {
  getKioskBluetoothTerminalUpdated,
  getKioskBluetoothTerminalUnpaired,
};
