import React, { useEffect, useState } from 'react';

import { Teammate, TeammatesApiFactory } from '@flipdish/api-client-typescript';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { accountActions } from '../../../actions/account.actions';
import { createApi } from '../../../helpers/utilities';
import Loading from '../../Loading';

const teammatesApi = createApi(TeammatesApiFactory);

const styles = () =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      flexDirection: 'column',
    },
    headline: {
      lineHeight: '1.2',
      marginBottom: '4px',
    },
    redirecting: {
      fontSize: '20px',
      color: 'rgba(0, 0, 0, 0.54)',
      lineHeight: '1.4',
      letterSpacing: 'normal',
    },
  });

type Props = WithStyles<typeof styles> & RouteComponentProps & MappedDispatch & MappedState;

function AcceptInvitation(props: Props) {
  const { classes, history } = props;

  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const otc = urlParams.get('otc');

    if (!otc) {
      setInitialized(true);
      setError(true);
      console.error(new Error('Invitation token is required!'));
    } else {
      teammatesApi.redeemInvitation(otc, '-').then((response) => {
        const { AppId, InvitationStatus } = response.Data;

        if (InvitationStatus === Teammate.InvitationStatusEnum.Accepted) {
          if (AppId && AppId !== '') {
            localStorage.setItem('selectedAppId', AppId);
          }
          if (props.account.authorized) {
            props.logout();
          } else {
            history.replace('/login');
          }
        } else {
          setInitialized(true);
          setExpired(true);
          setTimeout(() => {
            history.replace('/login');
          }, 1000 * 5);
        }
      });
    }
  }, []);

  if (loading) {
    return <Loading fullscreen animate={!initialized} onAnimationEnd={() => setLoading(false)} />;
  }

  if (expired) {
    return (
      <div className={classes.root}>
        <Typography variant="h2" color="primary" align="center" className={classes.headline}>
          <Translate id="Your_invitation_already_expired" />
        </Typography>
        <Typography color="textSecondary" align="center" className={classes.redirecting}>
          <Translate id="Redirecting_to" data={{ routeName: 'Login...' }} />
        </Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.root}>
        <Typography variant="h2" color="error" align="center">
          <Translate id="Error_please_try_again_later" />
        </Typography>
      </div>
    );
  }

  return <Loading fullscreen />;
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps) => {
  return {
    logout: () => {
      dispatch(accountActions.logout());
    },
  };
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { account } = state;
  return {
    account,
  };
};

const EnhancedComponent = compose(
  withStyles(styles, {
    name: 'AcceptInvitation',
  }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AcceptInvitation);

export { EnhancedComponent as AcceptInvitation };
