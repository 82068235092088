import * as React from 'react';

import type * as APIClient from '@flipdish/api-client-typescript';
import DeveloperIcon from '@mui/icons-material/CodeOutlined';
import LogOutIcon from '@mui/icons-material/MeetingRoomOutlined';
import ProfileIcon from '@mui/icons-material/PersonOutline';
import UpdateIcon from '@mui/icons-material/Update';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { getActiveLanguage, getTranslate, setActiveLanguage } from 'react-localize-redux';
import { type DispatchProp, connect } from 'react-redux';
import Permissions, { add, remove } from 'react-redux-permissions';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Md5 } from 'ts-md5';

import { accountActions } from '../actions/account.actions';
import constants from '../constants.json';
import { accountRoutesConst as routes } from '../routes/account.routes';
import { isSubscriptionsBillingEnabled } from '../selectors/app.selector';
import DevLongLanguageToggle from './DevLongLanguageToggle';
import HiddenFeatureToggle from './HiddenFeatureToggle';

const NavLinkListItem = ListItem as React.ComponentType<React.PropsWithChildren<any>>;

const styles = (theme: Theme) => {
  return createStyles({
    holder: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    unselectable: {
      tabIndex: -1,
      outline: 0,
    },
    blank: {
      color: 'inherit',
      textDecoration: 'none',
    },
    languageIcon: {
      marginRight: theme.spacing(1),
    },
    menu: {
      zIndex: 1400,
    },
    avatar: {
      height: '32px',
      width: '32px',
    },
    name: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.2px',
    },
    personalDetails: {
      paddingLeft: theme.spacing(2),
    },
  });
};
interface IMaterialUiProps {
  classes?: any;
}

interface IProfileMenuProps {
  account: APIClient.AccountDetail;
  currentApp: APIClient.App;
  dispatch: (input) => void;
  classes: any;
  translate: (input: string) => string;
  activeLanguage: string;
  showSubscriptions: boolean;
}

export type Props = IProfileMenuProps & IMaterialUiProps & DispatchProp<any>;

class ProfileMenu extends React.Component<Props, any> {
  public state = {
    anchorEl: undefined,
  };

  public handleHiddenFeatureChange = (action, role) => {
    const { dispatch, account } = this.props;
    if (action === 'add') {
      dispatch(add(role));
      dispatch(accountActions.update({ ...account, ShowHiddenFeatures: true }));
    } else {
      dispatch(remove(role));
      dispatch(accountActions.update({ ...account, ShowHiddenFeatures: false }));
    }
  };

  public handleDevLangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const activeLanguage = event.target.checked ? 'dev-lang' : 'en';
    this.props.dispatch(setActiveLanguage(activeLanguage));
  };

  public handleChange = (event, checked) => {
    this.setState({ auth: checked });
  };

  public handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  public handleClose = () => {
    this.setState({ anchorEl: undefined });
  };

  public render() {
    const { classes, account, currentApp, translate, showSubscriptions } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const appId = currentApp ? currentApp.AppId : 'global';
    const avatarImageUrl = account.Email
      ? 'https://www.gravatar.com/avatar/' + Md5.hashStr(account.Email)
      : '';

    const accountName = () => {
      if (account.Name && account.Name.length > 47) {
        return account?.Name?.substring(0, 47) + '...';
      }
      return account.Name;
    };

    return (
      <React.Fragment>
        <div
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenu}
          className={classes.holder}
          data-fd="profile-icon"
        >
          <Typography
            variant="h6"
            component="h6"
            className={classes.name}
            data-fd="profile-name-top-bar"
          >
            {accountName()}
          </Typography>
          <IconButton color="inherit" data-fd="profile-menu-btn">
            <Avatar src={avatarImageUrl} className={classes.avatar} />
          </IconButton>
        </div>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          className={classes.menu}
          id="menu-appbar"
          onClose={this.handleClose}
          open={open}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <ListItem className={classes.unselectable}>
            <Avatar src={avatarImageUrl} />
            <ListItemText
              className={classes.personalDetails}
              primary={accountName()}
              secondary={`${account.Email}`}
              data-fd="profile-name"
            />
          </ListItem>
          <Permissions except={['Onboarding']}>
            <NavLinkListItem
              button
              className={classes.blank}
              component={Link}
              data-fd="profile-account"
              onClick={this.handleClose}
              to={`/${appId}/profile`}
            >
              <ListItemIcon>
                <ProfileIcon />
              </ListItemIcon>
              <ListItemText primary={translate('My_account')} />
            </NavLinkListItem>
          </Permissions>
          <Permissions
            allowed={[
              'Owner',
              'StoreOwner',
              'StoreManager',
              'ManagedOwner',
              'FinanceManger',
              'FlipdishStaff',
            ]}
          >
            {appId !== 'global' && appId !== 'flipdish-global' && showSubscriptions ? (
              <NavLinkListItem
                button
                className={classes.blank}
                component={Link}
                data-fd="my-subscriptions"
                onClick={this.handleClose}
                to={`/${appId}/billing/subscriptions`}
              >
                <ListItemIcon>
                  <UpdateIcon />
                </ListItemIcon>
                <ListItemText primary={translate('My_subscriptions')} />
              </NavLinkListItem>
            ) : null}
          </Permissions>
          <Permissions except={['Onboarding']}>
            <NavLinkListItem
              className={classes.blank}
              component={Link}
              button
              to={`/${appId}/developers`}
              data-fd="menu-link-developers"
              onClick={this.handleClose}
            >
              <ListItemIcon>
                <DeveloperIcon />
              </ListItemIcon>
              <ListItemText primary={translate('developer_tools')} />
            </NavLinkListItem>
          </Permissions>
          <NavLinkListItem
            button
            className={classes.blank}
            component={Link}
            data-fd="profile-logout"
            onClick={this.handleClose}
            to={routes.Logout}
          >
            <ListItemIcon onClick={this.handleClose} style={{ minWidth: '56px' }}>
              <LogOutIcon />
            </ListItemIcon>
            <ListItemText primary={translate('Logout')} />
          </NavLinkListItem>
          <Permissions allowed={['FlipdishStaff']}>
            {constants.GIT_BRANCH !== 'master' ? (
              <MenuItem>
                <DevLongLanguageToggle
                  checked={this.props.activeLanguage === 'dev-lang'}
                  handleDevLangChange={this.handleDevLangChange}
                />
              </MenuItem>
            ) : null}
            <MenuItem>
              <HiddenFeatureToggle
                checked={account.ShowHiddenFeatures}
                handleHiddenFeatureChange={this.handleHiddenFeatureChange}
              />
            </MenuItem>
          </Permissions>
        </Menu>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { account, currentApp, locale } = state;
  return {
    account,
    currentApp,
    translate: getTranslate(locale),
    activeLanguage: getActiveLanguage(locale),
    showSubscriptions: isSubscriptionsBillingEnabled(state),
  };
}

const EnhancedComponent = compose(
  withStyles(styles, {
    name: 'ProfileMenu',
  }),
  connect(mapStateToProps)
)(ProfileMenu);

export { EnhancedComponent as ProfileMenu };
