import { SupportedCountry } from '@flipdish/api-client-typescript';
import _property from 'lodash/property';
import { createSelector } from 'reselect';

export const getAccount = (state: AppState) => state.account;

export const getIsAccountAuthorized = createSelector([getAccount], _property('authorized'));
export const getSignupSteps = createSelector([getAccount], _property('SignupSteps'));
export const getIsFirstSetup = createSelector([getSignupSteps], (signupSteps) => {
  if (signupSteps) {
    return signupSteps.length > 0;
  }
  return false;
});

export const getSupportedCountry = (
  supportedCountries?: SupportedCountry[],
  storeCountryCode?: string
) => {
  return supportedCountries && supportedCountries.find((c) => c.CountryCode === storeCountryCode);
};
