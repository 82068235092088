import * as React from 'react';

import Done from '@mui/icons-material/DoneOutlined';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { Translate } from 'react-localize-redux';
import { compose } from 'redux';

const styles = (theme: Theme): any => ({
  stepsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 5,
    top: 0,
    left: 0,
    right: 0,
    height: theme.spacing(12),
    backgroundColor: 'rgba(7, 4, 62, 0.7)',
    paddingLeft: theme.spacing(4),
    borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
  },
  stepDiv: {
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(15.5),
    height: theme.spacing(4),
    color: 'white',
    marginRight: theme.spacing(4),
  },
  stepCircle: {
    display: 'inline-block',
    height: theme.spacing(4),
    width: theme.spacing(4),
    border: 'white 2px solid',
    borderRadius: '50%',
    textAlign: 'center',
    paddingTop: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.0625),
  },
  stepCircleText: {
    color: 'white',
  },
  stepText: {
    display: 'inline-block',
    width: theme.spacing(11.5),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '0.5625rem',
    color: 'white',
    paddingLeft: theme.spacing(1.5),
    lineHeight: 'normal',
  },
  stepCircleActive: {
    backgroundColor: 'white',
  },
  stepCircleTextActive: {
    color: '#05149e',
  },
  stepFollowing: {
    opacity: 0.5,
  },
});

class StepsHeader extends React.Component<any, any> {
  public constructor(props) {
    super(props);
  }

  public render() {
    const { classes, steps, stepIndex } = this.props;

    return (
      <div className={classes.stepsHeader}>
        {steps.map((step, index) => (
          <div
            key={'step-' + index}
            className={`${classes.stepDiv} ${index > stepIndex ? classes.stepFollowing : ''}`}
          >
            <div
              className={`${classes.stepCircle} ${
                index === stepIndex ? classes.stepCircleActive : ''
              }`}
            >
              {index < stepIndex ? (
                <Done />
              ) : (
                <Typography
                  className={`${classes.stepCircleText} ${
                    index === stepIndex ? classes.stepCircleTextActive : ''
                  }`}
                  variant="subtitle1"
                >
                  {index + 1}
                </Typography>
              )}
            </div>
            <Typography className={classes.stepText} variant="body2">
              <Translate id={step} />
            </Typography>
          </div>
        ))}
      </div>
    );
  }
}

const EnhancedComponent = compose(
  withStyles(styles, {
    name: 'StepsHeader',
    withTheme: true,
  })
)(StepsHeader);

export { EnhancedComponent as StepsHeader };
