import React from 'react';

import { Field } from 'formik';

import { InputField, InputFieldProps } from '../../atoms/InputField/InputField';

type Props = InputFieldProps & {
  validate?: any;
  field?: any;
  form?: any;
  description?: string;
};

export const FormikInputField = (props: Props) => {
  const { name, fdKey, helperText, validate, disabled, ...rest } = props;
  return (
    <Field name={name} validate={validate}>
      {({ field, form }: Props) => {
        const { isSubmitting, errors, touched } = form;
        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean);
        return (
          <InputField
            {...field}
            {...rest}
            fdKey={fdKey}
            showError={showError}
            helperText={showError ? fieldError : helperText}
            disabled={isSubmitting || disabled}
          />
        );
      }}
    </Field>
  );
};
