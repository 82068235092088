import React from 'react';

import { useMutationImageUploadHook } from '@fd/customHooks/useMutationImageUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button } from '@fd/ui/atoms/Button';
import { ImageContainer } from '@fd/ui/atoms/ImageContainer';
import { UploadFileButton } from '@fd/ui/molecules/UploadFileButton';

import { notify, NotifyProps } from '../../../../layouts/Notify/actions';
import { generateSrcSet } from '../../utils';

type StyleProps = {
  isError: boolean;
};
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: (props) => (props.isError ? theme.spacing(2) : 0),
  },
  deleteButton: {
    marginLeft: theme.spacing(2),
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: (props) => (props.isError ? 0 : theme.spacing(2)),
    marginTop: theme.spacing(2),
  },
  largeImageBox: {
    marginRight: theme.spacing(3),
  },
  errorMessage: {
    // same colour as formik fields
    color: '#e53935',
  },
}));

export type ImagerySectionProps = MappedDispatch & {
  appId: string;
  description: string | JSX.Element;
  disabled?: boolean;
  errorMessage?: string | null;
  imageFileName?: string;
  isLoading: boolean;
  onImageUploaded: (imageName: string) => void;
  onImageRemoved: () => void;
  uploadImage: (appId: string, image: File) => Promise<any>;
  showLarge?: boolean;
  srcSet?: string;
  translate: TranslateFunction;
  smallImageTitle?: string | null;
  useFullUri?: boolean;
};

const ImagerySection = (props: ImagerySectionProps): JSX.Element => {
  const {
    appId,
    srcSet = undefined,
    description,
    disabled,
    imageFileName,
    isLoading,
    uploadImage,
    dispatchNotify,
    onImageUploaded,
    onImageRemoved,
    showLarge = false,
    translate,
    smallImageTitle = null,
    errorMessage = null,
    useFullUri = false,
  } = props;
  const classes = useStyles({ isError: !!errorMessage });

  const addImageMutation = useMutationImageUploadHook(
    uploadImage,
    appId,
    onImageUploaded,
    dispatchNotify,
    useFullUri
  );

  const handleImageUploaded = (imageFiles) => {
    if (imageFiles[0]) {
      addImageMutation.mutate(imageFiles[0]);
    }
  };

  const handleImageRemoved = () => {
    onImageRemoved();
  };

  const addImageButtonTitle = imageFileName
    ? translate('Change_image')
    : translate('Products_EditProductForm_AddImage');

  const imageLoading = isLoading || addImageMutation.isLoading;

  return (
    <>
      {description}
      <div className={classes.imagesContainer}>
        {showLarge && (
          <ImageContainer
            className={classes.largeImageBox}
            fdKey="large-product-image"
            disabled={disabled}
            srcSet={generateSrcSet(imageFileName, 'large')}
            isLoading={imageLoading}
            onFileDrop={handleImageUploaded}
            title={translate('Products_EditProductForm_Large') as string}
            variant="large"
            isError={!!errorMessage}
          />
        )}
        <ImageContainer
          title={smallImageTitle || (translate('Products_EditProductForm_Small') as string)}
          fdKey="small-product-image"
          disabled={disabled}
          srcSet={srcSet || generateSrcSet(imageFileName)}
          onFileDrop={handleImageUploaded}
          isLoading={imageLoading}
          variant="small"
          isError={!!errorMessage}
        />
      </div>
      <Typography className={classes.errorMessage} variant="caption">
        {errorMessage}
      </Typography>
      <div className={classes.buttonContainer}>
        <UploadFileButton
          disabled={disabled}
          fdKey="product-add-image"
          onFileChange={handleImageUploaded}
        >
          {addImageButtonTitle}
        </UploadFileButton>
        {imageFileName && (
          <Button
            className={classes.deleteButton}
            fdKey="product-delete-image"
            disabled={disabled}
            onClick={handleImageRemoved}
            variant="secondary"
          >
            <DeleteOutlineIcon />
          </Button>
        )}
      </div>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotify: (data: NotifyProps) => dispatch(notify(data)),
});

const EnhancedComponent = connect(null, mapDispatchToProps)(ImagerySection);
export { EnhancedComponent as ImagerySection };
