import React from 'react';

import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import InventoryIcon from '@mui/icons-material/Inventory2Outlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import List from '@mui/material/List';
import { connect } from 'react-redux';

import { toKebabCase } from '../../../helpers/strings';
import { getTranslate, Translate } from '../../../overrides/react-localize-redux';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import ListItemLinkButton from '../../../ui/List/ListItemLinkButton';
import { RMSModules } from '../../RMS/utils/rmsModules';

const PrintingTemplates = (props: MappedState) => {
  const { currentApp, translate } = props;

  const baseUrl = `/${currentApp?.AppId}/settings/printing-templates`;

  return (
    <PageLayout documentTitle="Printing_Templates" title={<Translate id="Printing_Templates" />}>
      <PaperContainer fluid>
        <List aria-label={translate('Printing_Templates') as string}>
          <ListItemLinkButton
            subtitle="Manage_Receipt_Templates"
            title="Receipt_Templates"
            iconComponent={<InventoryIcon />}
            link={`${baseUrl}/${toKebabCase(RMSModules.RECEIPT_TEMPLATES)}`}
          />
          <ListItemLinkButton
            title="Kitchen_Station_Templates"
            subtitle="Manage_Kitchen_Station_Printing_Templates"
            link={`${baseUrl}/${toKebabCase(RMSModules.KITCHEN_STATION_TEMPLATES)}`}
            iconComponent={<CategoryOutlinedIcon />}
          />
          <ListItemLinkButton
            title="Voucher_Templates"
            subtitle="Manage_Voucher_Printing_Templates"
            link={`${baseUrl}/${toKebabCase(RMSModules.VOUCHER_TEMPLATES)}`}
            iconComponent={<GroupsOutlinedIcon />}
          />
          <ListItemLinkButton
            title="Daily_Summary_Report_Templates"
            subtitle="Manage_Daily_Summary_Report_Printing_Templates"
            link={`${baseUrl}/${toKebabCase(RMSModules.DAILY_SUMMARY_REPORT_TEMPLATES)}`}
            iconComponent={<ReceiptOutlinedIcon />}
            isLast={true}
          />
        </List>
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    currentApp: state.currentApp,
  };
};

export default connect(mapStateToProps)(PrintingTemplates);
