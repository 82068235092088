import React from 'react';

import { Channel } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';

import AddChannelCard from '../AddChannelCard/AddChannelCard';

type Props = {
  channels: Channel[];
  onSelectOption: (channel: Channel) => void;
  assignedChannels: Channel[];
};

const AddChannelCardList = ({ channels, onSelectOption, assignedChannels }: Props) => {
  const addChannelButtonAction = (channel: Channel) => {
    onSelectOption(channel);
  };

  const checkIfChannelIsInstalled = (channelId?: number) => {
    if (!channelId) {
      return false;
    }

    for (const assignedChannel of assignedChannels) {
      if (assignedChannel.ChannelId === channelId) {
        return true;
      }
    }
  };

  const renderChannels = () =>
    channels?.map((channel, index) => (
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        data-fd={`add-channel-card-${index}`}
        key={channel.ChannelId}
      >
        <AddChannelCard
          buttonAction={() => addChannelButtonAction(channel)}
          titleId={`${channel.TranslationKey}_${channel.Source}` as TranslationId}
          descriptionId={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utlabore et dolore magna'
          }
          buttonTextId="Add"
          key={channel.ChannelId}
          installed={checkIfChannelIsInstalled(channel.ChannelId)}
        />
      </Grid>
    ));

  return (
    <Grid container spacing={3} data-fd="select-channel">
      {renderChannels()}
    </Grid>
  );
};

export default AddChannelCardList;
