import React, { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { Auth0CallbackPath } from '../../../constants/auth0.constants';
import { generateString } from '../../../helpers/utilities';
import { accountService } from '../../../services/account.service';
import Loading from '../../Loading';
import { storePostLoginRedirect } from './utils';

const Auth0Login = () => {
  const redirectUri = window.location.origin.toString() + Auth0CallbackPath;

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const screenHint = params.get('screen_hint');

  const { data, isPending, isError } = useQuery({
    queryKey: ['auth0-config'],
    queryFn: () => accountService.getAuth0Config(),
  });

  useEffect(() => {
    const postLoginRedirect = params.get('redirectUrl');
    storePostLoginRedirect(postLoginRedirect);
  }, [params]);

  useEffect(() => {
    if (isPending || isError || !data) {
      return;
    }

    // Generate random string for state in oauth flow. This should be checked in the callback for additional security.
    const state = generateString(16);
    sessionStorage.removeItem('fdauth0state');
    sessionStorage.setItem('fdauth0state', state);
    const { Auth0Domain, Auth0ClientId } = data;

    const query: { [key: string]: string | null | undefined } = {
      response_type: 'code',
      client_id: Auth0ClientId,
      redirect_uri: redirectUri,
      scope: 'openid profile email offline_access',
      login_hint: email,
      screen_hint: screenHint,
      state,
    };

    const loginUrl = `https://${Auth0Domain}/authorize?${qs.stringify(query)}`;

    window.location.href = loginUrl;
  }, [data, isPending, isError, redirectUri, email, screenHint]);

  return <Loading fullscreen />;
};

export default Auth0Login;
