import React, { useEffect } from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import { FormSection } from '@fd/ui/molecules';

import { getTranslate, Translate } from '../../../overrides/react-localize-redux';
import { useTracking } from '../../../services/amplitude/useTracking';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import AssignStoresVisibility from '../components/AssignStoresVisibility';

const useStyles = makeStyles((theme: Theme) => ({
  PaperContainer: {
    paddingBottom: theme.spacing(5),
  },
}));

type InnerProps = MappedState;
type Props = {} & InnerProps;

export const StoreVisibilityPage = (props: Props) => {
  const { appId, translate } = props;
  const classes = useStyles();
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_SalesChannels_storeVisibility', {
      action: 'logged_in',
    });
  }, []);

  return (
    <PageLayout
      strictToParent={true}
      toParent={`/${appId}/sales-channels/website-settings`}
      documentTitle="Stores_visibility"
      title={<Translate id="Stores_visibility" />}
      userPermissions={'EditSalesChannels'}
    >
      <PaperContainer fluid className={classes.PaperContainer}>
        <FormSection sectionTitle={translate('Stores_visibility') as string} showDivider={false}>
          <AssignStoresVisibility
            description={
              <Translate id="Change_the_visibility_of_the_stores_on_your_website_Only" />
            }
            appId={appId}
            channelId={3}
            translate={translate}
          />
        </FormSection>
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => {
  const { currentApp } = state;
  return {
    appId: currentApp.AppId as string,
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateToProps)(StoreVisibilityPage);
