import React, { useEffect } from 'react';

import {
  App,
  BankAccountDetail,
  StripeConnectedAccountInfo,
} from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useHistory, useParams } from 'react-router';

import { FeatureFlag } from '@fd/ui/FeatureFlag';
import PageLayout from '@fd/ui/Layout';
import GridContainer from '@fd/ui/Layout/GridContainer';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { notify, NotifyProps } from '../../../layouts/Notify/actions';
import { editBankAccountFeature } from '../../../selectors/app.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import * as bankingActions from '../../Finance/Banking/banking.actions';
import { countryCodeToCountry, getAccountById } from '../../Finance/Banking/banking.selectors';
import AlertBankDetails from '../../Finance/Banking/components/AlertBankDetails';
import EditAccountLink from '../../Finance/Banking/components/EditAccountLink';
import EditStripeAccountDetailsButton from '../../Finance/Banking/components/EditStripeAccountDetailsButton';
import GridDivider from '../../Finance/Banking/components/GridDivider';
import RemoveBankAccountButton from '../../Finance/Banking/components/RemoveBankAccountButton';
import StaffVerifyButtons from '../../Finance/Banking/components/StaffVerifyAccount';
import VerifyYourDetails from '../../Finance/Banking/components/VerifyYourDetails';
import DynamicAccountDetails from '../../Finance/Banking/Details/components/DynamicAccountDetails';
import { ManualAccountVerification } from '../../Finance/Banking/Details/components/ManualAccountVerification';
import LoadingDetails from '../../Finance/Banking/Details/LoadingDetails';
import StoreListTruncated from '../../StoreListTruncated';
import { bankingListSearch } from '../helpers';

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    paddingBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(3.25),
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(3),
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  snackbarContent: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    '&>div': {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  snackbarSpan: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.3px',
  },
  snackbarIcon: {
    width: '22px',
    marginRight: theme.spacing(1.5),
  },
  accountStatusUnverified: {
    color: '#ff395f',
    fontWeight: 'normal',
  },
  accountStatusPending: {
    color: '#fbcf7c',
    fontWeight: 'normal',
  },
  accountStatusVerified: {
    color: '#86e8bb',
    fontWeight: 'normal',
  },
  storesTruncated: {
    color: '#05149e',
    fontSize: '16px',
    padding: theme.spacing(0),
    textTransform: 'capitalize',
  },
  subHeadingBankDetails: {
    letterSpacing: '0.38px',
    fontSize: '14px',
    color: '#000000',
  },
  editStripeButton: {
    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(1),
    },
  },
  bottomGridDivider: {
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  stripeHelpText: {
    color: theme.palette.text.secondary,
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type BankingDetailsOnboardingProps = {
  accountPropId?: string;
};

export type Props = BankingDetailsOnboardingProps & { accountId?: string };

const BankingDetailsForOnboarding = (props: Props & MappedState & MappedDispatch) => {
  const {
    appId,
    account,
    accountPropId,
    editBankAccountFeature,
    getBankAccountDetails,
    removeBankAccount,
  } = props;
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLoading = account === undefined;
  const classes = useStyles();
  const { accountId } = useParams<{ accountId: string }>();

  const isStripeCountryAndVerified =
    account &&
    account.StripeConnectedAccountInfo &&
    account.AccountState === BankAccountDetail.AccountStateEnum.Verified;
  const stripeStatus = account?.StripeConnectedAccountInfo?.AccountStatus;
  const accountStatus = account?.AccountState;
  const showStripeAlert =
    account &&
    account.StripeConnectedAccountInfo &&
    account.StripeConnectedAccountInfo.AccountStatus ===
      StripeConnectedAccountInfo.AccountStatusEnum.UpdateExternalAccount;
  const showVerifyStripeDetails =
    account?.AccountState !== BankAccountDetail.AccountStateEnum.Unverified &&
    (stripeStatus === StripeConnectedAccountInfo.AccountStatusEnum.Unverified ||
      stripeStatus === StripeConnectedAccountInfo.AccountStatusEnum.AdditionalInformationRequired);
  const showEditStripeAccountDetails = account && stripeStatus && !showVerifyStripeDetails;
  const showVerifyActions =
    account && account.AccountState === BankAccountDetail.AccountStateEnum.AwatingVerification;
  const showEditButton =
    stripeStatus !== StripeConnectedAccountInfo.AccountStatusEnum.Rejected &&
    stripeStatus !== StripeConnectedAccountInfo.AccountStatusEnum.Disabled &&
    accountStatus !== BankAccountDetail.AccountStateEnum.Unverified;
  const hasActionButtons = showVerifyActions || (editBankAccountFeature && showEditButton);

  const bankingListUrl = `/${appId}/onboarding/banking?${bankingListSearch}`;

  useEffect(() => {
    getBankAccountDetails(accountPropId || accountId);
  }, [accountPropId, accountId]);

  const handleRemoveBankAccount = async () => {
    removeBankAccount();
    history.replace(bankingListUrl);
  };

  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent('portal_onboarding_banking_details', {
      action: 'logged_in',
    });
  }, []);

  return (
    <PageLayout
      documentTitle="Banking"
      strictToParent={true}
      title={account?.AccountName || ''}
      toParent={bankingListUrl}
    >
      <Permissions allowed={[App.AppResourceSetEnum.ViewBankAccounts]}>
        <VerifyYourDetails account={account} showVerifyStripeDetails={showVerifyStripeDetails} />
      </Permissions>
      {editBankAccountFeature && showStripeAlert && <AlertBankDetails />}
      <PaperContainer>
        {isLoading && <LoadingDetails />}

        {account != undefined && (
          <GridContainer>
            <DynamicAccountDetails account={account} />

            <GridDivider />

            <Grid item xs={12} className={clsx(classes.column, classes.gridItem)}>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle1">
                    <Translate id="Account_holder_address" />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    variant="standard"
                    data-fd={account.AccountHolderAddress}
                    defaultValue={account.AccountHolderAddress}
                    fullWidth
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    label={<Translate id="Address" />}
                    multiline
                    maxRows="5"
                  />
                  <TextField
                    variant="standard"
                    data-fd={account.AccountHolderCountryCode}
                    defaultValue={countryCodeToCountry(account.AccountHolderCountryCode)}
                    fullWidth
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    label={<Translate id="Country" />}
                    margin="none"
                  />
                  {account.VatNumber && (
                    <TextField
                      variant="standard"
                      data-fd={account.VatNumber}
                      defaultValue={account.VatNumber}
                      fullWidth
                      InputProps={{ readOnly: true, disableUnderline: true }}
                      label={<Translate id="Vat_number_EU_only" />}
                      margin="none"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <GridDivider />

            <Grid item xs={12} className={clsx(classes.column, classes.gridItem)}>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle1">
                    <Translate id="Associated_stores" />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  {account.StoreNames && account.StoreNames.length > 0 ? (
                    <>
                      <StoreListTruncated
                        btnFontSize={'16px'}
                        color="textPrimary"
                        minWidth={'large'}
                        storeNames={account.StoreNames}
                        textPrefix={false}
                        variant={'body1'}
                      />
                    </>
                  ) : (
                    <Typography className={classes.subHeadingBankDetails}>
                      <Translate id="No_associated_stores" />
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <GridDivider />

            {showEditStripeAccountDetails && (
              <>
                <Grid item xs={12} className={clsx(classes.column, classes.gridItem)}>
                  <Grid container>
                    <Grid item xs={12} sm={5}>
                      <Typography variant="subtitle1">
                        <Translate id="Payouts" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Box className={classes.editStripeButton}>
                        <EditStripeAccountDetailsButton
                          bankAccountId={account.Id}
                          stripeAccount={account.StripeConnectedAccountInfo!}
                        />
                      </Box>
                      <Box pt={2}>
                        <Typography variant="body2" className={classes.stripeHelpText}>
                          <Translate id="We_use_Stripe_helptext" />
                        </Typography>
                        <Typography variant="body2" className={classes.stripeHelpText}>
                          <Translate id="Youll_be_redirected_to_Stripe_helptext" />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <GridDivider />
              </>
            )}

            <Grid
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
              className={classes.gridItem}
            >
              {showVerifyActions && (
                <Permissions allowed={['FlipdishStaff']}>
                  <Box pt={isMobile ? 1.5 : 0} className={classes.button}>
                    <StaffVerifyButtons accountId={account.Id as number} />
                  </Box>
                </Permissions>
              )}
              {/* for stripe accounts we manually approve it first and after we show PayoutSchedule verification */}
              {isStripeCountryAndVerified && (
                <Permissions allowed={['FlipdishStaff']}>
                  <Box pt={isMobile ? 1.5 : 0} className={classes.button}>
                    <ManualAccountVerification
                      stripeAccount={account.StripeConnectedAccountInfo!}
                      appId={appId}
                      getBankAccountDetails={() =>
                        getBankAccountDetails(accountPropId || accountId)
                      }
                    />
                  </Box>
                </Permissions>
              )}

              <FeatureFlag flag="edit-bank-account">
                {showEditButton && (
                  <Box pt={isMobile ? 1.5 : 0} className={classes.button}>
                    <EditAccountLink accountId={account.Id as number} />
                  </Box>
                )}
              </FeatureFlag>
            </Grid>

            {hasActionButtons && (
              <Hidden mdUp>
                <div className={classes.bottomGridDivider}>
                  <GridDivider />
                </div>
              </Hidden>
            )}
          </GridContainer>
        )}
      </PaperContainer>

      {account != undefined && (
        <Box pt={isMobile ? 0.5 : 3} className={classes.button}>
          <RemoveBankAccountButton
            handleRemove={() => handleRemoveBankAccount()}
            storeNames={account.StoreNames}
          />
        </Box>
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps) => {
  const { locale } = initialState;
  const accountIdParam = ownProps.accountId ? ownProps.accountId : ownProps.accountPropId;

  return (state: AppState) => {
    return {
      appId: state.currentApp.AppId!,
      account: getAccountById(state, accountIdParam),
      editBankAccountFeature: editBankAccountFeature(state),
      translate: getTranslate(locale),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps: Props) => ({
  getBankAccountDetails: (accountId: string) => {
    dispatch(bankingActions.getBankAccountDetails(accountId));
  },
  removeBankAccount: () =>
    dispatch(
      bankingActions.removeBankAccount(Number(ownProps.accountPropId || ownProps.accountId))
    ),
  notify: (data: NotifyProps) => dispatch(notify(data)),
});

export default connect(mapStateToPropsFactory, mapDispatchToProps)(BankingDetailsForOnboarding);
