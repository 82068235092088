import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FormattedCurrency } from 'fd-react-intl';
import {
  GetCheckoutSummaryResponse,
  GetCheckoutSummaryResponseItems,
} from 'overrides/@flipdish/subscriptions-api-client-typescript/api';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import GenericTable from '@fd/ui/GenericTable/GenericTable';

import { productTableCheckoutMeta } from '../../data/ProductsMeta';
import { translateInterval } from '../../helpers';

const useStyles = makeStyles(() => ({
  boldText: {
    fontWeight: 500,
  },
  strikethorugh: {
    textDecoration: 'line-through',
  },
  breakWord: {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  modalButton: {
    marginTop: '2rem',
    alignSelf: 'end',
  },
}));

type Props = {
  items: GetCheckoutSummaryResponseItems[] | undefined;
  currency: string;
  isLoading: boolean;
  title: TranslationId;
  interval: GetCheckoutSummaryResponse.IntervalEnum;
} & MappedState;

const ProductTable: React.FC<React.PropsWithChildren<Props>> = ({
  items,
  currency,
  languageCode,
  translate,
  isLoading,
  appId,
  title,
  interval,
}: Props) => {
  const [columns, setColumns] = useState<Set<string>>(new Set());
  const [meta, setMeta] = useState<{ [key: string]: any }>();
  const [productTableData, setProductTableData] = useState<any>([]);
  const classes = useStyles();

  const createProductRows = () => {
    return items?.map((product) => {
      const shouldShowUnitAmount = (product?.quantity ?? 1) > 1 || product.proration === true;
      return {
        Product: (
          <Typography variant="body2" className={clsx(classes.boldText, classes.breakWord)}>
            {product?.description}
          </Typography>
        ),
        Qty: (
          <Typography variant="body2" className={classes.breakWord}>
            {product?.quantity}
          </Typography>
        ),
        Amount: (
          <>
            <Typography variant="body2" className={classes.breakWord}>
              {' '}
              {!!product?.discountAmount && (
                <>
                  <Typography
                    variant="caption"
                    className={clsx(classes.breakWord, classes.strikethorugh)}
                  >
                    <FormattedCurrency
                      value={(product.discountAmount + (product.amount as number)) as number}
                      locales={languageCode}
                      currency={currency.toString() || 'EUR'}
                    />
                  </Typography>{' '}
                </>
              )}
              <FormattedCurrency
                value={product?.amount as number}
                locales={languageCode}
                currency={currency.toString() || 'EUR'}
              />
              {product.proration && (
                <Typography variant="caption" className={classes.breakWord}>
                  {' '}
                  ({translateInterval(interval, 'singular', 'lower')}{' '}
                  {translate('Checkout_remaining')})
                </Typography>
              )}
            </Typography>
            {shouldShowUnitAmount && (
              <Typography variant="caption" className={classes.breakWord}>
                <FormattedCurrency
                  value={product.unitAmount as number}
                  locales={languageCode}
                  currency={currency.toString() || 'EUR'}
                />{' '}
                {product.proration ? (
                  <>
                    {translate('Checkout_per')} {translateInterval(interval, 'singular', 'lower')}
                  </>
                ) : (
                  translate('each')
                )}
              </Typography>
            )}
          </>
        ),
      };
    });
  };

  useEffect(() => {
    setColumns(
      new Set(
        productTableCheckoutMeta
          .filter((m) => m.isVisible)
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((m) => m.columnName)
      )
    );
    setMeta(
      productTableCheckoutMeta.reduce<{ [key: string]: any }>((obj, mData) => {
        obj[mData.columnName] = mData;
        return obj;
      }, {})
    );
    setProductTableData(createProductRows());
  }, [items, isLoading]);

  const setAllColumns = (col) => {
    setColumns(col);
  };

  return (
    <GenericTable
      maxCellWidthProp="300px"
      tableLayoutProp="auto"
      showTitle={true}
      title={title}
      rowsPerPage={100}
      page={0}
      loading={isLoading}
      AppId={appId || ''}
      columns={columns}
      data={productTableData ?? [{}]}
      languageCode={languageCode}
      meta={meta}
      metadata={productTableCheckoutMeta}
      onSortKeyPressed={() => undefined}
      order={'asc'}
      orderBy={'asc'}
      setColumns={setAllColumns}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp?.AppId,
    translate: getTranslate(state),
    languageCode: getActiveLanguage(state.locale),
  };
};

export default connect(mapStateToProps)(ProductTable);
