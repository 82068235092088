import React, { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { useTracking } from '../../../services/amplitude/useTracking';

const useStyles = makeStyles((theme: Theme) => ({
  textTitle: {
    fontSize: '36px',
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  btnPosition: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const WelcomeScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent('portal_onboarding_welcome', {
      action: 'logged_in',
    });
  }, []);

  return (
    <>
      <div className={classes.topContent}>
        <Typography color="primary" className={classes.textTitle}>
          <Translate id="Welcome_to_Flipdish" />
        </Typography>
        <Typography variant="body1">
          <Translate id="Let_us_walk_you_through_all_the_steps_needed_to_ge" />
        </Typography>
      </div>

      <Grid container direction={isMobile ? 'column' : 'row'} className={classes.btnPosition}>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1">
            <Translate id="Press_Next_when_youre_ready_to_begin" />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default WelcomeScreen;
