import React, { useEffect, useState } from 'react';

import { ChangePasswordModel } from '@flipdish/api-client-typescript';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { Dialog } from '@fd/ui/molecules/Dialog';

import { accountActions } from '../../../actions/account.actions';
import { passwordValidationApiErrors, validatePassword } from '../../../helpers/validation';
import { getTranslate, Translate } from '../../../overrides/react-localize-redux';
import { Button } from '../../../ui/atoms';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: '340px',
    maxWidth: '340px',
  },
  textFieldContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  newPassword: {
    marginBottom: '24px',
  },
  textField: {
    minWidth: '200px',
    margin: 0,
  },
  visibilityIcons: {
    marginLeft: '8px',
    opacity: 0.54,
  },
  newPasswordVisibilityIcon: {
    marginBottom: '20px',
  },
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  currentPassword: {
    marginTop: '8px',
    marginBottom: '36px',
  },
}));

type OuterProps = {
  open: boolean;
  account: any;
  onClose: () => void;
};

type InnerProps = MappedState & MappedDispatch;
type Props = OuterProps & InnerProps;

export const ChangePassword = (props: Props) => {
  const { account, updateAccountPassword, onClose, open, translate } = props;

  const classes = useStyles({});

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [currentPasswordMasked, setCurrentPasswordMasked] = useState<boolean>(true);

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordMasked, setNewPasswordMasked] = useState<boolean>(true);
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [currentPasswordError, setCurrentPasswordError] = useState<string | undefined>(undefined);
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (
      account?.error?.length > 0 &&
      account.IsVerified &&
      !passwordValidationApiErrors.includes(account.error)
    ) {
      setCurrentPasswordError(account.error.replace('.', '').replace(' ', '_'));
    } else {
      setCurrentPasswordError(undefined);
    }
  }, [account?.error]);

  const onChangeCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const onChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleChange = () => {
    const changePasswordModel = {} as ChangePasswordModel;

    changePasswordModel.NewPassword = newPassword ? newPassword : '';
    changePasswordModel.OldPassword = currentPassword ? currentPassword : '';

    if (!changePasswordModel.OldPassword) {
      setCurrentPasswordErrorMessage('Required'); // can't be empty
    } else if (
      changePasswordModel.OldPassword.length >
      changePasswordModel.OldPassword.trimStart().trimEnd().length
    ) {
      setCurrentPasswordErrorMessage('No_leading_or_trailing_space'); // can't start \ end with space
    }
    const passwordErrorValidation = validatePassword(changePasswordModel.NewPassword, translate);

    setNewPasswordErrorMessage(passwordErrorValidation);

    !passwordErrorValidation && updateAccountPassword(changePasswordModel);
  };

  const handleClose = () => {
    onClose();
    setNewPassword('');
    setCurrentPassword('');
    setNewPasswordErrorMessage(undefined);
    setNewPasswordMasked(true);
    setCurrentPasswordErrorMessage(undefined);
    setCurrentPasswordMasked(true);
    setCurrentPasswordError(undefined);
  };

  const currentPasswordHelperText =
    currentPasswordError != undefined
      ? translate(`${currentPasswordError}` as TranslationId)
      : currentPasswordErrorMessage && translate(`${currentPasswordErrorMessage}` as TranslationId);

  return (
    <Dialog
      className={classes.dialog}
      title={translate('Change_password') as string}
      onClose={handleClose}
      open={open}
      actions={[
        <Button
          className={classes.cancelButton}
          key="btn-password-change-cancel"
          fdKey="btn-password-change-cancel"
          onClick={handleClose}
          variant="secondary"
        >
          <Translate id="Cancel" />
        </Button>,
        <Button
          autoFocus
          key="btn-password-change-agree"
          fdKey="btn-password-change-agree"
          onClick={handleChange}
          variant="primary"
        >
          <Translate id="Change" />
        </Button>,
      ]}
    >
      <div className={clsx([classes.currentPassword, classes.textFieldContainer])}>
        <TextField
          variant="standard"
          className={classes.textField}
          type={currentPasswordMasked ? 'password' : 'text'}
          error={currentPasswordError != undefined || !!currentPasswordErrorMessage}
          label={translate('Your_current_password')}
          value={currentPassword}
          helperText={currentPasswordHelperText}
          onChange={onChangeCurrentPassword}
          inputProps={{
            'data-fd': 'txt-password-change-old-password',
          }}
        />
        {currentPasswordMasked ? (
          <Visibility
            className={
              currentPasswordError != undefined
                ? clsx(classes.visibilityIcons, classes.newPasswordVisibilityIcon)
                : classes.visibilityIcons
            }
            onClick={() => setCurrentPasswordMasked(false)}
            data-fd="txt-password-change-old-password-visibility-on"
          />
        ) : (
          <VisibilityOff
            className={
              currentPasswordError != undefined
                ? clsx(classes.visibilityIcons, classes.newPasswordVisibilityIcon)
                : classes.visibilityIcons
            }
            onClick={() => setCurrentPasswordMasked(true)}
            data-fd="txt-password-change-old-password-visibility-off"
          />
        )}
      </div>
      <div className={clsx(classes.textFieldContainer, classes.newPassword)}>
        <TextField
          variant="standard"
          placeholder={translate('At_least_12_characters') as string}
          className={classes.textField}
          type={newPasswordMasked ? 'password' : 'text'}
          error={newPasswordErrorMessage != undefined}
          label={translate('Set_a_new_password')}
          value={newPassword}
          helperText={newPasswordErrorMessage || translate('Password_must_contain_two_of')}
          onChange={onChangeNewPassword}
          inputProps={{
            'data-fd': 'txt-password-change-new-password',
          }}
        />
        {newPasswordMasked ? (
          <Visibility
            className={clsx(classes.visibilityIcons, classes.newPasswordVisibilityIcon)}
            onClick={() => setNewPasswordMasked(false)}
            data-fd="txt-password-change-new-password-visibility-on"
          />
        ) : (
          <VisibilityOff
            className={clsx(classes.visibilityIcons, classes.newPasswordVisibilityIcon)}
            onClick={() => setNewPasswordMasked(true)}
            data-fd="txt-password-change-new-password-visibility-off"
          />
        )}
      </div>
    </Dialog>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp.AppId as string,
    translate: getTranslate(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  updateAccountPassword: (password: ChangePasswordModel) => {
    dispatch(accountActions.updatePassword(password));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
