import React, { ChangeEvent, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

import defaultLogo from '../assets/images/app_logo_fork.svg';
import { getBackground } from './utils/generateSrcSet';

const useStyles = makeStyles({
  root: {
    height: 114,
    position: 'relative',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: 'rgba(0, 0, 0, 0.57)',
  },
  input: {
    top: 0,
    left: 0,
    position: 'absolute',
    opacity: 0,
    overflow: 'hidden',
    fontSize: '1000px',
    textIndent: '9999px',
  },
  deleteButton: {
    background: '#fff',
    borderRadius: '50%',
    position: 'absolute',
    top: 6,
    right: 6,
    padding: 0,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    '&:hover': {
      background: '#fff',
    },
  },
});

type Props = {
  fdKey?: string;
  url?: string | null;
  onChange: (data?: File) => void;
  deleteBtn?: boolean;
};

export default ({ onChange, fdKey, url, deleteBtn }: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const bgUrl = getBackground({
    src: url || defaultLogo,
    options: { height: 114, skip: !url || !!value, png: true },
  });

  return (
    <div className={classes.root} style={{ backgroundImage: `url(${bgUrl})` }}>
      <label className={classes.label}>
        <input
          type="file"
          value={value}
          data-fd={fdKey}
          className={classes.input}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);

            if (e.target.files) {
              const file = e.target.files[0];
              onChange(file);
            }
          }}
        />
      </label>
      {url && (value || deleteBtn) ? (
        <IconButton
          className={classes.deleteButton}
          onClick={() => {
            setValue('');
            onChange();
          }}
        >
          <DeleteIcon htmlColor="#ff395f" />
        </IconButton>
      ) : null}
    </div>
  );
};
