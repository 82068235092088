import { StoreGroup, StoreGroupBase, StoreGroupExtended } from '@flipdish/api-client-typescript';

import * as storeGroupConstants from '../constants/storegroup.constants';
import { patch } from '../helpers/patch';
import { createSkipAbleRequest } from '../helpers/requests';
import { getDebounceMaybe } from '../helpers/utilities';
import {
  closeAllOtherNotifications,
  ERROR_KEY,
  notifyError,
  notifySaved,
  notifySaving,
  SAVED_KEY,
  SAVING_KEY,
} from '../layouts/Notify/actions';
import * as services from '../services/storegroup.service';
import { action, actionError } from './utils';

export const isStoreGroupInState = (storeGroupId: number | string, state: AppState) =>
  state.orm.FlipdishStoreGroup.items.some((g) => g === storeGroupId);

// #region set search
const { STORE_GROUP_SET_SEARCH } = storeGroupConstants;

export type SetSearch = ReturnType<typeof setSearch>;
export const setSearch = (query?: string) => action(STORE_GROUP_SET_SEARCH, query);
// #endregion

// #region set selection
const { STORE_GROUP_SET_SELECTION } = storeGroupConstants;

export type SetSelection = ReturnType<typeof setSelection>;
export const setSelection = (storeGroupId: number) =>
  action(STORE_GROUP_SET_SELECTION, storeGroupId);
// #endregion

// #region load
const { STORE_GROUP_GET_ALL_REQUEST, STORE_GROUP_GET_ALL_SUCCESS, STORE_GROUP_GET_ALL_FAILURE } =
  storeGroupConstants;

type LoadProps = {} & {
  appId?: string;
  query?: string;
  page?: number;
  limit?: number;
};
export type LoadRequest = ReturnType<typeof loadRequest>;
export const loadRequest = (props: LoadProps) =>
  action(STORE_GROUP_GET_ALL_REQUEST, undefined, props);

export type LoadSuccess = ReturnType<typeof loadSuccess>;
export const loadSuccess = (
  storeGroups: StoreGroupExtended[],
  props: LoadProps & { totalCount: number }
) => action(STORE_GROUP_GET_ALL_SUCCESS, storeGroups, props);

export type LoadFailure = ReturnType<typeof loadFailure>;
export const loadFailure = (error: Error, props: LoadProps) =>
  actionError(STORE_GROUP_GET_ALL_FAILURE, props, error);

const skipAbleLoadStoreGroup = createSkipAbleRequest<
  services.LoadProps,
  ReturnType<typeof services.load>
>(services.load);

export const load =
  ({ appId, query, page, limit }: LoadProps) =>
  async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const meta = {
      appId: appId || (getState().currentApp.AppId as string),
      query: query || getState().storeGroups.search?.query || undefined,
      page,
      limit,
    };
    try {
      dispatch(loadRequest(meta));

      const result = await skipAbleLoadStoreGroup(meta);

      if (!result || (result && 'skipMe' in result)) {
        return;
      }

      const { Page, Limit, TotalRecordCount } = result;
      dispatch(
        loadSuccess(result.Data, {
          ...meta,
          page: Page,
          limit: Limit,
          totalCount: TotalRecordCount,
        })
      );
    } catch (error) {
      dispatch(loadFailure(error, meta));
    }
  };
// #endregion

// #region loadById
const { STORE_GROUP_GET_REQUEST, STORE_GROUP_GET_SUCCESS, STORE_GROUP_GET_FAILURE } =
  storeGroupConstants;

export type LoadByIdRequest = ReturnType<typeof loadByIdRequest>;
export const loadByIdRequest = (appId: string, storeGroupId: number) =>
  action(STORE_GROUP_GET_REQUEST, undefined, { appId, storeGroupId });

export type LoadByIdSuccess = ReturnType<typeof loadByIdSuccess>;
export const loadByIdSuccess = (storeGroup: StoreGroup, appId: string, storeGroupId: number) =>
  action(STORE_GROUP_GET_SUCCESS, storeGroup, { appId, storeGroupId });

export type LoadByIdFailure = ReturnType<typeof loadByIdFailure>;
export const loadByIdFailure = (error: Error, appId: string, storeGroupId: number) =>
  actionError(STORE_GROUP_GET_FAILURE, { appId, storeGroupId }, error);

export const loadById =
  (appId: string, storeGroupId: number) =>
  async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(loadByIdRequest(appId, storeGroupId));

      const result = await services.loadById(storeGroupId);
      dispatch(loadByIdSuccess(result, appId, storeGroupId));
    } catch (error) {
      dispatch(loadByIdFailure(error, appId, storeGroupId));
      throw error;
    }
  };
// #endregion

// #region create
const { STORE_GROUP_CREATE_REQUEST, STORE_GROUP_CREATE_SUCCESS, STORE_GROUP_CREATE_FAILURE } =
  storeGroupConstants;

export type CreateRequest = ReturnType<typeof createRequest>;
export const createRequest = (appId: string, storeGroup: Partial<StoreGroupBase>) =>
  action(STORE_GROUP_CREATE_REQUEST, { appId, storeGroup });

export type CreateSuccess = ReturnType<typeof createSuccess>;
export const createSuccess = (
  appId: string,
  storeGroup: StoreGroup,
  storeGroupBase: StoreGroupBase
) => action(STORE_GROUP_CREATE_SUCCESS, { appId, storeGroup });

export type CreateFailure = ReturnType<typeof createFailure>;
export const createFailure = (error: Error, appId: string, storeGroup: Partial<StoreGroupBase>) =>
  actionError(STORE_GROUP_CREATE_FAILURE, { appId, storeGroup }, error);

export const create =
  (appId: string, storeGroup: StoreGroupBase) =>
  async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(createRequest(appId, storeGroup));

      const result = await services.create(appId, storeGroup);
      dispatch(createSuccess(appId, result, storeGroup));
    } catch (error) {
      dispatch(createFailure(error, appId, storeGroup));
      throw error;
    }
  };
// #endregion

// #region update
const { STORE_GROUP_UPDATE_REQUEST, STORE_GROUP_UPDATE_SUCCESS, STORE_GROUP_UPDATE_FAILURE } =
  storeGroupConstants;

export type UpdateRequest = ReturnType<typeof updateRequest>;
export const updateRequest = (storeGroupId: number, storeGroup: Partial<StoreGroupBase>) =>
  action(STORE_GROUP_UPDATE_REQUEST, { storeGroupId, storeGroup });

export type UpdateSuccess = ReturnType<typeof updateSuccess>;
export const updateSuccess = (storeGroupId: number, storeGroup: StoreGroup) =>
  action(STORE_GROUP_UPDATE_SUCCESS, { storeGroupId, storeGroup });

export type UpdateFailure = ReturnType<typeof updateFailure>;
export const updateFailure = (
  error: Error,
  storeGroupId: number,
  storeGroup: Partial<StoreGroupBase>
) => actionError(STORE_GROUP_UPDATE_FAILURE, { storeGroupId, storeGroup }, error);

export const update =
  (storeGroupId: number, storeGroup: Partial<StoreGroupBase>) =>
  async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());

      dispatch(updateRequest(storeGroupId, storeGroup));

      const result = await services.update(storeGroupId, storeGroup);
      dispatch(updateSuccess(storeGroupId, result as StoreGroup));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (error) {
      dispatch(updateFailure(error, storeGroupId, storeGroup));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(error));
      throw error;
    }
  };
// #endregion

// #region remove
const { STORE_GROUP_REMOVE_REQUEST, STORE_GROUP_REMOVE_SUCCESS, STORE_GROUP_REMOVE_FAILURE } =
  storeGroupConstants;

export type RemoveRequest = ReturnType<typeof removeRequest>;
export const removeRequest = (storeGroupId: number) =>
  action(STORE_GROUP_REMOVE_REQUEST, undefined, storeGroupId);

export type RemoveSuccess = ReturnType<typeof removeSuccess>;
export const removeSuccess = (storeGroupId: number) =>
  action(STORE_GROUP_REMOVE_SUCCESS, storeGroupId);

export type RemoveFailure = ReturnType<typeof removeFailure>;
export const removeFailure = (error: Error, storeGroupId: number) =>
  actionError(STORE_GROUP_REMOVE_FAILURE, storeGroupId, error);

export const remove =
  (storeGroupId: number) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(removeRequest(storeGroupId));

      await services.remove(storeGroupId);
      dispatch(removeSuccess(storeGroupId));
    } catch (error) {
      dispatch(removeFailure(error, storeGroupId));
      throw error;
    }
  };
// #endregion

function updateLegacy(throttle?: boolean) {
  let executableFunction;
  return function (storeGroupId: number, updateStoreGroup: any) {
    return (dispatch) => {
      if (throttle && executableFunction) {
        executableFunction.cancel();
      }
      executableFunction = getDebounceMaybe(
        () =>
          services.update(storeGroupId, patch(updateStoreGroup, {})).then(
            (storeGroup) => {
              dispatch(success(storeGroup));
            },
            (error) => {
              dispatch(failure(error));
            }
          ),
        true,
        1000
      );
      dispatch(request(updateStoreGroup));
      executableFunction();
    };

    function request(storeGroup) {
      return {
        type: storeGroupConstants.STORE_GROUP_UPDATE_REQUEST,
        storeGroupId,
        storeGroup,
      };
    }

    function success(storeGroup: StoreGroup) {
      return {
        type: storeGroupConstants.STORE_GROUP_UPDATE_SUCCESS,
        storeGroupId,
        storeGroup,
      };
    }

    function failure(error) {
      return {
        type: storeGroupConstants.STORE_GROUP_UPDATE_FAILURE,
        storeGroupId,
        error,
      };
    }
  };
}

export const storeGroupActions = {
  load: loadById,
  loadAllForApp: load,
  create,
  update: updateLegacy,
  remove,
};
