import React from 'react';

import { Field, FieldProps } from 'formik';
import { Translate } from 'react-localize-redux';

import Tag from '@fd/ui/atoms/Tag';
import Switch from '@fd/ui/Switch/Switch';

import FormItemLayout from '../../FormItemLayout';

const IsDiscoverableField = () => (
  <Field name={'IsDiscoverable'}>
    {({ field, form: { isSubmitting } }: FieldProps) => {
      return (
        <FormItemLayout
          description={<Translate id="When_enabled_this_voucher_will_be_shown_throughout" />}
          label={
            <>
              <Translate id="Voucher_is_discoverable" />
              <Tag label="Beta" />
            </>
          }
          noSpace
        >
          <Switch
            checked={field.value}
            disabled={isSubmitting}
            fdKey={'Is_discoverable'}
            name={field.name}
            onChange={field.onChange}
            value={field.value}
          />
        </FormItemLayout>
      );
    }}
  </Field>
);

export default IsDiscoverableField;
