import {
  AuthorizationTokensApiFactory,
  HttpRequestResponseLogsApiFactory,
  OAuthApp,
  OAuthClientsApiFactory,
  WebhooksApiFactory,
  WebhookSubscription,
} from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const HttpRequestResponseLogsApi = createApi(HttpRequestResponseLogsApiFactory);

const OAuthClientsApi = createApi(OAuthClientsApiFactory);

const AuthorizationTokensApi = createApi(AuthorizationTokensApiFactory);

const WebhooksApi = createApi(WebhooksApiFactory);

export const developersService = {
  getOauthApps,
  getOauthAppById,
  getOauthAppSecretKey,
  createOauthApp,
  getOauthAppRedirectUris,
  createRedirectUri,
  deleteRedirectUri,
  getOauthAppAuthTokens,
  createOauthAppAccessToken,
  revokeAccessToken,
  getInteractionLogs,
  getWebhookLogs,
  getWebhooks,
  createWebhook,
  updateWebhook,
  deleteWebhook,
  getAllWebhookEventNames,
  deleteWebhookEventName,
  createWebhookEventName,
  deleteOauthApp,
};

async function getOauthApps(appId: string, oauthAppName?: string) {
  try {
    const incomingMessage = await OAuthClientsApi.getOAuthApps(appId, oauthAppName);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function getOauthAppById(clientId: string, appId: string) {
  try {
    const incomingMessage = await OAuthClientsApi.getOAuthClientByClientId(clientId, appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function getOauthAppSecretKey(clientId: string, appId: string) {
  try {
    const incomingMessage = await OAuthClientsApi.getOAuthClientSecret(clientId, appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function createOauthApp(oauthApp: OAuthApp, appId: string) {
  try {
    const incomingMessage = await OAuthClientsApi.createOAuthApp(appId, oauthApp);
    return incomingMessage;
  } catch (incomingMessage) {
    throw incomingMessage.body.Message;
  }
}

async function deleteOauthApp(oauthAppId: string, appId: string) {
  try {
    const incomingMessage = await OAuthClientsApi.deleteOAuthApp(oauthAppId, appId);
    return incomingMessage;
  } catch (incomingMessage) {
    throw incomingMessage.body.Message;
  }
}

async function getOauthAppRedirectUris(clientId: string, appId: string) {
  try {
    const incomingMessage = await OAuthClientsApi.getRedirectUris(clientId, appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function createRedirectUri(oauthAppId: string, uri: string, appId: string) {
  try {
    const incomingMessage = await OAuthClientsApi.addRedirectUri(oauthAppId, appId, uri);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function deleteRedirectUri(clientId: string, uriId: number, appId: string) {
  try {
    await OAuthClientsApi.removeRedirectUri(clientId, uriId, appId);
    return;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

let cachedParams = {
  page: 1,
  limit: 25,
};

async function getOauthAppAuthTokens(clientId: string, appId: string, params = cachedParams) {
  try {
    const incomingMessage = await AuthorizationTokensApi.getAuthorizationTokens(
      clientId,
      appId,
      params.page,
      params.limit
    );
    if (params && params !== cachedParams) {
      cachedParams = params;
    }
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function createOauthAppAccessToken(clientId: string, appId: string) {
  try {
    const incomingMessage = await OAuthClientsApi.getOauthAccessToken(clientId, appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function revokeAccessToken(key: string, appId: string) {
  try {
    await AuthorizationTokensApi.revokeToken(key, appId);
    return;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function getInteractionLogs(
  start: Date,
  end: Date,
  appId: string,
  filterByUserId?: number,
  guid?: string,
  page?: number,
  limit?: number
) {
  try {
    const incomingMessage = await HttpRequestResponseLogsApi.getLogs(
      //@ts-ignore generated types set all Date types to strings
      start,
      end,
      appId,
      filterByUserId,
      guid,
      page,
      limit
    );
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function getWebhookLogs(
  oauthAppId: string,
  webhookSubscriptionId: number,
  start: string,
  end: string,
  appId: string,
  page?: number,
  limit?: number
) {
  try {
    const incomingMessage = await WebhooksApi.getWebhookLogs(
      oauthAppId,
      webhookSubscriptionId,
      start,
      end,
      appId,
      page,
      limit
    );
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function getWebhooks(oauthAppId: string, appId: string, page?: number, limit?: number) {
  try {
    const incomingMessage = await WebhooksApi.getWebhookSubscriptions(
      oauthAppId,
      appId,
      page,
      limit
    );
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function createWebhook(
  oauthAppId: string,
  webhookSubscription: WebhookSubscription,
  appId: string
) {
  try {
    const incomingMessage = await WebhooksApi.createWebhookSubscription(
      oauthAppId,
      appId,
      webhookSubscription
    );
    return incomingMessage.Data;
  } catch (incomingMessage) {
    if (incomingMessage?.body?.Errors) {
      return incomingMessage?.body?.Errors;
    }
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function createWebhookEventName(
  oauthAppId: string,
  webhookSubscriptionId: number,
  eventName: string,
  appId: string
) {
  try {
    await WebhooksApi.createWebhookSubscriptionEventNames(
      oauthAppId,
      webhookSubscriptionId,
      eventName,
      appId
    );
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function deleteWebhookEventName(
  oauthAppId: string,
  webhookSubscriptionId: number,
  eventName: string,
  appId: string
) {
  try {
    await WebhooksApi.deleteWebhookSubscriptionEventName(
      oauthAppId,
      webhookSubscriptionId,
      eventName,
      appId
    );
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function updateWebhook(
  oauthAppId: string,
  webhookSubscriptionId: number,
  webhookSubscription: WebhookSubscription,
  appId: string
) {
  try {
    await WebhooksApi.updateWebhookSubscription(
      oauthAppId,
      webhookSubscriptionId,
      appId,
      webhookSubscription
    );
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function deleteWebhook(oauthAppId: string, webhookSubscriptionId: number, appId: string) {
  try {
    await WebhooksApi.deleteWebhookSubscription(oauthAppId, webhookSubscriptionId, appId);
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

async function getAllWebhookEventNames(appId: string) {
  try {
    const incomingMessage = await WebhooksApi.getWebhookEventNames(appId);

    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
