import React, { useEffect, useState } from 'react';

import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';

import PageLayout from '../../../ui/Layout';
import { resetStoreSearch } from '../../Reports/OrderReport.actions';
import CustomerListFilters from '../components/Filters/Filters';
import { CurrencyEnum } from '../components/Tables/CustomersDataTable';
import CustomersTable from '../components/Tables/CustomersTable';
import { resetCustomerList } from '../Customers.actions';
import { getStoreHeadersList } from '../Customers.selectors';

type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {};
type Props = InnerProps & OuterProps & RouteComponentProps;

const CustomerList = ({ location, resetCustomerList, resetStoreSearch }: Props) => {
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [storeIds, setStoreIdsFilter] = useState<number[] | CurrencyEnum>([]);
  const [currency, setCurrency] = useState<CurrencyEnum>();

  useEffect(() => {
    return () => {
      resetCustomerList();
      resetStoreSearch();
    };
  }, []);

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'customer.*' }}
      header={
        <CustomerListFilters
          currency={currency}
          location={location}
          searchFilter={searchFilter}
          setCurrency={setCurrency}
          setSearchFilter={setSearchFilter}
          setStoreIdsFilter={setStoreIdsFilter}
          storeIds={storeIds}
        />
      }
      documentTitle={'Customers'}
      title={<Translate id="Customers" />}
    >
      <CustomersTable
        currency={currency}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        storeIds={storeIds}
      />
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    storeHeaders: getStoreHeadersList(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  resetCustomerList: () => dispatch(resetCustomerList()),
  resetStoreSearch: () => dispatch(resetStoreSearch()),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  CustomerList
);
