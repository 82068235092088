import React, { ChangeEvent } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { type FormikProps, Field, Form as FormikForm, withFormik } from 'formik';

import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import ModalButtons from '@fd/ui/Modal/ModalButtons';
import { ModalContent } from '@fd/ui/Modal/ModalContent';
import Select from '@fd/ui/Select/Select';

export type FormFieldType = 'text' | 'dropdown' | 'checkbox';

type Option = {
  value: string;
  label: string;
};

type FormField = {
  name: string;
  type: FormFieldType;
  label: string;
  description?: string;
  options?: Option[];
};

type Form = {
  channel: string;
  fields: FormField[];
};

type Props = {
  form: Form;
  onSubmit: () => void;
  rejectForm: () => void;
};

const DynamicFormModal = ({
  form,
  handleSubmit,
  values,
  setValues,
  rejectForm,
}: Props & FormikProps<{}>) => {
  const setFormValues = (name: string, value: string) => {
    setValues({ ...values, [name]: value });
  };

  const getField = (field: FormField) => {
    switch (field.type) {
      case 'text':
        return (
          <TextField
            variant="standard"
            type={field.type}
            label={field.label}
            value={values[field.name]}
            onChange={(event) => setFormValues(field.name, event.target.value)}
            data-fd="form-field"
          />
        );
      case 'dropdown':
        return (
          <Select
            placeholder={field.label}
            options={field.options}
            onChange={(option: Option) => setFormValues(field.name, option.value)}
            TextFieldProps={{
              fdKey: 'form-field',
              name: 'form-field',
              value: values[field.name],
            }}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            label={field.label}
            control={
              <Checkbox
                onChange={(event: ChangeEvent, checked: boolean) =>
                  setFormValues(field.name, checked.toString())
                }
                value={values[field.name]}
                data-fd="form-field"
                color="secondary"
              />
            }
          />
        );
      default:
        return;
    }
  };

  const renderFormFields = () =>
    form.fields.map((field) => <Field key={field.name}>{() => getField(field)}</Field>);

  return (
    <Modal open={true} onClose={() => null} titleTextId="Submit">
      <ModalContent>
        <FormikForm data-fd="dynamic-form">{renderFormFields()}</FormikForm>
      </ModalContent>
      <ModalActions>
        <ModalButtons
          leftButtonAction={rejectForm}
          leftButtonTranslationId="Cancel"
          rightButtonAction={handleSubmit}
          rightButtonTranslationId="Submit"
        />
      </ModalActions>
    </Modal>
  );
};

export default withFormik<Props, {}>({
  displayName: 'DynamicForm',
  mapPropsToValues: () => {
    return {};
  },
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit();
    formikBag.setSubmitting(false);
  },
})(DynamicFormModal);
