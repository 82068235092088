import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getActiveLanguage, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { PageTitleWithBeta } from '../../layouts/Portal/PageTitleWithBeta';
import PageLayout from '../../ui/Layout';
import BulkShowHideTable from './components/BulkShowHideTable';
import { menuBulkReportMeta } from './data/MenuBulkEditMeta';

type InnerProps = MappedState & RouteComponentProps<{ menuId: string }>;
type Props = InnerProps;

const useStyles = makeStyles((theme: Theme) => ({
  menuBulkReport: {
    width: 'calc(100% + 24px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0',
    },
  },
  cardTextContent: {
    marginLeft: theme.spacing(2),
  },
  cardTextContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  successIcon: {
    color: '#20C26E',
  },
  warningIcon: {
    color: '#FFBC2C',
  },
  cardContent: {
    display: 'flex',
  },
  successCardContent: {
    display: 'flex',
  },
  tableCard: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  textContent: {
    display: 'inline-block',
  },
  card: {
    background: '#fff',
    width: '100%',
    padding: theme.spacing(0),
    margin: theme.spacing(2),
  },
  link: {
    color: '#05149E',
    textDecoration: 'none',
  },
}));

const MenuBulkReport = (props: Props) => {
  const { currentApp, match, languageCode, location } = props;
  const classes = useStyles();

  // @ts-ignore
  const failedUpdateArray = location.state?.failedArray;
  // @ts-ignore
  const successUpdateArray = location.state?.successArray;
  // @ts-ignore
  const itemName = location.state?.itemName;

  const renderShowHideSuccessCard = () => {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.successCardContent}>
          {successUpdateArray?.length > 0 ? (
            <CheckCircleOutlineIcon className={classes.successIcon} />
          ) : (
            <ReportProblemOutlinedIcon className={classes.warningIcon} />
          )}
          <Grid container className={classes.cardTextContent}>
            <Typography variant="body1">
              <Translate
                id="Instances_successfully_hidden"
                data={{
                  successCount: successUpdateArray?.length,
                  instanceItemName: itemName,
                }}
              />
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <PageLayout
      toParent={`/${currentApp.AppId}/menus/bulk-edit/${match.params?.menuId}`}
      strictToParent={true}
      title={<PageTitleWithBeta translateId={'Detailed_report'} />}
      documentTitle="Detailed_report"
    >
      <Grid container className={classes.menuBulkReport}>
        {renderShowHideSuccessCard()}
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <ReportProblemOutlinedIcon className={classes.warningIcon} />
            <Grid container className={classes.cardTextContent}>
              <Typography variant="body1">
                <Translate id="The_following_instances_cannot_be_hidden" />
              </Typography>
              <Grid container className={classes.cardTextContainer}>
                <Typography variant="caption" className={classes.textContent}>
                  <Translate id="Hide_instances_min_required_selected" />
                  <br />
                  <Translate id="To_hide_these_instances" />{' '}
                  <Link
                    className={classes.link}
                    to={`/${currentApp.AppId}/menus/${match.params.menuId}`}
                  >
                    <Translate id="View_Menu" />
                  </Link>
                  <br />
                  <Translate id="To_learn_more" />{' '}
                  <Link
                    to={{
                      pathname: `https://help.flipdish.com/en/articles/5268760-bulk-show-or-hide-items-from-my-menu`,
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={classes.link}
                  >
                    <Translate id="helpdesk" />
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <div className={classes.tableCard}>
            <BulkShowHideTable
              filter={''}
              languageCode={languageCode}
              loading={false}
              metaData={menuBulkReportMeta}
              tableData={failedUpdateArray}
              tableType={'Report'}
            />
          </div>
        </Card>
      </Grid>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { currentApp } = state;

  return {
    languageCode: getActiveLanguage(state.locale),
    currentApp,
  };
}
export default compose<{}, any>(
  withRouter,
  connect(mapStateToProps)
)(MenuBulkReport as React.FC<React.PropsWithChildren<Props>>);
