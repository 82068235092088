import React from 'react';

import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import ModalButtons from '@fd/ui/Modal/ModalButtons';
import { ModalContent } from '@fd/ui/Modal/ModalContent';

type Props = {
  acceptForm: () => void;
  rejectForm: () => void;
};

const ConsentFormModal = ({ acceptForm, rejectForm }: Props) => {
  return (
    <Modal open={true} onClose={() => null} titleTextId="Permission" fdKey="consent-form">
      <ModalContent>Consent form</ModalContent>
      <ModalActions>
        <ModalButtons
          leftButtonAction={rejectForm}
          leftButtonTranslationId="Cancel"
          rightButtonAction={acceptForm}
          rightButtonTranslationId="Accept_And_Continue"
          fdKey="consent-form-modal-buttons"
        />
      </ModalActions>
    </Modal>
  );
};

export default ConsentFormModal;
