import React, { useState } from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoomOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import {
  getActiveLanguage,
  getTranslate,
  setActiveLanguage,
  TranslateFunction,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions, { add, remove } from 'react-redux-permissions';
import { Link } from 'react-router-dom';

import { accountActions } from '../../actions/account.actions';
import DevLongLanguageToggle from '../../components/DevLongLanguageToggle';
import HiddenFeature from '../../components/HiddenFeature';
import HiddenFeatureToggle from '../../components/HiddenFeatureToggle';
import constants from '../../constants.json';
import { AccountState } from '../../reducers/account.reducer';
import { accountRoutesConst as routes } from '../../routes/account.routes';
import { isProductBasedMenusEnabled } from '../../selectors/app.selector';
import { flagService } from '../../services/flagService/flagService';
import MenuItem from './MenuItem';
import { ItemMetaData, menuItemsConfig, menuItemsConfigMobile } from './menuItemsConfig';

const useStyles = makeStyles({
  listItem: {
    height: '48px',
    borderRadius: '0 24px 24px 0',
    margin: 0,
    padding: 0,
  },
  listIcon: {
    height: 48,
    width: 48,
    margin: '0 20px 0 12px',
    minWidth: 0,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  switchesContainer: {
    paddingLeft: 16,
    paddingRight: 16,
  },
});

const renderMenuItem = (
  item: ItemMetaData,
  isMobile: boolean,
  expanded: string,
  handleExpansion: (val: string) => void,
  setExpanded: (val: string) => void,
  translate: TranslateFunction,
  setOpenSideNav: (open: boolean) => void
) => {
  let itemComponent = (
    <MenuItem
      key={item.title}
      isMobile={isMobile}
      expanded={expanded}
      setExpanded={setExpanded}
      handleExpansion={handleExpansion}
      translate={translate}
      setOpenSideNav={setOpenSideNav} // Pass it to MenuItem
      {...item}
    />
  );

  if (item.permissions && item.permissions.length) {
    itemComponent = (
      <Permissions key={item.title} allowed={item.permissions}>
        {itemComponent}
      </Permissions>
    );
  }

  if (item.hidden) {
    itemComponent = <HiddenFeature key={item.title}>{itemComponent}</HiddenFeature>;
  }

  return itemComponent;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    dynamicChannelSideMenuItems?: ItemMetaData[];
    onboardingSideMenuItems?: ItemMetaData[];
    setOpenSideNav: (open: boolean) => void;
  };

const SideNavMenu = (props: Props) => {
  const {
    account,
    add,
    currentApp,
    dynamicChannelSideMenuItems,
    isProductBasedMenusOn,
    onboardingSideMenuItems,
    remove,
    setActiveLanguage,
    translate,
    updateAccounts,
    setOpenSideNav,
    showRMS,
    showAddOnsBasedOnRMSSubscriptions,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { data: rmsData } = useQueryRMSCommonDataHook(
    currentApp?.AppId || '',
    showRMS,
    showAddOnsBasedOnRMSSubscriptions
  );
  const subscriptions = rmsData?.subscriptions;

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const appId = currentApp ? currentApp.AppId : 'global';

  let items =
    onboardingSideMenuItems ?? menuItemsConfig(currentApp, isProductBasedMenusOn, subscriptions);

  const itemsMobile = menuItemsConfigMobile(appId);
  const isMobile = !isDesktop;
  const [expanded, setExpanded] = useState('');

  const addDynamicChannelSideMenuItems = (sideMenuItems: ItemMetaData[]) => {
    sideMenuItems?.forEach((item) => {
      if (item.title === 'Sales_channels') {
        dynamicChannelSideMenuItems?.forEach((dynamicSideMenuItem) =>
          item.subItems?.push(dynamicSideMenuItem)
        );
      }
    });

    return sideMenuItems;
  };

  const handleDevLangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const activeLanguage = event.target.checked ? 'dev-lang' : 'en';
    setActiveLanguage(activeLanguage);
  };

  const handleHiddenFeatureChange = (action: string, role: string) => {
    const { account } = props;
    if (action === 'add') {
      add(role);
      updateAccounts(account, true);
    } else {
      remove(role);
      updateAccounts(account, false);
    }
  };

  const handleExpansion = (val: string) => {
    if (val === expanded) {
      // We collapse the submenu if it's already open
      setExpanded('');
    } else {
      setExpanded(val);
    }
  };

  items = addDynamicChannelSideMenuItems(items);

  return (
    <>
      {items.map((item) =>
        renderMenuItem(
          item,
          isMobile,
          expanded,
          handleExpansion,
          setExpanded,
          translate,
          setOpenSideNav
        )
      )}

      {!isDesktop && (
        <List style={{ paddingTop: 0 }}>
          <ListItem style={{ borderTop: '1px solid rgba(0,0,0,0.2088)' }}>
            <ListItemText primary={account ? account.Email : ''} />
          </ListItem>

          {itemsMobile.map((item) =>
            renderMenuItem(
              item,
              isMobile,
              expanded,
              handleExpansion,
              setExpanded,
              translate,
              setOpenSideNav
            )
          )}
          <ListItem
            button
            data-fd="menu-logout"
            disableGutters={true}
            className={classes.listItem}
            component={Link}
            to={routes.Logout}
          >
            <ListItemIcon className={classes.listIcon}>
              <MeetingRoomIcon />
            </ListItemIcon>
            <ListItemText
              primary={translate('Logout')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
          {isMobile && (
            <Permissions allowed={['FlipdishStaff']}>
              <div className={classes.switchesContainer}>
                {constants.GIT_BRANCH !== 'master' ? (
                  <DevLongLanguageToggle
                    checked={props.activeLanguage === 'dev-lang'}
                    handleDevLangChange={handleDevLangChange}
                  />
                ) : null}
                <HiddenFeatureToggle
                  checked={account.ShowHiddenFeatures}
                  handleHiddenFeatureChange={handleHiddenFeatureChange}
                />
              </div>
            </Permissions>
          )}
        </List>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    currentApp: state.currentApp,
    account: state.account,
    translate: getTranslate(state.locale),
    activeLanguage: getActiveLanguage(state.locale),
    isProductBasedMenusOn: isProductBasedMenusEnabled(state),
    showRMS: flagService.isFlagOn(state, 'showRMS'),
    showAddOnsBasedOnRMSSubscriptions: flagService.isFlagOn(
      state,
      'showAddOnsBasedOnRMSSubscriptions'
    ),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    setActiveLanguage: (activeLanguage: string) => dispatch(setActiveLanguage(activeLanguage)),
    add: (role: string) => dispatch(add(role)),
    remove: (role: string) => dispatch(remove(role)),
    updateAccounts: (account: AccountState, ShowHiddenFeatures: boolean) =>
      dispatch(accountActions.update({ ...account, ShowHiddenFeatures })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideNavMenu);
