import { notifyError } from '../layouts/Notify/actions';
import { onboardingService } from '../services/onboarding.service';
import { accountActions } from './account.actions';

function createInitialStore(storeName: string) {
  return (dispatch) => {
    onboardingService.createInitialStore(storeName).then(
      () => {
        dispatch(accountActions.getAccountDetails());
      },
      (error) => {
        if (error.message) {
          dispatch(notifyError({ message: error.message }));
        } else {
          dispatch(notifyError({ message: 'Something_went_wrong' }));
        }
      }
    );
  };
}

export const onboardingActions = {
  createInitialStore,
};
