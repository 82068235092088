import React, { useCallback, useEffect, useState } from 'react';

import { App } from '@flipdish/api-client-typescript';
import Drawer from '@mui/material/Drawer';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useLocation } from 'react-router';

import { appsService } from '../services/apps.service';
import { AppLoadingCircularProgress } from './AppLoadingCircularProgress';
import AppMenuItems from './AppMenuItems';
import AppMenuSearch from './AppMenuSearch';

const drawerWidth = 304;
const limit = 20;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: 1350,
  },
  drawer: {
    width: drawerWidth,
    wordBreak: 'break-all',
  },
  drawerOpen: {
    borderRight: 'none',
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      bottom: 0,
      top: 65,
    },
    right: 0,
  },
  drawerClose: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 65,
    },

    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    right: -drawerWidth,
  },
}));

type Props = {
  open: boolean;
  toggleAppMenu: () => void;
};

export default function AppMenu(props: Props) {
  const classes = useStyles({ drawerWidth });
  const { open, toggleAppMenu } = props;
  const [loading, setLoading] = useState(false);
  const [apps, setApps] = useState<App[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalAppsCount, setTotalAppsCount] = useState(0);
  const [currentTotalAppsCount, setCurrentTotalAppsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { search } = useLocation();

  useEffect(() => {
    if (search.includes('appSearch')) {
      const params = new URLSearchParams(search);
      const appSearch = params.get('appSearch');
      setSearchQuery(appSearch!);
    } else {
      getFurtherApps();
    }
  }, []);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery]);

  const loadApps = async (page: number, query = '') => {
    setLoading(true);
    try {
      const { Data, TotalRecordCount } = await appsService.getApps(query, page, limit);
      setLoading(false);
      setApps([...apps, ...Data]);
      setCurrentTotalAppsCount(TotalRecordCount);

      if (!query && TotalRecordCount !== totalAppsCount) {
        setTotalAppsCount(TotalRecordCount);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getFurtherApps = async () => {
    const newPage = pageNumber + 1;

    setPageNumber(newPage);
    loadApps(newPage, searchQuery);
  };

  const handleScroll = ({ target }) => {
    const { scrollHeight, clientHeight, scrollTop } = target;

    // scroll triggers when the compoment goes from has scroll bar to no scroll bar,
    // if there's no scrollbar, we should not fetch more apps.
    // However this hasScrollBar has its problem, see comment from this https://stackoverflow.com/a/5038256
    // it works for us now because we do not hide the overflow
    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;

    if (
      !loading &&
      hasScrollBar &&
      pageNumber * limit < currentTotalAppsCount &&
      Math.ceil(scrollTop) >= scrollTopMax
    ) {
      getFurtherApps();
    }
  };

  const handleSearch = useCallback(
    debounce((query: string) => {
      loadApps(1, query);
      setPageNumber(1);
    }, 500),
    []
  );

  return (
    <Drawer
      variant={matches ? 'permanent' : 'temporary'}
      anchor="right"
      classes={{
        root: classes.root,
        paper: clsx({
          [classes.drawer]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      PaperProps={{ elevation: open ? 10 : 0 }}
      open={open}
      onScroll={handleScroll}
      onClose={toggleAppMenu}
    >
      {(totalAppsCount > 8 || searchQuery !== '') && (
        <AppMenuSearch {...{ setSearchQuery, searchQuery, open }} />
      )}
      {open && <AppMenuItems apps={apps} toggleAppMenu={toggleAppMenu} open={open} />}
      {loading && <AppLoadingCircularProgress />}
    </Drawer>
  );
}
