import { useQuery } from '@tanstack/react-query';

import {
  getAssociations,
  getAssociationsKey,
  getRMSTokenFromFDAuthorization,
  getRMSTokenFromFDAuthorizationKey,
} from '../components/RMS/services';
import { FIVE_MINUTES } from '../components/RMS/utils/constants';

// TODO: Manage invalidating queries when new page is loaded/new user logs in
// TODO: Create Proper types once RMS data is known
export function useQueryRMSCommonDataHook(
  appId: string,
  showRMS: boolean,
  showAddOnsBasedOnRMSSubscriptions?: boolean
): {
  isLoading: boolean;
  data?: any;
  error: any;
} {
  const {
    isFetching: isLoadingAuthToken,
    data: authTokenResponse,
    error: authTokenError,
  } = useQuery({
    queryKey: [getRMSTokenFromFDAuthorizationKey, appId],
    queryFn: getRMSTokenFromFDAuthorization,
    retry: 1,
    // 5 minutes stale time - default is 0 causing refetch on new iframe load
    staleTime: FIVE_MINUTES,
    enabled: showRMS,
  });

  const {
    isFetching: isLoadingAssociations,
    data: associationsResponse,
    error: associationsError,
  } = useQuery({
    queryKey: [getAssociationsKey, authTokenResponse?.data?.token, appId],
    queryFn: () => getAssociations(appId, authTokenResponse?.data?.token || ''),
    enabled: !!authTokenResponse?.data?.token,
    retry: 1,
    // 5 minutes stale time - default is 0 causing refetch on new iframe load
    staleTime: FIVE_MINUTES,
  });

  const subscriptions = [];
  if (showAddOnsBasedOnRMSSubscriptions) {
    associationsResponse?.data?.[0]?.orgHierarchy?.properties?.forEach((property) => {
      property?.subscriptions?.length > 0 &&
        // @ts-ignore
        subscriptions.push(...property.subscriptions);
    });
  }

  return {
    isLoading: isLoadingAuthToken || isLoadingAssociations,
    data: {
      authToken: authTokenResponse?.data,
      associations: associationsResponse?.data,
      subscriptions,
    },
    error: authTokenError || associationsError,
  };
}

export default useQueryRMSCommonDataHook;
