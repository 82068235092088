import React, { useEffect, useMemo, useState } from 'react';

import { useInfiniteQueryStoreFilterHook } from '@fd/customHooks/useInfiniteQuerySoreFilter';
import { App, StoreHeader, Teammate, TeammateBase } from '@flipdish/api-client-typescript';
import Email from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import {
  createTeammate,
  deleteTeammate,
  getTeammateDetails,
  getTeammates as getTeammatesFromApi,
  updateTeammateDetails,
} from '../../actions/teammates.actions';
import { teammatesConstants } from '../../constants/teammates.constants';
import { validateEmail } from '../../helpers/utilities';
import { notify, NotifyProps } from '../../layouts/Notify/actions';
import PageLayout from '../../ui/Layout';
import PaperContainer from '../../ui/Layout/PaperContainer';
import { LoadingButton } from '../../ui/LoadingButton';
import { OptionType } from '../../ui/Select/Select';
import { getStoresByAppId } from '../Order/services';
import { ALL_STORES_KEY, getAllStoresOptionItem } from './constants';
import { ModifyPermissionLevel } from './ModifyPermissionLevel';
import { getCurrentUser, getTeammate, getTeammatesLoading } from './selectors';

const styles = (theme: Theme) => ({
  permissionsText: {
    fontSize: '1.125rem',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  responsiveTextField: {
    fontSize: '16px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    marginBottom: theme.spacing(3),
    color: 'rgba(0, 0, 0, 0.87)',
  },
  label: {
    left: '42px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  responsiveLabel: {
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  inputAdornment: {
    marginTop: '0!important',
    marginRight: '16px',
    paddingLeft: '4px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  responsiveAdornment: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  buttons: {
    paddingLeft: '56px',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: '20px',
    [theme.breakpoints.down(350)]: {
      paddingLeft: theme.spacing(2),
    },
  },
  cancel: {
    marginRight: theme.spacing(1),
  },
  remove: {
    marginRight: 'auto',
  },
  hyperlink: {
    textDecoration: 'none',
  },
  modalPaper: {
    position: 'absolute' as any,
    width: '280px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '4px',
  },
  buttonsHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  button: {
    fontSize: '14px',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.4',
    letterSpacing: '1.3px',
  },
  modalHeading: {
    fontSize: '20px',
    color: 'rgba(0,0,0,0.87)',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.3px',
    height: '24px',
    marginBottom: '12px',
  },
  modalSubtitle: {
    fontSize: '16px',
    color: 'rgba(0,0,0,0.6)',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.75',
    letterSpacing: '0.5px',
    marginBottom: '34px',
  },
  modalButton: {
    fontSize: '14px',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.14',
    letterSpacing: '1.3px',
  },
});

interface ITeammatesModifyProps {
  classes: any;
  currentApp: App;
  history: any;
  match: any;
  teammate: Teammate;
  teammateLoading: boolean;
  teammates: Teammate[];
  translate: any;
  currentUser: Teammate;
}

interface IExternalProps {
  showSuccessSnackbar?: (message: string) => any;
  stores: StoreHeader[];
}

type Props = ITeammatesModifyProps & IExternalProps & MappedDispatch;

type StoreID = number;
// string for all stores id as this prevent us ever hitting the store id
type SelectedStores = Array<{ label: string; value: StoreID | string }> | null;

const filterValuesBasedOnLastSelection = (stores: any[]) => {
  //multiple selected and last is All stores
  if (stores.length > 1 && stores[stores.length - 1]?.value === ALL_STORES_KEY) {
    return stores.filter((store) => store?.value === ALL_STORES_KEY);
    //multiple selected and first is all stores
  } else if (stores.length > 1 && stores.some((store) => store?.value === ALL_STORES_KEY)) {
    return stores.filter((storeValue) => storeValue?.value !== ALL_STORES_KEY);
  } else {
    return stores;
  }
};

const Modify: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    classes,
    createTeammate,
    currentApp,
    currentUser,
    match,
    notify,
    stores,
    teammate,
    teammateLoading,
    translate,
    updateTeammateDetails,
    dispatchGetTeammatesFromApi,
  } = props;
  const initialSelectedStoresWithAllStoresOption = [
    getAllStoresOptionItem(translate('All_stores')),
  ];

  const [query, setQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState<TeammateBase.AppAccessLevelEnum | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const [storeManagerStores, setStoreManagerStores] = useState<SelectedStores>(
    initialSelectedStoresWithAllStoresOption
  );
  const [storeOwnerStores, setStoreOwnerStores] = useState<SelectedStores>(
    initialSelectedStoresWithAllStoresOption
  );
  const [storeStaffStores, setStoreStaffStores] = useState<SelectedStores>(
    initialSelectedStoresWithAllStoresOption
  );
  const [financeManagerStores, setFinanceManagerStores] = useState<SelectedStores>(
    initialSelectedStoresWithAllStoresOption
  );
  const [storeReadOnlyAccessStores, setStoreReadOnlyAccessStores] = useState<SelectedStores>(
    initialSelectedStoresWithAllStoresOption
  );
  const [integratorStores, setIntegratorStores] = useState<SelectedStores>(
    initialSelectedStoresWithAllStoresOption
  );
  const [onboardingRole, setOnboardingRole] = useState<SelectedStores>(
    initialSelectedStoresWithAllStoresOption
  );

  const [newEmail, setNewEmail] = useState<string>('');
  const [setValueInitial, setSetValueInitial] = useState<boolean>(false);
  const [storeOptions, setStoreOptions] = useState<OptionType[]>();

  const {
    FinanceManger,
    Owner,
    ManagedOwner,
    StoreOwner,
    StoreManager,
    StoreStaff,
    StoreReadOnlyAccess,
    Integrator,
    Onboarding,
  } = TeammateBase.AppAccessLevelEnum;

  // #region useInfiniteQuery
  const fetchDataProps = {
    appId: currentApp.AppId!,
    query,
  };

  const {
    data: getStores,
    hasNextPage: hasStores,
    fetchNextPage: fetchStores,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQueryStoreFilterHook(fetchDataProps, getStoresByAppId, 'loadByAppId');

  useEffect(() => {
    if (getStores && getStores.pages) {
      const addNewStoreOptions = getStores.pages.reduce<OptionType[]>((total, page) => {
        page.Data.forEach((store) =>
          total.push({
            value: Number(store.StoreId),
            label: store.Name as string,
          })
        );
        return total;
      }, []);
      setStoreOptions(addNewStoreOptions);
    }
  }, [getStores?.pages]);

  const handleScroll = ({ target }) => {
    const { scrollHeight, clientHeight, scrollTop } = target;

    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;

    if (hasScrollBar && hasStores && Math.ceil(scrollTop) >= scrollTopMax) {
      fetchStores();
    }
  };

  const onSearchQueryChange = useMemo(
    () =>
      debounce((query: string) => {
        setQuery(query);
      }, 500),
    []
  );
  //#endregion

  const getSelectedStores = (): SelectedStores => {
    let selectedStores: SelectedStores = [];

    if (props.teammate.StoreIds && stores) {
      selectedStores = [];

      for (const StoreId of props.teammate.StoreIds) {
        const newStore = stores.find((store) => {
          return store.StoreId === StoreId;
        });
        if (newStore) {
          selectedStores!.push({
            label: newStore.Name as string,
            value: Number(newStore.StoreId),
          });
        }
      }
    }

    // no all stores option returned from the BE
    if (teammate?.HasAccessToAllStores && selectedStores?.length === 0) {
      selectedStores.push(initialSelectedStoresWithAllStoresOption[0]);
    }
    return selectedStores;
  };

  useEffect(() => {
    if (match.params.teammateId && !setValueInitial && stores && stores.length > 0) {
      if (teammate) {
        const {
          FinanceManger,
          Owner,
          ManagedOwner,
          StoreOwner,
          StoreManager,
          StoreStaff,
          StoreReadOnlyAccess,
          Integrator,
          Onboarding,
        } = TeammateBase.AppAccessLevelEnum;

        handleCleanupStores();
        switch (teammate.AppAccessLevel) {
          case TeammateBase.AppAccessLevelEnum.Owner:
            setSelectedValue(Owner);
            setSetValueInitial(true);
            break;
          case TeammateBase.AppAccessLevelEnum.ManagedOwner:
            setSelectedValue(ManagedOwner);
            setSetValueInitial(true);
            break;
          case TeammateBase.AppAccessLevelEnum.StoreOwner:
            setSelectedValue(StoreOwner);
            setSetValueInitial(true);
            setStoreOwnerStores(getSelectedStores());

            break;

          case TeammateBase.AppAccessLevelEnum.StoreManager:
            setSelectedValue(StoreManager);
            setSetValueInitial(true);
            setStoreManagerStores(getSelectedStores());

            break;
          case TeammateBase.AppAccessLevelEnum.StoreStaff:
            setSelectedValue(StoreStaff);
            setSetValueInitial(true);
            setStoreStaffStores(getSelectedStores());
            break;
          case TeammateBase.AppAccessLevelEnum.FinanceManger:
            setSelectedValue(FinanceManger);
            setSetValueInitial(true);
            setFinanceManagerStores(getSelectedStores());
            break;

          case TeammateBase.AppAccessLevelEnum.StoreReadOnlyAccess:
            setSelectedValue(StoreReadOnlyAccess);
            setSetValueInitial(true);
            setStoreReadOnlyAccessStores(getSelectedStores());
            break;
          case TeammateBase.AppAccessLevelEnum.Integrator:
            setSelectedValue(Integrator);
            setSetValueInitial(true);
            setIntegratorStores(getSelectedStores());
            break;
          case TeammateBase.AppAccessLevelEnum.Onboarding:
            setSelectedValue(Onboarding);
            setSetValueInitial(true);
            setOnboardingRole(getSelectedStores());
            break;
        }
      }
    }
  }, [teammate, stores]);

  const handleStoresChange = (name) => (value) => {
    const filteredValue = filterValuesBasedOnLastSelection(value);
    switch (name) {
      case FinanceManger:
        setFinanceManagerStores(filteredValue);
        break;
      case StoreManager:
        setStoreManagerStores(filteredValue);
        break;
      case StoreOwner:
        setStoreOwnerStores(filteredValue);
        break;
      case StoreStaff:
        setStoreStaffStores(filteredValue);
        break;
      case StoreReadOnlyAccess:
        setStoreReadOnlyAccessStores(filteredValue);
        break;
      case Integrator:
        setIntegratorStores(filteredValue);
        break;
      case Onboarding:
        setOnboardingRole(filteredValue);
        break;
    }
  };

  const handleCleanupStores = () => {
    setFinanceManagerStores(null);
    setStoreManagerStores(null);
    setStoreOwnerStores(null);
    setStoreStaffStores(null);
    setStoreReadOnlyAccessStores(null);
    setIntegratorStores(null);
    setOnboardingRole(null);
  };

  const handleNewEmailChange = (event) => {
    setNewEmail(event.target.value);
  };

  const handlePermissionChange = (value) => {
    setSelectedValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTeammate = () => {
    const { history, match } = props;
    handleClose();

    const callback = () => {
      history.push(`/${currentApp.AppId}/teammates/`);
    };

    props.deleteTeammate(match.params.teammateId, callback);
  };

  const getStoreIds = () => {
    const allStoreIds = stores?.map((store) => store.StoreId);

    const {
      Owner,
      ManagedOwner,
      StoreOwner,
      StoreManager,
      StoreStaff,
      FinanceManger,
      StoreReadOnlyAccess,
      Integrator,
      Onboarding,
    } = TeammateBase.AppAccessLevelEnum;

    const hasAccessToAllStores =
      currentUser.AppAccessLevel &&
      [Owner, ManagedOwner, StoreOwner].includes(currentUser.AppAccessLevel) &&
      currentUser.HasAccessToAllStores;

    const getAllStoreIds = () => {
      if (hasAccessToAllStores) {
        return [];
      }
      return allStoreIds;
    };

    const getIds = (stores: SelectedStores) => {
      return (stores?.length ?? 0) > 0 ? stores?.map((store) => store.value) : getAllStoreIds();
    };

    switch (selectedValue) {
      case Owner:
      case ManagedOwner:
        return initialSelectedStoresWithAllStoresOption;
      case StoreOwner:
        return getIds(storeOwnerStores);
      case StoreManager:
        return getIds(storeManagerStores);
      case StoreStaff:
        return getIds(storeStaffStores);
      case FinanceManger:
        return getIds(financeManagerStores);
      case StoreReadOnlyAccess:
        return getIds(storeReadOnlyAccessStores);
      case Integrator:
        return getIds(integratorStores);
      case Onboarding:
        return getIds(onboardingRole);
    }
  };

  const validateForm = (
    storeIds: ReturnType<typeof getStoreIds>,
    teammates: Teammate[],
    isNewUser: boolean
  ) => {
    if (isNewUser && newEmail === '') {
      notify({
        variant: 'error',
        message: translate('Email_cannot_be_empty'),
      });
      return true;
    } else if (isNewUser && !validateEmail(newEmail)) {
      notify({
        variant: 'error',
        message: translate('Incorrect_email_format'),
      });
      return true;
    } else if (!selectedValue) {
      notify({
        variant: 'error',
        message: 'Select a permission',
      });
      return true;
    } else if (!storeIds || storeIds?.length === 0) {
      notify({
        variant: 'error',
        message: 'Select the store(s) that the permission applies to',
      });
      return true;
    } else if (isNewUser) {
      const existingIndex = teammates.findIndex((teammate) => teammate.Email === newEmail);
      if (existingIndex != -1) {
        notify({
          variant: 'error',
          message: translate('You_have_already_invited', {
            email: newEmail,
          }),
        });
        return true;
      }
    }
  };

  const modifyTeammate = () => {
    const { history, match, teammates } = props;
    const storeIds = getStoreIds();
    const isNewUser = !match.params.teammateId;
    if (validateForm(storeIds, teammates, isNewUser)) {
      return;
    }
    if (isNewUser) {
      const callback = () => {
        history.push(`/${currentApp.AppId}/teammates/`);
      };

      createTeammate(newEmail, selectedValue, storeIds, callback);
    } else {
      const teammate: Teammate = props.teammate;

      const callback = () => {
        dispatchGetTeammatesFromApi();
        history.push(`/${currentApp.AppId}/teammates/`);
      };

      updateTeammateDetails(
        teammate.TeammateId,
        selectedValue,
        currentUser.HasAccessToAllStores,
        storeIds,
        callback
      );
    }
  };

  const appId = currentApp != null ? currentApp.AppId : 'global';

  const isMe = teammate && currentUser.Email === teammate.Email;
  const header = !teammate ? (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <TextField
          className={classes.responsiveTextField}
          variant="outlined"
          label={translate('Teammates_email_address')}
          InputLabelProps={{ className: classes.responsiveLabel }}
          value={newEmail}
          fullWidth
          placeholder={translate('Email')}
          autoFocus
          onChange={handleNewEmailChange}
          InputProps={{
            inputProps: {
              type: 'email',
              'data-fd': 'teammate-invite-email',
            },
            endAdornment: (
              <InputAdornment position="end" className={classes.responsiveAdornment}>
                <Email />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2" component="h2" className={classes.permissionsText}>
          {translate('Access_permissions')}
        </Typography>
      </Grid>
    </Grid>
  ) : null;

  const isNewUser = !match.params.teammateId;
  return (
    <PageLayout
      header={header}
      toParent={`/${currentApp.AppId}/teammates`}
      documentTitle="Teammates"
      caption={
        teammate
          ? teammate.Name && teammate.Name !== teammate.Email
            ? `${teammate.Name}, ${teammate.Email}`
            : teammate.Email
          : ''
      }
      title={match.params.teammateId ? translate('Edit_teammate') : translate('Invite_teammate')}
    >
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className={classes.modalPaper}>
          <Typography variant="h6" className={classes.modalHeading}>
            {translate('Remove_user')}?
          </Typography>
          <Typography variant="subtitle1" className={classes.modalSubtitle}>
            {translate('Pressing_remove', { appName: currentApp.Name })}
          </Typography>
          <div className={classes.buttonsHolder}>
            <Button
              color="primary"
              onClick={handleClose}
              className={classes.modalButton}
              data-fd="teammate-modal-cancel"
            >
              {translate('Cancel')}
            </Button>
            <Button
              color="primary"
              onClick={deleteTeammate}
              className={classes.modalButton}
              data-fd="teammate-modal-remove"
            >
              {translate('Remove')}
            </Button>
          </div>
        </div>
      </Modal>
      <PaperContainer fluid>
        <Permissions allowed={[App.AppResourceSetEnum.CreateTeammateOwner]}>
          <ModifyPermissionLevel
            dataFd="owner"
            desc={translate('Can_access_all_stores_and_settings')}
            disabled={isMe}
            handlePermissionChange={handlePermissionChange}
            handleStoresChange={handleStoresChange}
            isNewUser={isNewUser}
            permissionLevel={Owner}
            selectedValue={selectedValue}
            title={translate('Owner')}
          />
        </Permissions>

        <Permissions allowed={[App.AppResourceSetEnum.CreateTeammateManagedOwner]}>
          <ModifyPermissionLevel
            dataFd="managed-owner"
            desc={translate('Managed_owner_access_description')}
            disabled={isMe}
            handlePermissionChange={handlePermissionChange}
            handleStoresChange={handleStoresChange}
            isNewUser={isNewUser}
            permissionLevel={ManagedOwner}
            selectedValue={selectedValue}
            title={translate('Managed_owner')}
          />
        </Permissions>

        <Permissions allowed={[App.AppResourceSetEnum.CreateTeammateStoreOwner]}>
          <ModifyPermissionLevel
            dataFd="store-owner"
            desc={translate('Store_owner_access_description')}
            disabled={isMe}
            handlePermissionChange={handlePermissionChange}
            handleScroll={handleScroll}
            handleStoresChange={handleStoresChange}
            isNewUser={isNewUser}
            isLoading={isLoading || isFetchingNextPage}
            onSearchQueryChange={onSearchQueryChange}
            permissionLevel={StoreOwner}
            selectedValue={selectedValue}
            stores={storeOwnerStores}
            storesName="storeOwnerStores"
            suggestions={storeOptions}
            title={translate('Store_owner')}
          />
        </Permissions>

        <Permissions allowed={[App.AppResourceSetEnum.CreateTeammateStoreManager]}>
          <ModifyPermissionLevel
            dataFd="store-manager"
            desc={translate('Can_access_limited_settings')}
            disabled={isMe}
            handlePermissionChange={handlePermissionChange}
            handleScroll={handleScroll}
            handleStoresChange={handleStoresChange}
            isNewUser={isNewUser}
            isLoading={isLoading || isFetchingNextPage}
            onSearchQueryChange={onSearchQueryChange}
            permissionLevel={StoreManager}
            selectedValue={selectedValue}
            stores={storeManagerStores}
            storesName="storeManagerStores"
            suggestions={storeOptions}
            title={translate('Store_manager')}
          />
        </Permissions>

        <Permissions allowed={[App.AppResourceSetEnum.CreateTeammateStoreStaff]}>
          <ModifyPermissionLevel
            dataFd="store-staff"
            desc={translate('Can_access_limited_information')}
            disabled={isMe}
            handlePermissionChange={handlePermissionChange}
            handleScroll={handleScroll}
            handleStoresChange={handleStoresChange}
            isNewUser={isNewUser}
            isLoading={isLoading || isFetchingNextPage}
            onSearchQueryChange={onSearchQueryChange}
            permissionLevel={StoreStaff}
            secondaryDesc={translate('Can_view_all_data')}
            selectedValue={selectedValue}
            stores={storeStaffStores}
            storesName="storeStaffStores"
            suggestions={storeOptions}
            title={translate('Store_staff')}
          />
        </Permissions>

        <Permissions allowed={[App.AppResourceSetEnum.CreateTeammateFinanceManager]}>
          <ModifyPermissionLevel
            dataFd="finance-manager"
            desc={translate('Can_view_all_data')}
            disabled={isMe}
            handlePermissionChange={handlePermissionChange}
            handleScroll={handleScroll}
            handleStoresChange={handleStoresChange}
            isNewUser={isNewUser}
            isLoading={isLoading || isFetchingNextPage}
            onSearchQueryChange={onSearchQueryChange}
            permissionLevel={FinanceManger}
            selectedValue={selectedValue}
            stores={financeManagerStores}
            storesName="financeManagerStores"
            suggestions={storeOptions}
            title={translate('Finance_manager')}
          />
        </Permissions>

        <Permissions allowed={[App.AppResourceSetEnum.CreateTeammateStoreReadAccess]}>
          <ModifyPermissionLevel
            dataFd="store-readonly-access"
            desc={translate('Store_readonly_access_description')}
            disabled={isMe}
            handlePermissionChange={handlePermissionChange}
            handleScroll={handleScroll}
            handleStoresChange={handleStoresChange}
            isNewUser={isNewUser}
            isLoading={isLoading || isFetchingNextPage}
            onSearchQueryChange={onSearchQueryChange}
            permissionLevel={StoreReadOnlyAccess}
            selectedValue={selectedValue}
            stores={storeReadOnlyAccessStores}
            storesName="storeReadOnlyAccessStores"
            suggestions={storeOptions}
            title={translate('Store_readonly_access')}
          />
        </Permissions>

        <Permissions allowed={[App.AppResourceSetEnum.CreateTeammateIntegrator]}>
          <ModifyPermissionLevel
            dataFd="integrator"
            desc={translate('Integrator_description')}
            disabled={isMe}
            handlePermissionChange={handlePermissionChange}
            handleScroll={handleScroll}
            handleStoresChange={handleStoresChange}
            isNewUser={isNewUser}
            isLoading={isLoading || isFetchingNextPage}
            onSearchQueryChange={onSearchQueryChange}
            permissionLevel={Integrator}
            selectedValue={selectedValue}
            stores={integratorStores}
            storesName="integratorStores"
            suggestions={storeOptions}
            title={translate('Integrator')}
          />
        </Permissions>
        <Permissions allowed={['FlipdishStaff']}>
          <ModifyPermissionLevel
            dataFd="onboarding"
            desc={translate('Onboarding_description')}
            disabled={isMe}
            handlePermissionChange={handlePermissionChange}
            handleScroll={handleScroll}
            handleStoresChange={handleStoresChange}
            isNewUser={isNewUser}
            isLoading={isLoading || isFetchingNextPage}
            onSearchQueryChange={onSearchQueryChange}
            permissionLevel={Onboarding}
            selectedValue={selectedValue}
            stores={onboardingRole}
            storesName="onboardingRole"
            suggestions={storeOptions}
            title={translate('Onboarding')}
          />
        </Permissions>

        <Grid container className={classes.buttons}>
          {match.params.teammateId ? (
            <div className={classes.remove}>
              <Hidden mdDown>
                <Button
                  color="secondary"
                  className={classes.button}
                  onClick={handleOpen}
                  data-fd="teammate-remove"
                  disabled={teammateLoading || isMe}
                >
                  {translate('Remove_user')}
                </Button>
              </Hidden>
              <Hidden mdUp>
                <Button
                  color="secondary"
                  className={classes.button}
                  onClick={handleOpen}
                  data-fd="teammate-remove"
                  disabled={teammateLoading || isMe}
                >
                  {translate('Remove')}
                </Button>
              </Hidden>
            </div>
          ) : null}
          <Link
            to={'/' + appId + '/teammates'}
            className={classes.hyperlink}
            data-fd="teammate-cancel"
          >
            <Button color="primary" className={clsx(classes.button, classes.cancel)}>
              {translate('Cancel')}
            </Button>
          </Link>
          <LoadingButton
            buttonProps={{
              color: 'primary',
              onClick: modifyTeammate,
              'data-fd': 'teammate-save',
              className: classes.button,
              variant: 'contained',
            }}
            wrapperClassName={classes.button}
            loadingResources={[teammatesConstants.SET_TEAMMATE_LOADING]}
            loading={teammateLoading}
            disabled={teammateLoading || isLoading || isMe}
          >
            {match.params.teammateId ? translate('Save') : translate('Send_invite')}
          </LoadingButton>
        </Grid>
      </PaperContainer>
    </PageLayout>
  );
};

const mapStateToProps = (state: AppState, props: Props) => {
  const { locale } = state;

  return {
    currentApp: state.currentApp,
    currentUser: getCurrentUser(state),
    teammate: getTeammate(state, props),
    teammateLoading: getTeammatesLoading(state),
    teammates: state.teammates?.teammates || [],
    translate: getTranslate(locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    dispatchGetTeammatesFromApi: () => {
      dispatch(getTeammatesFromApi());
    },
    getTeammateDetails: (teammateId) => {
      dispatch(getTeammateDetails(teammateId));
    },
    notify: (data: NotifyProps) => {
      dispatch(notify(data));
    },
    createTeammate: (email, appAccessLevel, storeIds, callback) => {
      dispatch(createTeammate(email, appAccessLevel, storeIds, callback));
    },
    updateTeammateDetails: (
      TeammateId,
      selectedValue,
      hasAccessToAllStores,
      storeIds,
      callback
    ) => {
      dispatch(
        updateTeammateDetails(TeammateId, selectedValue, hasAccessToAllStores, storeIds, callback)
      );
    },
    deleteTeammate: (teammateId, callback) => {
      dispatch(deleteTeammate(teammateId, callback));
    },
  };
};

const EnhancedComponent = compose<any, IExternalProps>(
  withRouter,
  withStyles(styles, {
    name: 'Modify',
    withTheme: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(Modify);

const ComponentForTest = compose(
  withStyles(styles, {
    name: 'Modify',
    withTheme: true,
  }),
  connect(mapStateToProps)
)(Modify);

export { EnhancedComponent as Modify, ComponentForTest as ModifyTest };
