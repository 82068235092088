export type EntitlementPricing = {
  entitlementCode: string;
  countryCode: string;
  currency: string;
  languageCode: string;
  bundles: PricingInformation[];
};

export type PricingInformation = {
  name: TranslationId;
  description: TranslationId;
  prices: Price[];
  addOns: AddOn[];
};

type Price = {
  priceId: string;
  rucurring: PricingOccurrence;
  amount: number;
};

export type AddOn = {
  name: string;
  priceId: string;
  amount: number;
};

export enum PricingOccurrence {
  'OneTime' = 'OneTime',
  'Monthly' = 'Monthly',
  'Yearly' = 'Yearly',
}

const developmentPricingInformation: EntitlementPricing[] = [
  {
    entitlementCode: 'online_ordering',
    countryCode: 'IE',
    currency: 'EUR',
    languageCode: 'en',
    bundles: [
      {
        name: 'checkout_website_title',
        description: 'checkout_website_description',
        prices: [
          {
            priceId: 'price_1NcsWQH2UJL0gt2tOhzDcvj2',
            rucurring: PricingOccurrence.Monthly,
            amount: 69.0,
          },
          {
            priceId: 'price_1NcsWQH2UJL0gt2tOhzDcvj2',
            rucurring: PricingOccurrence.Yearly,
            amount: 69.0 * 10,
          },
        ],
        addOns: [
          {
            name: 'V2 Pro - Terminal',
            priceId: 'price_1Nf2RvH2UJL0gt2tdDoj9Znj',
            amount: 100,
          },
        ],
      },
      {
        name: 'checkout_website_app_title',
        description: 'checkout_website_app_description',
        prices: [
          {
            priceId: 'price_1NcsbZH2UJL0gt2tOHgxayBm',
            rucurring: PricingOccurrence.Monthly,
            amount: 99.0,
          },
          {
            priceId: 'price_1NcsbZH2UJL0gt2tOHgxayBm',
            rucurring: PricingOccurrence.Yearly,
            amount: 99.0 * 10,
          },
        ],
        addOns: [
          {
            name: 'V2 Pro - Terminal',
            priceId: 'price_1Nf2RvH2UJL0gt2tdDoj9Znj',
            amount: 100,
          },
        ],
      },
      {
        name: 'checkout_kiosk_title',
        description: 'checkout_kiosk_description',
        prices: [
          {
            priceId: 'price_1NcsbRH2UJL0gt2tYhjqzXZB',
            rucurring: PricingOccurrence.Monthly,
            amount: 79,
          },
          {
            priceId: 'price_1Ncse1H2UJL0gt2truS5SyH3',
            rucurring: PricingOccurrence.Yearly,
            amount: 79 * 10,
          },
        ],
        addOns: [
          {
            name: 'K2 Mini Kiosk with WisePOS E',
            priceId: 'price_1NcshtH2UJL0gt2tErCmqD0V',
            amount: 1270,
          },
        ],
      },
    ],
  },
  {
    entitlementCode: 'kiosk.system',
    countryCode: 'IE',
    currency: 'EUR',
    languageCode: 'en',
    bundles: [
      {
        name: 'checkout_kiosk_title',
        description: 'checkout_kiosk_description',
        prices: [
          {
            priceId: 'price_1NcsbRH2UJL0gt2tYhjqzXZB',
            rucurring: PricingOccurrence.Monthly,
            amount: 79,
          },
          {
            priceId: 'price_1Ncse1H2UJL0gt2truS5SyH3',
            rucurring: PricingOccurrence.Yearly,
            amount: 79 * 10,
          },
        ],
        addOns: [
          {
            name: 'K2 Mini Kiosk with WisePOS E',
            priceId: 'price_1NcshtH2UJL0gt2tErCmqD0V',
            amount: 1270,
          },
        ],
      },
    ],
  },
  {
    entitlementCode: 'appstore.app.app_907259269957550111',
    currency: 'EUR',
    languageCode: 'en',
    countryCode: 'IE',
    bundles: [
      {
        name: 'Checkout_Invalid_Data',
        description: 'Checkout_Invalid_Data',
        prices: [
          {
            rucurring: PricingOccurrence.Monthly,
            amount: 69,
            priceId: 'price_1OIRqtH2UJL0gt2tqVHDKZzV',
          },
        ],
        addOns: [],
      },
    ],
  },
  {
    entitlementCode: 'appstore.app.app_884456695764353024', // Unit test
    currency: 'EUR',
    languageCode: 'en',
    countryCode: 'IE',
    bundles: [
      {
        name: 'Checkout_Invalid_Data',
        description: 'Checkout_Invalid_Data',
        prices: [
          {
            rucurring: PricingOccurrence.Monthly,
            amount: 1,
            priceId: 'price_xxxxx',
          },
        ],
        addOns: [],
      },
    ],
  },
  {
    entitlementCode: 'appstore.app.app_907259269957990522', // Flipdish Subscription Test
    currency: 'EUR',
    languageCode: 'en',
    countryCode: 'IE',
    bundles: [
      {
        name: 'Checkout_Invalid_Data',
        description: 'Checkout_Invalid_Data',
        prices: [
          {
            rucurring: PricingOccurrence.Monthly,
            amount: 12.0,
            priceId: 'price_1OKJNLH2UJL0gt2tR27WkwpG',
          },
        ],
        addOns: [],
      },
    ],
  },
];

const productionPricingInformation: EntitlementPricing[] = [
  {
    entitlementCode: 'online_ordering',
    countryCode: 'GB',
    currency: 'GBP',
    languageCode: 'en',
    bundles: [
      // Website only bundle
      {
        name: 'checkout_website_title',
        description: 'checkout_website_description',
        prices: [
          {
            priceId: 'price_1NMD3vH2UJL0gt2t1Q4CdC7Y',
            rucurring: PricingOccurrence.Monthly,
            amount: 69.0,
          },
          {
            priceId: 'price_1NMD6QH2UJL0gt2t8U2apsal',
            rucurring: PricingOccurrence.Yearly,
            amount: 588.0,
          },
        ],
        addOns: [
          {
            name: 'Includes multi-page Website, Marketing Kit, Flipdish Portal, and Terminal',
            priceId: 'price_1NMD5eH2UJL0gt2teb5wvdm2',
            amount: 249.0,
          },
        ],
      },
      // Website and mobile app bundle
      {
        name: 'checkout_website_app_title',
        description: 'checkout_website_app_description',
        prices: [
          {
            priceId: 'price_1NMD94H2UJL0gt2tiYMXPnkH',
            rucurring: PricingOccurrence.Monthly,
            amount: 99.0,
          },
          {
            priceId: 'price_1NMD8rH2UJL0gt2tbm9aDeSE',
            rucurring: PricingOccurrence.Yearly,
            amount: 948.0,
          },
        ],
        addOns: [
          {
            name: 'Includes multi-page Website, Marketing Kit, Android and iOS Apps, Flipdish Portal, and Terminal. Does not include Apple developer fees.',
            priceId: 'price_1NMD7AH2UJL0gt2tjiUUrsuz',
            amount: 349.0,
          },
        ],
      },
      // Kiosk only bundle
      {
        name: 'checkout_kiosk_title',
        description: 'checkout_kiosk_description',
        prices: [
          {
            priceId: 'price_1NMD4sH2UJL0gt2tPx2YCCqD',
            rucurring: PricingOccurrence.Monthly,
            amount: 79,
          },
          {
            priceId: 'price_1NQXOXH2UJL0gt2tWnOT3h5H',
            rucurring: PricingOccurrence.Yearly,
            amount: 699,
          },
        ],
        addOns: [
          {
            name: 'Includes multi-page Website and Kiosk set up, Marketing Kit, Flipdish Portal, and Terminal',
            priceId: 'price_1NMD5eH2UJL0gt2teb5wvdm2',
            amount: 249.0,
          },
          {
            name: 'K2 Mini Kiosk, with card reader',
            priceId: 'price_1LxtaIH2UJL0gt2t8MnVe599',
            amount: 1000.0,
          },
        ],
      },
    ],
  },
  {
    entitlementCode: 'online_ordering',
    countryCode: 'IE',
    currency: 'EUR',
    languageCode: 'en',
    bundles: [
      // Website only bundle
      {
        name: 'checkout_website_title',
        description: 'checkout_website_description',
        prices: [
          {
            priceId: 'price_1NPhskH2UJL0gt2tucFLXKVN',
            rucurring: PricingOccurrence.Monthly,
            amount: 69.0,
          },
          {
            priceId: 'price_1NMCzWH2UJL0gt2trNiz2ShH',
            rucurring: PricingOccurrence.Yearly,
            amount: 588.0,
          },
        ],
        addOns: [
          {
            name: 'Includes multi-page Website, Marketing Kit, Flipdish Portal, and Terminal',
            priceId: 'price_1NMD9LH2UJL0gt2tGSuROmir',
            amount: 299.0,
          },
        ],
      },
      // Website and mobile app bundle
      {
        name: 'checkout_website_app_title',
        description: 'checkout_website_app_description',
        prices: [
          {
            priceId: 'price_1NMD4qH2UJL0gt2t4OELFXnm',
            rucurring: PricingOccurrence.Monthly,
            amount: 99.0,
          },
          {
            priceId: 'price_1NMD94H2UJL0gt2tzBCoT5GX',
            rucurring: PricingOccurrence.Yearly,
            amount: 948.0,
          },
        ],
        addOns: [
          {
            name: 'Includes multi-page Website, Marketing Kit, Android and iOS Apps, Flipdish Portal, and Terminal. Does not include Apple developer fees.',
            priceId: 'price_1NMD5FH2UJL0gt2tZGV4SxiO',
            amount: 399.0,
          },
        ],
      },
      // Kiosk only bundle
      {
        name: 'checkout_kiosk_title',
        description: 'checkout_kiosk_description',
        prices: [
          {
            priceId: 'price_1NMD4oH2UJL0gt2tWxkAwGlV',
            rucurring: PricingOccurrence.Monthly,
            amount: 79,
          },
          {
            priceId: 'price_1NMD86H2UJL0gt2t4I1VNv40',
            rucurring: PricingOccurrence.Yearly,
            amount: 699,
          },
        ],
        addOns: [
          {
            name: 'Includes multi-page Website and Kiosk set up, Marketing Kit, Flipdish Portal, and Terminal',
            priceId: 'price_1NMD9LH2UJL0gt2tGSuROmir',
            amount: 299.0,
          },
          {
            name: 'K2 Mini Kiosk, with card reader',
            priceId: 'price_1LxtaIH2UJL0gt2tiRVZx81y',
            amount: 1100.0,
          },
        ],
      },
    ],
  },
  {
    entitlementCode: 'appstore.app.app_556380326994116608', // Uber Direct
    currency: 'GBP',
    languageCode: 'en',
    countryCode: 'GB',
    bundles: [
      {
        name: 'Subscriptions',
        description: 'Subscriptions',
        prices: [
          {
            rucurring: PricingOccurrence.Monthly,
            amount: 0.0,
            priceId: 'price_1PJWgIH2UJL0gt2tDbQtTFag',
          },
        ],
        addOns: [],
      },
    ],
  },
  {
    entitlementCode: 'appstore.app.app_556380326994116608', // Uber Direct
    currency: 'CAD',
    languageCode: 'en',
    countryCode: 'CA',
    bundles: [
      {
        name: 'Subscriptions',
        description: 'Subscriptions',
        prices: [
          {
            rucurring: PricingOccurrence.Monthly,
            amount: 20.0,
            priceId: 'price_1OhAtAH2UJL0gt2trmxJTBhi',
          },
        ],
        addOns: [],
      },
    ],
  },
  {
    entitlementCode: 'appstore.app.app_556380326994116608', // Uber Direct
    currency: 'USD',
    languageCode: 'en',
    countryCode: 'US',
    bundles: [
      {
        name: 'Subscriptions',
        description: 'Subscriptions',
        prices: [
          {
            rucurring: PricingOccurrence.Monthly,
            amount: 20.0,
            priceId: 'price_1OhAkdH2UJL0gt2tSBg0pZrb',
          },
        ],
        addOns: [],
      },
    ],
  },
  {
    entitlementCode: 'appstore.app.app_556380326994116608', // Uber Direct
    currency: 'EUR',
    languageCode: 'en',
    countryCode: 'ES',
    bundles: [
      {
        name: 'Subscriptions',
        description: 'Subscriptions',
        prices: [
          {
            rucurring: PricingOccurrence.Monthly,
            amount: 20.0,
            priceId: 'price_1OhAmZH2UJL0gt2tN6x0vOt4',
          },
        ],
        addOns: [],
      },
    ],
  },
  {
    entitlementCode: 'appstore.app.app_672282244739497984', // Customer Feedback
    currency: 'GBP',
    languageCode: 'en',
    countryCode: 'GB',
    bundles: [
      {
        name: 'Subscriptions',
        description: 'Subscriptions',
        prices: [
          {
            rucurring: PricingOccurrence.Monthly,
            amount: 25.0,
            priceId: 'price_1PsMu6H2UJL0gt2tVSiONyIb',
          },
        ],
        addOns: [],
      },
    ],
  },
  {
    entitlementCode: 'appstore.app.app_672282244739497984', // Customer Feedback
    currency: 'EUR',
    languageCode: 'en',
    countryCode: 'IE',
    bundles: [
      {
        name: 'Subscriptions',
        description: 'Subscriptions',
        prices: [
          {
            rucurring: PricingOccurrence.Monthly,
            amount: 30.0,
            priceId: 'price_1PsMryH2UJL0gt2traeCnmbs',
          },
        ],
        addOns: [],
      },
    ],
  },
];

const isProduction = process.env.ENVIRONMENT === 'production';
export const pricingInformation = isProduction
  ? productionPricingInformation
  : developmentPricingInformation;
