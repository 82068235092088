import axios from 'axios';

import { Auth0CallbackPath } from '../constants/auth0.constants';
import { API_BASE } from '../helpers/apibase';
import { mapServerError } from './utils/serverErrorMapper';

const getFlipdishAuthorizationTokenKey = 'getFlipdishAuthorizationToken';

function identityApiBase(): string {
  if (process.env.VITE_PORTAL_IDENTITY_BASE_URL) {
    return process.env.VITE_PORTAL_IDENTITY_BASE_URL;
  }
  return API_BASE;
}

const getFlipdishAuthorizationToken = async (code: string) => {
  try {
    const redirectUri = window.location.origin.toString() + Auth0CallbackPath;
    const url = `${identityApiBase()}/api/auth/trygetjwtfordownstreamauth0?code=${code}&redirect_uri=${redirectUri}`;
    return await axios.get(url, { withCredentials: true });
  } catch (response) {
    throw mapServerError(response);
  }
};

// Function to refresh the JWT token
const refreshJwtToken = async () => {
  try {
    return await axios.get(`${identityApiBase()}/api/auth/TryGetJwtForDownstream?setCookie=true`, {
      withCredentials: true,
    });
  } catch (response) {
    throw mapServerError(response);
  }
};

export { getFlipdishAuthorizationToken, getFlipdishAuthorizationTokenKey, refreshJwtToken };
