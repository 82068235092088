import * as React from 'react';

import { History } from 'history';
import * as qs from 'qs';
import { match, RouteComponentProps, withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

export type WithRouteSearchParamsConfig = {} & {
  name: string;
};
export type WithRouteSearchParamsProps<P> = {} & {
  search?: P;
  history: History;
  match: match<any>;
  setSearch(value: P);
};

type Props = RouteComponentProps;
type State<T> = {} & { search?: T };

const WithRouteSearchParams =
  ({ name }: WithRouteSearchParamsConfig) =>
  <P extends {}>(
    Component: React.ComponentType<React.PropsWithChildren<WithRouteSearchParamsProps<P>>>
  ) =>
    compose<P & Props, P>(
      setDisplayName('WithRouteSearchParams'),
      withRouter
    )(
      class extends React.Component<Props & P, State<P>> {
        public state: State<P> = {
          search: undefined,
        };

        public UNSAFE_componentWillMount() {
          const search = this.getRouteSearch();
          this.setState({ search: search ? search[name] : undefined });
        }

        public componentDidUpdate(prevProps: Props) {
          if (prevProps.location.search !== this.props.location.search) {
            const search = this.getRouteSearch();
            this.setState({ search: search ? search[name] : undefined });
          }
        }

        public render() {
          const {
            location,
            staticContext,
            // @ts-ignore
            ...other
          } = this.props;
          return <Component {...other} search={this.state.search} setSearch={this.setSearch} />;
        }

        public setSearch = (value: P) => {
          this.setState({ search: !value ? undefined : value }, this.setRouteSearch);
        };

        private getRouteSearch = (): object | undefined => {
          const { location } = this.props;
          if (location.search !== '') {
            const searchProps = qs.parse(location.search, {
              ignoreQueryPrefix: true,
            });
            return searchProps || {};
          }
          return undefined;
        };
        private setRouteSearch = () => {
          const { history, location } = this.props;
          const currentSearch = this.getRouteSearch();
          // const newSearch
          const search = qs.stringify(
            {
              ...currentSearch,
              ...{ [name]: this.state.search },
            },
            { skipNulls: true, encodeValuesOnly: true, indices: false }
          );
          // tslint:disable-next-line:prefer-object-spread
          const newLocation = Object.assign({}, location, { search });
          history.replace(newLocation);
        };
      }
    );

export default WithRouteSearchParams;
