import React from 'react';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import GeneralKioskSettings from './GeneralKioskSettings';
import HomeScreenImageSelection from './HomeScreenImageSelection';

const useClasses = makeStyles(() => ({
  subHeading: {
    marginBottom: 16,
  },
  section: {
    marginBottom: 24,
  },
}));

const HomeScreenCustomisation = (props: MappedState) => {
  const { translate, currentApp } = props;
  const classes = useClasses();
  return (
    <PageLayout
      title={translate('Kiosk_Customisation')}
      toParent={`/${currentApp.AppId}/kiosks`}
      strictToParent
      documentTitle="Kiosks"
    >
      <Typography paragraph className={classes.section}>
        <Translate id="Changing_settings_here_applies_to_all_Kiosks_in_your_account" />
      </Typography>
      <PaperContainer className={classes.section}>
        <Typography variant="h5" className={classes.subHeading}>
          <Translate id="Kiosks_Home_Screen_Images" />
        </Typography>
        <HomeScreenImageSelection />
      </PaperContainer>
      <PaperContainer className={classes.section}>
        <Typography variant="h5" className={classes.subHeading}>
          <Translate id="Kiosk_Branding_Settings" />
        </Typography>
        <GeneralKioskSettings />
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
  currentApp: state.currentApp,
});

export default compose<MappedState, {}>(connect(mapStateToProps))(HomeScreenCustomisation);
