import {
  BankAccountCreate,
  BankAccountDetail,
  BankAccountDetailsUpdateRequest,
  BankAccountSummary,
  CountryWithAccountFieldsDefinitions,
  Store,
  StripeAccountLinkRequest,
} from '@flipdish/api-client-typescript';

import { action, actionError, createAsyncAction, dispatchAsync } from '../../../actions/utils';
import {
  closeNotifySaving,
  notify,
  notifyError,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { bankingServices } from '../../../services/banking.service';
import * as storeServices from '../../../services/store.service';
import { BankEvents } from '../../../signalr/hub.actions';

// #region getBankAccounts
export const BANKING_LOAD = 'BANKING_LOAD';
export const BANKING_LOAD_REQUEST = 'BANKING_LOAD_REQUEST';
export const BANKING_LOAD_SUCCESS = 'BANKING_LOAD_SUCCESS';
export const BANKING_LOAD_FAILURE = 'BANKING_LOAD_FAILURE';

export const getBankAccountsRequest = () => action(BANKING_LOAD_REQUEST);

export type GetBankAccountsSuccess = ReturnType<typeof getBankAccountsSuccess>;
export const getBankAccountsSuccess = (accounts: BankAccountSummary[]) =>
  action(BANKING_LOAD_SUCCESS, accounts);

export const getBankAccountsFailure = (error: Error) =>
  actionError(BANKING_LOAD_FAILURE, undefined, error);

export function getBankAccounts() {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    try {
      dispatch(getBankAccountsRequest());

      const result = await bankingServices.getBankAccounts(appId);
      dispatch(getBankAccountsSuccess(result));
    } catch (error) {
      if (
        error.message !==
        'Successful authentication, but authorization has been denied for this request.'
      ) {
        dispatch(getBankAccountsFailure(error));
        dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      }
    }
  };
}
// #endregion

// #region getBankAccountDetails
export const BANKING_DETAILS_LOAD = 'BANKING_DETAILS_LOAD';
export const BANKING_DETAILS_LOAD_REQUEST = 'BANKING_DETAILS_LOAD_REQUEST';
export const BANKING_DETAILS_LOAD_SUCCESS = 'BANKING_DETAILS_LOAD_SUCCESS';
export const BANKING_DETAILS_LOAD_FAILURE = 'BANKING_DETAILS_LOAD_FAILURE';

export const getBankAccountDetailsRequest = () => action(BANKING_DETAILS_LOAD_REQUEST);

export type GetBankAccountDetailsSuccess = ReturnType<typeof getBankAccountDetailsSuccess>;
export const getBankAccountDetailsSuccess = (props: BankAccountDetail) =>
  action(BANKING_DETAILS_LOAD_SUCCESS, props);

export const getBankAccountDetailsFailure = (error: Error, props) =>
  actionError(BANKING_DETAILS_LOAD_FAILURE, props, error);

export function getBankAccountDetails(accountId) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(getBankAccountDetailsRequest());

      const result = await bankingServices.getBankAccountById(appId, accountId);
      dispatch(getBankAccountDetailsSuccess(result));
    } catch (error) {
      dispatch(getBankAccountDetailsFailure(error, appId));
    }
  };
}
// #endregion

// #region createBankAccount
export const BANKING_CREATE = 'BANKING_CREATE';
export const BANKING_CREATE_REQUEST = 'BANKING_CREATE_REQUEST';
export const BANKING_CREATE_SUCCESS = 'BANKING_CREATE_SUCCESS';
export const BANKING_CREATE_FAILURE = 'BANKING_CREATE_FAILURE';

export const createBankAccountRequest = () => action(BANKING_CREATE_REQUEST);

export type CreateBankAccountSuccess = ReturnType<typeof createBankAccountSuccess>;
export const createBankAccountSuccess = (props: BankAccountDetail) =>
  action(BANKING_CREATE_SUCCESS, props);

export const createBankAccountFailure = (error: Error, props) =>
  actionError(BANKING_CREATE_FAILURE, props, error);

export function createBankAccount(account: BankAccountCreate, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(notifySaving({ persist: true }));
      dispatch(createBankAccountRequest());

      const result = await bankingServices.createBankAccount(appId, account);
      dispatch(createBankAccountSuccess(result));

      dispatch(closeNotifySaving());
    } catch (error) {
      dispatch(createBankAccountFailure(error, appId));

      dispatch(closeNotifySaving());
      dispatch(notifyError({ message: error.message, translate: true }));

      if (throwError) {
        throw error;
      }
    }
  };
}
// #endregion

// #region createBankAccount
export const BANKING_CREATE_WITH_CONNECT_ACCOUNT = 'BANKING_CREATE_WITH_CONNECT_ACCOUNT';
export const BANKING_CREATE_WITH_CONNECT_ACCOUNT_REQUEST =
  'BANKING_CREATE_WITH_CONNECT_ACCOUNT_REQUEST';
export const BANKING_CREATE_WITH_CONNECT_ACCOUNT_SUCCESS =
  'BANKING_CREATE_WITH_CONNECT_ACCOUNT_SUCCESS';
export const BANKING_CREATE_WITH_CONNECT_ACCOUNT_FAILURE =
  'BANKING_CREATE_WITH_CONNECT_ACCOUNT_FAILURE';

export const createBankAccountAndConnectedAccountRequest = () =>
  action(BANKING_CREATE_WITH_CONNECT_ACCOUNT_REQUEST);

export type CreateBankAccountAndConnectedAccountSuccess = ReturnType<
  typeof createBankAccountAndConnectedAccountSuccess
>;
export const createBankAccountAndConnectedAccountSuccess = (props: BankAccountDetail) =>
  action(BANKING_CREATE_WITH_CONNECT_ACCOUNT_SUCCESS, props);

export const createBankAccountAndConnectedAccountFailure = (error: Error, props) =>
  actionError(BANKING_CREATE_WITH_CONNECT_ACCOUNT_FAILURE, props, error);

export function createBankAccountAndConnectedAccount(
  account: BankAccountCreate,
  throwError = false
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(notifySaving({ persist: true }));
      dispatch(createBankAccountAndConnectedAccountRequest());

      const result = await bankingServices.createBankAccountAndConnectedAccount(appId, account);
      dispatch(createBankAccountAndConnectedAccountSuccess(result));

      dispatch(closeNotifySaving());
    } catch (error) {
      dispatch(createBankAccountAndConnectedAccountFailure(error, appId));

      dispatch(closeNotifySaving());
      dispatch(notifyError({ message: error.message }));

      if (throwError) {
        throw error;
      }
    }
  };
}
// #endregion

// #region createStripeConnectedAccountLink
export const BANKING_STRIPE_GET_LINK_REQUEST = 'BANKING_STRIPE_GET_LINK_REQUEST';
export const BANKING_STRIPE_GET_LINK_SUCCESS = 'BANKING_STRIPE_GET_LINK_SUCCESS';
export const BANKING_STRIPE_GET_LINK_FAILURE = 'BANKING_STRIPE_GET_LINK_FAILURE';

export const createStripeConnectedAccountLinkRequest = () =>
  action(BANKING_STRIPE_GET_LINK_REQUEST);

export type CreateStripeConnectedAccountLinkSuccess = ReturnType<
  typeof createStripeConnectedAccountLinkSuccess
>;
export const createStripeConnectedAccountLinkSuccess = (props: string) =>
  action(BANKING_STRIPE_GET_LINK_SUCCESS, props);

export const createStripeConnectedAccountLinkFailure = (error: Error, props) =>
  actionError(BANKING_STRIPE_GET_LINK_FAILURE, props, error);

export function createStripeConnectedAccountLink(
  stripeAccountLinkRequest: StripeAccountLinkRequest,
  throwError = false
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(notifySaving({ persist: true }));
      dispatch(createStripeConnectedAccountLinkRequest());

      const result = await bankingServices.createStripeConnectedAccountLink(
        appId,
        stripeAccountLinkRequest
      );
      //@ts-ignore
      dispatch(createStripeConnectedAccountLinkSuccess(result));

      dispatch(closeNotifySaving());
    } catch (error) {
      dispatch(createStripeConnectedAccountLinkFailure(error, appId));

      dispatch(closeNotifySaving());
      dispatch(notifyError({ message: error.message }));

      if (throwError) {
        throw error;
      }
    }
  };
}
// #endregion

// #region removeBankAccount
export const BANKING_REMOVE_DELETE = 'BANKING_REMOVE';
export const BANKING_REMOVE_REQUEST = 'BANKING_REMOVE_REQUEST';
export const BANKING_REMOVE_SUCCESS = 'BANKING_REMOVE_SUCCESS';
export const BANKING_REMOVE_FAILURE = 'BANKING_REMOVE_FAILURE';

export const removeBankAccountRequest = (accountId: number) =>
  action(BANKING_REMOVE_REQUEST, accountId);

export type RemoveBankAccountSuccess = ReturnType<typeof removeBankAccountSuccess>;
export const removeBankAccountSuccess = (accountId: number) =>
  action(BANKING_REMOVE_SUCCESS, accountId);

export const removeBankAccountFailure = (error: Error, accountId: number) =>
  actionError(BANKING_REMOVE_FAILURE, accountId, error);

export const removeBankAccount = (accountId: number) => {
  return async (dispatch, getState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(removeBankAccountRequest(accountId));

      await bankingServices.removeBankAccount(appId, accountId);
      dispatch(removeBankAccountSuccess(accountId));
      dispatch(
        notify({
          message: 'Bank_account_deleted',
          translate: true,
          variant: 'success',
          disableWindowBlurListener: true,
        })
      );
    } catch (error) {
      dispatch(removeBankAccountFailure(error, accountId));
      dispatch(notifyError({ message: error.message }));
    }
  };
};
// #endregion

// #region reset state
export const BANKING_RESET_STATE = 'BANKING_RESET_STATE';

export const reset = () => action(BANKING_RESET_STATE);
// #endregion

// #region SingalR
export const unSubscribeBankingUpdates = () => {
  return (dispatch: ThunkDispatch) => {
    dispatch(BankEvents.Unsubscribe.Created);
    dispatch(BankEvents.Unsubscribe.Updated);
    dispatch(BankEvents.Unsubscribe.Deleted);
  };
};

export const subscribeBankingUpdates = () => {
  return (dispatch: ThunkDispatch) => {
    dispatch(BankEvents.Subscribe.Created);
    dispatch(BankEvents.Subscribe.Updated);
    dispatch(BankEvents.Subscribe.Deleted);
  };
};
// #endregion

// #region attach bank account to store
export const ATTACH_BANK_ACCOUNT_TO_STORE_REQUEST = 'ATTACH_BANK_ACCOUNT_TO_STORE_REQUEST';
export const ATTACH_BANK_ACCOUNT_TO_STORE_SUCCESS = 'ATTACH_BANK_ACCOUNT_TO_STORE_SUCCESS';
export const ATTACH_BANK_ACCOUNT_TO_STORE_FAILURE = 'ATTACH_BANK_ACCOUNT_TO_STORE_FAILURE';
export const ATTACH_BANK_ACCOUNT_TO_STORE_RESET = 'ATTACH_BANK_ACCOUNT_TO_STORE_RESET';

export const attachBankAccountToStoreRequest = () => action(ATTACH_BANK_ACCOUNT_TO_STORE_REQUEST);

export const attachBankAccountToStoreSuccess = (accountId) =>
  action(ATTACH_BANK_ACCOUNT_TO_STORE_SUCCESS, accountId);

export const attachBankAccountToStoreFailure = (error: Error, props) =>
  actionError(ATTACH_BANK_ACCOUNT_TO_STORE_FAILURE, props, error);

export const attachBankAccountToStoreReset = () => action(ATTACH_BANK_ACCOUNT_TO_STORE_RESET);

export function attachBankAccountToStore(accountId: number, storeId: number, throwError = false) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      dispatch(attachBankAccountToStoreRequest());
      dispatch(notifySaving());

      await bankingServices.attachBankAccountToStore(appId, accountId, storeId);
      dispatch(attachBankAccountToStoreSuccess(accountId));
      dispatch(closeNotifySaving());
      dispatch(attachBankAccountToStoreReset());
    } catch (error) {
      dispatch(attachBankAccountToStoreFailure(error, appId));
      dispatch(closeNotifySaving());
      dispatch(notifyError({ message: error.message /*translate:true*/ }));

      if (throwError) {
        throw throwError;
      }
    }
  };
}
// #endregion

// #region getAttachedBankAccount
export const GET_ATTACHED_BANK_ACCOUNT_REQUEST = 'GET_ATTACHED_BANK_ACCOUNT_REQUEST';
export const GET_ATTACHED_BANK_ACCOUNT_SUCCESS = 'GET_ATTACHED_BANK_ACCOUNT_SUCCESS';
export const GET_ATTACHED_BANK_ACCOUNT_FAILURE = 'GET_ATTACHED_BANK_ACCOUNT_FAILURE';

export const getAttachedBankAccountRequest = () => action(GET_ATTACHED_BANK_ACCOUNT_REQUEST);

export const getAttachedBankAccountSuccess = (accountId) =>
  action(GET_ATTACHED_BANK_ACCOUNT_SUCCESS, accountId);

export const getAttachedBankAccountFailure = (error: Error, props) =>
  actionError(GET_ATTACHED_BANK_ACCOUNT_FAILURE, props, error);

export function getAttachedBankAccount(storeId: number) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(getAttachedBankAccountRequest());

      const result = await storeServices.getAttachedBankAccount(storeId);
      dispatch(getAttachedBankAccountSuccess(result));
    } catch (error) {
      dispatch(getAttachedBankAccountFailure(error, {}));
    }
  };
}
// #endregion

// #region Verify Bank Account
export const APPROVE_BANK_ACCOUNT = 'APPROVE_BANK_ACCOUNT';
export const approveBankAccount = (accountId) => action(APPROVE_BANK_ACCOUNT, accountId);

export const DISAPPROVE_BANK_ACCOUNT = 'DISAPPROVE_BANK_ACCOUNT';
export const disapproveBankAccount = (accountId) => action(DISAPPROVE_BANK_ACCOUNT, accountId);

export function updateBankAccountState(
  props: {
    accountId: number;
    accountState: BankAccountDetail.AccountStateEnum;
    reason: string;
  },
  throwError = false
) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const { accountId, accountState, reason } = props;
    const state = getState();
    const appId = state.currentApp.AppId as string;
    try {
      await bankingServices.updateBankAccountState(
        appId,
        accountId,
        accountState.toString(),
        reason
      );

      switch (accountState) {
        case BankAccountDetail.AccountStateEnum.Verified:
          dispatch(approveBankAccount(accountId));
          break;
        case BankAccountDetail.AccountStateEnum.Unverified:
          dispatch(disapproveBankAccount(accountId));
          break;
        default:
          break;
      }

      dispatch(notifySaved());
    } catch (error) {
      dispatch(notifyError({ message: error.message, translate: true }));
      if (throwError) {
        throw error;
      }
    }
  };
}

// #endregion

// #set country
export const SET_BANK_ACCOUNT_COUNTRY = 'SET_BANK_ACCOUNT_COUNTRY';
export const setCountry = (country) => action(SET_BANK_ACCOUNT_COUNTRY, country);
// #endregion

// #region get stores
export const GET_STORES_REQUEST = 'BANKING/GET_STORES_REQUEST';
export type GetStoresRequest = ReturnType<typeof getStoresRequest>;
export const getStoresRequest = () => action(GET_STORES_REQUEST, {});

export const GET_STORES_SUCCESS = 'BANKING/GET_STORES_SUCCESS';
export type GetStoresSuccess = ReturnType<typeof getStoresSuccess>;
export const getStoresSuccess = (payload: { stores: Store[] }) =>
  action(GET_STORES_SUCCESS, payload);

export const GET_STORES_FAILURE = 'BANKING/GET_STORES_FAILURE';
export type GetStoresFailure = ReturnType<typeof getStoresFailure>;
export const getStoresFailure = (error: Error) => actionError(GET_STORES_FAILURE, {}, error);

export function getStores(query?: string) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(getStoresRequest());
      const state = getState();
      const appId = state.currentApp.AppId;
      if (appId) {
        const stores = await bankingServices.getStoresByAppId({
          appId,
          page: 1,
          limit: 50,
          query,
        });
        dispatch(
          getStoresSuccess({
            stores: stores.Data,
          })
        );
      } else {
        dispatch(getStoresFailure(new Error('App id is undefined')));
      }
    } catch (error) {
      dispatch(getStoresFailure(error));
    }
  };
}

// #endregion
const ROOT_ACTION_NAME = 'BANKING';

export const bankingActions = {
  setBusinessTypeActions: createAsyncAction(`${ROOT_ACTION_NAME}:SET_BANK_ACCOUNT_BUSINESS_TYPE`),
  updateBankAccountActions: createAsyncAction<BankAccountDetailsUpdateRequest>(
    `${ROOT_ACTION_NAME}:UPDATE_BANK_ACCOUNT`
  ),
};

// #region setBankAccountBussinesTypeAsync
function setBankAccountBussinesTypeAsync(request: {
  BankAccountId: number;
  BusinessType: BankAccountDetail.BusinessTypeEnum;
}) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(
      dispatch,
      bankingActions.setBusinessTypeActions,
      async () => {
        await bankingServices.setBankAccountBusinessType(appId, request);
        return request;
      },
      {
        notifyOptions: [
          {
            type: 'saving',
          },
        ],
      }
    );
  };
}
// #endregion

// #region updateBankAccountAsync
function updateBankAccountAsync(id: number, account: BankAccountDetailsUpdateRequest) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(
      dispatch,
      bankingActions.updateBankAccountActions,
      async () => {
        await bankingServices.updateBankAccount(appId, id, account);
        return account;
      },
      {
        notifyOptions: [
          {
            type: 'custom',
            duration: 3000,
            message: 'Bank_account_details_saved',
          },
        ],
      }
    );
  };
}

// #endregion

export const bankingActionsAsync = {
  setBankAccountBussinesTypeAsync,
  updateBankAccountAsync,
};

// #region getCountriesWithFieldDefinitions
export const GET_COUNTRIES_WITH_FIELD_DEFINITIONS_REQUEST =
  'GET_COUNTRIES_WITH_FIELD_DEFINITIONS_REQUEST';
export const GET_COUNTRIES_WITH_FIELD_DEFINITIONS_SUCCESS =
  'GET_COUNTRIES_WITH_FIELD_DEFINITIONS_SUCCESS';
export const GET_COUNTRIES_WITH_FIELD_DEFINITIONS_FAILURE =
  'GET_COUNTRIES_WITH_FIELD_DEFINITIONS_FAILURE';

export const getCountriesWithFieldDefinitionsRequest = () =>
  action(GET_COUNTRIES_WITH_FIELD_DEFINITIONS_REQUEST);

export type GetCountriesWithFieldDefinitionsSuccess = ReturnType<
  typeof getCountriesWithFieldDefinitionsSuccess
>;
export const getCountriesWithFieldDefinitionsSuccess = (
  countriesWithFieldDefinitions: { [key: string]: CountryWithAccountFieldsDefinitions } | undefined
) => action(GET_COUNTRIES_WITH_FIELD_DEFINITIONS_SUCCESS, countriesWithFieldDefinitions);

export const getCountriesWithFieldDefinitionsFailure = (error: Error, props) =>
  actionError(GET_COUNTRIES_WITH_FIELD_DEFINITIONS_FAILURE, props, error);

export function getCountriesWithFieldDefinitions() {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(getCountriesWithFieldDefinitionsRequest());
      const state = getState();
      const appId = state.currentApp.AppId as string;

      const result = await bankingServices.getCountriesWithFieldDefinitions(appId);

      const countries = result.reduce<{ [key: string]: CountryWithAccountFieldsDefinitions }>(
        (dict, country) => {
          dict[country.CountryCode!] = country;
          return dict;
        },
        {}
      );
      dispatch(getCountriesWithFieldDefinitionsSuccess(countries));
    } catch (error) {
      dispatch(getCountriesWithFieldDefinitionsFailure(error, {}));
    }
  };
}
// #endregion
