import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import debounce from 'lodash/debounce';

import TextField, { TextFieldProps } from '../TextField/TextField';

const styles = () =>
  createStyles({
    root: {
      '& input[type=search]': {
        /* clears the 'X' from Internet Explorer */
        '&::-ms-clear': { display: 'none', width: 0, height: 0 },
        '&::-ms-reveal': { display: 'none', width: 0, height: 0 },
        /* clears the 'X' from Chrome */
        '&::-webkit-search-decoration': { display: 'none' },
        '&::-webkit-search-cancel-button': { display: 'none' },
        '&::-webkit-search-results-button': { display: 'none' },
        '&::-webkit-search-results-decoration': { display: 'none' },
      },
    },
  });

const SearchButton = ({ fdKey, onClick, disabled }) => (
  <IconButton
    aria-label="Search"
    data-fd={`${fdKey}_search_button`}
    onClick={onClick}
    disabled={disabled}
  >
    <SearchIcon />
  </IconButton>
);
const ClearButton = ({ fdKey, onClick, disabled }) => (
  <IconButton
    aria-label="Clear"
    data-fd={`${fdKey}_clear_button`}
    onClick={onClick}
    disabled={disabled}
  >
    <CloseIcon />
  </IconButton>
);

export type SearchTextFieldProps = TextFieldProps & {
  cancelOnEscape?: boolean;
  onChange?(value: string);
};
type State = {} & {
  value: string;
};
const SearchTextField = withStyles(styles, {
  name: 'FdSearchTextField',
})(
  class SearchTextField extends React.Component<
    SearchTextFieldProps & WithStyles<typeof styles>,
    State
  > {
    private $inputRef = React.createRef<HTMLInputElement>();

    public constructor(props) {
      super(props);

      this.state = {
        value: props.defaultValue || '',
      };

      // this.handleInputChange = debounce(this.handleInputChange, 500);
      this.onChange = debounce(this.onChange, 500);
    }

    public render() {
      const { fdKey, onChange, value, disabled, defaultValue, ...rest } = this.props;

      return (
        <form onSubmit={this.handleSubmit} className={this.props.classes.root}>
          <TextField
            {...rest}
            type="search"
            variant="outlined"
            fdKey={fdKey}
            inputRef={this.$inputRef}
            value={this.state.value}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!this.state.value ? (
                    <SearchButton
                      fdKey={fdKey}
                      disabled={disabled}
                      onClick={this.handleSearchClick}
                    />
                  ) : (
                    <ClearButton
                      fdKey={fdKey}
                      disabled={disabled}
                      onClick={this.handleClearClick}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            onChange={this.handleInputChange}
            onKeyUp={this.handleKeyUp}
          />
        </form>
      );
    }

    private handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
    };
    private handleChange = (value: string) => {
      if (this.state.value === value) {
        return;
      }
      this.setState({ value });
      this.onChange(value);
    };
    private onChange = (value: string) => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    };
    private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      this.handleChange(value);
    };
    private handleSearchClick = () => {
      if (!this.$inputRef.current) {
        return;
      }
      this.handleChange(this.$inputRef.current.value);
    };
    private handleClearClick = () => {
      this.handleChange('');
    };
    private handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.charCode === 13 || e.key === 'Enter') {
        this.handleChange(this.$inputRef.current!.value);
      } else if (this.props.cancelOnEscape && (e.charCode === 27 || e.key === 'Escape')) {
        this.handleChange('');
      }
      if (this.props.onKeyUp) {
        this.props.onKeyUp(e);
      }
    };
  }
);

export default SearchTextField;
