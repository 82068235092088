import React, { ReactElement, useEffect } from 'react';

import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import QueryBuilderOutlined from '@mui/icons-material/QueryBuilderOutlined';
import ShoppingCartOutlined from '@mui/icons-material/ShoppingCartOutlined';
import StoreOutlined from '@mui/icons-material/StoreOutlined';
import TimerOffOutlined from '@mui/icons-material/TimerOffOutlined';
import Warning from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';

import { storeActions, storePublish } from '../../actions/store.actions';
import preOrderSettingsSVG from '../../assets/images/ico_preorder.svg';
import serviceChargeSVG from '../../assets/images/service-charge.svg';
import { ReactComponent as TableSettingsIcon } from '../../assets/images/tableSettings.svg';
import * as storeConstants from '../../constants/store.constants';
import { getIsOwner } from '../../selectors/app.selector';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../selectors/loading.selector';
import { getSelectedStore } from '../../selectors/store.selector';
import { flagService } from '../../services';
import LinkButton from '../../ui/Button/LinkButton';
import PageLayout, { HORIZONTAL_SPACE_CLASSNAME } from '../../ui/Layout';
import FullWidthContainer from '../../ui/Layout/FullWidthContainer';
import PaperContainer from '../../ui/Layout/PaperContainer';
import Tooltip from '../../ui/Tooltip/Tooltip';
import { KioskIcon } from '../Kiosks/Components/KioskNestedMenuIcon';
import OrderBatchingIcon from './components/icons/OrderBatchingIcon';
import StoreTitle from './components/StoreTitle';

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([storeConstants.STORE_LOAD]);

const PreOrderSettingsIcon = () => <img src={preOrderSettingsSVG} style={{ width: '24px' }} />;
const ServiceChargeIcon = () => <img src={serviceChargeSVG} style={{ width: '24px' }} />;

const useStyles = makeStyles((theme: Theme) => ({
  rootItemDiv: {
    width: '100%',
    padding: '0px',
    margin: '0px',
    textTransform: 'none',
    borderRadius: 0,
  },
  item: {
    margin: '0px',
    minHeight: '72px',
  },
  itemIcon: {
    margin: '16px',
    marginLeft: '0',
    padding: '0px',
    minWidth: '0',
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
    },
  },
  zeroPadding: {
    padding: '0px',
  },
  divider: {
    marginLeft: '64px',
  },
  wordWrap: {
    wordWrap: 'break-word',
  },
  title: {
    margin: '0px 0px 16px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0px',
      display: 'none',
    },
  },
  listItemDanger: {
    color: '#ff426a',
  },
  storeNotPublished: {
    backgroundColor: '#dceafd',
    paddingTop: '17px',
    paddingRight: '24px',
    paddingBottom: '17px',
    paddingLeft: '42px',
  },
  storeNotPublishedPrimaryText: {
    fontSize: '18px',
  },
  storeNotPublishedSecondaryText: {
    fontSize: '12px',
  },
  publishStoreBtn: {
    letterSpacing: '1.25px',
    marginRight: '16px',
  },
}));

const routeData = (
  storeId: number,
  storeGroupId: number,
  showHiddenFeatures = false
): Array<{
  route: string;
  icon: ReactElement;
  title: TranslationId;
  description?: TranslationId;
  dataFd: string;
  hidden?: boolean;
  comingSoon?: boolean;
  permissions: string | string[];
}> => [
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/general`,
    icon: <StoreOutlined />,
    title: 'Store_general_settings_title',
    description: 'Set_address_location_phone_number_menu_and_timezon',
    dataFd: 'btn-general-settings',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/openinghours`,
    icon: <QueryBuilderOutlined />,
    title: 'Opening_hours_title',
    description: 'Opening_hours_description',
    dataFd: 'btn-opening-hours',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/openinghouroverrides`,
    icon: <TimerOffOutlined />,
    title: 'Opening_hour_overrides_title',
    description: 'Opening_hour_overrides_description',

    dataFd: 'btn-opening-hours-overrides',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/deliverysettings`,
    icon: <LocalShippingOutlined />,
    title: 'Delivery_settings_title',
    description: 'Set_delivery_zones_and_prices',
    dataFd: 'btn-delivery-settings',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/collection`,
    icon: <StoreOutlined />,
    title: 'Collection_settings_title',
    description: 'Collection_settings_description',
    dataFd: 'btn-collection-settings',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/ordersettings`,
    icon: <ShoppingCartOutlined />,
    title: 'Order_settings_title',
    description: 'Order_settings_description',
    dataFd: 'btn-order-settings',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/servicecharge`,
    icon: <ServiceChargeIcon />,
    title: 'Service_charge_title',
    description: 'Service_charge_description',
    dataFd: 'btn-order-servicecharge',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/ordernotifications`,
    icon: <NotificationsIcon />,
    title: 'Order_notifications_title',
    description: 'Order_notifications_description',
    dataFd: 'btn-order-notifications',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/preordersettings`,
    icon: <PreOrderSettingsIcon />,
    title: 'Pre_order_settings_title',
    dataFd: 'btn-pre-order-config',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/kiosksettings`,
    icon: <KioskIcon />,
    title: 'Store_Kiosk_Settings',
    description: 'Configure_your_store_settings_for_any_associated_Kiosks',
    dataFd: 'btn-store-kiosk-settings',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/tablesettings`,
    icon: <TableSettingsIcon />,
    title: 'Table_settings_title',
    description: 'Table_settings_button_description',
    dataFd: 'btn-table-settings',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/orderbatching`,
    icon: <OrderBatchingIcon />,
    title: 'Order_batching_title',
    description: 'Order_batching_description',
    dataFd: 'btn-order-batching',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/bankingsettings`,
    icon: <AccountBalanceOutlined />,
    title: 'Banking_title',
    description: 'Banking_settings_description',
    dataFd: 'btn-banking',
    permissions: 'ViewStores',
  },
  {
    route: `/storegroups/${storeGroupId}/stores/${storeId}/dangerzone`,
    icon: <Warning />,
    title: 'Danger_zone_title',
    description: 'Danger_zone_description',
    dataFd: 'btn-danger-zone',
    permissions: 'EditStores',
  },
];
type InnerProps = MappedProps & mapDispatchToProps;
type OuterProps = {};
type Props = InnerProps & OuterProps;
const StoreDetails = (props: Props) => {
  const {
    isOwner,
    isStorePublished,
    load,
    loading,
    loadingError,
    publishStore,
    routes,
    selectedApp,
    store,
    translate,
  } = props;
  const classes = useStyles();
  const { storeId } = useParams<{ storeId: string }>();

  const handleSubmit = () => {
    publishStore(Number(storeId), !!store?.Address?.DisplayForCustomer?.trim());
  };

  useEffect(() => {
    if (!store) {
      load(Number(storeId));
    }
  }, []);

  return (
    <>
      {loadingError && <Redirect to={'/' + selectedApp.AppId + '/storegroups'} />}

      {loading || !store ? (
        <CircularProgress style={{ margin: '10px' }} size={50} />
      ) : (
        <PageLayout
          auditLogsFilter={{ type: 'StoreId', value: `${storeId}` }}
          documentTitle="Store"
          title={<StoreTitle />}
          toParent={`/${selectedApp.AppId}/storegroups/${store.StoreGroupId}`}
          strictToParent
        >
          <PaperContainer fluid>
            <FullWidthContainer>
              <List dense className={classes.zeroPadding}>
                {!isStorePublished && (
                  <ListItem className={classes.storeNotPublished}>
                    <ListItemText
                      primary={translate("This_store_isn't_published_yet")}
                      secondary={translate('Ready_to_publish_store')}
                      style={{ paddingRight: '180px' }}
                      classes={{
                        primary: classes.storeNotPublishedPrimaryText,
                        secondary: classes.storeNotPublishedSecondaryText,
                      }}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip
                        fdKey={'tooltip-publish-store'}
                        messageId={'Only_an_owner_can_publish_or_unpublish_a_store'}
                        disableHoverListener={isOwner}
                        disableTouchListener={isOwner}
                      >
                        <div>
                          <Button
                            data-fd="publish_store_btn"
                            variant="contained"
                            color="primary"
                            className={classes.publishStoreBtn}
                            onClick={handleSubmit}
                            disabled={!isOwner}
                          >
                            {translate('Publish_store')}
                          </Button>
                        </div>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}

                {routes.map((route, index) => (
                  <LinkButton
                    disabled={!!route.comingSoon}
                    key={index}
                    className={classes.rootItemDiv}
                    fdKey={route.dataFd}
                    to={`/${selectedApp.AppId}${route.route}`}
                    style={
                      index === 0
                        ? { marginTop: '12px' }
                        : index + 1 === routes.length
                          ? { marginBottom: '12px' }
                          : {}
                    }
                  >
                    <Permissions allowed={[route.permissions]}>
                      <div style={{ width: '100%' }}>
                        <ListItem className={`${classes.item} ${HORIZONTAL_SPACE_CLASSNAME}`}>
                          <ListItemIcon
                            className={classes.itemIcon}
                            classes={{
                              root:
                                route.title === 'Danger_zone_title' ? classes.listItemDanger : '',
                            }}
                          >
                            {route.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={translate(route.title)}
                            classes={{
                              primary:
                                route.title === 'Danger_zone_title' ? classes.listItemDanger : '',
                              secondary:
                                route.title === 'Danger_zone_title' ? classes.listItemDanger : '',
                            }}
                            secondary={route.description && translate(route.description)}
                            style={{ paddingRight: '60px' }}
                          />
                          <ListItemSecondaryAction>
                            <IconButton className={classes.itemIcon}>
                              <KeyboardArrowRight />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {index + 1 < routes.length ? <Divider className={classes.divider} /> : ''}
                      </div>
                    </Permissions>
                  </LinkButton>
                ))}
              </List>
            </FullWidthContainer>
          </PaperContainer>
        </PageLayout>
      )}
    </>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, ownProps) => {
  const { storeId, storeGroupId } = ownProps.match.params;
  const showHiddenFeatures = state.permissions.some((p) => p === 'ShowHiddenFeatures');

  const routes = routeData(storeId, storeGroupId, showHiddenFeatures);
  let filteredRoutes = routes.filter((route) => !route.hidden);
  if (!state.permissions.includes('ViewAssignedBankAccount')) {
    filteredRoutes = filteredRoutes.filter((route) => route.title !== 'Banking_title');
  }
  if (!state.permissions.includes('EditStoreKioskSettings')) {
    filteredRoutes = filteredRoutes.filter((route) => route.title !== 'Store_Kiosk_Settings');
  }
  if (!state.permissions.includes('EditStoreKioskSettings')) {
    filteredRoutes = routes.filter((route) => route.title !== 'Store_Kiosk_Settings');
  }
  if (!state.permissions.includes('EditStores')) {
    filteredRoutes = filteredRoutes.filter((route) => route.title !== 'Danger_zone_title');
  }
  if (!flagService.isFlagOn(state, 'storeOrderBatching')) {
    filteredRoutes = filteredRoutes.filter((route) => route.title !== 'Order_batching_title');
  }
  const store = getSelectedStore(state);
  const isStorePublished = store && store.IsPublished;
  const isOwner = getIsOwner(state);

  return {
    routes: filteredRoutes,
    store,
    selectedApp: state.currentApp,
    loading: initialLoadingSelector(state),
    loadingError: initialLoadingErrorSelector(state),
    translate: getTranslate(state.locale),
    isStorePublished,
    isOwner,
  };
};

type mapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    load: (storeId: number) => {
      dispatch(storeActions.load(storeId));
    },
    publishStore: (storeId: number, canPublishStore: boolean) => {
      dispatch(storePublish(storeId, canPublishStore));
    },
  };
};

const EnhancedComponent = compose<InnerProps, OuterProps>(
  connect(mapStateToProps, mapDispatchToProps)
)(StoreDetails);

export { EnhancedComponent as StoreDetails };
