import {
  Coordinates,
  LeadTime,
  OrderBatchingConfiguration,
  OrderLeadTimes,
  ServiceCharge,
  Store,
  StoreAddress,
  StoreAddressBase,
  StoreBase,
  StoreCreateBase,
  StoreHeader,
  TipConfiguration,
} from '@flipdish/api-client-typescript';
import { StoreOrderNotificationContactDetails } from '@flipdish/api-client-typescript/private/api';
import { uniqueId } from 'lodash';

import * as storeConstants from '../constants/store.constants';
import {
  DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE,
  DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST,
  DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS,
  GET_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE,
  GET_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST,
  GET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS,
  SET_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE,
  SET_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST,
  SET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS,
  STORE_ARCHIVE,
  STORE_GET_LEAD_TIMES_SUCCESS,
  STORE_PREORDER_ENABLE_OPENING_HOURS_OVERRIDES,
  STORE_PUBLISH,
  STORE_UNPUBLISH,
  STORE_UPDATE_LEAD_TIMES_SUCCESS,
} from '../constants/store.constants';
import { getDebounceMaybe } from '../helpers/utilities';
import {
  closeAllOtherNotifications,
  ERROR_KEY,
  notifyError,
  notifySaved,
  notifySaving,
  SAVED_KEY,
  SAVING_KEY,
} from '../layouts/Notify/actions';
import ServerSubmissionError from '../models/ServerSubmissionError';
import {
  archiveStore,
  assignMenu,
  deleteOrderNotificationContacts,
  getOrderNotificationContacts,
  loadByAppId as loadByAppIdService,
  loadByAppIdHeaders,
  LoadByAppIdProps,
  publishStore,
  setOrderNotificationContacts,
  storeService,
  unpublishStore,
  updatePreOrderEnabled,
} from '../services/store.service';
import { action, actionError } from './utils';

export const storeActions = {
  loadAll: load,
  load: loadById,
  create,
  update,
  copy: clone,
  getStoreLeadTimes,
  updateStoreLeadTimes,
  getOrderBatchConfig,
  getTips,
  updateTips,
  getServiceCharge,
  updateOrderBatchConfig,
  updateServiceCharge,
};

// #region search
const { STORES_SET_SEARCH } = storeConstants;

export type SetSearch = ReturnType<typeof setSearch>;
export const setSearch = (query?: string) => action(STORES_SET_SEARCH, query);

// #endregion

// #region set selected store
const { STORE_SET_SELECTED } = storeConstants;

export type SetSelected = ReturnType<typeof setSelected>;
export const setSelected = (props: { storeGroupId: number; storeId: number }) =>
  action(STORE_SET_SELECTED, props);

// #endregion

// #region load
const { STORE_LOAD_ALL_REQUEST, STORE_LOAD_ALL_SUCCESS, STORE_LOAD_ALL_FAILURE } = storeConstants;

type LoadProps = {} & {
  storeGroupId?: number;
  query?: string;
  page?: number;
  limit?: number;
};

export type LoadRequest = ReturnType<typeof loadRequest>;
export const loadRequest = (props: LoadProps) => action(STORE_LOAD_ALL_REQUEST, undefined, props);

export type LoadSuccess = ReturnType<typeof loadSuccess>;
export const loadSuccess = (stores: Store[], props: LoadProps & { totalCount: number }) =>
  action(STORE_LOAD_ALL_SUCCESS, stores, props);

export type LoadFailure = ReturnType<typeof loadFailure>;
export const loadFailure = (error: Error, props: LoadProps) =>
  actionError(STORE_LOAD_ALL_FAILURE, props, error);

export function load(page?: number, limit?: number) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const storeGroupId = state.stores?.storeGroupId;
    const query = state.stores.search ? state.stores.search.query : undefined;
    const meta = {
      storeGroupId,
      query,
      page,
      limit,
    };

    try {
      dispatch(loadRequest(meta));

      const paginatedResult = await storeService.loadAll(query, page, limit, storeGroupId);
      const { Data, Page, Limit, TotalRecordCount } = paginatedResult;
      dispatch(
        loadSuccess(Data, {
          ...meta,
          page: Page,
          limit: Limit,
          totalCount: TotalRecordCount,
        })
      );
    } catch (error) {
      dispatch(loadFailure(error, meta));
    }
  };
}
// #endregion

// #region loadByAppId
const { STORE_LOAD_BY_APP_REQUEST, STORE_LOAD_BY_APP_SUCCESS, STORE_LOAD_BY_APP_FAILURE } =
  storeConstants;

export type LoadByAppIdRequest = ReturnType<typeof loadByAppIdRequest>;
export const loadByAppIdRequest = (props: LoadByAppIdProps) =>
  action(STORE_LOAD_BY_APP_REQUEST, undefined, props);

export type LoadByAppIdSuccess = ReturnType<typeof loadByAppIdSuccess>;
export const loadByAppIdSuccess = (
  stores: Store[],
  props: LoadByAppIdProps & { totalCount: number }
) => action(STORE_LOAD_BY_APP_SUCCESS, stores, props);

export type LoadByAppIdFailure = ReturnType<typeof loadByAppIdFailure>;
export const loadByAppIdFailure = (error: Error, props: LoadByAppIdProps) =>
  actionError(STORE_LOAD_BY_APP_FAILURE, props, error);

export function loadByAppId(props: { page?: number; limit?: number; query?: string } = {}) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    const meta = {
      appId,
      ...props,
    };

    try {
      dispatch(loadByAppIdRequest(meta));

      const paginatedResult = await loadByAppIdService(meta);
      if (paginatedResult) {
        const { Data, Page, Limit, TotalRecordCount } = paginatedResult;
        dispatch(
          loadByAppIdSuccess(Data, {
            ...meta,
            page: Page,
            limit: Limit,
            totalCount: TotalRecordCount,
          })
        );
      }
      return paginatedResult;
    } catch (error) {
      dispatch(loadByAppIdFailure(error, meta));
      return undefined;
    }
  };
}
// #endregion

// #region create
const { STORE_CREATE_REQUEST, STORE_CREATE_SUCCESS, STORE_CREATE_FAILURE } = storeConstants;

type CreateProps = {
  storeGroupId: number;
  store: StoreCreateBase;
};

export type CreateRequest = ReturnType<typeof createRequest>;
export const createRequest = (props: CreateProps) => action(STORE_CREATE_REQUEST, undefined, props);

export type CreateSuccess = ReturnType<typeof createSuccess>;
export const createSuccess = (store: Store, props: CreateProps) =>
  action(STORE_CREATE_SUCCESS, store, props);

export type CreateFailure = ReturnType<typeof createFailure>;
export const createFailure = (error: Error, props: CreateProps) =>
  actionError(STORE_CREATE_FAILURE, props, error);

export function create(props: CreateProps) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    try {
      dispatch(createRequest(props));

      const createStore = await storeService.create(props.storeGroupId, props.store);
      dispatch(createSuccess(createStore, props));
    } catch (error) {
      dispatch(loadFailure(error, props));
    }
  };
}
// #endregion

// #region clone

const { STORE_CREATE_CLONE_REQUEST, STORE_CREATE_CLONE_SUCCESS, STORE_CREATE_CLONE_FAILURE } =
  storeConstants;

type CloneProps = {
  storeGroupId: number;
  storeId: number;
};

export type CloneRequest = ReturnType<typeof cloneRequest>;
export const cloneRequest = (props: CloneProps) =>
  action(STORE_CREATE_CLONE_REQUEST, undefined, props);

export type CloneSuccess = ReturnType<typeof cloneSuccess>;
export const cloneSuccess = (store: Store, props: CloneProps) =>
  action(STORE_CREATE_CLONE_SUCCESS, store, props);

export type CloneFailure = ReturnType<typeof cloneFailure>;
export const cloneFailure = (error: Error, props: CloneProps) =>
  actionError(STORE_CREATE_CLONE_FAILURE, props, error);

export function clone(props: CloneProps) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(cloneRequest(props));

      const createdStore = await storeService.clone(props);
      dispatch(cloneSuccess(createdStore, props));
    } catch (error) {
      dispatch(cloneFailure(error, props));
    }
  };
}
// #endregion

function loadById(storeId: number) {
  return (dispatch) => {
    dispatch(request());

    return storeService.load(storeId).then(
      (restApiResultStore) => {
        dispatch(success(restApiResultStore));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: storeConstants.STORE_LOAD_REQUEST,
      storeId,
    };
  }

  function success(store: Store) {
    return {
      type: storeConstants.STORE_LOAD_SUCCESS,
      store,
    };
  }

  function failure(error) {
    return { type: storeConstants.STORE_LOAD_FAILURE, error, storeId };
  }
}

// #region update
const { STORE_UPDATE_REQUEST, STORE_UPDATE_SUCCESS, STORE_UPDATE_FAILURE } = storeConstants;

export const updateRequest = (storeId: number, storeGroupId: number, store: Partial<StoreBase>) =>
  action(STORE_UPDATE_REQUEST, { storeId, storeGroupId, store });

export const updateSuccess = (storeId: number, storeGroupId: number, store: Store) =>
  action(STORE_UPDATE_SUCCESS, { storeId, storeGroupId, store });

export const updateFailure = (
  storeId: number,
  storeGroupId: number,
  store: Partial<StoreBase>,
  error: Error
) => actionError(STORE_UPDATE_FAILURE, { storeId, storeGroupId, store }, error);

export const updatePromise =
  (storeId: number, storeGroupId: number, store: Partial<StoreBase>): ThunkAction =>
  (dispatch) => {
    dispatch(closeAllOtherNotifications(SAVING_KEY));
    dispatch(notifySaving());
    dispatch(updateRequest(storeId, storeGroupId, store));

    return storeService
      .update(storeId, store)
      .then((result) => {
        dispatch(closeAllOtherNotifications(SAVED_KEY));
        dispatch(notifySaved());
        return dispatch(updateSuccess(storeId, storeGroupId, result as Store));
      })
      .catch((error: ServerSubmissionError) => {
        dispatch(closeAllOtherNotifications(ERROR_KEY));
        dispatch(notifyError(error));
        dispatch(updateFailure(storeId, storeGroupId, store, error));
        throw error;
      });
  };
// #endregion
function update(throttle?: boolean) {
  let executableFunction;
  return function (storeId: number, store: StoreBase) {
    return (dispatch) => {
      if (throttle && executableFunction) {
        executableFunction.cancel();
      }
      executableFunction = getDebounceMaybe(
        () => {
          dispatch(closeAllOtherNotifications(SAVING_KEY));
          dispatch(notifySaving());

          return storeService.update(storeId, store).then(
            (restApiResultStore) => {
              dispatch(success(restApiResultStore));
              dispatch(closeAllOtherNotifications(SAVED_KEY));
              dispatch(notifySaved());
            },
            (error) => {
              dispatch(failure(error));
              dispatch(closeAllOtherNotifications(ERROR_KEY));
              dispatch(notifyError(error));
            }
          );
        },
        true,
        1000
      );
      dispatch(request(store));
      executableFunction();
    };

    function request(store) {
      return {
        type: storeConstants.STORE_UPDATE_REQUEST,
        storeId,
        store,
      };
    }

    function success(store: Store) {
      return {
        type: storeConstants.STORE_UPDATE_SUCCESS,
        storeId,
        store,
      };
    }

    function failure(error) {
      return { type: storeConstants.STORE_UPDATE_FAILURE, error, storeId };
    }
  };
}

// #region update address
const { STORE_UPDATE_ADDRESS_REQUEST, STORE_UPDATE_ADDRESS_SUCCESS, STORE_UPDATE_ADDRESS_FAILURE } =
  storeConstants;

export const updateAddressRequest = (
  storeId: number,
  storeGroupId: number,
  address: StoreAddressBase,
  coordinates?: Coordinates
) =>
  action(STORE_UPDATE_ADDRESS_REQUEST, {
    storeId,
    storeGroupId,
    address,
    coordinates,
  });

export const updateAddressSuccess = (
  storeId: number,
  storeGroupId: number,
  address: StoreAddress,
  coordinates?: Coordinates
) =>
  action(STORE_UPDATE_ADDRESS_SUCCESS, {
    storeId,
    storeGroupId,
    address,
    coordinates,
  });

export const updateAddressFailure = (
  error: Error,
  storeId: number,
  storeGroupId: number,
  address: StoreAddressBase,
  coordinates?: Coordinates
) =>
  actionError(STORE_UPDATE_ADDRESS_FAILURE, { storeId, storeGroupId, address, coordinates }, error);

export const updateAddress =
  (storeId: number, storeGroupId: number, address: StoreAddressBase, coordinates?: Coordinates) =>
  async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());
      dispatch(updateAddressRequest(storeId, storeGroupId, address, coordinates));

      const storeAddress = await storeService.updateStoreAddress(storeId, address);
      dispatch(updateAddressSuccess(storeId, storeGroupId, storeAddress, coordinates));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());

      if (coordinates) {
        await dispatch(updateCoordinates(storeId, storeGroupId, coordinates));
      }
    } catch (error) {
      dispatch(updateAddressFailure(error, storeId, storeGroupId, address, coordinates));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(error));
      throw error;
    }
  };
// #endregion

// #region update coordinates
const {
  STORE_UPDATE_ADDRESS_COORDINATES_REQUEST,
  STORE_UPDATE_ADDRESS_COORDINATES_SUCCESS,
  STORE_UPDATE_ADDRESS_COORDINATES_FAILURE,
} = storeConstants;

export const updateCoordinatesRequest = (
  storeId: number,
  storeGroupId: number,
  coordinates: Coordinates
) =>
  action(STORE_UPDATE_ADDRESS_COORDINATES_REQUEST, {
    storeId,
    storeGroupId,
    coordinates,
  });

export const updateCoordinatesSuccess = (
  storeId: number,
  storeGroupId: number,
  coordinates: Coordinates
) =>
  action(STORE_UPDATE_ADDRESS_COORDINATES_SUCCESS, {
    storeId,
    storeGroupId,
    coordinates,
  });

export const updateCoordinatesFailure = (
  storeId: number,
  storeGroupId: number,
  coordinates: Coordinates,
  error: Error
) =>
  actionError(
    STORE_UPDATE_ADDRESS_COORDINATES_FAILURE,
    { storeId, storeGroupId, coordinates },
    error
  );

export const updateCoordinates =
  (storeId: number, storeGroupId: number, coordinates: Coordinates) =>
  async (dispatch: ThunkDispatch) => {
    try {
      dispatch(updateCoordinatesRequest(storeId, storeGroupId, coordinates));

      const storeCoordinates = await storeService.updateStoreAddressCoordinates(
        storeId,
        coordinates
      );
      dispatch(updateCoordinatesSuccess(storeId, storeGroupId, storeCoordinates));
      dispatch(notifySaved());
      dispatch(loadByAppId());
    } catch (error) {
      dispatch(updateCoordinatesFailure(storeId, storeGroupId, coordinates, error));
      dispatch(notifyError({ message: error?.message }));
    }
  };
// #endregion

// #region reset
const { STORES_RESET } = storeConstants;

export const resetState = () => action(STORES_RESET);
// #endregion

// #region assignMenu
export const ASSIGN_MENU = 'ASSIGN_MENU';
export const ASSIGN_MENU_REQUEST = 'ASSIGN_MENU_REQUEST';
export const ASSIGN_MENU_SUCCESS = 'ASSIGN_MENU_SUCCESS';
export const ASSIGN_MENU_FAILURE = 'ASSIGN_MENU_FAILURE';

export type AssignMenuRequest = ReturnType<typeof assignMenuRequest>;
export const assignMenuRequest = () => action(ASSIGN_MENU_REQUEST);

export type AssignMenuRequestSuccess = ReturnType<typeof assignMenuRequestSuccess>;
export const assignMenuRequestSuccess = (
  storeId: number,
  menuId: number,
  storeName: string | undefined
) => action(ASSIGN_MENU_SUCCESS, { storeId, menuId, storeName });

export type AssignMenuRequestFailure = ReturnType<typeof assignMenuRequestFailure>;
export const assignMenuRequestFailure = (error) => action(ASSIGN_MENU_FAILURE, error);

export const assignMenuToStore = (
  storeId: number,
  menuId: number,
  storeName: string | undefined
) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());
      dispatch(assignMenuRequest());

      await assignMenu(storeId, menuId);

      dispatch(assignMenuRequestSuccess(storeId, menuId, storeName));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (e) {
      dispatch(assignMenuRequestFailure(e));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
    }
  };
};

// #endregion

// #region archive
export type ArchiveThisStore = ReturnType<typeof archiveThisStore>;
export const archiveThisStore = (storeId: number) => action(STORE_ARCHIVE, storeId);

export const storeArchive = (storeId: number) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());

      await archiveStore(storeId);
      dispatch(archiveThisStore(storeId));

      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
    }
  };
};
// #endregion

// #region publish
export type PublishThisStore = ReturnType<typeof publishThisStore>;
export const publishThisStore = (storeId: number) => action(STORE_PUBLISH, storeId);

export const storePublish = (storeId: number, canPublishStore = true) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      if (canPublishStore) {
        dispatch(closeAllOtherNotifications(SAVING_KEY));
        dispatch(notifySaving());

        await publishStore(storeId);
        dispatch(publishThisStore(storeId));

        dispatch(closeAllOtherNotifications(SAVED_KEY));
        dispatch(notifySaved());
      } else {
        dispatch(
          notifyError({
            key: uniqueId() + ERROR_KEY,
            message: 'Add_an_address_before_publishing_store',
            translate: true,
          })
        );
      }
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
    }
  };
};
// #endregion

// #region Unpublish
export type UnpublishThisStore = ReturnType<typeof unpublishThisStore>;
export const unpublishThisStore = (storeId: number) => action(STORE_UNPUBLISH, storeId);

export const storeUnpublish = (storeId: number) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());

      await unpublishStore(storeId);
      dispatch(unpublishThisStore(storeId));

      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
    }
  };
};
// #endregion

// #region update preorderenabled on hours override page
export type EnableStorePreOrder = ReturnType<typeof enableStorePreOrder>;
export const enableStorePreOrder = (
  storeId: number,
  deliveryType: 'Delivery' | 'Pickup',
  enabled: boolean
) => action(STORE_PREORDER_ENABLE_OPENING_HOURS_OVERRIDES, { storeId, deliveryType, enabled });

export const preOrderUpdate = (
  storeId: number,
  deliveryType: 'Delivery' | 'Pickup',
  enabled: boolean
) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());

      await updatePreOrderEnabled(storeId, deliveryType, enabled);
      dispatch(enableStorePreOrder(storeId, deliveryType, enabled));

      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
    }
  };
};
// #endregion

// #region store filter

export const GET_STORE_HEADERS = 'GET_STORE_HEADERS';

export type GetStoreHeaders = ReturnType<typeof getStoreHeaders>;
export const getStoreHeaders = (storeHeaders: StoreHeader[]) =>
  action(GET_STORE_HEADERS, storeHeaders);

export const storeHeadersGet = (appId, query?, page?, limit?) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      const storeHeaders = await loadByAppIdHeaders({ appId, query, page, limit });
      storeHeaders && dispatch(getStoreHeaders(storeHeaders));
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
    }
  };
};
//#endregion

//#region Order Notification Contacts
export const getStoreOrderNotificationContactsRequest = () =>
  action(GET_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST);

export const getStoreOrderNotificationContactsSuccess = (
  smsOrderNotifications: StoreOrderNotificationContactDetails[],
  emailOrderNotifications: StoreOrderNotificationContactDetails[]
) =>
  action(GET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS, {
    smsOrderNotifications,
    emailOrderNotifications,
  });

export const getStoreOrderNotificationContactsFailure = () =>
  action(GET_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE);

export const getStoreOrderNotificationContacts = (storeId: number) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      const details = await getOrderNotificationContacts(storeId);
      if (details.length) {
        const smsOrderNotifications = details.filter(
          (f: StoreOrderNotificationContactDetails) =>
            f.ContactType === StoreOrderNotificationContactDetails.ContactTypeEnum.Sms
        );
        const emailOrderNotifications = details.filter(
          (f: StoreOrderNotificationContactDetails) =>
            f.ContactType === StoreOrderNotificationContactDetails.ContactTypeEnum.Email
        );
        dispatch(
          getStoreOrderNotificationContactsSuccess(smsOrderNotifications, emailOrderNotifications)
        );
      }
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
    }
  };
};
// #endregion

//#region set store order notification
export const setStoreOrderNotificationContactsRequest = () =>
  action(SET_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST);

export const setStoreOrderNotificationContactsSuccess = (
  details: StoreOrderNotificationContactDetails
) => action(SET_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS, details);

export const setStoreOrderNotificationContactsFailure = () =>
  action(SET_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE);

export const setStoreOrderNotificationContacts = (
  storeId: number,
  details: StoreOrderNotificationContactDetails
) => {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      const response = await setOrderNotificationContacts(storeId, details);

      dispatch(setStoreOrderNotificationContactsSuccess(response));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
      throw e;
    }
  };
};
//#endregion

//#region delete store order notification
export const deleteStoreOrderNotificationContactsRequest = () =>
  action(DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_REQUEST);

export const deleteStoreOrderNotificationContactsSuccess = (
  ContactType: StoreOrderNotificationContactDetails.ContactTypeEnum,
  StoreOrderContactDetailId: number
) =>
  action(DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_SUCCESS, {
    ContactType,
    StoreOrderContactDetailId,
  });

export const deleteStoreOrderNotificationContactsFailure = () =>
  action(DELETE_STORE_ORDER_NOTIFICATION_CONTACTS_FAILURE);

export const deleteStoreOrderNotificationContacts = (
  storeId: number,
  details: StoreOrderNotificationContactDetails
) => {
  const { ContactType, StoreOrderContactDetailId } = details;
  return async (dispatch: ThunkDispatch) => {
    try {
      if (StoreOrderContactDetailId && ContactType) {
        dispatch(closeAllOtherNotifications(SAVING_KEY));
        await deleteOrderNotificationContacts(storeId, StoreOrderContactDetailId);

        dispatch(
          deleteStoreOrderNotificationContactsSuccess(ContactType, StoreOrderContactDetailId)
        );
        dispatch(closeAllOtherNotifications(SAVED_KEY));
      }
    } catch (e) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(e));
    }
  };
};
//#endregion

// #region Tipping
const { STORE_GET_TIPS_REQUEST, STORE_GET_TIPS_SUCCESS, STORE_GET_TIPS_FAILURE } = storeConstants;

type GetTipsProps = {
  storeId: number;
};

export type GetTipsRequest = ReturnType<typeof getTipsRequest>;
export const getTipsRequest = (props: GetTipsProps) =>
  action(STORE_GET_TIPS_REQUEST, undefined, props);

export type GetTipsSuccess = ReturnType<typeof getTipsSuccess>;
export const getTipsSuccess = (tipConfig: TipConfiguration, props: GetTipsProps) =>
  action(STORE_GET_TIPS_SUCCESS, tipConfig, props);

export type GetTipsFailure = ReturnType<typeof getTipsFailure>;
export const getTipsFailure = (error: Error, props: GetTipsProps) =>
  actionError(STORE_GET_TIPS_FAILURE, props, error);

export function getTips(storeId: number) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(getTipsRequest({ storeId }));
      const result = await storeService.getStoreTips(storeId);
      dispatch(getTipsSuccess(result, { storeId }));
    } catch (error) {
      dispatch(getTipsFailure(error, { storeId }));
    }
  };
}

const { STORE_UPDATE_TIPS_REQUEST, STORE_UPDATE_TIPS_SUCCESS, STORE_UPDATE_TIPS_FAILURE } =
  storeConstants;

type UpdateTipsProps = {
  storeId: number;
  tipConfig: TipConfiguration;
};

export type UpdateTipsRequest = ReturnType<typeof updateTipsRequest>;
export const updateTipsRequest = (props: UpdateTipsProps) =>
  action(STORE_UPDATE_TIPS_REQUEST, undefined, props);

export type UpdateTipsSuccess = ReturnType<typeof updateTipsSuccess>;
export const updateTipsSuccess = (tipConfig: TipConfiguration, props: UpdateTipsProps) =>
  action(STORE_UPDATE_TIPS_SUCCESS, tipConfig, props);

export type UpdateTipsFailure = ReturnType<typeof updateTipsFailure>;
export const updateTipsFailure = (error: Error, props: UpdateTipsProps) =>
  actionError(STORE_UPDATE_TIPS_FAILURE, props, error);

export function updateTips(props: UpdateTipsProps) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());
      dispatch(updateTipsRequest(props));

      const result = await storeService.updateStoreTips(props.storeId, props.tipConfig);

      dispatch(updateTipsSuccess(result, props));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (error) {
      dispatch(updateTipsFailure(error, props));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(error));
    }
  };
}
// #endregion

// #region Service Charge
const {
  STORE_GET_SERVICE_CHARGE_REQUEST,
  STORE_GET_SERVICE_CHARGE_SUCCESS,
  STORE_GET_SERVICE_CHARGE_FAILURE,
} = storeConstants;

type GetServiceChargeProps = {
  storeId: number;
};

export type GetServiceChargeRequest = ReturnType<typeof getServiceChargeRequest>;
export const getServiceChargeRequest = (props: GetServiceChargeProps) =>
  action(STORE_GET_SERVICE_CHARGE_REQUEST, undefined, props);

export type GetServiceChargeSuccess = ReturnType<typeof getServiceChargeSuccess>;
export const getServiceChargeSuccess = (
  serviceCharge: ServiceCharge,
  props: GetServiceChargeProps
) => action(STORE_GET_SERVICE_CHARGE_SUCCESS, serviceCharge, props);

export type GetServiceChargeFailure = ReturnType<typeof getServiceChargeFailure>;
export const getServiceChargeFailure = (error: Error, props: GetServiceChargeProps) =>
  actionError(STORE_GET_SERVICE_CHARGE_FAILURE, props, error);

export function getServiceCharge(storeId: number) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(getServiceChargeRequest({ storeId }));
      const result = await storeService.getStoreServiceCharge(storeId);
      dispatch(getServiceChargeSuccess(result, { storeId }));
    } catch (error) {
      dispatch(getServiceChargeFailure(error, { storeId }));
    }
  };
}

const {
  STORE_UPDATE_SERVICE_CHARGE_REQUEST,
  STORE_UPDATE_SERVICE_CHARGE_SUCCESS,
  STORE_UPDATE_SERVICE_CHARGE_FAILURE,
} = storeConstants;

type UpdateServiceChargeProps = {
  storeId: number;
  serviceCharge: ServiceCharge;
};

export type UpdateServiceChargeRequest = ReturnType<typeof updateServiceChargeRequest>;
export const updateServiceChargeRequest = (props: UpdateServiceChargeProps) =>
  action(STORE_UPDATE_SERVICE_CHARGE_REQUEST, undefined, props);

export type UpdateServiceChargeSuccess = ReturnType<typeof updateServiceChargeSuccess>;
export const updateServiceChargeSuccess = (
  serviceCharge: ServiceCharge,
  props: UpdateServiceChargeProps
) => action(STORE_UPDATE_SERVICE_CHARGE_SUCCESS, serviceCharge, props);

export type UpdateServiceChargeFailure = ReturnType<typeof updateServiceChargeFailure>;
export const updateServiceChargeFailure = (error: Error, props: UpdateServiceChargeProps) =>
  actionError(STORE_UPDATE_SERVICE_CHARGE_FAILURE, props, error);

export function updateServiceCharge(props: UpdateServiceChargeProps) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());
      dispatch(updateServiceChargeRequest(props));

      const result = await storeService.updateStoreServiceCharge(
        props.storeId,
        props.serviceCharge
      );

      dispatch(updateServiceChargeSuccess(result, props));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (error) {
      dispatch(updateServiceChargeFailure(error, props));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(error));
    }
  };
}
// #endregion

// #region Order Batching
const {
  STORE_GET_ORDER_BATCH_CONFIG_REQUEST,
  STORE_GET_ORDER_BATCH_CONFIG_SUCCESS,
  STORE_GET_ORDER_BATCH_CONFIG_FAILURE,
} = storeConstants;

type GetOrderBatchConfigProps = {
  storeId: number;
};

export type GetOrderBatchConfigRequest = ReturnType<typeof getOrderBatchConfigRequest>;
export const getOrderBatchConfigRequest = (props: GetOrderBatchConfigProps) =>
  action(STORE_GET_ORDER_BATCH_CONFIG_REQUEST, undefined, props);

export type GetOrderBatchConfigSuccess = ReturnType<typeof getOrderBatchConfigSuccess>;
export const getOrderBatchConfigSuccess = (
  orderBatchConfig: OrderBatchingConfiguration,
  props: GetOrderBatchConfigProps
) => action(STORE_GET_ORDER_BATCH_CONFIG_SUCCESS, orderBatchConfig, props);

export type GetOrderBatchConfigFailure = ReturnType<typeof getOrderBatchConfigFailure>;
export const getOrderBatchConfigFailure = (error: Error, props: GetOrderBatchConfigProps) =>
  actionError(STORE_GET_ORDER_BATCH_CONFIG_FAILURE, props, error);

export function getOrderBatchConfig(storeId: number) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(getOrderBatchConfigRequest({ storeId }));
      const result = await storeService.getStoreOrderBatchConfig(storeId);
      dispatch(getOrderBatchConfigSuccess(result, { storeId }));
    } catch (error) {
      dispatch(getOrderBatchConfigFailure(error, { storeId }));
    }
  };
}

const {
  STORE_UPDATE_ORDER_BATCH_CONFIG_REQUEST,
  STORE_UPDATE_ORDER_BATCH_CONFIG_SUCCESS,
  STORE_UPDATE_ORDER_BATCH_CONFIG_FAILURE,
} = storeConstants;

type UpdateOrderBatchConfigProps = {
  storeId: number;
  orderBatchConfig: OrderBatchingConfiguration;
};

export type UpdateOrderBatchConfigRequest = ReturnType<typeof updateOrderBatchConfigRequest>;
export const updateOrderBatchConfigRequest = (props: UpdateOrderBatchConfigProps) =>
  action(STORE_UPDATE_ORDER_BATCH_CONFIG_REQUEST, undefined, props);

export type UpdateOrderBatchConfigSuccess = ReturnType<typeof updateOrderBatchConfigSuccess>;
export const updateOrderBatchConfigSuccess = (props: UpdateOrderBatchConfigProps) =>
  action(STORE_UPDATE_ORDER_BATCH_CONFIG_SUCCESS, undefined, props);

export type UpdateOrderBatchConfigFailure = ReturnType<typeof updateOrderBatchConfigFailure>;
export const updateOrderBatchConfigFailure = (error: Error, props: UpdateOrderBatchConfigProps) =>
  actionError(STORE_UPDATE_ORDER_BATCH_CONFIG_FAILURE, props, error);

export function updateOrderBatchConfig(props: UpdateOrderBatchConfigProps, enableChanged: boolean) {
  return async (dispatch: ThunkDispatch) => {
    try {
      let savingStringKey = 'Updating_order_batch_timer';
      let savedStringKey = 'Order_batch_timer_updated';

      if (enableChanged) {
        if (props.orderBatchConfig.IsEnabled) {
          savingStringKey = 'Enabling_order_batching';
          savedStringKey = 'Order_batching_enabled';
        } else {
          savingStringKey = 'Disabling_order_batching';
          savedStringKey = 'Order_batching_disabled';
        }
      }

      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving({ loadingStringKey: savingStringKey }));
      dispatch(updateOrderBatchConfigRequest(props));

      await storeService.updateStoreOrderBatchConfig(props.storeId, props.orderBatchConfig);

      dispatch(updateOrderBatchConfigSuccess(props));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved({ savedStringKey: savedStringKey }));
    } catch (error) {
      dispatch(updateOrderBatchConfigFailure(error, props));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(error));
    }
  };
}

// #endregion

// #region Lead Times
export const getLeadTimesSuccess = (leadTimes: OrderLeadTimes) =>
  action(STORE_GET_LEAD_TIMES_SUCCESS, leadTimes);

export function getStoreLeadTimes(storeId: number) {
  return async (dispatch: ThunkDispatch) => {
    try {
      const result = await storeService.getStoreLeadTimes(storeId);
      dispatch(getLeadTimesSuccess(result));
    } catch (error) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(error));
    }
  };
}

export const updateStoreLeadTimesSuccess = (leadTimes: OrderLeadTimes) =>
  action(STORE_UPDATE_LEAD_TIMES_SUCCESS, leadTimes);

export function updateStoreLeadTimes(storeId: number, leadTime: LeadTime) {
  return async (dispatch: ThunkDispatch) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());
      const result = await storeService.updateStoreLeadTimes(storeId, leadTime);
      dispatch(updateStoreLeadTimesSuccess(result));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (error) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError(error));
    }
  };
}

// #endregion
