import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import { accountActions } from '../../actions/account.actions';
import { accountConstants } from '../../constants/account.constants';
import usePrevious from '../../custom-hooks/usePrevious';
import { getTranslate, Translate } from '../../overrides/react-localize-redux';
import { createLoadingSelector } from '../../selectors/loading.selector';
import ChangePassword from './components/ChangePassword';
import SetNewPassword from './components/SetNewPassword';

const useStyles = makeStyles((theme: Theme) => ({
  passwordButton: {
    margin: '0px 0px 12px 0px',
    padding: '4px 0px 4px 0px',
    minHeight: '24px',
    maxHeight: '24px',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      marginLeft: '16px',
      padding: '4px 0px 4px 0px',
    },
  },
  textFieldContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  currentPassword: {
    marginTop: '8px',
    marginBottom: '36px',
  },
  newPassword: {
    marginBottom: '24px',
  },
  textField: {
    minWidth: '200px',
    margin: 0,
  },
  visibilityIcons: {
    marginLeft: '8px',
    opacity: 0.54,
  },
  newPasswordVisibilityIcon: {
    marginBottom: '20px',
  },
}));

type InnerProps = MappedState &
  MappedDispatch & {
    children?: (onClick: (event: React.MouseEvent<HTMLElement>) => void) => React.ReactNode;
  };
type Props = InnerProps;

export const ProfilePassword = (props: Props) => {
  const { account, updatedPasswordSuccess, requestPin } = props;
  const classes = useStyles({});

  const [openSetNewPassword, setOpenSetNewPassword] = useState<boolean>(false);
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);

  const isAccountVerified = account && account.IsVerified;

  const changePassword = (event: React.MouseEvent<HTMLElement>) => {
    if (!account.IsVerified) {
      requestPin(account.Email);
      setOpenSetNewPassword(true);
    }
    setOpenChangePassword(true);
  };

  const updatedPasswordStatus = usePrevious(updatedPasswordSuccess);

  useEffect(() => {
    if (updatedPasswordStatus !== updatedPasswordSuccess) {
      setOpenSetNewPassword(false);
      setOpenChangePassword(false);
    }
  }, [updatedPasswordSuccess]);

  const onClose = () => {
    setOpenSetNewPassword(false);
    setOpenChangePassword(false);
  };

  return (
    <div>
      <Button
        color="primary"
        className={classes.passwordButton}
        onClick={changePassword}
        data-fd="btn-password-change-open"
      >
        {isAccountVerified ? (
          <Translate id="Change_password" />
        ) : (
          <Translate id="Set_a_new_password" />
        )}
      </Button>
      {isAccountVerified ? (
        <ChangePassword onClose={onClose} open={openChangePassword} account={account} />
      ) : (
        <SetNewPassword onClose={onClose} open={openSetNewPassword} account={account} />
      )}
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => {
  const { account, profile, locale } = state;
  return {
    account: account,
    profile: profile,
    translate: getTranslate(locale),
    loading:
      createLoadingSelector([accountConstants.UPDATE_PASSWORD])(state) ||
      createLoadingSelector([accountConstants.CREATE_PASSWORD_WITH_PIN])(state),
    updatedPasswordSuccess: state.account.updatePasswordSuccess,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  requestPin: (email: string) => {
    dispatch(accountActions.requestPasswordChangePin(email));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePassword);
