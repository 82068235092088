import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Coordinates, StoreAddress, SupportedCountry } from '@flipdish/api-client-typescript';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { type FormikProps, withFormik } from 'formik';
import Cookies from 'js-cookie';
import { debounce, union, without } from 'lodash';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { accountActions } from '../../../actions/account.actions';
import { storeActions, updateAddress } from '../../../actions/store.actions';
import markerSvg from '../../../assets/images/marker.svg';
import { DEFAULT_MAP_CENTER, GMAP_API_KEY, libraries } from '../../../constants/map.constant';
import { signup } from '../../../constants/signup.constants';
import { parseAddressFromResult } from '../../../helpers/geoCodeResultParser';
import {
  applyLatLngOffset,
  getLatLngOffset,
  getLatLngOfPositionOnMap,
} from '../../../helpers/maps';
import { notifyError } from '../../../layouts/Notify/actions';
import { getStores } from '../../../selectors/store.selector';
import withAmplitude, { WithAmplitudeProps } from '../../../services/amplitude/withAmplitude';
import {
  CurrentLocationResult,
  getCurrentLocation,
} from '../../../services/geolocation/browserGeolocation';
import ConfirmationForm from './ConfirmationForm';
import { scriptContents } from './FacebookTracker';
import LocatorForm from './LocatorForm';
import { mapsStyles } from './MapsStyles';
import { StepsHeader } from './StepsHeader';

const stepIndex = signup.steps.indexOf('Store_location');

const useStyles = makeStyles((theme: Theme) => ({
  overlay: {
    position: 'absolute',
    zIndex: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    right: theme.spacing(8),
    bottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  card: {
    background: '#fafafa',
    transition: 'all 0.3s ease',
    padding: theme.spacing(2),
    overflow: 'visible',
    [theme.breakpoints.down('sm')]: {
      width: '640px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: '520px',
    },
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    height: '100vh',
  },
  mapElement: {
    position: 'relative',
    flex: '1 1 auto',
    width: '100%',
    height: '100vh',
    minHeight: 132,
  },
  mapContainerConfirmForm: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: '63vh',
    },
  },

  stepsShort: {
    color: 'rgba(0, 0, 0, 0.38)',
    marginBottom: 6,
  },
}));

export type OnboardingFormValues = {
  addressFormatted: string;
  addressLine1: string;
  area: string;
  addressCountryCode: string;
  addressCity: string;
  administrativeAreaLevel1: string;
  postCode: string;
  coordinates: { lat: number; lng: number; skipGeocoding?: boolean };
};

type Props = MappedState & MappedDispatch & FormikProps<OnboardingFormValues> & WithAmplitudeProps;

const StoreLocation = ({
  account,
  values,
  setSubmitting,
  supportedCountries,
  setValues,
  setFieldValue,
  isValid,
  getAccount,
  store,
  translate,
  currentApp,
  loadStores,
  getSupportedCountriesList,
  handleReset,
  handleSubmit,
  handleBlur,
  handleChange,
  errors,
  isSubmitting,
  touched,
  trackEvent,
}: Props) => {
  const searchBoxRef = useRef(null);
  const markerLandscape = { x: 0.3, y: 0.4 };
  const markerPortrait = { x: 0.5, y: 0.35 };
  const markerCentered = { x: 0.5, y: 0.5 };
  let geocode: ReturnType<typeof debounce> | undefined = undefined;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GMAP_API_KEY ?? '',
    libraries,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState(DEFAULT_MAP_CENTER);
  const [timeoutIds, setTimeoutIds] = useState<number[]>([]);
  const [googleObjectAddress, setGoogleObjectAddress] = useState<{
    Results: google.maps.places.PlaceResult;
    Status: google.maps.GeocoderStatus;
  }>();
  const [supportedCountry, setSupportedCountry] = useState<SupportedCountry | null>();
  const [addressEntered, setAddressEntered] = useState<boolean>(false);
  const [confirmFields, setConfirmFields] = useState<boolean>(false);
  const [mapInitialized, setMapInitialized] = useState<boolean>(false);

  const classes = useStyles();

  //#region functions
  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [timeoutIds]);

  useEffect(() => {
    if (currentApp?.AppId) {
      loadStores();
    }

    if (map) {
      getCurrentLocation()
        .then(handleGetCurrentLocation)
        .catch((err) => console.error(err));
    }

    getSupportedCountriesList();

    function FacebookPixelTracker(advertisingConsent) {
      const scriptEl = document.createElement('script');
      const scriptText = document.createTextNode(scriptContents(advertisingConsent));
      scriptEl.appendChild(scriptText);
      document.head.appendChild(scriptEl);
    }

    process.env.NODE_ENV === 'production' &&
      !Cookies.get('_fbp') &&
      FacebookPixelTracker(account?.gdpr?.advertising);
  }, [currentApp, map]);

  useEffect(() => {
    if (values.coordinates?.skipGeocoding) {
      return;
    }
    geocodeLocation({ lat: values.coordinates.lat, lng: values.coordinates.lng });
  }, [values.coordinates]);

  useEffect(() => {
    trackEvent('portal_signup_location', {
      action: 'logged_in',
    });
  }, []);

  const isDefaultMapCenter = () => {
    return center.lat === DEFAULT_MAP_CENTER.lat && center.lng === DEFAULT_MAP_CENTER.lng;
  };

  const handleGetCurrentLocation = (coords: CurrentLocationResult) => {
    if (isDefaultMapCenter()) {
      const latLng = {
        lat: coords.latitude,
        lng: coords.longitude,
      };
      moveMapToNewLocationAndGeocode(latLng);
      setFieldValue('coordinates', latLng);
    }
  };

  const onSBLoad = useCallback((ref) => {
    searchBoxRef.current = ref;
  }, []);

  const onMapMovePlaceChange = (
    placesArg: google.maps.places.PlaceResult[],
    status: google.maps.GeocoderStatus
  ) => {
    if (status === google.maps.GeocoderStatus.OK) {
      handlePlacesChanged(placesArg, false);
    }
    setSubmitting(false);
  };

  const placeIsValid = (place: google.maps.places.PlaceResult) => {
    return place.address_components && place.formatted_address && place.geometry;
  };

  const getUpdatedPositionsForPlace = (location: google.maps.LatLng, panToLocation) => {
    const newCenter = translateFromMarkerToCenter(location);
    if (newCenter) {
      return {
        center: panToLocation ? newCenter.toJSON() : center,
        marker: panToLocation ? location.toJSON() : undefined,
      };
    }
  };

  const handlePlacesChanged = (
    placesArg: google.maps.places.PlaceResult[],
    panToLocation: boolean
  ) => {
    const place = placesArg[0];

    if (!place || !placeIsValid(place)) {
      console.log('Address invalid, unable to proceed.');
      return;
    }
    setGoogleObjectAddress({
      Results: place,
      Status: 'OK' as google.maps.GeocoderStatus.OK,
    });

    const addressInfo = parseAddressFromResult(place, supportedCountries);
    const country = addressInfo?.supportedCountry;
    setSupportedCountry(country);

    if (addressInfo == null) {
      return;
    }

    const updatedPositions = getUpdatedPositionsForPlace(
      place?.geometry?.location || new google.maps.LatLng(0, 0),
      panToLocation
    );

    const coords = updatedPositions?.marker
      ? { ...updatedPositions?.marker, skipGeocoding: true }
      : { ...values.coordinates, skipGeocoding: true };

    setValues({
      addressFormatted: addressInfo.fullAddress,
      addressLine1: addressInfo.addressLine1,
      area: addressInfo.area,
      addressCity: addressInfo.city,
      addressCountryCode: addressInfo.countryCode,
      postCode: addressInfo.postCode,
      administrativeAreaLevel1: addressInfo.administrativeAreaLevel1,
      coordinates: coords,
    });

    setAddressEntered(true);
    updatedPositions?.center && setCenter(updatedPositions?.center);
  };

  const geocodeLocation = (location: google.maps.LatLngLiteral) => {
    if (isLoaded) {
      if (geocode) {
        geocode.cancel();
      }
      const geocoder = new google.maps.Geocoder();
      geocode = debounce(geocoder.geocode, 100);

      geocode({ location }, onMapMovePlaceChange);
    }
  };

  //#region Marker Dragging
  const getMarkerPosition = () => {
    if (window.matchMedia('(max-width: 600px)').matches) {
      return markerCentered;
    }

    if (window.matchMedia('(orientation: portrait)').matches) {
      return markerPortrait;
    }

    if (window.matchMedia('(orientation: landscape)').matches) {
      return markerLandscape;
    }
    return markerCentered;
  };

  const getLatLngForMarkerScreenPosition = () => {
    const markerPosition = getMarkerPosition();
    return getLatLngOfPositionOnMap(markerPosition, map);
  };

  const translateFromMarkerToCenter = (newMarker): google.maps.LatLng | void => {
    if (map) {
      const offset = getLatLngOffset(getLatLngForMarkerScreenPosition(), map.getCenter(), map);
      return applyLatLngOffset(newMarker, offset, map);
    }
  };

  const moveMapToNewLocationAndGeocode = (markerLocation: google.maps.LatLngLiteral) => {
    if (map === null) {
      return;
    }
    const newCenter = translateFromMarkerToCenter(
      new google.maps.LatLng(markerLocation.lat, markerLocation.lng)
    );

    if (newCenter) {
      map.panTo(newCenter);

      const timeoutId = window.setTimeout(() => {
        setFieldValue('coordinates', markerLocation);
        setCenter(newCenter.toJSON());
        setTimeoutIds((prevTimeoutIds) => without(prevTimeoutIds, timeoutId));
      }, 500);

      setTimeoutIds((prevTimeoutIds) => union(prevTimeoutIds, [timeoutId]));
    }
  };

  const onMarkerDrag = () => {
    setSubmitting(true);
  };

  const onMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
    moveMapToNewLocationAndGeocode(e.latLng?.toJSON() || { lat: 0, lng: 0 });
  };

  //#endregion

  const onMapDrag = () => {
    setSubmitting(true);
    setFieldValue('coordinates', {
      ...getLatLngForMarkerScreenPosition().toJSON(),
      skipGeocoding: true,
    });
  };

  const onMapDragEnd = () => {
    const newMarkerLocation = getLatLngForMarkerScreenPosition().toJSON();
    const newCenter = map?.getCenter()?.toJSON();

    newCenter && setCenter(newCenter);
    setFieldValue('coordinates', newMarkerLocation);
  };

  const onMapZoomChanged = () => {
    if (map === null) {
      return;
    }
    const markerLatLng = new google.maps.LatLng(values.coordinates.lat, values.coordinates.lng);

    const newCenter = translateFromMarkerToCenter(markerLatLng);
    if (newCenter) {
      map.panTo(newCenter);
      setCenter(newCenter.toJSON());
    }
  };

  const onMapIdle = () => {
    if (mapInitialized) {
      return;
    }
    setMapInitialized(true);
  };

  const _handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    if (confirmFields) {
      handleSubmit(e);
    } else {
      setStepToConfirmLocation();
    }
  };

  const goBack = () => {
    setConfirmFields(false);
  };

  const setStepToConfirmLocation = () => {
    setConfirmFields(true);
  };

  const onSearchBoxPlaceChange = () => {
    if (!searchBoxRef.current) {
      return;
    }

    // @ts-ignore
    const places = searchBoxRef.current.getPlaces();

    if (places && places.length > 0) {
      handlePlacesChanged(places, true);
    }
  };

  //#endregion

  const renderGoogleMap = () => {
    return (
      isLoaded && (
        <div
          data-fd="mapContainer-custom"
          className={confirmFields ? classes.mapContainerConfirmForm : classes.mapContainer}
        >
          <GoogleMap
            center={center}
            mapContainerClassName={classes.mapElement}
            onDrag={onMapDrag}
            onDragEnd={onMapDragEnd}
            onIdle={onMapIdle}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onZoomChanged={onMapZoomChanged}
            options={{
              streetViewControl: false,
              scaleControl: false,
              mapTypeControl: false,
              panControl: false,
              rotateControl: false,
              fullscreenControl: false,
              zoomControl: false,
              styles: mapsStyles,
              scrollwheel: !confirmFields,
              draggable: !confirmFields,
            }}
            zoom={16}
          >
            <Marker
              draggable={!confirmFields}
              icon={markerSvg}
              onDrag={onMarkerDrag}
              onDragEnd={onMarkerDragEnd}
              position={values.coordinates}
            />
          </GoogleMap>
        </div>
      )
    );
  };

  return (
    <>
      {renderGoogleMap()}
      <div>
        <Hidden smDown>
          <StepsHeader steps={signup.steps} stepIndex={stepIndex} />
        </Hidden>
        <div className={classes.overlay}>
          <Card className={classes.card}>
            <CardContent>
              <Hidden smUp>
                <Typography variant="caption" className={classes.stepsShort}>
                  <Translate>
                    {(translate) => `${translate('Step')} ${stepIndex + 1}/${signup.steps.length}`}
                  </Translate>
                </Typography>
              </Hidden>
              <Typography variant="h4">
                {!confirmFields ? (
                  <Translate id="Where_is_your_store_located" />
                ) : (
                  <Translate id="Please_verify_the_address" />
                )}
              </Typography>
              <form onReset={handleReset} onSubmit={_handleSubmit} className={classes.form}>
                {confirmFields ? (
                  <ConfirmationForm
                    errors={errors}
                    getAccount={getAccount}
                    goBack={goBack}
                    handleBlur={handleBlur as any}
                    handleChange={handleChange}
                    coordinates={{
                      Latitude: values.coordinates.lat,
                      Longitude: values.coordinates.lng,
                    }}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    storeId={store?.StoreId}
                    storeName={store?.Name ?? ''}
                    supportedCountry={supportedCountry as SupportedCountry | null}
                    touched={touched}
                    translate={translate}
                    values={values}
                  />
                ) : (
                  <LocatorForm
                    addressEntered={addressEntered}
                    errors={errors}
                    googleObjectAddress={googleObjectAddress}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    onSearchBoxPlaceChange={onSearchBoxPlaceChange}
                    onSBLoad={onSBLoad}
                    setFieldValue={setFieldValue}
                    setStepToConfirmLocation={setStepToConfirmLocation}
                    storeName={store?.Name ?? ''}
                    supportedCountry={supportedCountry}
                    touched={touched}
                    translate={translate}
                    values={values}
                  />
                )}
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  return (state: AppState) => {
    const { account, currentApp } = state;
    const stores = getStores(state);
    const store = stores.length ? stores[0] : undefined;
    const supportedCountries = account.supportedCountries;

    return {
      account,
      currentApp,
      store,
      supportedCountries,
      translate: getTranslate(state.locale),
    };
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = () => {
  return (dispatch: ThunkDispatch) => {
    return {
      getAccount: () => {
        dispatch(accountActions.getAccountDetails());
      },
      loadStores: () => {
        dispatch(storeActions.loadAll());
      },
      getSupportedCountriesList: () => {
        dispatch(accountActions.getSupportedCountries());
      },
      save: async (
        storeId: number,
        storeGroupId: number,
        address: StoreAddress,
        coordinates: Required<Coordinates>
      ) => {
        dispatch(
          accountActions.setTimeZoneBasedOnLatLng(coordinates.Latitude, coordinates.Longitude)
        );
        await dispatch(updateAddress(storeId, storeGroupId, address, coordinates));
      },
      showSnackbarError: (msg: string) => dispatch(notifyError({ message: msg, translate: true })),
    };
  };
};

const EnhancedComponent = withFormik<Props, OnboardingFormValues>({
  displayName: 'StoreLocation',
  mapPropsToValues: () => {
    return {
      addressFormatted: '',
      addressLine1: '',
      area: '',
      addressCountryCode: '',
      addressCity: '',
      administrativeAreaLevel1: '',
      postCode: '',
      coordinates: { ...DEFAULT_MAP_CENTER },
    };
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    // Only used for Confirmation form if not using Dynamic fields
    try {
      const { store, save, getAccount } = props;
      const storeAddress: StoreAddress = {
        Line1: values.addressLine1,
        City: values.addressCity,
        Postcode: values.postCode,
        CountryCode: values.addressCountryCode,
      };
      const storeCoordinates: Required<Coordinates> = {
        Latitude: values.coordinates.lat,
        Longitude: values.coordinates.lng,
      };

      if (!store) {
        props.showSnackbarError('Store_is_required!');
        throw new Error('Store is required!');
      }

      (async () => {
        await save(
          store.StoreId as number,
          store.StoreGroupId as number,
          storeAddress,
          storeCoordinates
        );
        // get new setup steps
        getAccount();
      })();
    } catch (error) {
      setSubmitting(false);
      console.error(error);
    }
  },
  validate: (values, props) => {
    const errors: { [P in keyof OnboardingFormValues]?: string } = {};
    if (!values.addressLine1) {
      errors.addressLine1 = props.translate('Required') as string;
    }
    return errors;
  },
})(withAmplitude(StoreLocation));

export default connect<MappedState, MappedDispatch, any, AppState>(
  mapStateToPropsFactory,
  mapDispatchToPropsFactory
)(EnhancedComponent);
