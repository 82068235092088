import React from 'react';

import { Channel } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form as FormikForm, FormikProps, withFormik } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import ModalButtons from '@fd/ui/Modal/ModalButtons';
import { ModalContent } from '@fd/ui/Modal/ModalContent';
import Select from '@fd/ui/Select/Select';

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    paddingLeft: theme.spacing(3),
    color: '#828282',
    marginTop: theme.spacing(1.5),
  },
  bottomField: {
    marginTop: theme.spacing(4.7),
  },
}));

type Props = {
  leftButtonAction: () => void;
  rightButtonAction: () => void;
  leftButtonTranslationId: TranslationId;
  rightButtonTranslationId: TranslationId;
  titleId: TranslationId;
  subTitleId: TranslationId;
  fdKey?: string;
  channel: Channel;
  translate: TranslateFunction;
};

const StoreMappingModal = ({
  leftButtonAction,
  rightButtonAction,
  leftButtonTranslationId,
  rightButtonTranslationId,
  titleId,
  subTitleId,
  fdKey,
  channel,
  translate,
}: Props & FormikProps<{}>) => {
  const classes = useStyles();

  return (
    <Modal open={true} onClose={() => null} titleTextId={titleId} fdKey={fdKey}>
      <div className={classes.subTitle}>
        <Typography variant="body1" color="inherit">
          <Translate
            id={subTitleId}
            data={{ channel: translate(`${channel?.TranslationKey}_External` as TranslationId) }}
          />
        </Typography>
      </div>
      <ModalContent>
        <FormikForm data-fd="store-mapping-modal-form">
          <Typography variant="caption">
            <Translate id="Flipdish_store" />
          </Typography>
          <Field>
            {() => (
              <Select
                placeholder="Type"
                variant="standard"
                label="Option_label"
                TextFieldProps={{
                  fdKey: 'fd-store-form-field',
                  name: 'fd-store-form-field',
                  value: 'values[field.name]',
                }}
              />
            )}
          </Field>
          <div className={classes.bottomField}>
            <Typography variant="caption">
              <Translate id={`${channel.TranslationKey}_External` as TranslationId} />{' '}
              <Translate id="Id" />
            </Typography>
            <Field>
              {() => (
                <Select
                  placeholder="Type"
                  variant="standard"
                  label="Option_label"
                  TextFieldProps={{
                    fdKey: 'channel-store-form-field',
                    name: 'channel-store-form-field',
                    value: 'values[field.name]',
                  }}
                />
              )}
            </Field>
          </div>
        </FormikForm>
      </ModalContent>
      <ModalActions>
        <ModalButtons
          leftButtonAction={leftButtonAction}
          leftButtonTranslationId={leftButtonTranslationId}
          rightButtonAction={rightButtonAction}
          rightButtonTranslationId={rightButtonTranslationId}
          fdKey="delete-mapping-modal-buttons"
        />
      </ModalActions>
    </Modal>
  );
};

export default withFormik<Props, {}>({
  displayName: 'StoreMappingModal',
  handleSubmit: (values, formikBag) => {},
})(StoreMappingModal);
