import * as React from 'react';

import { SignupStep } from '@flipdish/api-client-typescript';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { accountActions } from '../../../actions/account.actions';

// TODO: Remove create subscription step from signup flow entirely
class CreateSubscription extends React.Component<any, any> {
  public constructor(props) {
    super(props);
    this.skipCreateSubscription();
  }

  public skipCreateSubscription = () => {
    const { dispatch } = this.props;

    dispatch(accountActions.skipSignupStep(SignupStep.ActionEnum.PaymentSubscription));
  };

  public render() {
    return <div />;
  }
}

const EnhancedComponent = compose(withRouter, connect())(CreateSubscription);

export { EnhancedComponent as CreateSubscription };
