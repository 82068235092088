import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import PageLayout from '@fd/ui/Layout';

import { notifyError, NotifyProps } from '../../../../../layouts/Notify/actions';
import { Discovery } from '../../components/Discovery';
import { LegacyMobileSalesChannelsConfig } from '../../components/LegacyMobileSalesChannelsConfig';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

const MobileApps = (props: MappedState & MappedDispatch) => {
  const { hasAndroidApp, hasIosApp, translate } = props;
  const classes = useStyles();

  const renderLegacyPage = () => {
    if (hasAndroidApp || hasIosApp) {
      return <LegacyMobileSalesChannelsConfig />;
    } else {
      return <Discovery translate={translate} />;
    }
  };

  return (
    <PageLayout documentTitle="Mobile_apps" title={<Translate id="Mobile_apps" />}>
      <Permissions allowed={['ViewSalesChannels', 'EditSalesChannels']}>
        <div className={classes.container}>{renderLegacyPage()}</div>
      </Permissions>
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    hasIosApp: currentApp.HasIosApp,
    hasAndroidApp: currentApp.HasAndroidApp,
    translate: getTranslate(state.locale),
  };
};

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(MobileApps);
export { EnhancedComponent as MobileApps };
