import React, { useState } from 'react';

import IntercomIcon from '@mui/icons-material/ChatOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import SupportIcon from '@mui/icons-material/OpenInNewOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import { TutorialButton } from '../../components/Tutorial/Button';
import { getSupportLanguageUrl } from './HelpMenu';

const useStyles = makeStyles((theme: Theme) => ({
  collapseRoot: {
    position: 'absolute',
    top: '65px',
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    zIndex: 10,
  },
  iconGrid: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

type OuterProps = {
  tutorialIsOpen?: boolean;
  setTutorialIsOpen: (boolean) => void;
  tutorialHasNotification?: boolean;
};

type Props = OuterProps & MappedState;

const TopNavMobileExpandedMenu = (props: Props) => {
  const { language, setTutorialIsOpen, tutorialHasNotification, tutorialIsOpen } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <IconButton
        aria-label="toggle-top-nav-menu-expand"
        data-fd="toggle-top-nav-menu-expand-button"
        component={'button'}
        size="medium"
        onClick={() => setExpanded(!expanded)}
      >
        {!expanded && <KeyboardArrowDown />}
        {expanded && <KeyboardArrowUp />}
      </IconButton>

      <Collapse
        in={expanded}
        className={classes.collapseRoot}
        collapsedSize={expanded ? '72px' : undefined}
        component="div"
      >
        <Grid container>
          <Grid item xs={3} className={classes.iconGrid}>
            <TutorialButton
              tutorialIsOpen={tutorialIsOpen}
              setTutorialIsOpen={setTutorialIsOpen}
              hasNotification={tutorialHasNotification}
            />
          </Grid>
          <Grid item xs={3} className={classes.iconGrid}>
            <a
              aria-label="link-to-flipdish-support"
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
              target="_blank"
              href={getSupportLanguageUrl(language)}
              rel="noopener noreferrer"
            >
              <IconButton>
                <SupportIcon />
              </IconButton>
            </a>
          </Grid>
          <Grid item xs={3} className={classes.iconGrid}>
            <a
              aria-label="link-to-flipdish-contact-us"
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
              target="_blank"
              href="https://help.flipdish.com/s/contactsupport?language=en_GB"
              rel="noopener noreferrer"
            >
              <IconButton>
                <IntercomIcon />
              </IconButton>
            </a>
          </Grid>
          <Grid item xs={3} className={classes.iconGrid}>
            <a
              aria-label="link-to-flipdish-academy"
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
              target="_blank"
              href="https://academy.flipdish.com/"
              rel="noopener noreferrer"
            >
              <IconButton>
                <SchoolOutlinedIcon />
              </IconButton>
            </a>
          </Grid>
        </Grid>

        <Divider />
      </Collapse>
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  language: state.account.Language,
});

export default connect(mapStateToProps)(TopNavMobileExpandedMenu);
