import React, { useEffect, useMemo, useRef, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import memoizeOne from 'memoize-one';
import { parse as parseQueryString } from 'qs';
import { connect } from 'react-redux';
import { checkVisibility } from 'react-redux-permissions/dist/core';
import {
  type RouteComponentProps,
  matchPath,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { accountActions, setReferrer } from '../actions/account.actions';
import { appsActions } from '../actions/apps.actions';
import { oneMinute } from '../components/common/timeUtils';
import Loading from '../components/Loading';
import {
  getOnboardingConfigs,
  getOnboardingConfigsQueryKey,
  getOnboardingItems,
  getOnboardingItemsQueryKey,
} from '../components/OnboardingV2/onboarding.service';
import { gdpr } from '../helpers/gdpr';
import { store } from '../helpers/store';
import { getAccount, getIsFirstSetup } from '../selectors/account.selector';
import { getIsOnboarding } from '../selectors/app.selector';
import { permissionsSelector } from '../selectors/permissions.selector';
import { flagService } from '../services';
import { useTracking } from '../services/amplitude/useTracking';
import { appsService } from '../services/apps.service';
import { refreshJwtToken } from '../services/auth.service';
import { sendDatadogRumData } from '../services/datadogRUM';
import { googleAnalytics } from '../services/google_analytics';
import { intercomService } from '../services/intercom.service';
import { intercom } from '../services/intercom/index';
import { initialise as initaliseDataDogLogging } from '../services/loggerService';
import { accountRoutesConst } from './account.routes';
import routes from './index.routes';

const DEFAULT_MIN_LOADING_TIMEOUT = 1300;

//#region helpers
const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';domain=flipdish.com;path=/';
};

const initReferrer = (search: string, setReferrer: (ids: { Rid: number; Cid: any }) => void) => {
  try {
    const referalQs = parseQueryString(search, { ignoreQueryPrefix: true });
    if (!isEmpty(referalQs)) {
      const { rid, cid } = referalQs;
      setCookie('fd_referrer', `${rid} ${cid}`, 180);
      const referralIds = { Rid: Number(rid), Cid: cid };
      setReferrer(referralIds);
    }
  } catch (error) {}
};

const isPublicRoute = memoizeOne((pathname: string) =>
  routes.publicRoutes
    .filter((r) => !('to' in r))
    .some((r) => matchPath(pathname, { path: r.path }) !== null)
);
const isFirstSetupRoute = memoizeOne((pathname: string) =>
  routes.firstSetupRoutes
    .filter((r) => !('to' in r))
    .some((r) => matchPath(pathname, { path: r.path }) !== null)
);
const isFirstSetupV2Route = memoizeOne((pathname: string) =>
  routes.firstSetupRoutesV2
    .filter((r) => !('to' in r))
    .some((r) => matchPath(pathname, { path: r.path }) !== null)
);

const createRoute = (
  props: RoutePropsExtended | RedirectPropsExtended,
  appPermissions: AppState['permissions']
) => {
  if ('to' in props) {
    // only Redirect has `to` prop required
    const { name, group, key = '', ...rest } = props;
    return <Redirect {...rest} key={`${name}.${key}`} />;
  }

  if ('permissions' in props) {
    const { permissions, name, group, key = '', ...rest } = props;
    if (checkVisibility(appPermissions, permissions, [])) {
      return <Route {...rest} key={`${name}.${key}`} />;
    }
    return null;
  }

  const { name, group, key = '', ...rest } = props;
  return <Route {...rest} key={`${name}.${key}`} />;
};
//#endregion

type Props = RouteComponentProps & MappedState & MappedDispatch;
const AuthRouter: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    isFirstSetup,
    isOnboarding,
    account,
    currentApp,
    registeredRoutes,
    location,
    history,
    permissions,
    isFirstSetupV2Config,
    refreshFDAuthorizationToken,
    isSplitActuallyReady,
    hasOnBoardingPermissions,
  } = props;

  const isFirstSetupV2FFEnabled =
    isFirstSetupV2Config?.tester && account.Email?.includes(isFirstSetupV2Config?.tester);

  const { identifyUser } = useTracking(account);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  const { data } = useQuery({
    queryKey: [intercomService.getIntercomUserHashQueryKey, 'getUserHash'],
    queryFn: () => intercomService.getUserHash(),
    enabled: account.authorized,
  });

  //#region onboarding v2
  const { data: onboardingConfigs, isFetched: onboardConfigsFetched } = useQuery({
    queryKey: [getOnboardingConfigsQueryKey, currentApp.AppId],
    queryFn: async () => {
      return await getOnboardingConfigs(currentApp.AppId ?? '');
    },
    enabled: !!currentApp.AppId && hasOnBoardingPermissions && !!isFirstSetupV2FFEnabled,
  });

  const onboardingConfig = React.useMemo(() => {
    return onboardingConfigs?.Data.find(
      (cfg) => cfg.ConfigType === 'OnboardingWizard' && cfg.IsEnabled
    );
  }, [onboardingConfigs]);

  useEffect(() => {
    if (refreshFDAuthorizationToken) {
      void refreshJwtToken();

      const tokenRefreshInterval = setInterval(() => {
        void refreshJwtToken();
      }, 5 * oneMinute);

      return () => clearInterval(tokenRefreshInterval);
    }
  }, [refreshFDAuthorizationToken]);

  useEffect(() => {
    if (onboardConfigsFetched && !onboardingConfig?.IsEnabled) {
      setInitialized(true);
    }
  }, [onboardConfigsFetched, onboardingConfig]);

  const { data: onboardingV2Steps, isFetched: isOnboardingV2StepsFetched } = useQuery({
    queryKey: [getOnboardingItemsQueryKey, currentApp.AppId],
    queryFn: async () => {
      const response = await getOnboardingItems(
        currentApp.AppId ?? '',
        onboardingConfig?.WebActivationWizardMilestoneId
      );
      return response?.Data?.RootItems?.[0]?.Items?.[0]?.Items?.filter(
        (step) => step?.Status !== 'Completed'
      );
    },
    enabled: !!onboardingConfig?.IsEnabled,
  });

  useEffect(() => {
    if (isOnboardingV2StepsFetched) {
      setInitialized(true);
    }
  }, [isOnboardingV2StepsFetched]);

  const isFirstSetupV2 = !!onboardingV2Steps?.length && isFirstSetupV2FFEnabled;

  //#endregion

  //#region initialize
  const startLoadingTime = useRef<number>();
  const pathBefore = useRef<string>();
  useEffect(() => {
    startLoadingTime.current = Date.now();

    (function setPathBefore() {
      const { pathname } = location;
      // now any route matches private /:appId/ :(
      if (
        pathname !== '/' &&
        !isPublicRoute(pathname) &&
        !isFirstSetupRoute(pathname) &&
        !isFirstSetupV2Route(pathname)
      ) {
        pathBefore.current = pathname;
      }
    })();

    initReferrer(location.search, props.setReferrer);

    props.getAccountDetails().catch(() => {
      // not login
      setTimeout(
        () => {
          setInitialized(true);
        },
        DEFAULT_MIN_LOADING_TIMEOUT - (Date.now() - startLoadingTime.current!)
      );
    });
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const code = params.get('code');
    const isNewSignup = !!code;

    // Prevents briefly showing portal home screen before onboarding screens display
    if (isNewSignup && account.authorized && !onboardConfigsFetched) {
      setInitialized(false);
      setLoading(true);
    }
  }, [account, onboardConfigsFetched]);

  useEffect(() => {
    if (account.authorized && account.AccountId) {
      const getAppId = () => {
        let tryAppId: string | undefined;
        if (pathBefore.current !== undefined) {
          const match = matchPath<{ appId: 'string' }>(pathBefore.current, { path: '/:appId' });
          if (match && match.params.appId) {
            tryAppId = match.params.appId;
          }
        }
        if (!tryAppId) {
          // WARNING!!!! THIS WILL MATCH ANY STRING
          const { pathname } = location;
          if (
            !isPublicRoute(pathname) &&
            !isFirstSetupRoute(pathname) &&
            !isFirstSetupV2Route(pathname)
          ) {
            const match = matchPath<{ appId: 'string' }>(pathname, {
              path: '/:appId',
            });
            if (match && match.params.appId) {
              tryAppId = match.params.appId;
            }
          }
        }
        return tryAppId || 'flipdish-global';
      };

      (async () => {
        if (!currentApp.AppId) {
          const app = await props.setCurrentApp(getAppId());
          if (!app?.AppId && isFirstSetupV2FFEnabled) {
            const response = await appsService.createApp(account.Email);
            props.setCurrentApp(response.appName);
          }
        }

        if (isFirstSetupV2FFEnabled) {
          if (onboardingV2Steps?.length === 0 && pathBefore.current) {
            const path = pathBefore.current;
            pathBefore.current = undefined;
            if (path !== location.pathname) {
              history.replace(path);
            }
          }
        }
        // TODO: remove else block after onboardingV2 goes live
        else {
          if ((account.SignupSteps || []).length === 0 && pathBefore.current) {
            const path = pathBefore.current;
            pathBefore.current = undefined;
            if (path !== location.pathname) {
              history.replace(path);
            }
          }
        }
        // TODO: remove this block when onboardinfg V2 is released
        if (isSplitActuallyReady && !isFirstSetupV2FFEnabled) {
          setTimeout(
            () => {
              setInitialized(true);
            },
            DEFAULT_MIN_LOADING_TIMEOUT - (Date.now() - startLoadingTime.current!)
          );
        }
      })();
    }
  }, [account, onboardingV2Steps, currentApp, isFirstSetupV2FFEnabled, isSplitActuallyReady]);
  //#endregion

  //#region page events trackers
  const prevPathName = useRef<string>();
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname !== prevPathName.current) {
        prevPathName.current = location.pathname;
        googleAnalytics.sendPageView(location.pathname);
      }
    });
    return () => {
      unlisten && unlisten();
    };
  }, []);
  //#endregion

  //#region GDPR -init on first load if not on create_password page
  gdpr.useInitGdpr(!location.pathname.includes(accountRoutesConst.CreatePassword));
  //#endregion

  //#region boot intercom
  useEffect(() => {
    //@ts-ignore
    if (window.Intercom && account.authorized && account.gdpr && !isEmpty(currentApp)) {
      intercom.boot({
        AccountId: account.AccountId!,
        Email: account.Email!,
        Name: account.Name!,
        IsSelfServeUser: account.IsSelfServeUser!,
        AppId: currentApp.AppId!,
        UserHash: data?.UserHash as string,
      });
    }
  }, [account, currentApp]);
  //#endregion

  //#region Split IO -init on first load to retrieve feature flags from Split
  useEffect(() => {
    const isLoggedIn = !!account?.Email;
    const TRAFFIC_TYPE = flagService.TRAFFIC_TYPE;
    const splitParams = {
      appId: currentApp.AppId,
      isFlipdishStaff: permissions.includes('FlipdishStaff'),
      userId: account.AccountId || account.DeviceId,
      languageId: account.Language,
      trafficType: isLoggedIn
        ? TRAFFIC_TYPE.USER_TRAFFIC_TYPE
        : TRAFFIC_TYPE.ANONYMOUS_USER_TRAFFIC_TYPE,
    };

    flagService.initialiseSplit(store, splitParams);
    sendDatadogRumData(account);
    identifyUser();
  }, [account?.AccountId, currentApp?.AppId, permissions]);
  //#endregion

  //#region DataDog -init on first load to allow logging of errors
  useEffect(() => {
    initaliseDataDogLogging();
  }, []);
  //#endregion

  const routes = useMemo(() => {
    if (loading) {
      return null;
    }

    if (!account.authorized) {
      datadogRum.setUserProperty('setup_status', 'not_authenticated');
      datadogRum.setGlobalContextProperty('setup_status', 'not_authenticated');
      return registeredRoutes
        .filter((r) => r.group === 'public' || r.group === 'publicAndPrivate')
        .map((r) => createRoute(r, permissions));
    }

    if (isFirstSetupV2) {
      datadogRum.setUserProperty('setup_status', 'first_time_setup_V2');
      datadogRum.setGlobalContextProperty('setup_status', 'first_time_setup_V2');
      return registeredRoutes
        .filter((r) => r.group === 'first_time_setup_V2' || r.path === accountRoutesConst.Logout)
        .map((r) => createRoute(r, permissions));
    }

    if (isFirstSetup && isSplitActuallyReady && !isFirstSetupV2FFEnabled) {
      datadogRum.setUserProperty('setup_status', 'first_time_setup');
      datadogRum.setGlobalContextProperty('setup_status', 'first_time_setup');
      return registeredRoutes
        .filter((r) => r.group === 'first_time_setup' || r.path === accountRoutesConst.Logout)
        .map((r) => createRoute(r, permissions));
    }

    if (isOnboarding) {
      datadogRum.setUserProperty('setup_status', 'onboarding_setup');
      datadogRum.setGlobalContextProperty('setup_status', 'onboarding_setup');
      return registeredRoutes
        .filter((r) => r.group === 'onboarding_setup')
        .map((r) => createRoute(r, permissions));
    }

    datadogRum.setUserProperty('setup_status', 'logged_in');
    datadogRum.setGlobalContextProperty('setup_status', 'logged_in');

    return registeredRoutes
      .filter((r) => r.group === 'private' || r.group === 'publicAndPrivate')
      .map((r) => createRoute(r, permissions));
  }, [
    loading,
    account,
    isFirstSetup,
    isOnboarding,
    registeredRoutes,
    permissions,
    isFirstSetupV2,
    isSplitActuallyReady,
    isFirstSetupV2FFEnabled,
  ]);

  if (loading) {
    return (
      <Loading
        fullscreen
        animate={!initialized}
        onAnimationEnd={() => {
          // When setInitialized(true) onAnimationEnd sets loading false
          setLoading(false);
        }}
      />
    );
  }

  return <Switch>{routes}</Switch>;
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getOnBoardingPermissions = permissionsSelector.hasPermissionFactory([
    'Owner',
    'FlipdishStaff',
  ]);

  return (state: AppState) => {
    const isFirstSetupV2Config: Record<'tester', string | undefined> =
      flagService.getSplitValueConfig(state, 'firstSetupV2')?.config;

    return {
      account: getAccount(state),
      currentApp: state.currentApp,
      isFirstSetup: getIsFirstSetup(state),
      isOnboarding: getIsOnboarding(state),
      isFirstSetupV2Config,
      permissions: state.permissions,
      refreshFDAuthorizationToken: flagService.isFlagOn(state, 'refreshFDAuthorizationToken'),
      registeredRoutes: state.routing.routes,
      hasOnBoardingPermissions: getOnBoardingPermissions(state),
      // TODO: remove when onboarding V2 goes live
      isSplitActuallyReady: !!Object.entries(state.splitio?.treatments || {})?.length,
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getAccountDetails: () => dispatch(accountActions.getAccountDetails(true)),
  setCurrentApp: (appId: string | undefined) => dispatch(appsActions.setCurrentApp(appId)),
  setReferrer: (referralIds) => dispatch(setReferrer(referralIds)),
});

export default compose<Props, {}>(
  setDisplayName('AuthRouter'),
  withRouter,
  connect(mapStateToPropsFactory, mapDispatchToProps)
)(AuthRouter);
