import React, { useEffect, useState } from 'react';

import { useQueryPendingMenuChangesHook } from '@fd/customHooks/useQueryPendingMenuChangesHook';
import {
  Menu,
  MenuItemOptionSet,
  MenuItemOptionSetItem,
  MenuSectionItem,
} from '@flipdish/api-client-typescript';
import { useQueryClient } from '@tanstack/react-query';
import { TranslateFunction } from 'react-localize-redux';

import { catalogChangesService } from '../../../../services/catalogChanges.service';
import { ChangedCatalogElement } from '../../types';
import { CatalogChangesDrawer } from '../CatalogChangesDrawer';
import { PendingMenuChangesBanner } from '../PendingMenuChangesBanner';

type Props = {
  appId: string;
  menu: Menu | null;
  menuId: number;
  onChangesPublished: () => void;
  translate: TranslateFunction;
};

export const MenuMessage = ({ appId, menu, menuId, onChangesPublished, translate }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [changedElements, setChangedElements] = useState<ChangedCatalogElement[]>([]);
  const queryClient = useQueryClient();

  const { data } = useQueryPendingMenuChangesHook(
    [catalogChangesService.getPendingMenuChangesQueryKey, appId, menuId],
    { appId: appId, menuId: menuId, catalogElementId: '', page: 1, limit: 50 },
    true
  );

  useEffect(() => {
    if (menu !== null) {
      getChangedCatalogElements();
    }
  }, [data, menu]);

  const checkIfItemHasChanged = (
    changedCatalogElements: ChangedCatalogElement[],
    item: MenuSectionItem | MenuItemOptionSet | MenuItemOptionSetItem
  ) => {
    const changeItemIndex = data?.findIndex(
      (change) => change.CatalogItemId === item.CatalogItemId
    );
    if (changeItemIndex !== -1) {
      changedCatalogElements.push({
        elementName: item.Name || '',
        elementId: item.CatalogItemId || '',
        imageUrl: item.ImageUrl,
      });
    }
  };
  const checkOptionSetItems = (
    changedCatalogElements: ChangedCatalogElement[],
    setItems: MenuItemOptionSetItem[]
  ) => {
    for (const optionSetItem of setItems) {
      checkIfItemHasChanged(changedCatalogElements, optionSetItem);
    }
  };

  const checkOptionSets = (
    changedCatalogElements: ChangedCatalogElement[],
    optionSets: MenuItemOptionSet[]
  ) => {
    for (const optionSet of optionSets) {
      checkIfItemHasChanged(changedCatalogElements, optionSet);
      checkOptionSetItems(changedCatalogElements, optionSet.MenuItemOptionSetItems || []);
    }
  };

  const checkMenuItems = (
    changedCatalogElements: ChangedCatalogElement[],
    menuItems: MenuSectionItem[]
  ) => {
    for (const menuItem of menuItems) {
      checkIfItemHasChanged(changedCatalogElements, menuItem);
      checkOptionSets(changedCatalogElements, menuItem.MenuItemOptionSets || []);
    }
  };

  const getChangedCatalogElements = () => {
    const changedCatalogElements: ChangedCatalogElement[] = [];
    if (menu !== null) {
      for (const section of menu.MenuSections || []) {
        checkMenuItems(changedCatalogElements, section.MenuItems || []);
      }
    }
    setChangedElements(changedCatalogElements);
  };

  const handleDrawerPublish = () => {
    queryClient.invalidateQueries(catalogChangesService.getPendingMenuChangesQueryKey);
    onChangesPublished();
  };

  return (
    <>
      <CatalogChangesDrawer
        appId={appId}
        catalogElements={changedElements}
        menuId={menuId}
        onChangesPublished={handleDrawerPublish}
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        translate={translate}
      />
      {data && data?.length > 0 && (
        <PendingMenuChangesBanner
          appId={appId}
          menuId={menuId}
          numberOfChanges={data?.length}
          onChangesPublished={onChangesPublished}
          onReviewClick={() => setIsDrawerOpen(true)}
          showReviewButton
          translate={translate}
        />
      )}
    </>
  );
};
