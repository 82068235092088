import React from 'react';

import MuiSvgIcon from '@mui/material/SvgIcon';

const OrderBatchingIcon = (): JSX.Element => {
  return (
    <MuiSvgIcon aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.275 22.0001C15.975 22.0001 14.8625 21.5376 13.9375 20.6126C13.0125 19.6876 12.55 18.5751 12.55 17.2751C12.55 15.9751 13.0125 14.8626 13.9375 13.9376C14.8625 13.0126 15.975 12.5501 17.275 12.5501C18.575 12.5501 19.6875 13.0126 20.6125 13.9376C21.5375 14.8626 22 15.9751 22 17.2751C22 18.5751 21.5375 19.6876 20.6125 20.6126C19.6875 21.5376 18.575 22.0001 17.275 22.0001ZM18.725 19.8001L19.425 19.1001L17.55 17.2251V14.4251H16.575V17.5751L18.725 19.8001ZM4.5 20.6501C4.08333 20.6501 3.72917 20.5043 3.4375 20.2126C3.14583 19.9209 3 19.5668 3 19.1501V4.6001C3 4.18343 3.12917 3.82926 3.3875 3.5376C3.64583 3.24593 3.86667 3.1001 4.05 3.1001H9.1C9.21667 2.51676 9.50417 2.0376 9.9625 1.6626C10.4208 1.2876 10.95 1.1001 11.55 1.1001C12.15 1.1001 12.6792 1.2876 13.1375 1.6626C13.5958 2.0376 13.8833 2.51676 14 3.1001H19.05C19.4667 3.1001 19.8208 3.24593 20.1125 3.5376C20.4042 3.82926 20.55 4.18343 20.55 4.6001V11.9751C20.3 11.8251 20.0542 11.6959 19.8125 11.5876C19.5708 11.4793 19.3167 11.3834 19.05 11.3001V4.6001H16.4V7.8501H7.15V4.6001H4.5V19.1501H11.325C11.4083 19.4001 11.5083 19.6459 11.625 19.8876C11.7417 20.1293 11.8833 20.3834 12.05 20.6501H4.5ZM12 4.5001C12.2833 4.5001 12.5208 4.40426 12.7125 4.2126C12.9042 4.02093 13 3.78343 13 3.5001C13 3.21676 12.9042 2.97926 12.7125 2.7876C12.5208 2.59593 12.2833 2.5001 12 2.5001C11.7167 2.5001 11.4792 2.59593 11.2875 2.7876C11.0958 2.97926 11 3.21676 11 3.5001C11 3.78343 11.0958 4.02093 11.2875 4.2126C11.4792 4.40426 11.7167 4.5001 12 4.5001Z"
        fill="currentColor"
      />
    </MuiSvgIcon>
  );
};

export default OrderBatchingIcon;
