import React, { useEffect, useState } from 'react';

import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { PageTitleWithBeta } from '../../../../layouts/Portal/PageTitleWithBeta';
import { useTracking } from '../../../../services/amplitude/useTracking';
import PageLayout from '../../../../ui/Layout';
import { getBankAccounts } from '../../Banking/banking.actions';
import { isDateSet, renderDatePeriod } from '../helpers';
import { PayoutStatus } from '../types';
import PayoutFilters from './PayoutFilters';
import PayoutTable from './PayoutTable';
import TableSummary from './TableSummary';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.54)',
    },
  },
  dateRange: {
    color: 'rgba(0,0,0,0.58)',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  skeletonPaper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
  },
}));

export type PeriodProps = {
  startDate: moment.Moment | string | undefined;
  endDate: moment.Moment | string | undefined;
};

type InnerProps = MappedProps & MappedDispatch;
type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type Props = InnerProps;
const PayoutReports = (props: Props) => {
  const { languageCode, loadSetup, payoutSummaryLoading } = props;
  const { trackPreviewEvent } = useTracking();

  //#region filters state
  const [currentPeriodFilter, setCurrentPeriodFilter] = useState<PeriodProps>({
    startDate: undefined,
    endDate: undefined,
  });
  const [payoutStates, setPayoutStates] = useState<PayoutStatus[]>();
  const [accountIds, setAccountIds] = useState<number[]>();
  const [currency, setCurrency] = useState<CurrencyEnum>('EUR');
  //#endregion

  const onDateChange = (start: moment.Moment, end: moment.Moment) => {
    setCurrentPeriodFilter({ startDate: start, endDate: end });
  };

  const classes = useStyles();

  useEffect(() => {
    loadSetup();
    trackPreviewEvent('View payouts list page');
  }, []);

  return (
    <PageLayout
      title={<PageTitleWithBeta translateId="Payouts" />}
      documentTitle={'Payouts'}
      header={
        <PayoutFilters
          setPayoutStates={setPayoutStates}
          setAccountIds={setAccountIds}
          onDateChange={onDateChange}
        />
      }
    >
      {payoutSummaryLoading ? (
        <Skeleton width={'20%'} height={24} />
      ) : (
        <Typography variant={'subtitle1'} className={classes.dateRange}>
          {renderDatePeriod(currentPeriodFilter)}
        </Typography>
      )}
      {isDateSet(currentPeriodFilter) && (
        <>
          <TableSummary
            accountIds={accountIds}
            currentPeriodFilter={currentPeriodFilter}
            payoutStates={payoutStates}
          />

          <PayoutTable
            accountIds={accountIds}
            currency={currency}
            currentPeriodFilter={currentPeriodFilter}
            languageCode={languageCode}
            payoutStates={payoutStates}
          />
        </>
      )}
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { payoutSummaryLoading } = state.payouts;
  return {
    languageCode: getActiveLanguage(state.locale),
    payoutSummaryLoading,
    translate: getTranslate(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  loadSetup: () => {
    dispatch(getBankAccounts());
  },
});

export default connect<MappedProps, MappedDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(PayoutReports);
