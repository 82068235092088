import React, { useCallback, useEffect } from 'react';

import { HttpRequestAndResponseLog } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { compose } from 'recompose';

import useFilterablePagination from '../../../custom-hooks/useFilterablePagination';
import { formatDuration } from '../../../helpers/utilities';
import EmptyComponent from '../../../ui/EmptyComponent';
import PageLayout, { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import GridContainer from '../../../ui/Layout/GridContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import TableCellLink from '../../../ui/TableCellLink/TableCellLink';
import Filter from '../../common/Filter';
import { Loader } from '../../common/Loader';
import TablePagination from '../../common/TablePagination/TablePagination';
import { getInteractionLogs, setInteractionLog } from '../actions';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    fontSize: '12px',
    lineHeight: 1.33,
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  longTextCell: {
    wordBreak: 'break-all',
  },
  listItemLink: {
    display: 'flex',
    padding: `20px ${theme.spacing(2)} 12px`,
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  listItem: {
    display: 'flex',
    padding: `20px ${theme.spacing(2)} 12px`,
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eaf2ff',
    },
  },
  uri: {
    fontSize: '16px',
    lineHeight: 1.5,
    marginBottom: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subList: {
    margin: 0,
    '& dt': {
      float: 'left',
    },
  },
  normalWordBreak: {
    '& a': {
      wordBreak: 'normal',
    },
  },
  oneLineText: {
    whiteSpace: 'nowrap',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type MappedProps = ReturnType<typeof mapStateToProps>;
type DispatchedProps = ReturnType<typeof mapDispatchToProps>;

type Props = MappedProps & RouteComponentProps & DispatchedProps;

const InteractionLogs = ({
  data: { Data, TotalRecordCount },
  history,
  translate,
  currentApp,
  loading,
  getInteractionLogs,
  setInteractionLog,
}: Props) => {
  const { filters, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleFilter } =
    useFilterablePagination(history, false);
  const classes = useStyles();

  const getDate = useCallback(
    (key: 'after' | 'before') => {
      const filter = filters.find((filter) => filter.type.includes(key));

      return filter
        ? moment(Number(filter.value)).format()
        : key === 'before'
          ? moment().format()
          : '2010-01-01T12:00';
    },
    [filters]
  );

  useEffect(() => {
    // @ts-ignore
    getInteractionLogs(getDate('after'), getDate('before'), undefined, page + 1, rowsPerPage);
  }, [page, rowsPerPage, filters]);

  const header = (
    <GridContainer>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <Filter
          value={filters}
          dateFilter
          placeholder={translate('Filter_by_date_and_time') as string}
          onChange={handleFilter}
        />
      </Grid>
    </GridContainer>
  );

  return (
    <PageLayout
      fluid
      header={header}
      documentTitle="API_interactions"
      title={translate('API_interactions')}
      toParent={`/${currentApp.AppId}/developers/logs`}
    >
      {loading ? (
        <Loader />
      ) : Data.length ? (
        <PaperContainer fluid>
          <Hidden lgDown>
            <Table aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <TableCell>{translate('Requested_on')}</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>{translate('Status')}</TableCell>
                  <TableCell>{translate('Action')}</TableCell>
                  <TableCell>URI</TableCell>
                  <TableCell>{translate('Duration')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Data.map((log, index) => {
                  const link = `/${currentApp.AppId}/developers/logs/${log.Guid}`;

                  return (
                    <TableRow key={index} onClick={() => setInteractionLog(log)}>
                      <TableCellLink className={classes.normalWordBreak} to={link}>
                        {log.CreatedDateTime}
                      </TableCellLink>
                      <TableCellLink className={classes.oneLineText} to={link}>
                        {log.UserId}
                      </TableCellLink>
                      <TableCellLink className={classes.oneLineText} to={link}>
                        {log.StatusCode}
                      </TableCellLink>
                      <TableCellLink className={classes.oneLineText} to={link}>
                        {log.Verb}
                      </TableCellLink>
                      <TableCellLink to={link} className={classes.longTextCell}>
                        {log.RequestUri}
                      </TableCellLink>
                      <TableCellLink className={classes.oneLineText} to={link}>
                        {formatDuration(log.CallDurationInMilliseconds)}
                      </TableCellLink>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Hidden>
          <Hidden lgUp>
            <ul className={classes.list}>
              {Data.map((log, ind) => {
                return (
                  <li key={ind}>
                    <NavLink
                      to={`/${currentApp.AppId}/developers/logs/${log.Guid}`}
                      onClick={() => setInteractionLog(log)}
                      className={`${classes.listItemLink} ${HORIZONTAL_SPACE_CLASSNAME}`}
                    >
                      <span>URI</span>
                      <span className={`${classes.uri} ${classes.longTextCell}`}>
                        {log.RequestUri}
                      </span>
                      <dl className={classes.subList}>
                        <dt>{translate('Requested_on')}:</dt>
                        <dd>{log.CreatedDateTime}</dd>
                        <dt>User ID:</dt>
                        <dd>{log.UserId}</dd>
                        <dt>{translate('Status')}:</dt>
                        <dd>{log.StatusCode}</dd>
                        <dt>{translate('Action')}:</dt>
                        <dd>{log.Verb}</dd>
                        <dt>{translate('Duration')}:</dt>
                        <dd>{formatDuration(log.CallDurationInMilliseconds)}</dd>
                      </dl>
                    </NavLink>
                    <Divider />
                  </li>
                );
              })}
            </ul>
          </Hidden>
          <Hidden mdDown>
            <TablePagination
              component="div"
              count={TotalRecordCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Hidden>
          <Hidden mdUp>
            <TablePagination
              component="div"
              labelRowsPerPage=""
              count={TotalRecordCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Hidden>
        </PaperContainer>
      ) : (
        <EmptyComponent
          title="Empty_interaction_logs_header"
          subtitle="Empty_interaction_logs_subheader"
        />
      )}
    </PageLayout>
  );
};

function mapStateToProps(state) {
  const { developers, locale } = state;
  return {
    currentApp: state.currentApp,
    translate: getTranslate(locale),
    loading: developers.isLoading,
    data: developers.interactionLogs,
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getInteractionLogs: (
    start: Date,
    end: Date,
    filterByUserId?: number,
    page?: number,
    limit?: number
  ) => dispatch(getInteractionLogs(start, end, filterByUserId, page, limit)),
  setInteractionLog: (data: HttpRequestAndResponseLog) => dispatch(setInteractionLog(data)),
});

export default compose<Props, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(InteractionLogs);
