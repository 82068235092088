import React from 'react';

import { HydraDeviceDetails } from '@flipdish/api-client-typescript';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListIcon from '@mui/material/ListItemIcon';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import ListItemLink from '../../../ui/List/ListItemLink';
import ListItemIcon from '../../Devices/Components/DeviceListItemIcon';
import { getKioskDetailsUrlFactory } from '../Kiosks.selectors';
import KioskListDetails from './KioskListDetails';

const useStyles = makeStyles({
  icon: {
    minWidth: 0,
  },
});

type KioskListItemProps = {
  kiosk: HydraDeviceDetails;
  kioskId: string | undefined;
  isLast: boolean;
};

type Props = KioskListItemProps & MappedProps;
const KioskListItem = ({ detailsUrl, isLast, kioskId, kiosk }: Props) => {
  const classes = useStyles();

  if (!kiosk || !detailsUrl) {
    return null;
  }

  return (
    <ListItemLink
      divider={!isLast}
      button
      key={kioskId}
      fdKey={`${kioskId}`}
      to={detailsUrl}
      className={HORIZONTAL_SPACE_CLASSNAME}
      alignItems="center"
    >
      <ListItemAvatar>
        <ListItemIcon deviceStatus={kiosk.Status} deviceType={kiosk.DeviceType} />
      </ListItemAvatar>

      <KioskListDetails kiosk={kiosk} />
      <ListIcon className={classes.icon}>
        <KeyboardArrowRight color="action" />
      </ListIcon>
    </ListItemLink>
  );
};

type MappedProps = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
function mapStateToPropsFactory(state, props: KioskListItemProps) {
  const getKioskDetailsUrl = getKioskDetailsUrlFactory(props.kioskId);
  return (state: AppState) => {
    const detailsUrl = getKioskDetailsUrl(state);

    return {
      detailsUrl,
    };
  };
}
export default compose<Props, KioskListItemProps>(connect(mapStateToPropsFactory))(KioskListItem);
