import { createElement, ReactElement } from 'react';

import { createSelector } from 'reselect';

import { encodeHTML } from '../../helpers/encodeHTML';
import { stripHTML } from '../../helpers/stripHTML';
import { addTranslationForLanguage, initialize, setActiveLanguage } from './actions';
import localeReducer, {
  LocaleState as OriginalLocalState,
  Options as OriginalOptions,
} from './localeReducer';
import Translate from './Translate';

export type LocalizeProps = {
  translate: TranslateFunction;
};

export type Options = OriginalOptions;
export type LocaleState = OriginalLocalState;
export type Language = string;

export type TranslatePlaceholderData = {
  [key: string]: string | number;
};

export type Translation = {
  [key: string]: { [key: string]: string };
};

const getLocale = (state: any) => {
  return 'locale' in state ? state.locale : state;
};
const getLanguages = createSelector([getLocale], (locale: LocaleState) => locale.languages);
const getOptions = createSelector([getLocale], (locale: LocaleState) => locale.options);
export const getActiveLanguageSelector = createSelector(
  [getLocale],
  (locale: LocaleState) => locale.activeLanguage
);
const htmlTagsPattern = /(&[^\s]*;|<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[\^'">\s]+))?)+\s*|\s*)\/?>)/;
const hasHtmlTags = (value: string): boolean => {
  if (typeof value === 'string') {
    return value.search(htmlTagsPattern) >= 0;
  }
  return false;
};

const parseString = (str: string, data?: TranslatePlaceholderData) => {
  if (!data) {
    return str;
  }

  const sanitizeUserData = (str) => encodeHTML(stripHTML(String(str)));

  return str.replace(/\${(=?.*?)}/g, (_: string, key: string) =>
    sanitizeUserData(data[key.trim()])
  );
};

export type TranslateFunction = ReturnType<typeof getTranslate>;

// Use this symbol for find missing translations. Set to '#' for example. Only in dev mode!
const DEBUG_SYMBOL = '';
const replaceAllDots = /\./g;
export const getTranslate = createSelector(
  [getActiveLanguageSelector, getLanguages, getOptions],
  (activeLanguage: string, languages: Translation, stateOptions: Options) => {
    function translateFn(
      key: string | TranslationId,
      data: TranslatePlaceholderData | null | undefined,
      options: Options & {
        missingTranslationMsg: string;
      }
    ): string | ReactElement;
    function translateFn(
      key: TranslationId,
      data?: TranslatePlaceholderData,
      options?: Options
    ): string | ReactElement;
    function translateFn(key: TranslationId, data?: TranslatePlaceholderData, options?: Options) {
      const lang = languages[activeLanguage];
      const translateOptions = { ...stateOptions, ...options };

      //#region TEMP while moving away from Loco to new service we r replacing all '.' w '_'
      let val = lang ? lang[key] : undefined;
      if (!val && key && key.indexOf('.') !== -1) {
        val = lang[key.replace(replaceAllDots, '_')];
      }
      //#endregion

      const translatedValue =
        DEBUG_SYMBOL + parseString(val, data) ||
        (!translateOptions.showMissingTranslationMsg
          ? '-'
          : parseString(translateOptions.missingTranslationMsg || '', {
              code: activeLanguage,
              key: String(key),
            }));

      // dangerouslySetInnerHTML data is already sanitized at this point
      return translateOptions.renderInnerHtml && hasHtmlTags(translatedValue)
        ? (createElement('span', {
            dangerouslySetInnerHTML: { __html: translatedValue },
          }) as ReactElement)
        : (translatedValue as string);
    }

    return translateFn;
  }
);

export const getActiveLanguage = (state: LocaleState): Language => state.activeLanguage;

export { addTranslationForLanguage, initialize, localeReducer, setActiveLanguage, Translate };
