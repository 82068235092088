import React, { useState } from 'react';

import { Channel } from '@flipdish/api-client-typescript';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import PageLayout from '@fd/ui/Layout';
import { PageHeader } from '@fd/ui/molecules/PageHeader';

import { useQuerySalesChannelsHook } from '../../../custom-hooks/useQuerySalesChannelsHook';
import {
  closeNotifySaving as closeNotifySavingAction,
  notifyError as notifyErrorAction,
  NotifyProps,
  notifySaved as notifySavedAction,
  notifySaving as notifySavingAction,
} from '../../../layouts/Notify/actions';
import { Translate } from '../../../overrides/react-localize-redux';
import { channelsService } from '../../../services/channels.service';
import AddChannelCardList from '../components/AddChannelCardList/AddChannelCardList';
import ConsentFormModal from '../components/ConsentFormModal/ConsentFormModal';
import DynamicFormModal, { FormFieldType } from '../components/DynamicFormModal/DynamicFormModal';
import { addChannelFlowSteps } from '../constants';

type Props = MappedState & MappedDispatchToProps;

const mockDynamicForm = {
  channel: 'UberEats',
  fields: [
    {
      name: 'textFieldName',
      type: 'text' as FormFieldType,
      label: 'Text Field',
      description: 'Description of text field',
    },
    {
      name: 'dropDownName',
      type: 'dropdown' as FormFieldType,
      label: 'Dropdown',
      description: 'Description of dropdown field',
      options: [
        {
          value: 'Option 1',
          label: 'option1',
        },
        {
          value: 'Option 2',
          label: 'option2',
        },
      ],
    },
    {
      name: 'checkboxName',
      type: 'checkbox' as FormFieldType,
      label: 'Checkbox',
      description: 'Description of Checkbox field',
    },
  ],
};

const useStyles = makeStyles((theme: Theme) => ({
  pageContent: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  addChannelList: {
    marginTop: theme.spacing(4),
  },
}));

const AddChannel = ({
  appId,
  notifySaving,
  notifySaved,
  closeNotifySaving,
  notifyError,
}: Props) => {
  const classes = useStyles();

  const [flowStep, setFlowStep] = useState<number>(addChannelFlowSteps.SELECT_CHANNEL_STEP);

  const [selectedChannel, setSelectedChannel] = useState<Channel>();

  const { data: channelsData } = useQuerySalesChannelsHook(
    () => channelsService.getChannels(appId),
    'channels',
    appId !== undefined
  );

  const { data: assignedChannelsData, refetch } = useQuerySalesChannelsHook(
    () => channelsService.getAssignedChannels(appId),
    'assignedChannels',
    appId !== undefined
  );

  const refetchAssignedChannels = () => {
    refetch();
  };

  const selectChannel = (channel: Channel) => {
    setFlowStep(addChannelFlowSteps.CONSENT_FORM_STEP);
    setSelectedChannel(channel);
  };

  const acceptConsentForm = () => {
    setFlowStep(addChannelFlowSteps.DYNAMIC_FORM_STEP);
  };

  const rejectForm = () => {
    setFlowStep(addChannelFlowSteps.SELECT_CHANNEL_STEP);
  };

  const submitDynamicForm = async () => {
    if (selectedChannel?.ChannelId) {
      try {
        notifySaving();
        await channelsService.assignAppIdToSalesChannel(appId, selectedChannel?.ChannelId);
        closeNotifySaving();
        notifySaved();

        setFlowStep(addChannelFlowSteps.SELECT_CHANNEL_STEP);
        refetchAssignedChannels();
      } catch (error) {
        closeNotifySaving();
        const errorNotificatiion: NotifyProps = { message: error.message, translate: false };
        notifyError(errorNotificatiion);
      }
    }
  };

  return (
    <PageLayout documentTitle="Add_Channel" title={<Translate id="Add_sales_channel" />}>
      <div className={classes.pageContent}>
        <PageHeader
          heading={<Translate id="External_sales_channels" />}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in volupt"
          fdKey="page-header"
        />
        <div className={classes.addChannelList}>
          <AddChannelCardList
            onSelectOption={selectChannel}
            channels={
              channelsData?.Data.filter((x: Channel) => x.Source === Channel.SourceEnum.External) ||
              []
            }
            assignedChannels={assignedChannelsData?.Data || []}
          />
        </div>
      </div>
      {flowStep === addChannelFlowSteps.CONSENT_FORM_STEP && (
        <ConsentFormModal acceptForm={acceptConsentForm} rejectForm={rejectForm} />
      )}
      {flowStep === addChannelFlowSteps.DYNAMIC_FORM_STEP && (
        <DynamicFormModal
          form={mockDynamicForm}
          onSubmit={submitDynamicForm}
          rejectForm={rejectForm}
        />
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp.AppId as string,
  };
};

type MappedDispatchToProps = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifySaving: () => dispatch(closeNotifySavingAction()),
  notifyError: (data: NotifyProps) => dispatch(notifyErrorAction(data)),
  notifySaving: () => dispatch(notifySavingAction({ persist: true })),
  notifySaved: () => dispatch(notifySavedAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddChannel);
