import { get } from 'lodash';
import { createSelector } from 'reselect';

import { TFeatureFlag } from '../ui/FeatureFlag';

const selectCurrentAppState = (state: AppState) => state.currentApp;

export const getIsOwner = createSelector([selectCurrentAppState], (currentApp) => {
  return currentApp.AppAccessLevel && currentApp.AppAccessLevel.toString() === 'Owner'
    ? true
    : false;
});

export const getIsOnboarding = createSelector([selectCurrentAppState], (currentApp) => {
  return !!(currentApp?.AppAccessLevel?.toString() === 'Onboarding');
});

export const isFlipdishGlobal = createSelector([selectCurrentAppState], (currentApp) => {
  return currentApp.AppId && currentApp.AppId.includes('flipdish-global') ? true : false;
});

export const displayStripeFeature = createSelector([selectCurrentAppState], (currentApp) => {
  return currentApp.Features && currentApp.Features.includes('enable-stripe-cc') ? true : false;
});

export const isProductBasedMenusEnabled = createSelector([selectCurrentAppState], (currentApp) => {
  return currentApp.Features && currentApp.Features.includes('pbm') ? true : false;
});

export const orderCapacityFeature = createSelector([selectCurrentAppState], (currentApp) => {
  return currentApp.Features && currentApp.Features.includes('order-capacity') ? true : false;
});

export const editBankAccountFeature = createSelector([selectCurrentAppState], (currentApp) => {
  return currentApp.Features && currentApp.Features.includes('edit-bank-account') ? true : false;
});

export const isSubscriptionsBillingEnabled = createSelector(
  [selectCurrentAppState],
  (currentApp) => {
    return currentApp.Features && currentApp.Features.includes('subscriptions') ? true : false;
  }
);

export const showPausedPayouts = createSelector([selectCurrentAppState], (currentApp) => {
  return currentApp.Features && currentApp.Features.includes('payouts-paused') ? true : false;
});

export const createAppSelector = (propertyName, defaultValue) => (state) => {
  return get(state, `currentApp.${propertyName}`, defaultValue);
};

export const appMapCenterSelector = createSelector([(state) => state.currentApp], (state) => {
  return {
    lat: (state.MapCenter as any).Latitude,
    lng: (state.MapCenter as any).Longitude,
  };
});

const hasFeatureFactory = (featureName: TFeatureFlag) =>
  createSelector([selectCurrentAppState], (currentApp) => {
    return currentApp.Features?.includes(featureName) ? true : false;
  });

export const appSelectors = { hasFeatureFactory };
