import React, { ReactNode, useState } from 'react';

import FormControl from '@mui/material/FormControl';

import { ComponentProps, getAutoFdKey } from '../Common';
import { FkField, TFkFieldProps } from '../FkField/FkField';
import Select, { OptionType } from '../Select/Select';

type Props = {
  isClearable?: boolean;
  isMulti?: boolean;
  maxChips?: number;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  onChange?: (values: (number | string)[] | number | string) => void;
  onLoadOptions?: () => Promise<Array<OptionType[]>>;
  options?: OptionType[];
  renderNoOptions?: (props) => ReactNode;
  variant?: string;
  validation?: any;
  noErrorTranslate?: boolean;
  textFieldProps?: any;
} & ComponentProps;

const FkMultiSelect = (props: Props) => {
  const {
    disabled,
    fdKey,
    fieldName,
    fieldType,
    isClearable,
    isMulti,
    maxChips,
    menuPlacement,
    onChange,
    options,
    variant,
    validation = null,
    noErrorTranslate = false,
    textFieldProps = () => {},
  } = props;

  const [shrink, setShrink] = useState(false);
  const getValue = (value: number | string) => {
    return options?.find((o) => o.value === value);
  };

  const getValues = (values: (number | string)[]) => {
    return values.map((v) => options?.find((o) => o.value === v));
  };

  const handleSingleChange = (value: OptionType) => {
    onChange && onChange(value.value);
    return value.value;
  };

  const handleMultiChange = (values: OptionType[]) => {
    const vals = values.map((m) => m.value);
    onChange && onChange(vals);
    return vals;
  };

  return (
    <FkField
      componentProps={props}
      removeTopPadding={props.removeTopPadding}
      showHelperText={false}
      validateFunc={validation}
    >
      {({ field, form }: TFkFieldProps) => {
        const { errors, touched } = form;
        const fieldError = touched[field.name] && errors[field.name];
        const value = isMulti
          ? (getValues(field.value) as OptionType[])
          : (getValue(field.value) as OptionType);
        return (
          <FormControl fullWidth>
            <Select
              noErrorTranslate={noErrorTranslate}
              dataFd={fdKey || getAutoFdKey(fieldType, fieldName)}
              isClearable={isClearable}
              isMulti={isMulti}
              maxChips={maxChips}
              menuPlacement={menuPlacement}
              fieldError={fieldError}
              onFocus={() => setShrink(true)}
              onChange={(values: OptionType[] | OptionType) => {
                const vals = isMulti
                  ? handleMultiChange(values as OptionType[])
                  : handleSingleChange(values as OptionType);
                form.setFieldValue(field.name, vals);
              }}
              onBlur={() => {
                setShrink(false);
                form.setFieldTouched(field.name, true);
              }}
              options={options}
              placeholder={''}
              TextFieldProps={{
                ...textFieldProps(shrink, value),
                fdKey: 'stores-dropdown',
                name: 'stores-select',
              }}
              value={value}
              variant={variant as 'outlined' | 'standard' | undefined}
              isDisabled={disabled || form.isSubmitting}
            />
          </FormControl>
        );
      }}
    </FkField>
  );
};

export default FkMultiSelect;
