import chunk from 'lodash/chunk';
import isArray from 'lodash/isArray';
import moment from 'moment';

import { formatPayoutDetailsBreakdown } from './DataHelper/PayoutSummaryBreakdownTableData';
import { formatPayoutDetailsSummary } from './DataHelper/PayoutSummaryTableData';
import { formatPayoutDetailsSummaryNew } from './DataHelper/PayoutSummaryTableDataNew';
import {
  Balance,
  Chargebacks,
  FlipdishFees,
  OtherCharges,
  PayoutStore,
  PayoutSummary,
  PayoutSummaryItem,
  PosRevenue,
  Revenue,
  RevenueAdjustments,
  ThirdPartyFees,
} from './types';

export const renderDatePeriod = (currentPeriodFilter) => {
  const startDate = moment(currentPeriodFilter.startDate).format('DD MMMM YYYY');
  const endDate = moment(currentPeriodFilter.endDate).format('DD MMMM YYYY');
  const datePeriod = startDate === endDate ? startDate : `${startDate} - ${endDate}`;
  return datePeriod;
};

export const customRange = [
  { label: 'Last_x_days', period: '30', value: 4, url: 'last30Days' },
  { label: 'Last_x_days', period: '365', value: 17, url: 'last365Days' },
  { label: 'Custom', value: 0, url: 'custom' },
];

export const isDateSet = (currentPeriodFilter) => {
  return currentPeriodFilter.startDate && currentPeriodFilter.endDate;
};

export const addValues = (a: number | string | undefined, b: number | string | undefined) => {
  const valueA = Number(a) || 0;
  const valueB = Number(b) || 0;

  return valueA + valueB;
};

export const subtractValues = (a: number | string | undefined, b: number | string | undefined) => {
  const valueA = Number(a) || 0;
  const valueB = Number(b) || 0;

  return valueA - valueB;
};

export const formatCurrency = (
  value: number | string,
  languageCode: string,
  currency: string | null
) => {
  const val = typeof value === 'string' ? parseFloat(value) : value;
  return val.toLocaleString(languageCode, {
    style: 'currency',
    currency: currency as string,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const getStatusStringId = (paymentStatus) => {
  // update the statuses which don't return a translatable string.
  switch (paymentStatus) {
    case 'InTransit':
      return 'In_transit';
    case 'Pending':
      return 'Pending_bank_account';
    default:
      return paymentStatus;
  }
};

export const serializePayoutResponse = (data, languageCode: string) => {
  return data.map((item) => {
    return {
      PayoutId: item.PayoutId,
      PayeeBankAccountId: item.PayeeBankAccountId,
      AccountName: item.AccountName,
      Currency: item.Currency,
      CutoffDate: item.CutoffDate,
      CreatedDate: item.CreatedDate,
      DestinationBank: {
        mainText: item.AccountName,
        subtext: `${item.DestinationBank} **** ${item.DestinationAccount}`,
      },
      PayoutStatus: {
        status: getStatusStringId(item.PayoutStatus),
      },
      OnlineSales: formatCurrency(item.OnlineSalesAmount, languageCode, item.Currency),
      OnlineSalesTax: formatCurrency(item.OnlineSalesTax, languageCode, item.Currency),
      DeliveryCharges: formatCurrency(item.OnlineSalesDeliveryCharges, languageCode, item.Currency),
      TipsOnOnlineSales: formatCurrency(item.OnlineSalesTips, languageCode, item.Currency),
      TotalRevenue: formatCurrency(item.TotalOnlineRevenue, languageCode, item.Currency),
      RefundsOnOnlineSales: formatCurrency(
        item.OnlineSalesRefundedAmount,
        languageCode,
        item.Currency
      ),
      CashFeesChargedToCustomer: formatCurrency(item.CustomerCashFees, languageCode, item.Currency),
      TotalRevenueAdjustments: formatCurrency(
        item.TotalOnlineRevenueAdjustments,
        languageCode,
        item.Currency
      ),
      OnlineSalesFees: formatCurrency(
        addValues(item.OnlineSalesFees, item.TotalThirdPartyFees),
        languageCode,
        item.Currency
      ),
      CashSalesFees: formatCurrency(item.CashSalesFees, languageCode, item.Currency),
      RefundedFees: formatCurrency(
        addValues(item.CashSalesRefundedFees, item.OnlineSalesRefundedFees),
        languageCode,
        item.Currency
      ),
      VATonFees: formatCurrency(item.SalesFeesVat, languageCode, item.Currency),
      TotalFees: formatCurrency(
        addValues(item.TotalFees, item.TotalThirdPartyFees),
        languageCode,
        item.Currency
      ),
      ChargebackAmount: formatCurrency(item.ChargebackAmount, languageCode, item.Currency),
      RefundedFeesOnChargebacks: formatCurrency(
        item.ChargebackRefundedFees,
        languageCode,
        item.Currency
      ),
      TotalChargebackCost: formatCurrency(item.TotalChargebackCost, languageCode, item.Currency),
      TotalOtherCharges: formatCurrency(item.TotalOtherCharges, languageCode, item.Currency),
      OpeningBalance: formatCurrency(item.OpeningBalance, languageCode, item.Currency),
      ClosingBalance: formatCurrency(item.ClosingBalance, languageCode, item.Currency),
      PayoutAmount: formatCurrency(item.Amount, languageCode, item.Currency),
      DeliveryIntegrationFee: formatCurrency(
        item.DeliveryIntegrationFee,
        languageCode,
        item.Currency
      ),
      DeliveryIntegrationTipFee: formatCurrency(
        item.DeliveryIntegrationTipFee,
        languageCode,
        item.Currency
      ),
      PosSalesAmount: formatCurrency(item.PosSalesAmount, languageCode, item.Currency),
      PosSalesTax: formatCurrency(item.PosSalesTax, languageCode, item.Currency),
      PosSalesFees: formatCurrency(item.PosSalesFees, languageCode, item.Currency),
      TipsOnPosSales: formatCurrency(item.TipsOnPosSales, languageCode, item.Currency),
      PosSalesRefundedAmount: formatCurrency(
        item.PosSalesRefundedAmount,
        languageCode,
        item.Currency
      ),
      PosSalesChargebackAmount: formatCurrency(
        item.PosSalesChargebackAmount,
        languageCode,
        item.Currency
      ),
      TotalThirdPartyFees: formatCurrency(item.TotalThirdPartyFees, languageCode, item.Currency),
      hasWarning: item.PayoutStatus === 'Failed' || item.PayoutStatus === 'Cancelled',
    };
  });
};

/**
 * Groups payout summary response by currency.
 */
export const serializeSummaryResponse = (response) => {
  const groupedResponse = response.reduce(function (r, a) {
    r[a.Currency] = r[a.Currency] || [];
    if (a.PayoutStatus === 'Pending') {
      r[a.Currency] = {
        ...r[a.Currency],
        currency: a.Currency,
        pending: {
          totalAmount: a.TotalAmount,
          totalCount: a.TotalCount,
        },
      };
    } else if (a.PayoutStatus === 'Paid') {
      r[a.Currency] = {
        ...r[a.Currency],
        currency: a.Currency,
        complete: {
          totalAmount: a.TotalAmount,
          totalCount: a.TotalCount,
        },
      };
    }
    return r;
  }, Object.create(null));
  return Object.values(groupedResponse) as PayoutSummaryItem[];
};

/**
 * Accepts an array of objects of the same structure and reduces by summing the corresponding values
 * @param {{}[]} array{}[]
 * @returns {[]}
 */
export const sumPayoutObjectValues = (data: PayoutStore[]) => {
  if (data?.length > 0 && data[0] !== null) {
    const keys = Object.keys(data[0]);
    return data.reduce(function (acc, item) {
      for (const key of keys) {
        acc = {
          ...acc,
          [key]: acc[key] + item[key],
        };
      }
      return acc;
    });
  } else {
    return;
  }
};

export const sumPayoutNumberValues = (data: number[]) => {
  if (data?.length > 0 && data[0] !== null) {
    return data.reduce(function (acc, item) {
      return (acc = acc + item);
    });
  }
};

//#region Old get summary totals, this will be deleted once the new one is tested

export const getSummaries = (data: PayoutStore[], storeIdsFilter: number[] | number) => {
  const amount: number[] = [];
  const revenue: {}[] = [];
  const revenueAdjustments: {}[] = [];
  const flipdishFees: {}[] = [];
  const chargebacks: {}[] = [];
  const otherCharges: {}[] = [];
  const balance: {}[] = [];
  const posRevenue: {}[] = [];
  const thirdPartyFees: {}[] = [];
  const filteredData = filterByStoreIds(data, storeIdsFilter);

  filteredData.forEach((storeEntry) => {
    amount.push(storeEntry.Amount as number);
    revenue.push(storeEntry.Revenue as Revenue);
    revenueAdjustments.push(storeEntry.RevenueAdjustments as RevenueAdjustments);
    flipdishFees.push(storeEntry.FlipdishFees as FlipdishFees);
    chargebacks.push(storeEntry.Chargebacks as Chargebacks);
    otherCharges.push(storeEntry.OtherCharges as OtherCharges);
    balance.push(storeEntry.Balance as Balance);
    thirdPartyFees.push(storeEntry.ThirdPartyFees as ThirdPartyFees);
    storeEntry.PosRevenue && posRevenue.push(storeEntry.PosRevenue as PosRevenue);
  });
  return {
    Amount: sumPayoutNumberValues(amount),
    Revenue: sumPayoutObjectValues(revenue),
    RevenueAdjustments: sumPayoutObjectValues(revenueAdjustments),
    FlipdishFees: sumPayoutObjectValues(flipdishFees),
    Chargebacks: sumPayoutObjectValues(chargebacks),
    OtherCharges: sumPayoutObjectValues(otherCharges),
    Balance: sumPayoutObjectValues(balance),
    PosRevenue: sumPayoutObjectValues(posRevenue),
    ThirdPartyFees: sumPayoutObjectValues(thirdPartyFees),
  };
};

export const getSummaryTotals = (
  payoutDetailSummary,
  storeIdsFilter: number[] | number,
  currency: string,
  languageCode: string,
  translate: (id: TranslationId) => void
) => {
  if (payoutDetailSummary?.PayoutStores) {
    const summaryTotals = getSummaries(payoutDetailSummary.PayoutStores, storeIdsFilter);
    return formatPayoutDetailsSummary(
      summaryTotals as PayoutSummary,
      currency,
      languageCode,
      translate
    );
  }
  return payoutDetailSummary;
};

//#endregion

//#region NEW get summary totals

export const getPayoutSummary = (data: PayoutStore[], storeIdsFilter: number[] | number) => {
  const amount: number[] = [];
  const balance: {}[] = [];
  const chargebacks: {}[] = [];
  const flipdishFees: {}[] = [];
  const otherCharges: {}[] = [];
  const posRevenue: {}[] = [];
  const revenue: {}[] = [];
  const revenueAdjustments: {}[] = [];
  const thirdPartyFees: {}[] = [];
  const filteredData = filterByStoreIds(data, storeIdsFilter);

  filteredData.forEach((storeEntry) => {
    amount.push(storeEntry.Amount as number);
    balance.push(storeEntry.Balance as Balance);
    chargebacks.push(storeEntry.Chargebacks as Chargebacks);
    flipdishFees.push(storeEntry.FlipdishFees as FlipdishFees);
    otherCharges.push(storeEntry.OtherCharges as OtherCharges);
    revenue.push(storeEntry.Revenue as Revenue);
    revenueAdjustments.push(storeEntry.RevenueAdjustments as RevenueAdjustments);
    thirdPartyFees.push(storeEntry.ThirdPartyFees as ThirdPartyFees);
    storeEntry.PosRevenue && posRevenue.push(storeEntry.PosRevenue as PosRevenue);
  });
  return {
    Amount: sumPayoutNumberValues(amount),
    Balance: sumPayoutObjectValues(balance),
    Chargebacks: sumPayoutObjectValues(chargebacks),
    FlipdishFees: sumPayoutObjectValues(flipdishFees),
    OtherCharges: sumPayoutObjectValues(otherCharges),
    PosRevenue: sumPayoutObjectValues(posRevenue),
    Revenue: sumPayoutObjectValues(revenue),
    RevenueAdjustments: sumPayoutObjectValues(revenueAdjustments),
    ThirdPartyFees: sumPayoutObjectValues(thirdPartyFees),
  };
};

export const getSummaryTotalsNew = (payoutDetailSummary, storeIdsFilter: number[] | number) => {
  if (payoutDetailSummary?.PayoutStores) {
    const summaryTotals = getPayoutSummary(payoutDetailSummary.PayoutStores, storeIdsFilter);
    return formatPayoutDetailsSummaryNew(summaryTotals as PayoutSummary);
  }
  return payoutDetailSummary;
};

export const getBalanceAccountSummary = (
  data: PayoutStore[],
  storeIdsFilter: number[] | number
) => {
  const amount: number[] = [];
  const balance: {}[] = [];
  const chargebacks: {}[] = [];
  const flipdishFees: {}[] = [];
  const otherCharges: {}[] = [];
  const posRevenue: {}[] = [];
  const revenue: {}[] = [];
  const revenueAdjustments: {}[] = [];
  const thirdPartyFees: {}[] = [];
  const filteredData = filterByStoreIds(data, storeIdsFilter);

  filteredData.forEach((storeEntry) => {
    amount.push(storeEntry.Amount as number);
    balance.push(storeEntry.Balance as Balance);
    chargebacks.push(storeEntry.Chargebacks as Chargebacks);
    flipdishFees.push(storeEntry.FlipdishFees as FlipdishFees);
    otherCharges.push(storeEntry.OtherCharges as OtherCharges);
    revenue.push(storeEntry.Revenue as Revenue);
    revenueAdjustments.push(storeEntry.RevenueAdjustments as RevenueAdjustments);
    thirdPartyFees.push(storeEntry.ThirdPartyFees as ThirdPartyFees);
    storeEntry.PosRevenue && posRevenue.push(storeEntry.PosRevenue as PosRevenue);
  });
  return {
    Amount: sumPayoutNumberValues(amount),
    Balance: sumPayoutObjectValues(balance),
    Chargebacks: sumPayoutObjectValues(chargebacks),
    FlipdishFees: sumPayoutObjectValues(flipdishFees),
    OtherCharges: sumPayoutObjectValues(otherCharges),
    PosRevenue: sumPayoutObjectValues(posRevenue),
    Revenue: sumPayoutObjectValues(revenue),
    RevenueAdjustments: sumPayoutObjectValues(revenueAdjustments),
    ThirdPartyFees: sumPayoutObjectValues(thirdPartyFees),
  };
};

export const getBalanceAccount = (payoutDetailSummary, storeIdsFilter: number[] | number) => {
  if (payoutDetailSummary?.PayoutStores) {
    const summaryTotals = getBalanceAccountSummary(
      payoutDetailSummary.PayoutStores,
      storeIdsFilter
    );
    return summaryTotals;
  }
  return payoutDetailSummary;
};

export const getSummariesBreakdown = (data: PayoutStore[], storeIdsFilter: number[] | number) => {
  const revenue: {}[] = [];
  const revenueAdjustments: {}[] = [];
  const flipdishFees: {}[] = [];
  const posRevenue: {}[] = [];
  const thirdPartyFees: {}[] = [];
  const filteredData = filterByStoreIds(data, storeIdsFilter);

  filteredData.forEach((storeEntry) => {
    flipdishFees.push(storeEntry.FlipdishFees as FlipdishFees);
    revenue.push(storeEntry.Revenue as Revenue);
    revenueAdjustments.push(storeEntry.RevenueAdjustments as RevenueAdjustments);
    thirdPartyFees.push(storeEntry.ThirdPartyFees as ThirdPartyFees);
    storeEntry.PosRevenue && posRevenue.push(storeEntry.PosRevenue as PosRevenue);
  });
  return {
    FlipdishFees: sumPayoutObjectValues(flipdishFees),
    PosRevenue: sumPayoutObjectValues(posRevenue),
    Revenue: sumPayoutObjectValues(revenue),
    RevenueAdjustments: sumPayoutObjectValues(revenueAdjustments),
    ThirdPartyFees: sumPayoutObjectValues(thirdPartyFees),
  };
};

export const getSummaryTotalsBreakdown = (
  payoutDetailSummary,
  storeIdsFilter: number[] | number,
  currency: string,
  languageCode: string,
  translate: (id: TranslationId) => void
) => {
  if (payoutDetailSummary?.PayoutStores) {
    const summaryTotals = getSummariesBreakdown(payoutDetailSummary.PayoutStores, storeIdsFilter);
    return formatPayoutDetailsBreakdown(
      summaryTotals as PayoutSummary,
      currency,
      languageCode,
      translate
    );
  }
  return payoutDetailSummary;
};

//#endregion

export const swapSigns = (value: number) => {
  //makes positive values negative and negative values postive
  return value > 0 ? -Math.abs(value) : Math.abs(value);
};

export const formatBalance = (balance: number, isSwapSigns?: boolean) => {
  //stops -€0.00 from displaying if balance is equal to -0.00112
  if (Number(balance.toFixed(2)) === 0) return 0;
  if (isSwapSigns) return swapSigns(balance);
  return balance;
};

export const formatPayoutByStores = (data: PayoutStore[], limit: number) => {
  //takes Payout Stores data and formats it for Payout By Stores table
  const formattedData = data.map((store) => ({
    StoreName: store.StoreName,
    OnlineSales: store.Revenue?.OnlineSalesAmount,
    OnlineSalesTax: store.Revenue?.OnlineSalesTax,
    OnlineDeliveryCharges: store.Revenue?.OnlineSalesDeliveryCharges,
    Tips: store.Revenue?.OnlineSalesTips,
    RefundsOnOnlineSales: store.RevenueAdjustments?.OnlineSalesRefundedAmount,
    CashFeesChargedToCustomer: store.RevenueAdjustments?.CustomerCashFees,
    TotalFlipdishFees: store.FlipdishFees?.TotalFees,
    TotalChargebacksCost: store.Chargebacks?.TotalChargebackCost,
    TotalOtherCharges: store.OtherCharges?.TotalOtherCharges,
    OpeningBalance: store.Balance?.OpeningBalance && formatBalance(store.Balance?.OpeningBalance),
    ClosingBalance: store.Balance?.ClosingBalance && formatBalance(store.Balance?.ClosingBalance),
    Amount: store.Amount,
    TotalThirdPartyFees: store.ThirdPartyFees?.TotalThirdPartyFees,
  }));
  //paginate data according to limit
  return chunk(formattedData, limit);
};

export const sumSummaryValue = (
  data: PayoutStore[],
  object: string,
  value: string,
  storeIdsFilter: number[] | number,
  defaultValue = 0
) => {
  try {
    const filteredData = filterByStoreIds(data, storeIdsFilter);
    const result = filteredData.reduce(
      (total, currentValue) => (total = total + currentValue[object][value]),
      0 //initialValue
    );
    if (isNaN(result)) return defaultValue;
    return Math.abs(result);
  } catch {
    return defaultValue;
  }
};

export const filterByStoreIds = (data: PayoutStore[], storeIdsFilter: number[] | number) => {
  if (!isArray(storeIdsFilter))
    return data.filter((payoutStore) => payoutStore.StoreId === storeIdsFilter);

  return data.filter(({ StoreId }) => storeIdsFilter.includes(StoreId as number));
};

export const mapStoreIdToName = (data, payoutStores: PayoutStore[]) => {
  if (data) {
    return data.map((payoutStore) => {
      const StoreHeaders = payoutStores.find((s) => s.StoreId === payoutStore.StoreId);
      return {
        ...payoutStore,
        StoreName: StoreHeaders ? StoreHeaders.StoreName : payoutStore.storeId,
      };
    });
  }
  return [];
};

export const mapTableDataToPagination = (data, payoutStores: PayoutStore[]) => ({
  Data: mapStoreIdToName(data.Data, payoutStores),
  Page: data.Page,
  Limit: data.Limit,
  TotalRecordCount: data.TotalRecordCount,
});

export const renderCurrency = (currency, languageCode, value) => {
  const formattedValue = Math.abs(value).toLocaleString(languageCode, {
    style: 'currency',
    currency: currency,
  });
  return value < 0 ? `(${formattedValue})` : formattedValue;
};
