import { SupportedCountry } from '@flipdish/api-client-typescript';
import { filter, includes } from 'lodash';

export const parseAddressFromResult = (result, supportedCountries) => {
  try {
    const components = result.address_components;

    const roomNumber = parse((ac) => ac.long_name, components, 'room');
    const streetNumber = parse((ac) => ac.long_name, components, 'street_number');
    const route = parse((ac) => ac.long_name, components, 'route');
    const neighborhood = parse((ac) => ac.long_name, components, 'neighborhood');
    const sublocality = parse((ac) => ac.long_name, components, 'sublocality');
    let postCode = parse((ac) => ac.long_name, components, 'postal_code');
    // TODO: implement handling of "sublocality_level_1-5" for areas that use it
    const area = neighborhood != '' ? neighborhood : sublocality;
    const countryCode = parse((ac) => ac.short_name, components, 'country');
    // TODO: better "administrative_area_level_1-5" handling
    const administrativeAreaLevel1 = parse(
      (ac) => ac.long_name,
      components,
      'administrative_area_level_1'
    );

    let supportedCountry: SupportedCountry =
      supportedCountries && supportedCountries.find((c) => c.CountryCode === countryCode);

    const isUsa = supportedCountry && supportedCountry.CountryCode === 'US';

    let showPostcode = supportedCountry && supportedCountry.PostCodeType === 0 ? false : true;

    const addressFirst = supportedCountry && supportedCountry.AddressLayout === 1;
    const fullStreetAddressFirst = supportedCountry && supportedCountry.AddressLayout === 2;

    let building = merge([roomNumber, streetNumber]);
    let town = parse((ac) => ac.long_name, components, 'locality');
    let postalTown = parse((ac) => ac.long_name, components, 'postal_town');

    let usingName = false;

    if (building == '' && result.name) {
      building = result.name;
      usingName = true;
    }

    town = town == '' ? postalTown : town;
    postCode = showPostcode ? postCode : '';

    let addressLine1 = addressFirst ? merge([route, building]) : route;
    if (!addressFirst && !route.startsWith(building)) {
      addressLine1 = merge([building, route], usingName ? ', ' : ' ');
    }

    let fullAddressRest = addressFirst
      ? merge([merge([route, area]), building, town], ' ')
      : fullStreetAddressFirst
        ? merge([route, area, town, administrativeAreaLevel1, postCode])
        : merge([route, area, town, postCode]);

    let fullAddress =
      !addressFirst && !fullAddressRest.startsWith(building)
        ? merge([building, fullAddressRest], usingName ? ', ' : ' ')
        : fullAddressRest;

    addressLine1 =
      supportedCountry && supportedCountry.PostCodeType === 2 && isUsa
        ? addressLine1 + ', ' + town
        : supportedCountry && supportedCountry.PostCodeType === 2 && !isUsa
          ? addressLine1
          : supportedCountry && supportedCountry.PostCodeType === 0
            ? addressLine1 + ', ' + area
            : addressLine1;

    town = supportedCountry
      ? supportedCountry.PostCodeType === 2 && isUsa
        ? administrativeAreaLevel1
        : town
      : town;

    return {
      fullAddress,
      addressLine1,
      area,
      city: town,
      postCode,
      countryCode,
      supportedCountry,
      administrativeAreaLevel1: isUsa ? administrativeAreaLevel1 : town,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
};

function parse(accessor, components, key) {
  const component = components.find((ac) => includes(ac.types, key));

  if (component) {
    const value = accessor(component);
    if (value && !value.startsWith('Unnamed ')) {
      return value;
    }
  }
  return '';
}

function merge(addressParts, delimiter?: string) {
  const delimiterString = delimiter ? delimiter : ', ';
  return filter(addressParts, (n) => n != '').join(delimiterString);
}
