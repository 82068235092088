import React, { useEffect } from 'react';

import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PageLayout from '../../../ui/Layout';
import { webSiteAndAppActionsAsync } from '../actions';
import { webSiteAndAppSelectors } from '../selectors';
import CookieSettingsForm, { FormValues } from './CookieSettingsForm';

type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {};
type Props = InnerProps & OuterProps;

const CookieSettings = (props: Props) => {
  const { allowDisablingOfGdpr, complianceType, currentApp, handleSubmit, translate } = props;

  useEffect(() => {
    props.load();
  }, []);

  return (
    <PageLayout
      toParent={`/${currentApp.AppId}/sales-channels/website-settings`}
      strictToParent={true}
      documentTitle="Cookie_management"
      title={translate('Cookie_management')}
      caption={translate('Cookie_managment_description')}
    >
      {complianceType && (
        <CookieSettingsForm
          allowDisablingOfGdpr={allowDisablingOfGdpr}
          complianceType={complianceType}
          onSubmit={handleSubmit}
          translate={translate}
        />
      )}
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  load: () => dispatch(webSiteAndAppActionsAsync.getCompliance()),
  handleSubmit: (data: FormValues) =>
    dispatch(webSiteAndAppActionsAsync.setCompliance(data.complianceType)),
});

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  complianceType: webSiteAndAppSelectors.getComplianceType(state),
  allowDisablingOfGdpr: state?.website?.allowDisablingOfGdpr,
  currentApp: state.currentApp,
  translate: getTranslate(state.locale),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  CookieSettings
);
