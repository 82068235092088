import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import Kitchen from '../../../../assets/images/kitchen.jpg';

type Props = {
  titleId: TranslationId;
  descriptionId: string; // to do: TranslationId
  buttonAction: () => void;
  buttonTextId: TranslationId;
  installed?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    boxShadow: 'none',
  },
  imageDiv: {
    marginRight: theme.spacing(2),
  },
  image: {
    width: 48,
    height: 48,
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 0,
  },
  installed: {
    color: '#20C26E',
    marginTop: theme.spacing(2),
  },
  installedIcon: {
    color: '#20C26E',
  },
  caption: {
    letterSpacing: 0.3,
  },
}));

const AddChannelCard = ({
  titleId,
  descriptionId,
  buttonAction,
  buttonTextId,
  installed,
}: Props) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" flexDirection="row" flexWrap="nowrap">
          <Box className={classes.imageDiv}>
            <img src={Kitchen} className={classes.image} alt="" data-fd="card-image" />
          </Box>
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              justifyContent="space-between"
            >
              <Typography variant="body1" data-fd="card-title">
                <Translate id={titleId} />
              </Typography>
              {installed && (
                <CheckCircleOutlineIcon
                  className={classes.installedIcon}
                  data-fd={`installed-icon`}
                />
              )}
            </Box>
            <Typography variant="caption" className={classes.caption} data-fd="card-description">
              {descriptionId}
            </Typography>
            {installed && (
              <Typography className={classes.installed}>
                <Translate id="Installed" />
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
      {!installed && (
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            variant="contained"
            onClick={buttonAction}
            data-fd={`card-button-${titleId}`}
            className={classes.button}
          >
            <Translate id={buttonTextId} />
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default AddChannelCard;
