import React from 'react';

import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import HomeScreenCustomisation from './Components/HomeScreenCustomisation';
import KioskCreate from './Create/KioskCreate';
import KioskEdit from './Edit/KioskEdit';
import KioskList from './KioskList';
import KioskTelemetry from './Telemetry/KioskTelemetry';

type Props = RouteComponentProps;

const KioskRoutes = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="kiosk">
      <Switch>
        <Route
          exact
          path={`${path}/homescreen_customisation`}
          component={HomeScreenCustomisation}
        />
        <Route exact path={`${path}/new`} component={KioskCreate} />
        <Route exact path={`${path}/:id`} component={KioskEdit} />
        <Route exact path={`${path}/:id/telemetry`} component={KioskTelemetry} />
        <Route path={`${path}`} component={KioskList} />
      </Switch>
    </ErrorBoundary>
  );
};

export default withRouter(KioskRoutes);
