import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { type FieldProps, Field } from 'formik';
import debounce from 'lodash/debounce';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import Select, { type OptionType } from '@fd/ui/Select/Select';

import { useInfiniteQueryStoreFilterHook } from '../../../custom-hooks/useInfiniteQuerySoreFilter';
import { loadByAppId } from '../../../services/store.service';
import FieldWrapper from '../../Finance/Banking/components/fields/FieldWrapper';

type OuterProps = {
  fieldName: string;
  preSelectedStores?: OptionType[];
};

type InnerProps = MappedState;
type Props = {} & InnerProps & OuterProps;

const AssignStoresField: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { appId, preSelectedStores } = props;
  const [query, setQuery] = useState('');
  const [storeSelectValue, setStoreSelectValue] = useState<OptionType[]>();
  const [storeOptions, setStoreOptions] = useState<OptionType[]>();
  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    if (preSelectedStores?.length) {
      setStoreSelectValue(preSelectedStores);
    }
  }, [preSelectedStores]);

  const fetchDataProps = {
    appId,
    query,
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQueryStoreFilterHook(fetchDataProps, loadByAppId, 'loadByAppId');

  useEffect(() => {
    if (data && data.pages) {
      const addNewStoreOptions = data.pages.reduce<OptionType[]>((total, page) => {
        page.Data.forEach((store) =>
          total.push({
            label: store.Name as string,
            value: String(store.StoreId),
            isDisabled: false,
          })
        );

        return total;
      }, []);
      setStoreOptions(addNewStoreOptions);

      if (preSelectedStores && addNewStoreOptions) {
        const stores = addNewStoreOptions
          .filter(
            (all) => !preSelectedStores?.some((assigned) => assigned.value == Number(all.value))
          )
          .map((s) => {
            return {
              label: s.label,
              value: s.value,
            };
          });
        setStoreOptions(stores);
      }
    }
  }, [data?.pages]);

  const handleScroll = ({ target }) => {
    const { scrollHeight, clientHeight, scrollTop } = target;

    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;

    if (hasScrollBar && hasNextPage && Math.ceil(scrollTop) >= scrollTopMax) {
      fetchNextPage();
    }
  };
  //#endregion

  const onSearchQueryChange = useMemo(
    () =>
      debounce((query: string) => {
        setQuery(query);
      }, 500),
    []
  );

  const validate = (value: string[]) => {
    if (value.length === 0 || value === undefined) {
      return <Translate id="Please_select_a_store" />;
    }
    return;
  };

  return (
    <Box onScroll={handleScroll}>
      <FieldWrapper>
        <Field name={'StoreIds'} validate={validate}>
          {({ field, form }: FieldProps) => {
            const { errors, touched, isSubmitting } = form;
            const fieldError = errors[field.name] as string | undefined;
            const showError = !!fieldError && (touched[field.name] as boolean | undefined);

            const handleSelectedStoreChange = (values: OptionType[]) => {
              setStoreSelectValue(values);

              const storeId = values.map((v) => v.value);

              setShrink(true);
              form.setFieldValue('StoreIds', storeId, false);
              form.errors.StoreIds = undefined;
            };

            return (
              <Select
                {...field}
                dataFd="campaign-loyalty-store-select"
                fieldError={showError ? fieldError : undefined}
                isClearable
                isDisabled={isSubmitting}
                isLoading={isLoading || isFetchingNextPage}
                isMulti
                onFocus={() => {
                  setQuery('');
                }}
                onChange={(values: OptionType[]) => {
                  handleSelectedStoreChange(values);
                  form.errors.Store = undefined;
                }}
                options={storeOptions}
                placeholder={''}
                setSearchQuery={onSearchQueryChange}
                TextFieldProps={{
                  fdKey: 'stores-dropdown',
                  name: 'selected-stores',
                  InputLabelProps: { shrink: false },
                }}
                value={storeSelectValue}
                variant="standard"
              />
            );
          }}
        </Field>
      </FieldWrapper>
    </Box>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale, currentApp } = state;
  return {
    translate: getTranslate(locale),
    appId: currentApp.AppId!,
  };
};

const EnhancedComponent = connect(mapStateToProps)(AssignStoresField);

export default EnhancedComponent;
