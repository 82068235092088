import * as React from 'react';

import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { compose } from 'recompose';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '530px',
      },
    },
    h1: {
      color: '#ffffff',
      [theme.breakpoints.down('md')]: {
        lineHeight: '1.25',
        letterSpacing: '-0.2px',
        fontSize: '1.5rem',
      },
    },
    subTitle: {
      fontWeight: 'normal',
    },
    title: {
      fontWeight: 900,
      // textTransform: 'lowercase'
    },
  });

export interface ILoginTitleProps {
  title: string;
  titlePrefix?: string;
  children?: React.ReactNode;
}
const Title = compose<WithStyles<typeof styles> & ILoginTitleProps, ILoginTitleProps>(
  withStyles(styles, {
    name: 'FdLoginTitle',
    withTheme: true,
  })
)(({ classes, title, titlePrefix, children }) => (
  <div className={classes.root}>
    <Typography variant="h1" className={classes.h1}>
      {titlePrefix && (
        <>
          <span className={classes.subTitle}>{titlePrefix}</span>
          <br />
        </>
      )}
      <span className={classes.title}>{title}</span>
    </Typography>
    {children}
  </div>
));

export default Title;
