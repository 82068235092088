import {
  BluetoothTerminalStatus,
  HydraApiFactory,
  HydraRegistrationRequest,
  KioskEntitlementsApiFactory,
  KioskSettings,
  RestApiPaginationResultHydraDeviceDetails,
  RestApiPaginationResultStoreHeader,
  RestApiPaginationResultStoreValidationConfig,
  RestApiResultBluetoothTerminalStatus,
  RestApiResultHydraDeviceDetails,
  RestApiResultKioskSettings,
  RestApiResultTelemetrySeriesResult,
  StoresApiFactory,
} from '@flipdish/api-client-typescript';
import axios from 'axios';
import qs from 'qs';

import { getApiEndPoint } from '../../helpers/apibase';
import { createApi } from '../../helpers/utilities';
import { mapServerError } from '../../services/utils/serverErrorMapper';
import { getExtraApiCallsToMake } from './helpers';

const baseURL = getApiEndPoint();

const portalApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

const api = createApi(HydraApiFactory);

// #region get kiosk list
export async function getKioskList(
  appId: string,
  deviceType: 'Kiosk' | 'Terminal' | 'LegacyPrinter',
  pageIndex?: number,
  pageSize?: number,
  deviceName?: string
) {
  const query = qs.stringify(
    { deviceName, pageIndex, pageSize },
    {
      skipNulls: true,
      encodeValuesOnly: true,
      indices: false,
    }
  );

  try {
    const incomingMessage = await portalApi.get<RestApiPaginationResultHydraDeviceDetails>(
      `/${appId}/hydra/${deviceType}/list?${query}`
    );
    return incomingMessage.data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}

export async function getKioskById(
  appId: string,
  deviceType: 'Kiosk' | 'Terminal' | 'LegacyPrinter',
  deviceId: string
) {
  try {
    const response = await portalApi.get<RestApiResultHydraDeviceDetails>(
      `/${appId}/hydra/${deviceType}/${deviceId}`
    );
    return response.data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region get kiosk settings
const getKioskSettingsV1QueryKey = 'getKioskSettingsV1';
export async function getKioskSettingsV1(appId: string, deviceId: string) {
  try {
    const response = await portalApi.get<RestApiResultKioskSettings>(
      `/${appId}/kiosksettings/${deviceId}`
    );
    return response.data.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}

export async function setKioskSettingsV1({
  appId,
  deviceId,
  settings,
}: {
  appId: string;
  deviceId: string;
  settings: KioskSettings;
}) {
  try {
    const response = await portalApi.post<RestApiResultKioskSettings>(
      `/${appId}/kiosksettings/${deviceId}`,
      settings
    );
    return response.data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}

export async function getKioskSettings() {
  try {
    const incomingMessage = await api.getSettings();
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region create Kiosk
export async function createKiosk(appId: string, kiosk: HydraRegistrationRequest) {
  try {
    const incomingMessage = await api.register(appId, kiosk);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region unregister Kiosk
export async function unregisterKiosk(appId: string, deviceId: string) {
  try {
    await api.unAssign(appId, deviceId);
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region get stores
const storesApi = createApi(StoresApiFactory);

export type GetStoresByAppIdProps = {
  appId: string;
  query?: string;
  page?: number;
  limit?: number;
};

export async function getStoresByAppId(props: GetStoresByAppIdProps) {
  try {
    const { appId, query, page, limit } = props;
    const incomingMessage = await storesApi.getStoresByAppId(appId, query, page, limit);
    return incomingMessage;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

// #region get EMV Terminal list
export type EMVDevice = {
  EmvTerminalId: number;
  TerminalId: string;
  Url: string;
  Token: string;
  SoftwareHouseId: string;
  InstallerId: string;
};

export async function getEMVTerminals(appId: string) {
  try {
    const incomingMessage = await api.hydraGetEmvsForAppId(appId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}

// #endregion

// #region assign emv to kiosk
export async function assignEmv(appId: string, hydraUserId: number, emvTerminalId: number) {
  try {
    await api.assignEmv(appId, hydraUserId, emvTerminalId);
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}

export async function unAssignEmv(appId: string, hydraUserId: number) {
  try {
    await api.unassignEmv(appId, hydraUserId);
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
// #endregion

//#region get, set Cash payment
const getCashPaymentMethodQueryKey = 'getKioskCashPaymentMethod';
const getCashPaymentMethod = async ({
  appId,
  deviceId,
}: {
  appId: string;
  deviceId: string | undefined;
}) => {
  try {
    const response = await portalApi.get<{
      Data: { IsCashVisibleToCustomer: boolean; UserId: number };
    }>(`${appId}/kioskcashsettings/${deviceId}`);
    return response.data.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const setCashPaymentMethod = async ({
  appId,
  deviceId,
  isCashEnabled,
}: {
  appId: string;
  deviceId: string | undefined;
  isCashEnabled: boolean;
}) => {
  try {
    await portalApi.post(
      `${appId}/kioskupdatecashsettings?deviceId=${deviceId}&isCashEnabled=${isCashEnabled}`
    );
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};
//#endregion

//#region Stripe Terminals
const getStripeTerminalDetailsQueryKey = 'getStripeTerminalDetails';

const setStripeTerminalParingModeService = async ({
  appId,
  deviceId,
  deviceType = BluetoothTerminalStatus.DeviceTypeEnum.CHIPPER2X,
}: {
  appId: string;
  deviceId: string | undefined;
  deviceType?: BluetoothTerminalStatus.DeviceTypeEnum;
}) => {
  try {
    await portalApi.post(`${appId}/cardreaders/kiosk/${deviceId}/bluetooth/${deviceType}/pair`);
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const getStripeTerminalBluetoothStatus = async (appId: string, deviceId?: string) => {
  try {
    const response = await portalApi.get<RestApiResultBluetoothTerminalStatus>(
      `${appId}/cardreaders/kiosk/${deviceId}/bluetooth/status`
    );
    return response.data.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const unpairStripeTerminal = async (appId: string, deviceId?: string) => {
  try {
    await portalApi.delete(`${appId}/cardreaders/kiosk/${deviceId}/bluetooth/unpair`);
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const unregisterStripeTerminal = async ({
  appId,
  deviceId,
  readerId,
}: {
  appId: string;
  deviceId?: string;
  readerId?: string;
}) => {
  try {
    await portalApi.delete(`${appId}/payments/terminals/stripe/unregister`, {
      data: { kioskDeviceId: deviceId, readerId },
    });
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};
//#endregion

//#region Assign/Unassign stores from Kiosk
const assignStoreToKiosk = async ({
  appId,
  deviceId,
  storeId,
}: {
  appId: string;
  deviceId: string | undefined;
  storeId: number;
}) => {
  try {
    await portalApi.post(`${appId}/AttachStoreToKiosk/${deviceId}/store/${storeId}`);
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const unassignStoreFromKiosk = async ({
  appId,
  deviceId,
  storeId,
}: {
  appId: string;
  deviceId: string | undefined;
  storeId: number;
}) => {
  try {
    await portalApi.delete(`${appId}/DetachStoreFromKiosk/${deviceId}/store/${storeId}`);
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const getStoreHeaders = async (appId: string) => {
  try {
    const response = await portalApi.get<RestApiPaginationResultStoreHeader>(
      `${appId}/stores/header?limit=40`
    );
    const { Limit, Page, TotalRecordCount } = response.data;
    const stores = response.data.Data;
    if (TotalRecordCount > Limit) {
      const MaxSafeLimit = 3;
      const apiCallsToMake = getExtraApiCallsToMake({ Limit, MaxSafeLimit, TotalRecordCount });

      let page = Page + 1;

      for (let i = 0; i < apiCallsToMake; i++) {
        const extraResponse = await portalApi.get<RestApiPaginationResultStoreHeader>(
          `${appId}/stores/header?page=${page}`
        );
        stores.push(...extraResponse.data.Data);
        page++;
      }
    }
    return stores;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};
//#endregion

//#region StoreValidation

const getStoreValidationsQueryKey = 'getStoreValidations';

const getStoreValidations = async ({
  appId,
  page,
  limit,
  storeIds,
}: {
  appId: string;
  page?: number;
  limit?: number;
  storeIds?: Array<string>;
}) => {
  try {
    let query = '';
    storeIds?.forEach((s) => {
      query += `&storeId=${s}`;
    });

    const response = await portalApi.get<RestApiPaginationResultStoreValidationConfig>(
      `${appId}/storevalidation/kiosk?page=${page}&limit=${limit}${query}`
    );
    return response.data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};
//#endregion

//#region Telemetry
const getTelemetryByKioskIdQueryKey = 'getTelemetryByKioskId';
export async function getTelemetryByKioskId(
  appId: string,
  deviceId: string,
  startDate: Date,
  endDate: Date,
  variables: string[]
) {
  try {
    const response = await portalApi.post<RestApiResultTelemetrySeriesResult>(
      `/${appId}/kioskiot/timeseries/query`,
      {
        kioskId: deviceId,
        variables,
        startDate,
        endDate,
      }
    );
    return response.data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
}
//#endregion

const kioskEntitlementsApi = createApi(KioskEntitlementsApiFactory);

async function getKioskDeviceEntitlements(appId: string) {
  try {
    const result = await kioskEntitlementsApi.queryKioskEntitlements(appId);
    return result.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const kioskServices = {
  setKioskSettingsV1,
  getKioskSettingsV1,
  getKioskSettingsV1QueryKey,
  assignStoreToKiosk,
  getCashPaymentMethod,
  getCashPaymentMethodQueryKey,
  getKioskList,
  getStoresByAppId,
  getStoreHeaders,
  getStoreValidations,
  getStripeTerminalBluetoothStatus,
  getStripeTerminalDetailsQueryKey,
  getStoreValidationsQueryKey,
  getTelemetryByKioskId,
  getTelemetryByKioskIdQueryKey,
  setCashPaymentMethod,
  setStripeTerminalParingModeService,
  unassignStoreFromKiosk,
  unpairStripeTerminal,
  unregisterStripeTerminal,
  getKioskDeviceEntitlements,
};
