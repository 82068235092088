import React from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import { Button } from '@fd/ui/atoms';

const useStyles = makeStyles((theme: Theme) => ({
  rightBottom: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const NextBtn = ({ navigateToNextStep }) => {
  const classes = useStyles();

  return (
    <Button
      fdKey="button"
      variant="primary"
      onClick={navigateToNextStep}
      className={classes.rightBottom}
    >
      <Translate id="Next" />
    </Button>
  );
};

export default NextBtn;
