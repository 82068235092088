import React from 'react';

import { useTracking } from './useTracking';

export type WithAmplitudeProps = {
  trackEvent: (eventName: string, options?: Record<string, string>) => void;
};

const withAmplitude = (Component) => {
  return (props) => {
    const tracking = useTracking();

    return <Component {...props} trackEvent={tracking.trackEvent} />;
  };
};

export default withAmplitude;
