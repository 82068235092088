import { HydraDeviceDetails, HydraRegistrationRequest } from '@flipdish/api-client-typescript';

import {
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  TERMINAL_CREATE_SUCCESS,
  TERMINAL_UNREGISTER_FAILURE,
  TERMINAL_UNREGISTER_REQUEST,
  TERMINAL_UNREGISTER_SUCCESS,
  TERMINALS_LOAD_REQUEST,
  TERMINALS_LOAD_SUCCESS,
} from './Devices.actions';

type DefaultState = {
  terminals: HydraDeviceDetails[];
  printers: HydraDeviceDetails[];
  getStoresLoading: boolean;
  isDevicesEmpty: boolean;
  isLoading: boolean;
  stores: [];
  terminalsTotalCount: number;
  printersTotalCount: number;
  terminal: HydraRegistrationRequest;
  error?: any;
};

const defaultState: DefaultState = {
  terminals: [],
  printers: [],
  getStoresLoading: false,
  isDevicesEmpty: false,
  isLoading: false,
  stores: [],
  terminalsTotalCount: 0,
  printersTotalCount: 0,
  terminal: { StoreIds: [0], DeviceName: '', PinCode: 0 },
};

export default function DevicesReducer(state = defaultState, action): DefaultState {
  switch (action.type) {
    case TERMINALS_LOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        ...(action.payload && {
          terminalsTotalCount: 0,
          printersTotalCount: 0,
          printers: [],
          terminals: [],
        }),
        error: undefined,
      };
    case TERMINALS_LOAD_SUCCESS: {
      const terminals = [...state.terminals, ...action.payload.terminals];
      const printers = [...state.printers, ...action.payload.printers];

      return {
        ...state,
        printers,
        terminals,
        isLoading: false,
        printersTotalCount: action.payload.printersTotalCount,
        terminalsTotalCount: action.payload.terminalsTotalCount,
        isDevicesEmpty: printers.length === 0 && terminals.length === 0,
      };
    }
    case TERMINAL_CREATE_SUCCESS: {
      return {
        ...state,
        isDevicesEmpty: false,
        terminals: [...state.terminals, action.payload],
      };
    }
    case GET_STORES_REQUEST:
      return { ...state, getStoresLoading: true };
    case GET_STORES_SUCCESS: {
      const { stores } = action.payload;
      return {
        ...state,
        stores,
        getStoresLoading: false,
      };
    }

    case TERMINAL_UNREGISTER_REQUEST:
      return {
        ...state,
        error: undefined,
      };

    case TERMINAL_UNREGISTER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case TERMINAL_UNREGISTER_SUCCESS:
      return {
        ...state,
        printers: state.printers.filter((item) => item.DeviceId !== action.payload),
        terminals: state.terminals.filter((item) => item.DeviceId !== action.payload),
      };

    default:
      return state;
  }
}
