import {
  App,
  AppCompliance,
  AppConfigUpdateModel,
  DnsRecordInformation,
  IndexPage,
  IndexPageBase,
  Language,
  WebsiteImage,
} from '@flipdish/api-client-typescript';
import { AppExternalServices } from '@flipdish/api-client-typescript/private/api';

import { action, createAsyncAction, dispatchAsync } from '../../actions/utils';
import {
  closeAllOtherNotifications,
  closeNotifySaving,
  ERROR_KEY,
  notifyError,
  notifySaved,
  notifySaving,
  SAVED_KEY,
  SAVING_KEY,
} from '../../layouts/Notify/actions';
import { domainFormatCheck } from './helpers';
import { DNSRecord } from './reducer';
import appConfigService from './service';

//#region setAppConfig
export const SET_APP_CONFIG = 'SET_APP_CONFIG';
export const SET_APP_CONFIG_REQUEST = `${SET_APP_CONFIG}_REQUEST`;
export const SET_APP_CONFIG_SUCCESS = `${SET_APP_CONFIG}_SUCCESS`;
export const SET_APP_CONFIG_FAILURE = `${SET_APP_CONFIG}_FAILURE`;

export type SetAppConfigRequest = ReturnType<typeof setAppConfigRequest>;
export const setAppConfigRequest = () => action(SET_APP_CONFIG_REQUEST);

export const RESET_WEBSITE_STATUS_FIELDS = 'RESET_WEBSITE_STATUS_FIELDS';
export const resetWebsiteStatusFields = () => action(RESET_WEBSITE_STATUS_FIELDS);

export type SetAppConfigRequestSuccess = ReturnType<typeof setAppConfigRequestSuccess>;
export const setAppConfigRequestSuccess = (appId: string, config: App) =>
  action(SET_APP_CONFIG_SUCCESS, { data: config, id: appId });

export type SetAppConfigRequestFailure = ReturnType<typeof setAppConfigRequestFailure>;
export const setAppConfigRequestFailure = () => action(SET_APP_CONFIG_FAILURE);

export const setAppConfig = (config: AppConfigUpdateModel) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(setAppConfigRequest());
      const data = await appConfigService.setAppConfig(appId, config);

      dispatch(setAppConfigRequestSuccess(appId, data));
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
      dispatch(setAppConfigRequestFailure());
    }
  };
};
//#endregion

//#region setAppConfig Host name
export const SET_HOSTNAME_APP_CONFIG = 'SET_HOSTNAME_APP_CONFIG';
export const SET_HOSTNAME_APP_CONFIG_REQUEST = `${SET_HOSTNAME_APP_CONFIG}_REQUEST`;
export const SET_HOSTNAME_APP_CONFIG_SUCCESS = `${SET_HOSTNAME_APP_CONFIG}_SUCCESS`;
export const SET_HOSTNAME_APP_CONFIG_FAILURE = `${SET_HOSTNAME_APP_CONFIG}_FAILURE`;

export type SetHostNameAppConfigRequest = ReturnType<typeof setHostNameAppConfigRequest>;
export const setHostNameAppConfigRequest = () => action(SET_HOSTNAME_APP_CONFIG_REQUEST); // does nothing

export type SetHostNameAppConfigRequestSuccess = ReturnType<
  typeof setHostNameAppConfigRequestSuccess
>;
export const setHostNameAppConfigRequestSuccess = (
  dnsRecords: DNSRecord[],
  isEmbed?: boolean,
  hasSubdomain?: boolean
) =>
  action(SET_HOSTNAME_APP_CONFIG_SUCCESS, {
    dnsRecords,
    isEmbed,
    hasSubdomain,
  });

export type SetHostNameAppConfigRequestFailure = ReturnType<
  typeof setHostNameAppConfigRequestFailure
>;
export const setHostNameAppConfigRequestFailure = () => action(SET_HOSTNAME_APP_CONFIG_FAILURE);

export const setHostNameAppConfig = (
  hostname: string,
  isEmbed?: boolean,
  hasSubdomain?: boolean
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    const dnsRecords = getState().website.dns.map((item) => {
      if (hostname && item.Target === 'websites-api.flipdish.com') {
        return {
          ...item,
          Host: `${item.Host}.${domainFormatCheck(hostname)}`,
        };
      }
      return item;
    });

    try {
      dispatch(notifySaving());
      dispatch(setHostNameAppConfigRequest());
      await appConfigService.setAppHostName(appId, hostname, isEmbed);

      dispatch(setHostNameAppConfigRequestSuccess(dnsRecords, isEmbed, hasSubdomain));
      dispatch(closeNotifySaving());
      dispatch(notifySaved());
    } catch (error) {
      dispatch(closeNotifySaving());
      dispatch(notifyError({ message: error.message }));
      dispatch(setHostNameAppConfigRequestFailure());
    }
  };
};
//#endregion

//#region get App embed state
export const GET_APP_EMBED_STATE = 'GET_APP_EMBED_STATE';
export const GET_APP_EMBED_STATE_REQUEST = 'GET_APP_EMBED_STATE_REQUEST';
export const GET_APP_EMBED_STATE_SUCCESS = 'GET_APP_EMBED_STATE_SUCCESS';
export const GET_APP_EMBED_STATE_FAILURE = 'GET_APP_EMBED_STATE_FAILURE';

export type GetAppEmbedStateRequest = ReturnType<typeof getAppEmbedStateRequest>;
export const getAppEmbedStateRequest = () => action(GET_APP_EMBED_STATE_REQUEST);

export type GetAppEmbedStateSuccess = ReturnType<typeof getAppEmbedStateSuccess>;
export const getAppEmbedStateSuccess = (isEmbed?: boolean) =>
  action(GET_APP_EMBED_STATE_SUCCESS, isEmbed);

export type GetAppEmbedStateFailure = ReturnType<typeof getAppEmbedStateFailure>;
export const getAppEmbedStateFailure = (error) => action(GET_APP_EMBED_STATE_FAILURE, error);

export const getAppEmbedState = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;
    try {
      dispatch(getAppEmbedStateRequest());
      const isEmbed = await appConfigService.getAppEmbeddedStatus(appId);
      dispatch(getAppEmbedStateSuccess(isEmbed));
    } catch (error) {
      dispatch(getAppEmbedStateFailure(error));
    }
  };
};
//#endregion
//#region uploadAppConfigLogo
export const UPLOAD_APP_CONFIG_LOGO = 'UPLOAD_APP_CONFIG_LOGO';
export const UPLOAD_APP_CONFIG_LOGO_REQUEST = `${UPLOAD_APP_CONFIG_LOGO}_REQUEST`;
export const UPLOAD_APP_CONFIG_LOGO_SUCCESS = `${UPLOAD_APP_CONFIG_LOGO}_SUCCESS`;
export const UPLOAD_APP_CONFIG_LOGO_FAILURE = `${UPLOAD_APP_CONFIG_LOGO}_FAILURE`;

export type UploadAppConfigLogoRequest = ReturnType<typeof uploadAppConfigLogoRequest>;
export const uploadAppConfigLogoRequest = () => action(UPLOAD_APP_CONFIG_LOGO_REQUEST);

export type UploadAppConfigLogoRequestSuccess = ReturnType<
  typeof uploadAppConfigLogoRequestSuccess
>;
export const uploadAppConfigLogoRequestSuccess = () => action(UPLOAD_APP_CONFIG_LOGO_SUCCESS);

export type UploadAppConfigLogoRequestFailure = ReturnType<
  typeof uploadAppConfigLogoRequestFailure
>;
export const uploadAppConfigLogoRequestFailure = () => action(UPLOAD_APP_CONFIG_LOGO_FAILURE);

export const uploadAppConfigLogo = (data: FormData) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(uploadAppConfigLogoRequest());

      await appConfigService.uploadAppConfigLogo(appId, data);

      dispatch(uploadAppConfigLogoRequestSuccess());
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
      dispatch(uploadAppConfigLogoRequestFailure());
    }
  };
};
//#endregion

//#region setAppConfigLanguages
export const SET_APP_CONFIG_LANGUAGES = 'SET_APP_CONFIG_LANGUAGES';
export const SET_APP_CONFIG_LANGUAGES_REQUEST = `${SET_APP_CONFIG_LANGUAGES}_REQUEST`;
export const SET_APP_CONFIG_LANGUAGES_SUCCESS = `${SET_APP_CONFIG_LANGUAGES}_SUCCESS`;
export const SET_APP_CONFIG_LANGUAGES_FAILURE = `${SET_APP_CONFIG_LANGUAGES}_FAILURE`;

export type SetAppConfigLanguagesRequest = ReturnType<typeof setAppConfigLanguagesRequest>;
export const setAppConfigLanguagesRequest = () => action(SET_APP_CONFIG_LANGUAGES_REQUEST);

export type SetAppConfigLanguagesRequestSuccess = ReturnType<
  typeof setAppConfigLanguagesRequestSuccess
>;
export const setAppConfigLanguagesRequestSuccess = (languages: Language[]) =>
  action(SET_APP_CONFIG_LANGUAGES_SUCCESS, { languages });

export type SetAppConfigLanguagesRequestFailure = ReturnType<
  typeof setAppConfigLanguagesRequestFailure
>;
export const setAppConfigLanguagesRequestFailure = () => action(SET_APP_CONFIG_LANGUAGES_FAILURE);

export const setAppConfigLanguages = (languages: Language[]) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(setAppConfigLanguagesRequest());

      await appConfigService.setAppConfigLanguages(appId, languages);

      dispatch(setAppConfigLanguagesRequestSuccess(languages));
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
      dispatch(setAppConfigLanguagesRequestFailure());
    }
  };
};
//#endregion

// #region getWebsiteSettings
export const GET_WEBSITE_SETTINGS = 'GET_WEBSITE_SETTINGS';
export const GET_WEBSITE_SETTINGS_REQUEST = `${GET_WEBSITE_SETTINGS}_REQUEST`;
export const GET_WEBSITE_SETTINGS_SUCCESS = `${GET_WEBSITE_SETTINGS}_SUCCESS`;
export const GET_WEBSITE_SETTINGS_FAILURE = `${GET_WEBSITE_SETTINGS}_FAILURE`;

export type GetWebsiteSettingsRequest = ReturnType<typeof getWebsiteSettingsRequest>;
export const getWebsiteSettingsRequest = () => action(GET_WEBSITE_SETTINGS_REQUEST);

export type GetWebsiteSettingsRequestSuccess = ReturnType<typeof getWebsiteSettingsRequestSuccess>;
export const getWebsiteSettingsRequestSuccess = (appId: string, data: IndexPage) =>
  action(GET_WEBSITE_SETTINGS_SUCCESS, data, appId);

export type GetWebsiteSettingsRequestFailure = ReturnType<typeof getWebsiteSettingsRequestFailure>;
export const getWebsiteSettingsRequestFailure = (error) =>
  action(GET_WEBSITE_SETTINGS_FAILURE, error);

export const getWebsiteSettings = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(getWebsiteSettingsRequest());

      const { Data } = await appConfigService.getWebsiteSettings(appId);

      dispatch(getWebsiteSettingsRequestSuccess(appId, Data));
    } catch (error) {
      dispatch(getWebsiteSettingsRequestFailure(error));
    }
  };
};
//#endregion

//#region setWebsiteSettings
export const SET_WEBSITE_SETTINGS = 'SET_WEBSITE_SETTINGS';
export const SET_WEBSITE_SETTINGS_REQUEST = `${SET_WEBSITE_SETTINGS}_REQUEST`;
export const SET_WEBSITE_SETTINGS_SUCCESS = `${SET_WEBSITE_SETTINGS}_SUCCESS`;
export const SET_WEBSITE_SETTINGS_FAILURE = `${SET_WEBSITE_SETTINGS}_FAILURE`;

export type SetWebsiteSettingsRequest = ReturnType<typeof setWebsiteSettingsRequest>;
export const setWebsiteSettingsRequest = () => action(SET_WEBSITE_SETTINGS_REQUEST);

export type SetWebsiteSettingsRequestSuccess = ReturnType<typeof setWebsiteSettingsRequestSuccess>;
export const setWebsiteSettingsRequestSuccess = (appId: string, data: IndexPage) =>
  action(SET_WEBSITE_SETTINGS_SUCCESS, data, appId);

export type SetWebsiteSettingsRequestFailure = ReturnType<typeof setWebsiteSettingsRequestFailure>;
export const setWebsiteSettingsRequestFailure = (error) =>
  action(SET_WEBSITE_SETTINGS_FAILURE, error);

export const setWebsiteSettings = (indexPage: IndexPageBase) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(setWebsiteSettingsRequest());

      const { Data } = await appConfigService.setWebsiteSettings(appId, indexPage);

      dispatch(setWebsiteSettingsRequestSuccess(appId, Data));
    } catch (error) {
      dispatch(setWebsiteSettingsRequestFailure(error));
    }
  };
};
//#endregion

//#region uploadImage
export const UPLOAD_WEBSITE_SETTINGS_IMAGE = 'UPLOAD_WEBSITE_SETTINGS_IMAGE';
export const UPLOAD_WEBSITE_SETTINGS_IMAGE_REQUEST = `${UPLOAD_WEBSITE_SETTINGS_IMAGE}_REQUEST`;
export const UPLOAD_WEBSITE_SETTINGS_IMAGE_SUCCESS = `${UPLOAD_WEBSITE_SETTINGS_IMAGE}_SUCCESS`;
export const UPLOAD_WEBSITE_SETTINGS_IMAGE_FAILURE = `${UPLOAD_WEBSITE_SETTINGS_IMAGE}_FAILURE`;

export type UploadWebsiteSettingsImageRequest = ReturnType<
  typeof uploadWebsiteSettingsImageRequest
>;
export const uploadWebsiteSettingsImageRequest = () =>
  action(UPLOAD_WEBSITE_SETTINGS_IMAGE_REQUEST);

export type UploadWebsiteSettingsImageRequestSuccess = ReturnType<
  typeof uploadWebsiteSettingsImageRequestSuccess
>;
export const uploadWebsiteSettingsImageRequestSuccess = (
  appId: string,
  data: WebsiteImage | WebsiteImage[]
) => action(UPLOAD_WEBSITE_SETTINGS_IMAGE_SUCCESS, data, appId);

export type UploadWebsiteSettingsImageRequestFailure = ReturnType<
  typeof uploadWebsiteSettingsImageRequestFailure
>;
export const uploadWebsiteSettingsImageRequestFailure = (error) =>
  action(UPLOAD_WEBSITE_SETTINGS_IMAGE_FAILURE, error);

export const uploadWebsiteSettingsImage = (
  imageLocation: WebsiteImage.ImageLocationEnum,
  data: FormData | FormData[]
) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      let images;

      dispatch(uploadWebsiteSettingsImageRequest());

      if (Array.isArray(data)) {
        images = await Promise.all(
          data.map((item) => appConfigService.uploadImage(appId, imageLocation, item))
        );
      } else {
        images = await appConfigService.uploadImage(appId, imageLocation, data);
      }

      dispatch(uploadWebsiteSettingsImageRequestSuccess(appId, images));
    } catch (error) {
      dispatch(uploadWebsiteSettingsImageRequestFailure(error));
    }
  };
};
//#endregion

//#region deleteImage
export const DELETE_WEBSITE_SETTINGS_IMAGE = 'DELETE_WEBSITE_SETTINGS_IMAGE';
export const DELETE_WEBSITE_SETTINGS_IMAGE_REQUEST = `${DELETE_WEBSITE_SETTINGS_IMAGE}_REQUEST`;
export const DELETE_WEBSITE_SETTINGS_IMAGE_SUCCESS = `${DELETE_WEBSITE_SETTINGS_IMAGE}_SUCCESS`;
export const DELETE_WEBSITE_SETTINGS_IMAGE_FAILURE = `${DELETE_WEBSITE_SETTINGS_IMAGE}_FAILURE`;

export type DeleteWebsiteSettingsImageRequest = ReturnType<
  typeof deleteWebsiteSettingsImageRequest
>;
export const deleteWebsiteSettingsImageRequest = () =>
  action(DELETE_WEBSITE_SETTINGS_IMAGE_REQUEST);

export type DeleteWebsiteSettingsImageRequestSuccess = ReturnType<
  typeof deleteWebsiteSettingsImageRequestSuccess
>;
export const deleteWebsiteSettingsImageRequestSuccess = (
  appId: string,
  imageId: number | number[]
) => action(DELETE_WEBSITE_SETTINGS_IMAGE_SUCCESS, imageId, appId);

export type DeleteWebsiteSettingsImageRequestFailure = ReturnType<
  typeof deleteWebsiteSettingsImageRequestFailure
>;
export const deleteWebsiteSettingsImageRequestFailure = (error) =>
  action(DELETE_WEBSITE_SETTINGS_IMAGE_FAILURE, error);

export const deleteWebsiteSettingsImage = (imageId: number | number[]) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(deleteWebsiteSettingsImageRequest());

      if (Array.isArray(imageId)) {
        await Promise.all(imageId.map((id) => appConfigService.deleteImage(appId, id)));
      } else {
        await appConfigService.deleteImage(appId, imageId);
      }

      dispatch(deleteWebsiteSettingsImageRequestSuccess(appId, imageId));
    } catch (error) {
      dispatch(deleteWebsiteSettingsImageRequestFailure(error));
    }
  };
};
//#endregion

//#region getDNSStatus
export const GET_DNS_STATUS = 'GET_DNS_STATUS';
export const GET_DNS_STATUS_REQUEST = `${GET_DNS_STATUS}_REQUEST`;
export const GET_DNS_STATUS_SUCCESS = `${GET_DNS_STATUS}_SUCCESS`;
export const GET_DNS_STATUS_FAILURE = `${GET_DNS_STATUS}_FAILURE`;

export type GetDNSStatusRequest = ReturnType<typeof getDNSStatusRequest>;
export const getDNSStatusRequest = () => action(GET_DNS_STATUS_REQUEST);

export type GetDNSStatusRequestSuccess = ReturnType<typeof getDNSStatusRequestSuccess>;
export const getDNSStatusRequestSuccess = (appId: string, data: DnsRecordInformation) =>
  action(GET_DNS_STATUS_SUCCESS, data, appId);

export type GetDNSStatusRequestFailure = ReturnType<typeof getDNSStatusRequestFailure>;
export const getDNSStatusRequestFailure = (error) => action(GET_DNS_STATUS_FAILURE, error);

export const getDNSStatus = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(getDNSStatusRequest());

      const { Data } = await appConfigService.getDNSStatus(appId);

      dispatch(getDNSStatusRequestSuccess(appId, Data));
    } catch (error) {
      dispatch(getDNSStatusRequestFailure(error));
    }
  };
};
//#endregion

//#region checkDNS
export const CHECK_DNS = 'CHECK_DNS';
export const CHECK_DNS_REQUEST = `${CHECK_DNS}_REQUEST`;
export const CHECK_DNS_SUCCESS = `${CHECK_DNS}_SUCCESS`;
export const CHECK_DNS_FAILURE = `${CHECK_DNS}_FAILURE`;

export type CheckDNSRequest = ReturnType<typeof checkDNSRequest>;
export const checkDNSRequest = () => action(CHECK_DNS_REQUEST);

export type CheckDNSRequestSuccess = ReturnType<typeof checkDNSRequestSuccess>;
export const checkDNSRequestSuccess = () => action(CHECK_DNS_SUCCESS);

export type CheckDNSRequestFailure = ReturnType<typeof checkDNSRequestFailure>;
export const checkDNSRequestFailure = (error) => action(CHECK_DNS_FAILURE, error);

export const checkDNS = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(checkDNSRequest());

      await appConfigService.checkDNS(appId);

      dispatch(checkDNSRequestSuccess());

      dispatch(getDNSStatus());
    } catch (error) {
      dispatch(checkDNSRequestFailure(error));
    }
  };
};
//#endregion

//#region reset DNS Records
export const RESET_DNS_RECORDS = 'RESET_DNS_RECORDS';
export const resetDnsRecords = () => action(RESET_DNS_RECORDS);
//#endregion

//#region Website/Settings
//#region Google Analytics, Facebook Pixel
export const GET_TRACKING_CODES_REQUEST = 'GET_TRACKING_CODES_REQUEST';
export const GET_TRACKING_CODES_SUCCESS = 'GET_TRACKING_CODES_SUCCESS';
export const GET_TRACKING_CODES_FAILURE = 'GET_TRACKING_CODES_FAILURE';

export type GetTrackingCodesRequest = ReturnType<typeof getTrackingCodesRequest>;
export const getTrackingCodesRequest = () => action(GET_TRACKING_CODES_REQUEST);

export const getTrackingCodesSuccess = (payload: AppExternalServices) =>
  action(GET_TRACKING_CODES_SUCCESS, payload);

export const getTrackingCodesFailure = (payload) => action(GET_TRACKING_CODES_FAILURE, payload);

export const getTrackingCodes = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(getTrackingCodesRequest());
      const response = await appConfigService.getTrackingCodes(appId);
      dispatch(getTrackingCodesSuccess(response.Data));
    } catch (error) {
      dispatch(getTrackingCodesFailure(error));
    }
  };
};

export const SET_TRACKING_CODES_REQUEST = 'SET_TRACKING_CODES_REQUEST';
export const SET_TRACKING_CODES_SUCCESS = 'SET_TRACKING_CODES_SUCCESS';
export const SET_TRACKING_CODES_FAILURE = 'SET_TRACKING_CODES_FAILURE';

export const setTrackingCodesSuccess = (payload: AppExternalServices) =>
  action(SET_TRACKING_CODES_SUCCESS, payload);

export const setTrackingCodesFailure = (payload) => action(SET_TRACKING_CODES_FAILURE, payload);

export const setTrackingCodes = (trackingCodes: AppExternalServices) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;
    const appExternalServices = { ...trackingCodes, AppId };
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());
      await appConfigService.setTrackingCodes(AppId, appExternalServices);
      dispatch(setTrackingCodesSuccess(trackingCodes));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (error) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(setTrackingCodesFailure(error));
      dispatch(notifyError(error));
    }
  };
};
//#endregion
//#region SMS Restaurant name
export const GET_SMS_RESTAURANT_NAME_REQUEST = 'GET_SMS_RESTAURANT_NAME_REQUEST';
export const GET_SMS_RESTAURANT_NAME_SUCCESS = 'GET_SMS_RESTAURANT_NAME_SUCCESS';
export const GET_SMS_RESTAURANT_NAME_FAILURE = 'GET_SMS_RESTAURANT_NAME_FAILURE';

export const getSMSRestaurantNameRequest = () => action(GET_SMS_RESTAURANT_NAME_REQUEST);

export const getSMSRestaurantNameSuccess = (payload: string) =>
  action(GET_SMS_RESTAURANT_NAME_SUCCESS, payload);

export const getSMSRestaurantNameFailure = (payload) =>
  action(GET_SMS_RESTAURANT_NAME_FAILURE, payload);

export const getSMSRestaurantName = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const appId = getState().currentApp.AppId as string;

    try {
      dispatch(getSMSRestaurantNameRequest());
      const response = await appConfigService.getsetSMSRestaurantName(appId);
      dispatch(getSMSRestaurantNameSuccess(response.Data));
    } catch (error) {
      dispatch(getTrackingCodesFailure(error));
    }
  };
};

export const SET_SMS_RESTAURANT_NAME_REQUEST = 'SET_SMS_RESTAURANT_NAME_REQUEST';
export const SET_SMS_RESTAURANT_NAME_SUCCESS = 'SET_SMS_RESTAURANT_NAME_SUCCESS';
export const SET_SMS_RESTAURANT_NAME_FAILURE = 'SET_SMS_RESTAURANT_NAME_FAILURE';

export const setSMSRestaurantNameRequest = () => action(SET_SMS_RESTAURANT_NAME_REQUEST);

export const setSMSRestaurantNameSuccess = (payload: string) =>
  action(SET_SMS_RESTAURANT_NAME_SUCCESS, payload);

export const setSMSRestaurantNameFailure = (payload) =>
  action(SET_SMS_RESTAURANT_NAME_FAILURE, payload);

export const setSMSRestaurantName = (smsRestaurantName: string) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const AppId = getState().currentApp.AppId as string;
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());
      await appConfigService.setSMSRestaurantName(AppId, smsRestaurantName);
      dispatch(setSMSRestaurantNameSuccess(smsRestaurantName));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (error) {
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(setSMSRestaurantNameFailure(error));
      dispatch(notifyError(error));
    }
  };
};

//#endregion

const ROOT_ACTION_NAME = 'WEB_SITE_AND_APP_SETTINGS';

export const webSiteAndAppActions = {
  getCompliance: createAsyncAction<AppCompliance>(`${ROOT_ACTION_NAME}:GET_COMPLIANCE`),
  setCompliance: createAsyncAction<AppCompliance>(`${ROOT_ACTION_NAME}:SET_COMPLIANCE`),
};

const getCompliance = () => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(dispatch, webSiteAndAppActions.getCompliance, async () => {
      return await appConfigService.getCompliance(appId);
    });
  };
};

const setCompliance = (complianceType: AppCompliance.ComplianceTypeEnum) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;

    await dispatchAsync(
      dispatch,
      webSiteAndAppActions.setCompliance,
      async () => {
        return await appConfigService.setCompliance(appId, complianceType);
      },
      {
        notifyOptions: [
          {
            type: 'saving',
          },
        ],
      }
    );
  };
};

export const webSiteAndAppActionsAsync = {
  getCompliance,
  setCompliance,
};
