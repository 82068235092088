import React, { useCallback, useEffect, useState } from 'react';

import usePrevious from '@fd/customHooks/usePrevious';
import { OnboardingItemUpdate } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { generalConstants } from '../../constants/general.constants';
import { notifyError } from '../../layouts/Notify/actions';
import ErrorScreen from '../../layouts/Portal/ErrorScreen';
import ScrollToTop from '../../layouts/Portal/ScrollToTop';
import SideNav from '../../layouts/Portal/SideNav';
import TopNav from '../../layouts/Portal/TopNav';
import { permissionsSelector } from '../../selectors/permissions.selector';
import { logger } from '../../services/loggerService';
import PageLayout from '../../ui/Layout';
import useTutorialData from '../Tutorial/hooks/useTutorialData';
import useTutorialStore from '../Tutorial/hooks/useTutorialStore';
import { tutorialService } from '../Tutorial/tutorial.service';
import NextBtn from './components/NextBtn';
import {
  addBankAccountStepId,
  bankAccountListStepId,
  bankingListSearch,
  findItemById,
  getCurrentStep,
  getFirstIncompleteItemId,
  onboardingRoutes,
  onboardingWizardMileStoneId,
} from './helpers';

const { SCROLLABLE_CONTAINER } = generalConstants;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '75vh',
    paddingLeft: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  switch: {
    zIndex: 1,
    flexGrow: 1,
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column',
    '-webkit-overflow-scrolling': 'touch',
  },
  nextBtnWrapper: {
    margin: theme.spacing(2, 2, 2, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  error: {
    margin: theme.spacing(2),
  },
}));

const OnboardingSetup = (props: MappedState & MappedDispatch) => {
  const { AppId, dispatchNotifyError, translate, hasOnBoardingPermissions } = props;

  const classes = useStyles();
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  // This part is to get the storeId and to pass it to the useTutorialData hook
  // We will need to think of a better way to get storeId, maybe we add only the storeIds that user has access to in the redux store
  // BE would need to send us the storeIds that teammates have access
  // But that is for future work.
  const {
    data: storeData,
    isLoading: storeLoading,
    isFetching: storeFetching,
  } = useTutorialStore(AppId);

  const storeId = storeData?.StoreId;
  const { data, isLoading, isFetching } = useTutorialData(
    AppId,
    storeId,
    onboardingWizardMileStoneId,
    hasOnBoardingPermissions
  );

  const loadingFromApi = storeLoading || storeFetching || isLoading || isFetching;
  const [open, setOpen] = useState(false);

  const toggleSideNav = () => setOpen((prevOpen) => !prevOpen);

  const [currentStep, setCurrentStep] = useState<{ id?: number; hash?: number }>({
    id: getCurrentStep(location.search),
  });

  const currentRoute = onboardingRoutes.find((route) => route.step === currentStep?.id);
  const prevCurrentRoute = usePrevious(currentRoute);

  useEffect(() => {
    // abort if using location based step selection (for support etc)
    if (data && !location.search) {
      const id = getFirstIncompleteItemId(data);
      id && setCurrentStep({ id });
    } else if (data && location.search === `?${bankingListSearch}`) {
      if (
        findItemById(data, addBankAccountStepId)?.Status !==
        OnboardingItemUpdate.StatusEnum.Completed
      ) {
        updateOnboardingItemStatus(addBankAccountStepId, OnboardingItemUpdate.StatusEnum.Completed);
      }
    } else if (data && location.search) {
      const currentStep = onboardingRoutes.find((route) => `?${route.search}` === location.search);
      currentStep?.step && setCurrentStep({ id: currentStep.step, hash: Math.random() });
    }
  }, [data, location.search]);

  useEffect(() => {
    if (data && currentRoute && (prevCurrentRoute !== currentRoute || !location.search)) {
      history.push({
        search: currentRoute.search,
        pathname: `/${AppId}/onboarding/${currentRoute.path}`,
      });
    }
  }, [currentRoute, data, currentStep]);

  const mutation = useMutation({
    mutationFn: ({
      onboardingItemId,
      status,
    }: {
      onboardingItemId: number;
      status: OnboardingItemUpdate;
    }) => tutorialService.updateTutorialItem(AppId, storeId ?? 0, onboardingItemId, status),

    onSuccess: () => {
      // Invalidate query to trigger refetch
      queryClient.invalidateQueries({
        queryKey: [tutorialService.getTutorialKey, AppId, storeId],
      });
    },

    onError: () => {
      dispatchNotifyError();
    },
  });

  const updateOnboardingItemStatus = useCallback(
    async (onboardingItemId: any, status: any) => {
      await mutation.mutateAsync({ onboardingItemId, status });
    },
    [mutation]
  );

  const navigateToNextStep = useCallback(() => {
    const currentItem = findItemById(data, currentStep?.id);
    if (currentItem?.Status && currentItem.Status !== OnboardingItemUpdate.StatusEnum.Completed) {
      updateOnboardingItemStatus(currentStep?.id, OnboardingItemUpdate.StatusEnum.Completed);
    }
    const currentStepIndex = onboardingRoutes.findIndex((route) => route.step === currentStep.id);
    // TODO: Refactor this to use data order over route order
    let nextStep = onboardingRoutes?.[currentStepIndex + 1]?.step || onboardingRoutes[0].step;
    if (
      nextStep === addBankAccountStepId &&
      findItemById(data, addBankAccountStepId)?.Status === OnboardingItemUpdate.StatusEnum.Completed
    ) {
      nextStep = bankAccountListStepId;
    }
    setCurrentStep({ id: nextStep });
  }, [currentStep, updateOnboardingItemStatus, data]);

  const getLoadingOrError = () => {
    if (loadingFromApi) {
      return (
        <div className={classes.loading}>
          <CircularProgress size={100} />
        </div>
      );
    } else {
      logger.error(`onboarding Error Boundary`, {
        message: `No data displayed for onboarding - AppId: ${AppId} - StoreId: ${storeId} - MilestoneId: ${onboardingWizardMileStoneId}`,
      });
      return <ErrorScreen translate={translate} />;
    }
  };

  return (
    <div className={classes.root}>
      <TopNav
        open={open}
        toggleSideNav={toggleSideNav}
        tutorialIsOpen={false}
        setTutorialIsOpen={() => false}
      />

      <div className={classes.wrapper}>
        <SideNav open={open} toggleSideNav={toggleSideNav} />
        <main className={classes.content}>
          <div className={classes.switch} id={SCROLLABLE_CONTAINER}>
            {!location.search || loadingFromApi ? (
              getLoadingOrError()
            ) : (
              <ScrollToTop onMount>
                <PageLayout title={''}>
                  <div className={classes.container}>
                    {currentRoute?.value}

                    {currentRoute?.disableNextBtn ? (
                      ''
                    ) : (
                      <Grid container justifyContent="flex-end">
                        <Box className={classes.nextBtnWrapper}>
                          <NextBtn navigateToNextStep={navigateToNextStep} />
                        </Box>
                      </Grid>
                    )}
                  </div>
                </PageLayout>
              </ScrollToTop>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (state: AppState) => {
  const getOnBoardingPermissions = permissionsSelector.hasPermissionFactory(['Onboarding']);
  return (state: AppState) => {
    return {
      AppId: state.currentApp.AppId!,
      permissions: state.permissions,
      translate: getTranslate(state.locale),
      hasOnBoardingPermissions: getOnBoardingPermissions(state),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    dispatchNotifyError: () =>
      dispatch(notifyError({ message: 'Something_went_wrong', translate: true })),
  };
}

export default connect(mapStateToPropsFactory, mapDispatchToProps)(OnboardingSetup);
