import React, { useState } from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { generalConstants } from '../../../constants/general.constants';
import ScrollToTop from '../../../layouts/Portal/ScrollToTop';
import SideNav from '../../../layouts/Portal/SideNav';
import TopNav from '../../../layouts/Portal/TopNav';
import PageLayout from '../../../ui/Layout';
import { onboardingRoutes, parseChannelsToSideMenuItems } from '../helpers';
import BankingDetailsForOnboarding from './BankingDetailsForOnboarding';

const { SCROLLABLE_CONTAINER } = generalConstants;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '75vh',
    paddingLeft: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  switch: {
    zIndex: 1,
    flexGrow: 1,
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column',
    '-webkit-overflow-scrolling': 'touch',
  },
}));

const OnboardingComponentWrapper = (props: MappedState) => {
  const { AppId, permissions } = props;
  const classes = useStyles();
  //   const queryClient = useQueryClient();
  const { accountId } = useParams<{ accountId: string }>();

  const [open, setOpen] = useState(false);

  const toggleSideNav = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const [currentStep, setCurrentStep] = useState<number>(0);

  const currentRoute = onboardingRoutes[currentStep];

  return (
    <div className={classes.root}>
      <TopNav
        open={open}
        toggleSideNav={toggleSideNav}
        tutorialIsOpen={false}
        setTutorialIsOpen={() => false}
      />

      <div className={classes.wrapper}>
        <SideNav
          open={open}
          toggleSideNav={toggleSideNav}
          onboardingSideMenuItems={parseChannelsToSideMenuItems(onboardingRoutes, props.AppId)}
          onClickSetCurrentStep={(step: number) => setCurrentStep(step)}
        />

        <main className={classes.content}>
          <div className={classes.switch} id={SCROLLABLE_CONTAINER}>
            <ScrollToTop onMount>
              <PageLayout title={''}>
                <div className={classes.container}>
                  <BankingDetailsForOnboarding accountPropId={accountId} />
                </div>
              </PageLayout>
            </ScrollToTop>
          </div>
        </main>
      </div>
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  return {
    AppId: state.currentApp.AppId!,
    permissions: state.permissions,
  };
}

export default connect(mapStateToProps, null)(OnboardingComponentWrapper);
