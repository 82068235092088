import {
  HttpRequestAndResponseLog,
  OAuthApp,
  OauthClientRedirectUri,
  OAuthTokenModel,
  RestApiPaginationResultHttpRequestAndResponseLog,
  RestApiPaginationResultWebhookLog,
  RestApiPaginationResultWebhookSubscription,
} from '@flipdish/api-client-typescript';

import { developersConstants } from '../../constants/developers.constants';
import {
  CREATE_OAUTHAPP_ACCESS_TOKEN_FAILURE,
  CREATE_OAUTHAPP_ACCESS_TOKEN_REQUEST,
  CREATE_OAUTHAPP_ACCESS_TOKEN_SUCCESS,
  CREATE_OAUTHAPP_FAILURE,
  CREATE_OAUTHAPP_REQUEST,
  CREATE_OAUTHAPP_SUCCESS,
  CREATE_REDIRECT_URI_REQUEST,
  CREATE_REDIRECT_URI_SUCCESS,
  CREATE_WEBHOOK_EVENTNAME_SUCCESS,
  CREATE_WEBHOOK_FAILURE,
  CREATE_WEBHOOK_REQUEST,
  CREATE_WEBHOOK_SUCCESS,
  DELETE_REDIRECT_URI_FAILURE,
  DELETE_REDIRECT_URI_REQUEST,
  DELETE_REDIRECT_URI_SUCCESS,
  DELETE_WEBHOOK_EVENTNAME_SUCCESS,
  DELETE_WEBHOOK_FAILURE,
  DELETE_WEBHOOK_REQUEST,
  DELETE_WEBHOOK_SUCCESS,
  GET_ALL_WEBHOOK_EVENTNAMES_FAILURE,
  GET_ALL_WEBHOOK_EVENTNAMES_REQUEST,
  GET_ALL_WEBHOOK_EVENTNAMES_SUCCESS,
  GET_INTERACTION_LOG_FAILURE,
  GET_INTERACTION_LOG_REQUEST,
  GET_INTERACTION_LOG_SUCCESS,
  GET_INTERACTION_LOGS_FAILURE,
  GET_INTERACTION_LOGS_REQUEST,
  GET_INTERACTION_LOGS_SUCCESS,
  GET_OAUTHAPP_AUTH_TOKENS_FAILURE,
  GET_OAUTHAPP_AUTH_TOKENS_REQUEST,
  GET_OAUTHAPP_AUTH_TOKENS_SUCCESS,
  GET_OAUTHAPP_BY_ID_REQUEST,
  GET_OAUTHAPP_BY_ID_SUCCESS,
  GET_OAUTHAPP_REDIRECT_URIS_FAILURE,
  GET_OAUTHAPP_REDIRECT_URIS_REQUEST,
  GET_OAUTHAPP_REDIRECT_URIS_SUCCESS,
  GET_OAUTHAPP_SECRET_KEY_SUCCESS,
  GET_OAUTHAPP_WEBHOOKS_FAILURE,
  GET_OAUTHAPP_WEBHOOKS_REQUEST,
  GET_OAUTHAPP_WEBHOOKS_SUCCESS,
  GET_OAUTHAPPS_FAILURE,
  GET_OAUTHAPPS_REQUEST,
  GET_OAUTHAPPS_SUCCESS,
  GET_WEBHOOK_LOGS_FAILURE,
  GET_WEBHOOK_LOGS_REQUEST,
  GET_WEBHOOK_LOGS_SUCCESS,
  RESET_WEBHOOK_ERROR,
  REVOKE_OAUTHAPP_ACCESS_TOKEN_FAILURE,
  REVOKE_OAUTHAPP_ACCESS_TOKEN_REQUEST,
  REVOKE_OAUTHAPP_ACCESS_TOKEN_SUCCESS,
  SET_INTERACTION_LOG,
  UPDATE_WEBHOOK_FAILURE,
  UPDATE_WEBHOOK_REQUEST,
  UPDATE_WEBHOOK_SUCCESS,
} from './actions/index';

interface State {
  oauthApps: OAuthApp[];
  interactionLogs: RestApiPaginationResultHttpRequestAndResponseLog;
  interactionLog?: HttpRequestAndResponseLog;
  oauthApp: OAuthApp & {
    secretKey?: string;
  };
  loghistory?: any;
  webhookLogs: RestApiPaginationResultWebhookLog;
  isLoading?: boolean;
  webhooks: RestApiPaginationResultWebhookSubscription & {
    isProcessing: boolean;
    allEventNames: string[];
    apiError?: string;
  };
  redirectUris: OauthClientRedirectUri[];
  error?: string;
  authTokens: {
    Limit: number;
    Page: number;
    TotalRecordCount: number;
    Data: OAuthTokenModel[];
  };
  newAuthToken?: string;
}

const defaultState: State = {
  oauthApps: [],
  oauthApp: {},
  interactionLogs: {
    Page: 1,
    Limit: 1,
    TotalRecordCount: 100,
    Data: [],
  },
  webhooks: {
    Data: [],
    Page: 1,
    Limit: 25,
    TotalRecordCount: 0,
    allEventNames: [],
    isProcessing: false,
  },
  isLoading: false,
  redirectUris: [],
  authTokens: {
    Limit: 25,
    Page: 1,
    TotalRecordCount: 0,
    Data: [],
  },
  webhookLogs: {
    Page: 1,
    Limit: 1,
    TotalRecordCount: 100,
    Data: [],
  },
};

const { LOGHISTORY_REQUEST, LOGHISTORY_SUCCESS } = developersConstants;

export function developers(state = defaultState, action): State {
  let index;

  switch (action.type) {
    case LOGHISTORY_REQUEST:
      return {
        ...state,
      };
    case LOGHISTORY_SUCCESS: {
      return {
        ...state,
        loghistory: action.loghistory,
      };
    }
    case REVOKE_OAUTHAPP_ACCESS_TOKEN_REQUEST:
    case CREATE_OAUTHAPP_ACCESS_TOKEN_REQUEST:
    case GET_OAUTHAPP_AUTH_TOKENS_REQUEST:
    case DELETE_REDIRECT_URI_REQUEST:
    case CREATE_REDIRECT_URI_REQUEST:
    case CREATE_OAUTHAPP_REQUEST:
    case GET_OAUTHAPP_BY_ID_REQUEST:
    case GET_INTERACTION_LOGS_REQUEST:
    case GET_OAUTHAPP_WEBHOOKS_REQUEST:
    case GET_WEBHOOK_LOGS_REQUEST:
    case GET_OAUTHAPPS_REQUEST:
    case GET_OAUTHAPP_REDIRECT_URIS_REQUEST:
    case GET_INTERACTION_LOG_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_OAUTHAPPS_SUCCESS: {
      return {
        ...state,
        oauthApps: action.payload.oauthApps,
        isLoading: false,
      };
    }
    case GET_OAUTHAPPS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case GET_OAUTHAPP_BY_ID_SUCCESS: {
      return {
        ...state,
        oauthApp: { ...state.oauthApp, ...action.payload.oauthApp },
        isLoading: false,
      };
    }
    case GET_OAUTHAPP_REDIRECT_URIS_SUCCESS: {
      return {
        ...state,
        oauthApp: {
          ...state.oauthApp,
        },
        isLoading: false,
        redirectUris: action.payload.redirectUris,
      };
    }
    case GET_OAUTHAPP_SECRET_KEY_SUCCESS: {
      return {
        ...state,
        oauthApp: {
          ...state.oauthApp,
          secretKey: action.payload.secretKey,
        },
      };
    }
    case CREATE_REDIRECT_URI_SUCCESS: {
      return {
        ...state,
        redirectUris: [...state.redirectUris, action.payload.uri],
        isLoading: false,
      };
    }
    case REVOKE_OAUTHAPP_ACCESS_TOKEN_FAILURE:
    case CREATE_OAUTHAPP_ACCESS_TOKEN_FAILURE:
    case GET_OAUTHAPP_AUTH_TOKENS_FAILURE:
    case DELETE_REDIRECT_URI_FAILURE:
    case CREATE_OAUTHAPP_SUCCESS:
    case CREATE_OAUTHAPP_FAILURE:
    case GET_OAUTHAPP_WEBHOOKS_FAILURE:
    case GET_WEBHOOK_LOGS_FAILURE:
    case GET_OAUTHAPP_REDIRECT_URIS_FAILURE:
    case GET_INTERACTION_LOG_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case DELETE_REDIRECT_URI_SUCCESS: {
      return {
        ...state,
        redirectUris: state.redirectUris.filter((uri) => uri.Id !== action.payload.uriId),
        isLoading: false,
      };
    }
    case GET_INTERACTION_LOGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        interactionLogs: action.payload,
      };
    }
    case GET_INTERACTION_LOGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_OAUTHAPP_AUTH_TOKENS_SUCCESS: {
      return {
        ...state,
        authTokens: action.payload,
        isLoading: false,
      };
    }
    case REVOKE_OAUTHAPP_ACCESS_TOKEN_SUCCESS: {
      const tokenKey = action.payload;
      const authTokens = state.authTokens.Data.filter((t) => t.Key !== tokenKey);

      return {
        ...state,
        authTokens: {
          ...state.authTokens,
          Data: authTokens,
          TotalRecordCount: state.authTokens.TotalRecordCount - 1,
        },
        isLoading: false,
      };
    }

    case CREATE_OAUTHAPP_ACCESS_TOKEN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        newAuthToken: action.payload.authToken,
      };
    }
    case GET_OAUTHAPP_WEBHOOKS_SUCCESS: {
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    case CREATE_WEBHOOK_REQUEST:
    case UPDATE_WEBHOOK_REQUEST:
    case DELETE_WEBHOOK_REQUEST:
    case GET_ALL_WEBHOOK_EVENTNAMES_REQUEST:
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          isProcessing: true,
        },
      };
    case UPDATE_WEBHOOK_SUCCESS:
      index = state.webhooks.Data.findIndex((item) => item.Id === action.payload.Id);

      if (index >= 0) {
        return {
          ...state,
          webhooks: {
            ...state.webhooks,
            Data: [
              ...state.webhooks.Data.slice(0, index),
              action.payload,
              ...state.webhooks.Data.slice(index + 1),
            ],
            isProcessing: false,
            apiError: undefined,
          },
        };
      }

      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          isProcessing: false,
        },
      };
    case CREATE_WEBHOOK_FAILURE:
    case UPDATE_WEBHOOK_FAILURE:
    case DELETE_WEBHOOK_FAILURE:
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          isProcessing: false,
          apiError: action.payload?.[0]?.Errors?.[0] ?? 'error',
        },
      };
    case CREATE_WEBHOOK_SUCCESS:
    case DELETE_WEBHOOK_SUCCESS:
    case GET_ALL_WEBHOOK_EVENTNAMES_FAILURE:
    case RESET_WEBHOOK_ERROR:
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          isProcessing: false,
          apiError: undefined,
        },
      };
    case GET_ALL_WEBHOOK_EVENTNAMES_SUCCESS:
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          allEventNames: action.payload,
          isProcessing: false,
        },
      };
    case GET_WEBHOOK_LOGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        webhookLogs: action.payload,
      };
    }
    case GET_INTERACTION_LOG_SUCCESS:
    case SET_INTERACTION_LOG: {
      return {
        ...state,
        isLoading: false,
        interactionLog: action.payload,
      };
    }
    case DELETE_WEBHOOK_EVENTNAME_SUCCESS:
      index = state.webhooks.Data.findIndex((item) => item.Id === action.payload.id);

      if (index >= 0) {
        const webhook = state.webhooks.Data[index];
        const eventNames = webhook.EventNames || [];
        const eventIndex = eventNames.indexOf(action.payload.name);

        if (eventIndex >= 0) {
          return {
            ...state,
            webhooks: {
              ...state.webhooks,
              Data: [
                ...state.webhooks.Data.slice(0, index),
                {
                  ...webhook,
                  EventNames: [
                    ...eventNames.slice(0, eventIndex),
                    ...eventNames.slice(eventIndex + 1),
                  ],
                },
                ...state.webhooks.Data.slice(index + 1),
              ],
              isProcessing: false,
            },
          };
        }
      }

      return state;

    case CREATE_WEBHOOK_EVENTNAME_SUCCESS:
      index = state.webhooks.Data.findIndex((item) => item.Id === action.payload.id);

      if (index >= 0) {
        const webhook = state.webhooks.Data[index];
        const eventNames = webhook.EventNames || [];

        return {
          ...state,
          webhooks: {
            ...state.webhooks,
            Data: [
              ...state.webhooks.Data.slice(0, index),
              {
                ...webhook,
                EventNames: [...eventNames, action.payload.name],
              },
              ...state.webhooks.Data.slice(index + 1),
            ],
            isProcessing: false,
          },
        };
      }

      return state;
    default:
      return state;
  }
}
