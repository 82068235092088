export const getLatLngOfPositionOnMap = (position, map) => {
  const mapBounds = map.getBounds();
  if (!mapBounds) {
    return new google.maps.LatLng(0, 0);
  }
  const northEast = mapBounds.getNorthEast();
  const southWest = mapBounds.getSouthWest();
  const markerLat = northEast.lat() - (northEast.lat() - southWest.lat()) * position.y;
  const markerLng = northEast.lng() - (northEast.lng() - southWest.lng()) * (1 - position.x);
  return new google.maps.LatLng(markerLat, markerLng);
};

export const getLatLngOffset = (location1, location2, map) => {
  const worldCoordsLocation1 = convertLatLngToWorldPoint(location1, map);
  const worldCoordsLocation2 = convertLatLngToWorldPoint(location2, map);
  return new google.maps.Point(
    worldCoordsLocation2.x - worldCoordsLocation1.x,
    worldCoordsLocation2.y - worldCoordsLocation1.y
  );
};

export const applyLatLngOffset = (location, offset, map) => {
  const worldCoords = convertLatLngToWorldPoint(location, map);
  const offsetPoint = new google.maps.Point(worldCoords.x + offset.x, worldCoords.y + offset.y);
  return convertWorldPointToLatLng(offsetPoint, map);
};

export const convertLatLngToWorldPoint = (latLng, map) => {
  const projection = map.getProjection();
  if (!projection) {
    return new google.maps.Point(0, 0);
  }
  return projection.fromLatLngToPoint(latLng);
};

export const convertWorldPointToLatLng = (worldPoint, map) => {
  const projection = map.getProjection();
  if (!projection) {
    return new google.maps.LatLng(0, 0);
  }
  return projection.fromPointToLatLng(worldPoint);
};
