import React from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from 'react-loading-skeleton';

import { HORIZONTAL_SPACE_CLASSNAME } from '../../../../ui/Layout';

const LoadingListItem: React.FC<React.PropsWithChildren<{}>> = () => {
  return (
    <ListItem
      component="div"
      disableGutters
      className={HORIZONTAL_SPACE_CLASSNAME}
      data-fd="loading-list-item"
    >
      <ListItemAvatar>
        <Skeleton circle width={40} height={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton width={'60%'} height={18} />}
        secondary={<Skeleton width={'50%'} height={14} />}
      />
    </ListItem>
  );
};

export default LoadingListItem;
