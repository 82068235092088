import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { type Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useMutation } from '@tanstack/react-query';
import { connect } from 'react-redux';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../../layouts/Notify/actions';
import { checkout } from '../../../../services/checkout.service';

const useStyles = makeStyles((theme: Theme) => ({
  cancel: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  productId: string;
  subscriptionId: string;
  refreshSubscription: () => void;
} & MappedDispatch &
  MappedState;

const ActionsButton = ({
  refreshSubscription,
  appId,
  closeNotifySaving,
  notifyError,
  notifySaving,
  notifySaved,
  subscriptionId,
  productId,
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { mutateAsync, isPending: isUpdatingSubscription } = useMutation({
    mutationFn: () => {
      notifySaving();
      return checkout(appId, subscriptionId, {
        removedItems: [productId],
      });
    },

    onSuccess: () => {
      closeNotifySaving();
      notifySaved();
      refreshSubscription();
    },
    onError: () => {
      closeNotifySaving();
      notifyError({ message: 'Something_went_wrong', translate: true });
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelProduct = async () => {
    handleClose();
    await mutateAsync();
  };

  return (
    <>
      <IconButton
        aria-label="delete"
        size="large"
        color="default"
        aria-controls={open ? `more-menu-${productId}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-fd={`more-button-${productId}`}
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <Menu
        id={`more-menu-${productId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-fd={`more-menu-${productId}`}
      >
        <MenuItem
          onClick={handleCancelProduct}
          className={classes.cancel}
          disabled={isUpdatingSubscription}
          data-fd={`cancel-product-button-${productId}`}
        >
          Cancel product
        </MenuItem>
      </Menu>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifySaving: () => dispatch(notifySaving()),
  notifySaved: () => dispatch(notifySaved()),
  closeNotifySaving: () => dispatch(closeNotifySaving()),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp!.AppId!,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsButton);
