import React from 'react';

import { AppStoreApp } from '@flipdish/api-client-typescript';
import { Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

import { ExternalLink } from '../../../ui/atoms';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import Spacer from '../../../ui/Spacer';
import generateSrcSet from '../../../ui/utils/generateSrcSet';
import Tag from '../../common/Tag';
import { translateAppStoreEnums } from '../utils/appStoreEnumTranslateUtils';

const useStyles = makeStyles((theme: Theme) => ({
  appIcon: {
    alignItems: 'left',
    borderRadius: '100%',
    display: 'flex',
    height: 126,
    justifyContent: 'left',
    minWidth: 126,
    paddingTop: 2,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xl')]: {
      marginRight: theme.spacing(2),
    },
  },
  topDataBox: {
    width: 20,
    textAlign: 'justify',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xl')]: {
      marginLeft: theme.spacing(0),
    },
  },
  elementData: {
    marginTop: theme.spacing(2),
    textAlign: 'justify',
  },
  topTags: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export type AppStoreAppDetailCardProps = {
  appStoreApp: AppStoreApp;
  buttonText: string;
  buttonAction: () => void;
  isConfigured: boolean;
};

export const AppStoreAppDetailCard = ({
  appStoreApp,
  buttonText,
  buttonAction,
  isConfigured,
}: AppStoreAppDetailCardProps) => {
  const classes = useStyles();
  const AppStoreAppIcon = () => {
    return (
      <Box className={classes.appIcon}>
        {appStoreApp.Logo && (
          <img
            style={{
              borderRadius: '10%',
              height: 126,
              width: 126,
            }}
            srcSet={generateSrcSet(appStoreApp.Logo, {
              png: true,
              width: 126,
            })}
            src={appStoreApp.Logo}
            data-fd={`app_store_logo_${appStoreApp.Id}`}
          />
        )}
      </Box>
    );
  };
  const getVerificationStatusTranslationId = (
    verificationStatus: AppStoreApp.VerificationStatusEnum
  ) => {
    switch (verificationStatus) {
      case AppStoreApp.VerificationStatusEnum.Draft:
        return 'Draft' as TranslationId;
      case AppStoreApp.VerificationStatusEnum.Submitted:
        return 'Submitted' as TranslationId;
    }
  };

  return (
    <>
      <Hidden mdDown>
        <Spacer size={8} />
      </Hidden>
      <PaperContainer hasBorderOnMobile>
        <Grid container>
          <Grid>
            <AppStoreAppIcon />
          </Grid>
          <Grid item xs={12} sm md lg container>
            <Grid item xs container direction="row" spacing={2}>
              <Grid item xs className={classes.topDataBox}>
                <Grid
                  container
                  direction={'row'}
                  spacing={1}
                  justifyContent={'flex-start'}
                  className={classes.topTags}
                >
                  {isConfigured && (
                    <Grid item>
                      <Tag tagName={'Installed'} showGreenTag noMargin />
                    </Grid>
                  )}
                  {appStoreApp.VerificationStatus !==
                    AppStoreApp.VerificationStatusEnum.Verified && (
                    <Grid item>
                      <Tag
                        noMargin
                        tagName={
                          getVerificationStatusTranslationId(
                            appStoreApp.VerificationStatus
                          ) as TranslationId
                        }
                        showGreyTag
                      />
                    </Grid>
                  )}
                  {appStoreApp.Internal && (
                    <Tag tagName={'AppStore_Internal'} showGreyTag noMargin />
                  )}
                </Grid>
                <Typography variant="h4">{appStoreApp.Name}</Typography>
                <Typography variant="body2">{appStoreApp.Description}</Typography>

                <Grid className={classes.elementData}>
                  <Translate id="Built_by" />:{' '}
                  {appStoreApp.Support?.CompanyWebsite ? (
                    <ExternalLink href={appStoreApp.Support.CompanyWebsite}>
                      {appStoreApp.DeveloperName}
                    </ExternalLink>
                  ) : (
                    appStoreApp.DeveloperName
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Permissions allowed={['Owner']}>
                {buttonText && buttonAction && (
                  <Button
                    variant="contained"
                    color="primary"
                    data-fd="app-store-install-button"
                    onClick={() => buttonAction()}
                  >
                    {buttonText}
                  </Button>
                )}
              </Permissions>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Stack direction="column" spacing={2}>
          <Box>
            <Typography variant="caption">
              <Translate id="About_the_app" />
            </Typography>
            <Typography variant="body2">{appStoreApp.Details}</Typography>
          </Box>

          <Box>
            <Typography variant="caption" component="p" gutterBottom>
              <Translate id="App_store_categories" />
            </Typography>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
              {appStoreApp.Categories.map((category) => (
                <Chip
                  label={translateAppStoreEnums(String(category))}
                  variant="outlined"
                  size="small"
                  key={category}
                />
              ))}
            </Stack>
          </Box>

          <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            {appStoreApp.Support?.PrivacyPolicy && (
              <ExternalLink href={appStoreApp.Support.PrivacyPolicy}>
                <Translate id="Privacy_Policy" />
              </ExternalLink>
            )}
            {appStoreApp.Support?.TermsOfUse && (
              <ExternalLink href={appStoreApp.Support.TermsOfUse}>
                <Translate id="Terms_of_Use" />
              </ExternalLink>
            )}
            {appStoreApp.Support?.LearnMore && (
              <ExternalLink href={appStoreApp.Support.LearnMore}>
                <Translate id="Learn_more" />
              </ExternalLink>
            )}
            {appStoreApp.Support?.SupportEmail && (
              <ExternalLink href={'mailto:' + appStoreApp.Support?.SupportEmail}>
                <Translate id="Support_Contact" />
              </ExternalLink>
            )}
          </Stack>
        </Stack>
      </PaperContainer>
      <Hidden mdDown>
        <Spacer size={8} />
      </Hidden>
    </>
  );
};
