import React, { useCallback, useEffect, useState } from 'react';

import {
  AppConfigUpdateModel,
  Language,
  OnboardingItemUpdate,
} from '@flipdish/api-client-typescript';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PageLayout from '../../../ui/Layout';
import useTutorialStore from '../../Tutorial/hooks/useTutorialStore';
import { TutorialNotifier } from '../../Tutorial/Notifier';
import {
  getSMSRestaurantName,
  setAppConfig,
  setAppConfigLanguages,
  setSMSRestaurantName,
  uploadAppConfigLogo,
} from '../../WebsiteAndApp/actions';
import { getSMSRestaurantNameSelector } from '../../WebsiteAndApp/selectors';
import GeneralSettingsForm, { FormValues } from './GeneralSettingsForm';

const CATEGORIES: { [key: string]: AppConfigUpdateModel.ApplicationCategoryEnum } = {
  '0': AppConfigUpdateModel.ApplicationCategoryEnum.Restaurant,
  '1': AppConfigUpdateModel.ApplicationCategoryEnum.Cafe,
  '2': AppConfigUpdateModel.ApplicationCategoryEnum.Convenience,
};

const isLanguagesEqual = (newLanguages: Language[], previousLanguages: Language[]): boolean => {
  if (newLanguages.length !== previousLanguages.length) {
    return false;
  }

  return newLanguages.every((item, index) => {
    const prevItem = previousLanguages[index];

    return prevItem && item.LanguageId === prevItem.LanguageId;
  });
};

const hasChanges = (
  newConfig: AppConfigUpdateModel,
  previousConfig: AppConfigUpdateModel
): boolean => {
  return Object.keys(newConfig).some((key) => {
    const newValue = newConfig[key];
    let previousValue = previousConfig[key];

    if (key === 'ApplicationCategory' && previousValue !== undefined) {
      previousValue = CATEGORIES[previousValue];
    }

    return newValue !== previousValue;
  }, null);
};

type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {};
type Props = InnerProps & OuterProps;

const GeneralSettings = (props: Props) => {
  const {
    getSMSRestaurantName,
    smsRestaurantName,
    currentApp,
    setSMSRestaurantName,
    translate,
    updateConfig,
    setLanguages,
    LogoImageUrl,
    uploadLogo,
  } = props;
  const { MainColor, Name, Languages, ApplicationCategory, IsPanaceaEnabled = false } = currentApp;

  useEffect(() => {
    getSMSRestaurantName();
  }, []);

  const hasUpdatedLogo =
    LogoImageUrl !==
    ('https://flipdish.imgix.net/r6nn7KYuxhnDUUAFAXnTrrvwY.png' ||
      'https://flipdish.imgix.net/gddSky3brWXB4bHWcfax5M130.jpg');
  const hasUpdatedBrandColor = MainColor !== ('fd5a5b' || '000000');
  const storeId = useTutorialStore(currentApp.AppId).data?.StoreId;

  const [updateTutorialLogoTask, setUpdateTutorialLogoTask] = useState<boolean>(hasUpdatedLogo);

  const saveSettings = useCallback(
    ({
      name,
      SMSRestaurantName,
      languages,
      type,
      webAndAppsButtonColour,
      logo,
      IsPanaceaEnabled,
    }: FormValues & { logo?: File }) => {
      const config: AppConfigUpdateModel = {
        Name: name,
        IsPanaceaEnabled,
        ApplicationCategory: type,
        MainColor: webAndAppsButtonColour.slice(1),
      };

      const newLanguages = languages.map((language) => {
        return {
          LanguageId: language.value,
          Name: language.label,
          DisplayOrder: language.displayOrder,
        };
      }) as Language[];

      setSMSRestaurantName(SMSRestaurantName);

      if (logo) {
        const formData = new FormData();
        formData.append('files[]', logo);
        setUpdateTutorialLogoTask(true);
        uploadLogo(formData);
      }

      if (!isLanguagesEqual(newLanguages, currentApp.Languages || [])) {
        setLanguages(newLanguages);
      }

      if (hasChanges(config, currentApp)) {
        updateConfig(config);
      }
    },
    [updateConfig, setLanguages, uploadLogo, currentApp]
  );

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'app.*' }}
      documentTitle="General_settings"
      title={translate('General_settings')}
    >
      {updateTutorialLogoTask && storeId && (
        <TutorialNotifier
          onboardingItemId={1031}
          status={'Completed' as OnboardingItemUpdate}
          storeId={storeId}
        />
      )}
      {hasUpdatedBrandColor && storeId && (
        <TutorialNotifier
          onboardingItemId={1032}
          status={'Completed' as OnboardingItemUpdate}
          storeId={storeId}
        />
      )}
      <GeneralSettingsForm
        applicationCategory={ApplicationCategory}
        brandName={Name}
        isPanaceaEnabled={IsPanaceaEnabled}
        languages={Languages}
        webAndAppsButtonColour={MainColor}
        smsRestaurantName={smsRestaurantName}
        submit={saveSettings}
      />
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setSMSRestaurantName: (smsRestaurantName: string) =>
    dispatch(setSMSRestaurantName(smsRestaurantName)),
  getSMSRestaurantName: () => dispatch(getSMSRestaurantName()),
  uploadLogo: (data: FormData) => dispatch(uploadAppConfigLogo(data)),
  updateConfig: (config: AppConfigUpdateModel) => dispatch(setAppConfig(config)),
  setLanguages: (languages: Language[]) => dispatch(setAppConfigLanguages(languages)),
});

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
  translate: getTranslate(state.locale),
  LogoImageUrl: state.currentApp.LogoImageUrl,
  smsRestaurantName: getSMSRestaurantNameSelector(state),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  GeneralSettings
);
