import React from 'react';

import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { compose, lifecycle, setDisplayName } from 'recompose';

import { setSelected } from '../../actions/store.actions';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import OpeningHourOverrides from '../OpeningHourOverrides/OpeningHourOverrides';
import OpeningHours from '../OpeningHours/OpeningHours';
import StoreBankingSettings from './Banking/StoreBankingSettings';
import DangerZoneList from './DangerZoneSettings/DangerZoneList';
import DeliverySettings from './DeliverySettings/DeliverySettings';
import StoreKioskSettings from './KioskSettings/KioskSettings';
import OrderBatching from './OrderBatching/OrderBatching';
import PreOrderSettings from './PreOrderSettings/components/PreOrderSettings';
import PreOrderSettingsList from './PreOrderSettings/PreOrderSettingsList';
import StoreServiceCharge from './ServiceCharge/StoreServiceCharge';
import { StoreCollectionSettings } from './StoreCollectionSettings';
import { StoreDetails } from './StoreDetails';
import StoreGeneralSettings from './StoreGeneralSettings';
import StoreOrderNotifications from './StoreOrderNotifications/StoreOrderNotifications';
import StoreOrderSettings from './StoreOrderSettings';
import TableSettings from './TableSettings/TableSettings';
import { StoreDetailsRouteParams } from './types';

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps: StoreDetailsRouteParams) => {
  const storeGroupId = Number(ownProps.match.params.storeGroupId);
  const storeId = Number(ownProps.match.params.storeId);
  return (dispatch: ThunkDispatch, ownProps) => {
    return {
      setSelection: () => {
        dispatch(setSelected({ storeGroupId, storeId }));
      },
    };
  };
};

const StoreRoutes = compose<MappedDispatch & StoreDetailsRouteParams, StoreDetailsRouteParams>(
  setDisplayName('StoreRoutes'),
  connect(null, mapDispatchToPropsFactory),
  lifecycle<MappedDispatch & StoreDetailsRouteParams, {}>({
    componentWillMount() {
      this.props.setSelection();
    },
  })
)(({ match }) => {
  return (
    <ErrorBoundary identifier={'store-settings'}>
      <Switch>
        <Route exact path={`${match.path}`} component={StoreDetails} />
        <Route exact path={`${match.path}/general`} component={StoreGeneralSettings} />
        <Route exact path={`${match.path}/openinghours`} component={OpeningHours} />
        <Route exact path={`${match.path}/openinghouroverrides`} component={OpeningHourOverrides} />
        <Route exact path={`${match.path}/deliverysettings`} component={DeliverySettings} />
        <Route exact path={`${match.path}/collection`} component={StoreCollectionSettings} />
        <Route exact path={`${match.path}/ordersettings`} component={StoreOrderSettings} />
        <Route
          exact
          path={`${match.path}/ordernotifications`}
          component={StoreOrderNotifications}
        />
        <Route exact path={`${match.path}/preordersettings`} component={PreOrderSettingsList} />
        <Route exact path={`${match.path}/kiosksettings`} component={StoreKioskSettings} />
        <Route exact path={`${match.path}/servicecharge`} component={StoreServiceCharge} />
        <Route exact path={`${match.path}/bankingsettings`} component={StoreBankingSettings} />
        <Route exact path={`${match.path}/dangerzone`} component={DangerZoneList} />
        <Route
          exact
          path={`${match.path}/preordersettings/:type(collection|delivery)`}
          component={PreOrderSettings}
        />
        <Route exact path={`${match.path}/tablesettings`} component={TableSettings} />
        <Route exact path={`${match.path}/orderbatching`} component={OrderBatching} />
      </Switch>
    </ErrorBoundary>
  );
});

export default StoreRoutes;
