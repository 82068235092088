import React, { useState } from 'react';

import { Field, FieldProps } from 'formik';
import { getCountrySpecifications } from 'ibantools';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { formikValidate } from '../../../../../helpers/validation';
import { bankingSelectors } from '../../banking.selectors';
import { excludedCountries, nonIBANSupportedCountries } from '../../constants';
import FieldWrapper from './FieldWrapper';
import SingleSelect from './SingleSelect';

type CountrySpec = {
  value: string;
  label: string;
};

export const countries = Object.entries(getCountrySpecifications())
  .reduce<CountrySpec[]>((agg, [key, spec]) => {
    if (excludedCountries.includes(key)) {
      return agg;
    }
    if (spec.IBANRegistry || nonIBANSupportedCountries.includes(key)) {
      agg.push({
        value: key,
        label: spec.name,
      });
    }
    return agg;
  }, [])
  .sort((a, b) => a.label.localeCompare(b.label));

const validate = (value: string) => {
  return formikValidate.required(value);
};

type CountrySelectorFormFieldProps = {
  name: string;
  fdKey: string;
  readOnly?: boolean;
};

type Props = CountrySelectorFormFieldProps & MappedProps;

const CountrySelectorFormField = (props: Props) => {
  const { name, fdKey, countriesList } = props;
  return (
    <FieldWrapper>
      <Field
        name={name}
        validate={validate}
        component={({ field, form }: FieldProps) => {
          const { errors, touched, isSubmitting } = form;
          // TODO extract into component
          const [selected, setSelected] = useState(() => {
            if (field.value) {
              return countriesList.find((c) => c.value === field.value);
            }
            return undefined;
          });

          const fieldError = errors[field.name] as string | undefined;
          const showError = !!fieldError && (touched[field.name] as boolean | undefined);

          const handleChanges = (idk) => {
            form.setFieldValue(field.name, idk.value, false);
            setSelected(idk);
          };

          return (
            <SingleSelect
              {...field}
              value={selected}
              onChange={handleChanges}
              options={countriesList}
              dataFd={fdKey}
              label={<Translate id="Country" />}
              fullWidth
              disabled={isSubmitting}
              error={showError}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={showError ? fieldError : undefined}
              margin="none"
            />
          );
        }}
      />
    </FieldWrapper>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const countriesList = bankingSelectors.getCountryList(state);
  return {
    countriesList,
  };
}

export default compose<Props, CountrySelectorFormFieldProps>(connect(mapStateToProps, {}))(
  CountrySelectorFormField
);
