import React from 'react';

import { OnboardingItemUpdate } from '@flipdish/api-client-typescript';
import BankIcon from '@mui/icons-material/AccountBalanceOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';

import { ItemMetaData } from '../../layouts/Portal/menuItemsConfig';
import BankingCreate from '../Finance/Banking/Create/BankingCreate';
import BankingList from '../Finance/Banking/List/BankingList';
import Profile from '../Profile/Profile';
import BankAccount from './components/BankAccount';
import MyAccount from './components/MyAccount';
import WelcomeScreen from './components/WelcomeScreen';

export const bankingListSearch = 'step=bank_account_list';

export const tutorialMilestoneId = 1;
export const onboardingWizardMileStoneId = 2000;
export const addBankAccountStepId = 2008;
export const bankAccountListStepId = 2009;

export const parseChannelsToSideMenuItems = (onboardingRoutes: Array<any>, currentApp?: string) => {
  if (!currentApp) return [];

  const menuItems: ItemMetaData[] = [];
  onboardingRoutes.forEach((assignedChannel) => {
    if (assignedChannel.title) {
      menuItems.push({
        title: `${assignedChannel.title}` as TranslationId,
        path: `/${currentApp}/onboarding/${assignedChannel.path}?${assignedChannel.search}`,
        step: assignedChannel.step,
        icon: assignedChannel.icon,
      });
    }
  });

  return menuItems;
};

export const onboardingRoutes = [
  {
    key: '/welcome_screen',
    value: <WelcomeScreen />,
    title: 'My_account',
    icon: <HomeIcon />,
    step: 2002,
    search: 'step=welcome_screen',
    path: 'account',
  },
  {
    key: '/my_account',
    value: <MyAccount />,
    step: 2004,
    search: 'step=my_account',
    path: 'account',
  },
  {
    key: '/profile_account',
    value: <Profile hideBackButton />,
    search: 'step=profile_account',
    step: 2005,
    path: 'account',
  },
  {
    key: '/bank_account',
    value: <BankAccount />,
    title: 'Bank_accounts',
    icon: <BankIcon />,
    step: 2007,
    search: 'step=bank_accounts',
    path: 'banking',
  },
  {
    key: '/add_bank_account',
    value: <BankingCreate hideBackButton goToNextStep={bankingListSearch} />,
    search: 'step=add_bank_account',
    disableNextBtn: true,
    step: addBankAccountStepId,
    path: 'banking',
  },
  {
    key: '/bank_account_list',
    value: <BankingList />,
    disableNextBtn: true,
    search: bankingListSearch,
    step: bankAccountListStepId,
    path: 'banking',
  },
];

export const findFirstIncompleteStep = (data) => {
  return data?.Items?.find((item) => item.Status !== OnboardingItemUpdate.StatusEnum.Completed);
};

export const getFirstIncompleteItemId = (steps) => {
  const topLevel = findFirstIncompleteStep(steps);
  const secondLevel = findFirstIncompleteStep(topLevel);
  const childLevel = findFirstIncompleteStep(secondLevel);

  return childLevel?.OnboardingItemId;
};

export const getCurrentStep = (locationSearch: string) => {
  const currentStep = onboardingRoutes?.find(
    (route) => `?${route.search}` === locationSearch
  )?.step;
  return currentStep;
};

export const findItemById = (data: any, itemId?: number) => {
  if (data?.Items) {
    for (const item of data.Items) {
      if (item?.OnboardingItemId === itemId) {
        return item;
      }

      const foundItem = findItemById(item, itemId);
      if (foundItem) {
        return foundItem;
      }
    }
  }

  return null;
};
