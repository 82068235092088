import React, { useEffect } from 'react';

import { App, OnboardingItemUpdate } from '@flipdish/api-client-typescript';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { storeActions } from '../../actions/store.actions';
import { getSelectedStore } from '../../selectors/store.selector';
import { useTracking } from '../../services/amplitude/useTracking';
import { StoreEvents } from '../../signalr/hub.actions';
import PageLayout from '../../ui/Layout';
import GridContainer from '../../ui/Layout/GridContainer';
import PaperContainer from '../../ui/Layout/PaperContainer';
import Spacer from '../../ui/Spacer';
import { TutorialNotifier } from '../Tutorial/Notifier';
import Legend from './components/Legend';
import Title from './components/Title';
import OpeningHoursForm from './OpeningHoursForm';
import { getIsUpdating } from './selectors';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  title: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingBottom: '8px',
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

function OpeningHoursContainer(props: MappedState & MappedDispatch) {
  const { loadStore, loadDependencies, storeName, canEdit } = props;
  const { appId, storeGroupId, storeId } = useParams<{
    appId: string;
    storeGroupId: string;
    storeId: string;
  }>();
  const classes = useStyles();

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_openingHours', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    if (!storeName) {
      loadStore(Number(storeId));
    }
  }, [storeName, storeId]);

  useEffect(() => {
    props.subscribeEvents();
    return () => {
      props.unsubscribeEvents();
    };
  }, []);

  return (
    <PageLayout
      caption={storeName}
      title={<Title storeId={Number(storeId)} />}
      toParent={`/${appId}/storegroups/${storeGroupId}/stores/${storeId}`}
    >
      {loadDependencies ? (
        <Spacer size={8} variant={'horizontal'}>
          <CircularProgress size={50} />
        </Spacer>
      ) : (
        <PaperContainer>
          <GridContainer>
            <Grid item xs={12} className={classes.gridItem}>
              <Legend loading={loadDependencies} />
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: '24px' }} className={classes.gridItem}>
              <GridContainer>
                <Hidden mdDown>
                  <Grid item md={2} className={classes.gridItem} />
                </Hidden>
                <Grid item xs={12} md={10} className={classes.gridItem}>
                  <Typography variant="h4" className={classes.title}>
                    <Translate id="Opening_hours_collection_title" />
                  </Typography>
                </Grid>
              </GridContainer>
              <OpeningHoursForm
                id="CollectionHours"
                deliveryType="Pickup"
                storeGroupId={storeGroupId}
                storeId={storeId}
                autoFocus
                disabled={!canEdit}
              />
            </Grid>

            <Grid item xs={12} md={6} className={classes.gridItem}>
              <GridContainer>
                <Grid item xs={12} className={classes.gridItem}>
                  <Typography variant="h4" className={classes.title}>
                    <Translate id="Opening_hours_delivery_title" />
                  </Typography>
                </Grid>
              </GridContainer>
              <OpeningHoursForm
                isLast
                id="DeliveryHours"
                deliveryType="Delivery"
                storeGroupId={storeGroupId}
                storeId={storeId}
                disabled={!canEdit}
              />
            </Grid>
          </GridContainer>
          {storeId && (
            <TutorialNotifier
              onboardingItemId={102}
              status={'Completed' as OnboardingItemUpdate}
              storeId={parseInt(storeId)}
            />
          )}
        </PaperContainer>
      )}
    </PageLayout>
  );
}

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => {
  const store = getSelectedStore(state);
  const storeName = (store && store.Name) || '';
  const canEdit = state.permissions.some(
    (p) => p === App.AppResourceSetEnum.UpdateStoresOpeningHours.toString()
  );

  return {
    storeName,
    autoSave: getIsUpdating(state),
    loadDependencies: !store,
    canEdit,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch) => {
  return {
    loadStore: (storeId: number) => {
      dispatch(storeActions.load(storeId));
    },
    subscribeEvents: () => {
      dispatch(StoreEvents.Subscribe.OpeningHoursUpdated);
    },
    unsubscribeEvents: () => {
      dispatch(StoreEvents.Unsubscribe.OpeningHoursUpdated);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpeningHoursContainer);
