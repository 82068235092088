import React from 'react';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles(() => ({
  textTitle: {
    fontSize: '36px',
    marginTop: '20px',
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
}));

const OnboardingNextSteps = () => {
  const classes = useStyles();
  return (
    <div className={classes.topContent}>
      <Typography color="primary" className={classes.textTitle}>
        <Translate id="Next_Steps" />
      </Typography>
      <Typography variant="body1">
        <Translate id="Flipdish_are_setting_up_your_account_and_will_cont" />
      </Typography>
    </div>
  );
};

export default OnboardingNextSteps;
