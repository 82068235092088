import { IntercomApiFactory } from '@flipdish/api-client-typescript';

import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const intercomApi = createApi(IntercomApiFactory);

const getIntercomUserHashQueryKey = 'getIntercomUserHas';

const getUserHash = async () => {
  try {
    const result = await intercomApi.userHash();
    return result.Data;
  } catch (result) {
    const err = await mapServerError(result);
    throw err;
  }
};

export const intercomService = { getIntercomUserHashQueryKey, getUserHash };
