import React, { useEffect, useRef, useState } from 'react';

import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import { generalConstants } from '../../../../constants/general.constants';
import { AppLoadingCircularProgress } from '../../../AppLoadingCircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative' as any,
    backgroundColor: 'rgba(220, 234, 253, 0.5)',
    [theme.breakpoints.down('md')]: {
      marginRight: '-16px',
      marginLeft: '-16px',
      width: 'auto',
    },
  },
  titleHolder: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute' as any,
    top: '36px',
    left: 0,
    right: 0,
    zIndex: 100,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column' as any,
      alignItems: 'center',
      top: '12px',
    },
  },
  chartTitle: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.71,
    letterSpacing: '0.1px',
  },
  loader: {
    height: '323.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const VoucherLineChart = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const xs = useMediaQuery(theme.breakpoints.down('sm'));

  const [chartWidth, setChartWidth] = useState(generalConstants.MAX_CHART_WIDTH);
  const callsChart = useRef<HTMLDivElement | null>(null);

  const { vouchers, translate } = useSelector((state: AppState) => ({
    vouchers: state.vouchers,
    translate: getTranslate(state.locale),
  }));

  useEffect(() => {
    if (callsChart.current) {
      setChartWidth(callsChart.current.clientWidth);
    }

    const handleResize = () => {
      if (callsChart.current) {
        setChartWidth(callsChart.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let dataStatistics = vouchers.voucherStatistics;
  const sliceIndex = 9;

  dataStatistics = dataStatistics.slice(-sliceIndex);

  const tickValues: number[] = [];
  const tickFormats: any[] = [];

  const format = 'DD MMM';

  for (let i = 1; i < dataStatistics.length; i++) {
    tickValues.push(i);

    const day = moment(dataStatistics[i].PeriodStart).format('DD');

    if (isMobile && i != 1 && day != '01') {
      tickFormats.push(moment(dataStatistics[i].PeriodStart).format('DD'));
      continue;
    }

    tickFormats.push(moment(dataStatistics[i].PeriodStart).format(format).toLowerCase());
  }

  const data = dataStatistics.map((dataStatistic, key) => ({
    date: key,
    calls: dataStatistic.Value,
  }));

  const scatterData = [...data];
  scatterData.shift();
  scatterData.pop();

  let maxCalls = 0;

  for (const dataPoint of data) {
    if (dataPoint.calls > maxCalls) {
      maxCalls = dataPoint.calls;
    }
  }

  const maxCallsDigits = Math.round(maxCalls).toString().length;
  const powerOfTen = maxCallsDigits === 1 ? 10 : Math.pow(10, maxCallsDigits - 1);

  const upperLimit = (Math.floor(maxCalls / powerOfTen) + 1) * powerOfTen + powerOfTen;

  const verticalAxisValues: any[] = [
    0,
    upperLimit / 5,
    (2 * upperLimit) / 5,
    (3 * upperLimit) / 5,
    (4 * upperLimit) / 5,
    upperLimit,
  ];

  const verticalAxisTicks = verticalAxisValues;

  const loading = vouchers.voucherStatisticsTimestamp;

  return (
    <div ref={callsChart} className={classes.root}>
      <div className={classes.titleHolder}>
        <Typography variant="h6" component="h6" className={classes.chartTitle}>
          {vouchers.voucher.VoucherType === 'CreditNote'
            ? translate('Total_orders')
            : translate('Total_customers')}
        </Typography>
      </div>
      {loading ? (
        <div className={classes.loader}>
          <AppLoadingCircularProgress />
        </div>
      ) : null}

      {!loading ? (
        <VictoryChart
          domainPadding={0.5}
          containerComponent={<VictoryVoronoiContainer />}
          width={chartWidth}
          height={320}
        >
          <VictoryAxis
            tickValues={tickValues}
            tickFormat={tickFormats}
            style={{
              axis: { stroke: '#4a9af3' },
              tickLabels: {
                fontSize: xs ? 10 : 11,
                fontFamily: 'Roboto',
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 'normal',
                letterSpacing: '0.2px',
                color: '#4a4a4a',
              },
            }}
            tickLabelComponent={<VictoryLabel dy={-2} />}
          />
          <VictoryAxis
            dependentAxis
            tickValues={verticalAxisValues}
            tickFormat={verticalAxisTicks}
            style={{
              axis: { stroke: '#4a9af3' },
              tickLabels: {
                fontSize: xs ? 10 : 11,
                fontFamily: 'Roboto',
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 'normal',
                letterSpacing: '0.2px',
                color: '#4a4a4a',
              },
            }}
            tickLabelComponent={<VictoryLabel dx={2} />}
          />
          {data.length > 0 ? (
            <VictoryArea
              style={{
                data: {
                  fill: 'rgba(185, 212, 252, 0.7)',
                  stroke: '#4a9af3',
                  strokeWidth: 1,
                },
              }}
              data={data}
              x="date"
              y="calls"
            />
          ) : null}
          <VictoryScatter
            data={scatterData}
            style={{
              data: {
                fill: ({ active }) => (active ? '#fff' : '#4a9af3'),
                stroke: ({ active }) => (active ? '#4a9af3' : ''),
                strokeWidth: ({ active }) => (active ? 1 : 0),
              },
              labels: { padding: 4 },
            }}
            size={({ active }) => (active ? 5 : 3)}
            x="date"
            y="calls"
            labels={({ datum }) => datum.calls}
            labelComponent={
              <VictoryTooltip
                cornerRadius={1}
                pointerLength={5}
                flyoutStyle={{
                  fill: 'rgba(255, 255, 255, 0.95)',
                  strokeWidth: 0,
                }}
                style={{
                  fontSize: 11,
                  fontFamily: 'Roboto',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: '1.33',
                  letterSpacing: '0.4px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  zIndex: 101,
                }}
              />
            }
          />
        </VictoryChart>
      ) : null}
    </div>
  );
};

export default VoucherLineChart;
