import React, { useRef, useState } from 'react';

import IntercomIcon from '@mui/icons-material/ChatOutlined';
import HelpIcon from '@mui/icons-material/HelpOutline';
import SupportIcon from '@mui/icons-material/OpenInNewOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  linkIcon: {
    minWidth: '40px',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  menu: {
    zIndex: 1400,
  },
});

type Props = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  language: state.account.Language,
});

export const SUPPORTED_LANGUAGES = ['en', 'fr', 'de', 'es', 'nl'];
export const getSupportLanguageUrl = (langCode = '') => {
  const usedLangCode = SUPPORTED_LANGUAGES.includes(langCode) ? langCode : 'en';
  return `https://help.flipdish.com/${usedLangCode}`;
};

export function HelpMenu({ language = '' }: Props) {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div
        aria-owns={anchorEl ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        data-fd="help-icon"
      >
        <IconButton ref={anchorRef} onClick={() => setAnchorEl(anchorRef.current)}>
          <HelpIcon />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        className={classes.menu}
        id="menu-list-grow"
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={handleClose}>
          <a
            className={classes.link}
            href={getSupportLanguageUrl(language)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemIcon className={classes.linkIcon}>
              <SupportIcon fontSize="small" />
            </ListItemIcon>
            <Translate id="Support_articles" />
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            className={classes.link}
            href="https://help.flipdish.com/s/contactsupport?language=en_GB"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemIcon className={classes.linkIcon}>
              <IntercomIcon fontSize="small" />
            </ListItemIcon>
            <Translate id="Talk_to_us" />
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a
            className={classes.link}
            href="https://academy.flipdish.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemIcon className={classes.linkIcon}>
              <SchoolOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Translate id="Flipdish_Academy" />
          </a>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(HelpMenu);
