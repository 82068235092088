import React from 'react';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles(() => ({
  textTitle: {
    fontSize: '36px',
  },
  topContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
}));

const MyAccount = () => {
  const classes = useStyles();
  return (
    <div className={classes.topContent}>
      <Typography color="primary" className={classes.textTitle}>
        <Translate id="My_account" />
      </Typography>
      <Typography variant="body1">
        <Translate id="First_are_the_personal_details_you_need_to_provide" />
      </Typography>
      <Typography variant="body1">
        <Translate id="Please_ensure_you_complete_all_the_fields_and_sele" />
      </Typography>
    </div>
  );
};

export default MyAccount;
