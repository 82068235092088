import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import InputAdornment from '@mui/material/InputAdornment';
import type { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { InputField, Typography } from '@fd/ui/atoms';

import { onboardingActions } from '../../../actions/onboarding.actions';
import aboutYouMobileSvg from '../../../assets/images/about_you_mobile.svg';
import aboutYouWideSvg from '../../../assets/images/about_you_wide.svg';
import { accountConstants } from '../../../constants/account.constants';
import { signup } from '../../../constants/signup.constants';
import { LoadingButton } from '../../../ui/LoadingButton';
import InfoIcon from '../../../ui/Tooltip/InfoIcon';
import Tooltip from '../../../ui/Tooltip/Tooltip';
import { StepsHeader } from './StepsHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    surveyBackground: {
      position: 'absolute',
      backgroundImage: `url(${aboutYouWideSvg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${aboutYouMobileSvg})`,
        position: 'static',
        height: '38.75vh',
        minHeight: 200,
      },
    },
    card: {
      position: 'absolute',
      zIndex: 2,
      flex: 1,
      right: theme.spacing(8),
      bottom: theme.spacing(8),
      minWidth: 600,
      borderRadius: '2px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 500,
      },
      [theme.breakpoints.down('lg')]: {
        minWidth: 500,
      },
      [theme.breakpoints.down('sm')]: {
        position: 'static',
        width: '100%',
        right: 0,
        bottom: 0,
        height: '61.25vh',
        minHeight: 300,
        minWidth: 0,
        borderRadius: 0,
      },
    },
    cardContent: {
      padding: theme.spacing(4),
    },
    form: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    formActions: {
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'row-reverse',
      },
    },
    formFields: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    formField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    link: {
      position: 'relative',
      color: '#05149e',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
      },
    },
    centerCaption: {
      textAlign: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },

    stepsShort: {
      color: 'rgba(0, 0, 0, 0.38)',
      marginBottom: 6,
    },
    buttonContainer: {
      minWidth: 140,
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    button: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      backgroundColor: '#ff0046',
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(6),
        width: theme.spacing(28),
      },
      color: '#fff',
      '&:hover': {
        backgroundColor: '#b20031',
      },
      '&:active': {
        backgroundColor: '#b20031',
      },
      letterSpacing: '1.4px',
      [theme.breakpoints.down('lg')]: {
        letterSpacing: '1.3px',
      },
    },
  })
);

type Props = MappedState & MappedDispatch;

const StoreName = (props: Props) => {
  const { account, createStoreName } = props;
  const classes = useStyles();

  const [storeName, setStoreName] = useState('');

  const stepIndex = signup.steps.indexOf('Account_created');
  const typedAccount = account;

  if (!typedAccount.SignupSteps) {
    return <div />;
  }

  const handleInputChange = (event) => {
    setStoreName(event.target.value);
  };

  const handleSubmit = () => {
    createStoreName(storeName);
  };

  return (
    <>
      <div className={classes.surveyBackground} />
      <Hidden smDown>
        <StepsHeader steps={signup.steps} stepIndex={stepIndex} />
      </Hidden>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Hidden smUp>
            <Typography variant="caption" className={classes.stepsShort}>
              <Translate id="Step" /> {stepIndex + 1}/{signup.steps.length}
            </Typography>
          </Hidden>
          <Typography variant="h4">
            <Translate id="Sign_up_store_name" />
          </Typography>

          <InputField
            fdKey="store_name"
            label="Store Name"
            name="Name"
            type="text"
            variant="outlined"
            className={classes.formField}
            fullWidth
            autoFocus
            value={storeName}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    messageId={'Sign_up_store_name_tooltip'}
                    fdKey="Sign_up_store_name_tooltip"
                    id="Sign_up_store_name_tooltip"
                  >
                    <InfoIcon size={20} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.formActions}
            >
              <Grid item xs={12} sm={5}>
                <LoadingButton
                  wrapperClassName={classes.buttonContainer}
                  fullWidth
                  color={'primary'}
                  variant={'contained'}
                  fdKey="finish"
                  size="large"
                  className={clsx(classes.formField, classes.button)}
                  loadingResources={[accountConstants.ANSWER_SIGNUP_QUESTION]}
                  onClick={handleSubmit}
                >
                  <Translate id="Create_your_store" />
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    account: state.account,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch) => ({
  createStoreName: (name: string) => {
    dispatch(onboardingActions.createInitialStore(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreName);
