import React, { useEffect, useRef, useState } from 'react';

import { App, StoreGroup } from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import DeliveryZonesEmptyImage from '../../assets/images/DeliveryZonesEmpty';
import { createDeliveryZoneIdsSelector } from '../../selectors/storeDeliveryZone.selector';
import { ListItem } from './components';
import CreateDeliveryZoneDialog from './CreateDeliveryZoneDialog';
import { IDeliveryZone } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: '0',
    },
  },
  header: {
    padding: '24px 24px 20px 24px',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '16px',
    },
  },
  title: {
    fontSize: '28px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subtitle: {
    height: '24px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  list: {
    maxHeight: 'calc(100vh * 0.2)',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      maxHeight: '128px',
      overflow: 'auto',
      padding: '0',
    },
  },
  createWrapper: {
    height: '24px',
  },
  createButtonWrapper: {
    position: 'absolute',
    right: '24px',
    bottom: '-28px',
    [theme.breakpoints.only('xs')]: {
      bottom: '-68px',
    },
  },
  createButton: {
    color: '#fff',
  },
  emptyWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  imageWrapper: {
    alignSelf: 'center',
  },
  headline5Wrapper: {
    padding: '16px 24px 10px 24px',
  },
  headline5: {
    width: '287px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.75',
    letterSpacing: '0.5px',
    color: '#05149e',
  },
}));

type MappedStateType = ReturnType<ReturnType<typeof mapStateToProps>>;
const mapStateToProps = (_initialState: any, { storeId }: DeliveryZoneListPropsType) => {
  const zoneIdsSelector = createDeliveryZoneIdsSelector(storeId);

  return (state: AppState) => {
    const zoneIds = zoneIdsSelector(state);
    return {
      zoneIds,
      isEmpty: state.deliveryZones.initLoad && zoneIds.length === 0,
    };
  };
};

export type DeliveryZoneListPropsType = {
  storeId: number;
  isSmallScreen: boolean;
  currency: StoreGroup.CurrencyEnum;
  subtitle: string;
  onChange(changes: IDeliveryZone): void;
  onSelect(deliveryZoneId: number): void;
  onRemove(deliveryZoneId: number): void;
  onCreate({ DeliveryFee, MinimumDeliveryOrderAmount, shape }): void;
};

const DeliveryZoneList = ({
  zoneIds,
  storeId,
  onCreate,
  isEmpty,
  currency,
  isSmallScreen,
  onSelect,
  onChange,
  onRemove,
  subtitle,
}: DeliveryZoneListPropsType & MappedStateType) => {
  const [zoneIdLength, setzoneIdLength] = useState(zoneIds.length);
  const inputEl = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  useEffect(() => {
    if (zoneIdLength < zoneIds.length) {
      if (inputEl.current) {
        const scrollHere = inputEl.current.scrollHeight;
        inputEl.current.scrollTo(0, scrollHere);
        setzoneIdLength(zoneIds.length);
      }
    }
  }, [zoneIds]);

  return (
    <div className={classes.root} data-fd="delivery_zones_list">
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title}>
          <Translate>{(translate) => translate('Delivery_zones_title')}</Translate>
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {subtitle}
        </Typography>
      </div>
      <Divider />
      {currency && zoneIds.length > 0 && (
        <div ref={inputEl}>
          <List className={classes.list} data-fd="delivery_zones_form_list">
            {zoneIds.map((id, idx) => {
              return (
                <ListItem
                  key={id}
                  storeId={storeId}
                  deliveryZoneId={id}
                  index={idx}
                  currency={currency}
                  isSmallScreen={isSmallScreen}
                  onSelect={onSelect}
                  onChange={onChange}
                  onRemove={onRemove}
                />
              );
            })}
          </List>
        </div>
      )}

      <Permissions allowed={[App.AppResourceSetEnum.CreateDeliveryZones]}>
        {isEmpty && currency ? (
          <div className={classes.emptyWrapper}>
            <Grid container direction="column">
              <Grid item className={classes.headline5Wrapper}>
                <Typography variant="h5" className={classes.headline5}>
                  <Translate>
                    {(translate) => translate('Create_delivery_zone_description')}
                  </Translate>
                </Typography>
              </Grid>
              <Grid item className={classes.imageWrapper}>
                <DeliveryZonesEmptyImage />
              </Grid>
            </Grid>
          </div>
        ) : null}

        <div className={classes.createWrapper}>
          <div className={classes.createButtonWrapper}>
            {currency && (
              <CreateDeliveryZoneDialog
                className={classes.createButton}
                onCreate={onCreate}
                currency={currency}
              />
            )}
          </div>
        </div>
      </Permissions>
    </div>
  );
};

export default connect(mapStateToProps)(DeliveryZoneList);
