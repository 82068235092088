import type { RedirectProps, RouteProps } from 'react-router';
import { combineReducers } from 'redux';

import { defaultRoutingRoutesState } from '../routes/index.routes';
import { routerReducer } from '../routes/reduxHistory';

declare global {
  type RouteGroup =
    /** Renders only when user is authorized (login) */
    | 'private'
    /** Renders only when user is not authorized */
    | 'public'
    /** Renders only when user is login */
    | 'first_time_setup'
    /** Renders only when user is logged in V2 flag is set to on */
    | 'first_time_setup_V2'
    /** Renders when user is authorized and not authorized */
    | 'publicAndPrivate'
    /** Renders only when user is login */
    | 'onboarding_setup';

  interface RoutePropsExtended extends RouteProps {
    /** Unique name `{module}.{route}`
     * @example
     * "settings.general"
     * */
    name: string;
    group: RouteGroup;
    key?: string;
    permissions?: AppState['permissions'];
  }
  interface RedirectPropsExtended extends RedirectProps {
    /** Unique name `{module}.{route}`
     * @example
     * "settings.general"
     * */
    name: string;
    group: RouteGroup;
    key?: string;
  }
}

function routesReducer(
  state = defaultRoutingRoutesState || {},
  action: { payload: (RoutePropsExtended | RedirectPropsExtended)[]; type: string }
) {
  switch (action.type) {
    case '@@router/SET_ROUTES':
      return action.payload;
    default:
      return state;
  }
}

const routingReducer = combineReducers({
  router: routerReducer,
  routes: routesReducer,
});

export default routingReducer;
