import {
  BluetoothTerminalStatus,
  HydraDeviceDetails,
  KioskBluetoothTerminalUpdatedEvent,
} from '@flipdish/api-client-typescript';

import { kioskCardReaderConstants } from '../../signalr/hub.events';
import {
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  KIOSK_CREATE_SUCCESS,
  KIOSK_LOAD_SUCCESS,
  KIOSK_UNREGISTER_FAILURE,
  KIOSK_UNREGISTER_REQUEST,
  KIOSK_UNREGISTER_SUCCESS,
  KIOSKS_LOAD_REQUEST,
  KIOSKS_LOAD_SUCCESS,
  RESET_CARD_READER_EVENTS,
} from './Kiosks.actions';

type DefaultState = {
  kiosks: HydraDeviceDetails[];
  isLoading: boolean;
  isKiosksEmpty: boolean;
  stores: [];
  error?: any;
  getStoresLoading: boolean;
  totalRecords: number;
  kioskBluetoothTerminalUpdated?: BluetoothTerminalStatus;
  kioskBluetoothTerminalUnpaired?: boolean;
  unregisteringKioskComplete?: boolean;
};

const defaultState: DefaultState = {
  kiosks: [],
  isLoading: false,
  isKiosksEmpty: false,
  stores: [],
  totalRecords: 0,
  getStoresLoading: false,
  kioskBluetoothTerminalUpdated: {},
  kioskBluetoothTerminalUnpaired: false,
};

export default function KiosksReducer(state = defaultState, action): DefaultState {
  switch (action.type) {
    case 'KIOSKS_UPDATE_STORES': {
      const updatedKioskList = state.kiosks.filter((k) => k.DeviceId !== action.payload.DeviceId);
      return {
        ...state,
        kiosks: [...updatedKioskList, action.payload],
      };
    }
    case KIOSKS_LOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        kiosks: action.payload.page === 1 ? [] : state.kiosks,
      };
    case KIOSK_UNREGISTER_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case KIOSKS_LOAD_SUCCESS:
      return {
        ...state,
        kiosks: [...state.kiosks, ...action.payload.Data],
        totalRecords: action.payload.TotalRecordCount,
        isLoading: false,
        isKiosksEmpty: action.payload.Data.length === 0,
        unregisteringKioskComplete: false,
      };
    case KIOSK_LOAD_SUCCESS: {
      return {
        ...state,
        kiosks: [...state.kiosks, action.payload.Data as HydraDeviceDetails],
        totalRecords: state.totalRecords + 1,
        isLoading: false,
        isKiosksEmpty: false,
      };
    }
    case KIOSK_CREATE_SUCCESS: {
      return {
        ...state,
        isKiosksEmpty: false,
        kiosks: [...state.kiosks, action.payload],
        totalRecords: state.totalRecords + 1,
      };
    }
    case GET_STORES_REQUEST:
      return { ...state, getStoresLoading: true };
    case GET_STORES_SUCCESS: {
      const { stores } = action.payload;
      return {
        ...state,
        stores,
        getStoresLoading: false,
      };
    }
    case KIOSK_UNREGISTER_SUCCESS:
      return {
        ...state,
        kiosks: state.kiosks.filter((item) => item.DeviceId !== action.payload),
        totalRecords: state.totalRecords >= 1 ? state.totalRecords - 1 : 0,
        unregisteringKioskComplete: true,
      };
    case KIOSK_UNREGISTER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case kioskCardReaderConstants.UPDATED: {
      const { payload }: { payload: KioskBluetoothTerminalUpdatedEvent } = action;
      return {
        ...state,
        kioskBluetoothTerminalUpdated: payload.BluetoothTerminalStatus,
        kioskBluetoothTerminalUnpaired: false,
      };
    }
    case kioskCardReaderConstants.UNPAIRED: {
      return {
        ...state,
        kioskBluetoothTerminalUnpaired: true,
        kioskBluetoothTerminalUpdated: undefined,
      };
    }
    case RESET_CARD_READER_EVENTS: {
      return {
        ...state,
        kioskBluetoothTerminalUpdated: {},
        kioskBluetoothTerminalUnpaired: false,
      };
    }
    default:
      return state;
  }
}
