import { useState } from 'react';

import {
  GOOGLE_RECAPTCHA_DEV_ENV_SITE_KEY,
  GOOGLE_RECAPTCHA_PROD_ENV_SITE_KEY,
} from '../components/Auth/Onboarding/constants';
import { store } from '../helpers/store';
import { flagService } from '../services/flagService';

export const useRecaptcha = () => {
  const [isRecaptchaValid, setIsReCaptchaValid] = useState<boolean>(false);

  const handleRecaptchaTest = () => {
    setIsReCaptchaValid(true);
  };

  const handleRecaptchaExpired = () => {
    setIsReCaptchaValid(false);
  };

  // to be removed in https://flipdish.atlassian.net/browse/CLM-1122
  const recaptchaSiteKeyToBeRemoved =
    process.env.NODE_ENV === 'development' ||
    window.location.host.includes('portal.flipdishdev.com')
      ? GOOGLE_RECAPTCHA_DEV_ENV_SITE_KEY
      : GOOGLE_RECAPTCHA_PROD_ENV_SITE_KEY;

  const useInjectedRecaptchaKey = flagService.isFlagOn(store.getState(), 'useInjectedRecaptchaKey');

  const recaptchaSiteKey = useInjectedRecaptchaKey
    ? process.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY
    : recaptchaSiteKeyToBeRemoved;

  return {
    isRecaptchaValid,
    handleRecaptchaTest,
    handleRecaptchaExpired,
    recaptchaSiteKey,
  };
};
