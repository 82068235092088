// Adapted from react-code-input: https://github.com/40818419/react-code-input
import React from 'react';

import { type StyledComponentProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { getTranslate, Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { maxLength } from '../../../helpers/validation';
import { IAppState } from '../../../reducers/root.reducer';

const styles = (theme: any): any => ({
  codeInput: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: theme.spacing(0.5),
    '&:before': {
      content: '"\\E15B"',
      fontFamily: 'Material Icons',
      position: 'absolute',
      width: '20px',
      height: '20px',
      left: 'calc(50% - 10px)',
      top: 'calc(50% - 10px)',
      display: 'block',
      color: 'rgba(0, 0, 0, 0.38)',
      textAlign: 'center',
    },
  },
  input: {
    MozAppearance: 'textfield',
    width: '32px',
    borderRadius: 0,
    fontSize: '16px',
    height: '40px',
    color: 'rgba(0, 0, 0, 0.87)',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    textAlign: 'center',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&:nth-child(3)': {
      marginRight: '6%',
    },
    '&:nth-child(4)': {
      marginLeft: '6%',
    },
    '&:focus': {
      outline: 0,
      position: 'relative',
      top: 1,
      borderWidth: '0 0 2px',
      borderColor: `${theme.palette.primary.dark}`,
    },
  },
  title: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  titleActive: {
    color: `${theme.palette.primary.dark}`,
  },
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  helperText: {
    color: 'rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    bottom: '-20px',
  },
  errorInput: {
    borderBottom: `1px solid ${theme.palette.error.main}`,
    '&:focus': {
      outline: 0,
      borderWidth: '0 0 2px',
      borderColor: `${theme.palette.error.main}`,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
});

const keys = {
  Backspace: 'Backspace',
  Delete: 'Delete',
  End: 'End',
  Home: 'Home',
  ArrowLeft: 'ArrowLeft',
  ArrowUp: 'ArrowUp',
  ArrowRight: 'ArrowRight',
  ArrowDown: 'ArrowDown',
  E: 'e',
};

const filterKeys = ['-', '.'];

export interface IProps {
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange(value: string): void;
}

export type PinCodeInputProps = StyledComponentProps & IProps & { translate: TranslateFunction };

const nonDigits = /[^0-9]/g;

class PinCodeInput extends React.Component<PinCodeInputProps, any> {
  private inputRefs;

  public constructor(props) {
    super(props);

    const { disabled, error, helperText, value } = props;

    this.state = {
      value: this.getStateValue(value),
      active: false,
      touched: false,
      error,
      helperText,
      disabled,
    };

    this.inputRefs = [];
  }

  public componentDidUpdate(prevProps, prevState, snapshot) {
    const { disabled, error, helperText } = this.props;
    if (
      prevProps.disabled !== disabled ||
      prevProps.error !== error ||
      prevProps.helperText !== helperText
    ) {
      this.setState({
        disabled,
        error,
        helperText,
      });
    }
  }

  public handleFocus = (e) => {
    if (!this.state.touched) {
      this.setState({ touched: true });
    }
    this.setState({ active: true });
    this.setCursorPosition(e.target);
  };

  public handleClick = (e) => {
    this.setCursorPosition(e.target);
  };

  public handleBlur = (e) => {
    this.setState({ active: false });
  };

  public handleChange = (e) => {
    const inputValue = this.getSafeValue(String(e.target.value));
    const currentId = Number(e.target.dataset.id);
    const stateValue = this.state.value;
    let newStateValue = stateValue;

    if (inputValue !== '') {
      const before = currentId === 0 ? '' : stateValue.slice(0, currentId);
      const after = stateValue.slice(currentId + 1);
      newStateValue = (before + inputValue + after).slice(0, maxLength.pin);

      const candidateId = currentId + inputValue.length;
      const nextId = candidateId >= maxLength.pin ? maxLength.pin - 1 : candidateId;
      const newTarget = this.inputRefs[nextId];

      if (newTarget) {
        newTarget.focus();
      }
    }

    this.doStateChange(newStateValue, stateValue);
  };

  public handleKeyDown = (e) => {
    const currentId = Number(e.target.dataset.id);
    const nextTarget = this.inputRefs[currentId + 1];
    const prevTarget = this.inputRefs[currentId - 1];

    const stateValue = this.state.value;
    let newStateValue = stateValue;
    let before = '';
    let after = '';

    if (filterKeys.indexOf(e.key) > -1) {
      e.preventDefault();
      return;
    }

    switch (e.key) {
      case keys.Backspace:
        e.preventDefault();
        before = currentId === 0 ? '' : stateValue.slice(0, currentId);
        after = stateValue.slice(currentId + 1);
        newStateValue = this.getStateValue(before + after);
        if (prevTarget) {
          prevTarget.focus();
        }
        break;
      case keys.Delete:
        e.preventDefault();
        before = stateValue.slice(0, currentId + 1);
        after = stateValue.slice(currentId + 2);
        newStateValue = this.getStateValue(before + after);
        break;
      case keys.Home:
        this.inputRefs[0].focus();
        break;
      case keys.End:
        this.inputRefs[maxLength.pin - 1].focus();
        break;
      case keys.ArrowLeft:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
        }
        break;
      case keys.ArrowRight:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
        }
        break;
      case keys.ArrowUp:
        e.preventDefault();
        break;
      case keys.ArrowDown:
        e.preventDefault();
        break;
      case keys.E:
        e.preventDefault();
        break;
      default:
        break;
    }

    this.doStateChange(newStateValue, stateValue);
    this.setCursorPosition(e.target);
  };

  public render() {
    const { classes, error, helperText, translate } = this.props;
    const { active, disabled, touched, value } = this.state;

    if (!classes) {
      return null;
    }

    const inputValues = value.split('').map((x) => x.replace(nonDigits, ''));

    const helperTextToDisplay = this.getHelpTextToDisplay(helperText, value, translate);
    const dirty = value !== '';
    const showHelpText = error || (!active && dirty && touched && helperTextToDisplay);

    return (
      <div className={classes.wrapper}>
        <Typography
          variant="caption"
          className={clsx(classes.title, {
            [classes.titleActive || '']: active,
            [classes.error || '']: error,
          })}
        >
          <Translate id="number_digit_pin_code" />
        </Typography>
        <div className={classes.codeInput}>
          {inputValues.map((value: string, i: number) => {
            const id = `pin-digit-${i + 1}`;
            return (
              <input
                ref={(ref) => {
                  this.inputRefs[i] = ref;
                }}
                id={id}
                className={clsx(classes.input, {
                  [classes.errorInput || '']: error,
                })}
                autoFocus={i === 0}
                value={value}
                key={id}
                type="text"
                min={0}
                max={9}
                maxLength={maxLength.pin}
                autoComplete="off"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onClick={this.handleClick}
                onKeyDown={this.handleKeyDown}
                data-id={i}
                data-fd={id}
                disabled={disabled}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            );
          })}
        </div>
        {showHelpText ? (
          <Typography
            variant="caption"
            className={clsx(classes.helperText, {
              [classes.error || '']: error,
            })}
          >
            {helperTextToDisplay}
          </Typography>
        ) : null}
      </div>
    );
  }

  private doStateChange(newStateValue, oldStateValue) {
    if (oldStateValue !== newStateValue) {
      this.setState({ value: newStateValue });
      if (this.props.onChange) {
        this.props.onChange(this.getSafeValue(newStateValue));
      }
    }
  }

  private getStateValue(value?: string): string {
    const initialValue = value || '';
    const digitsOnly = initialValue.replace(nonDigits, '');
    const paddedValue = (digitsOnly + ' '.repeat(maxLength.pin)).slice(0, maxLength.pin);
    return paddedValue;
  }

  private getSafeValue(value?: string): string {
    return (value || '').replace(nonDigits, '');
  }

  private getHelpTextToDisplay(helperText, value, translate) {
    const ownHelpTextKey = this.toHelpTextKey(value);
    const ownHelpText = ownHelpTextKey ? translate(ownHelpTextKey) : undefined;
    const helperTextToDisplay = helperText || ownHelpText;
    return helperTextToDisplay;
  }

  private toHelpTextKey(value: string) {
    if (value.length < maxLength.pin) {
      return 'Required';
    }
    return undefined;
  }

  private setCursorPosition(target) {
    target.selectionStart = target.value.length;
    target.selectionEnd = target.value.length;
  }
}

function mapStateToProps(state: IAppState) {
  return { translate: getTranslate(state.locale) };
}

const EnhancedComponent = compose<any, IProps>(
  withStyles(styles, {
    name: 'PinCodeInput',
    withTheme: true,
  }),
  connect(mapStateToProps)
)(PinCodeInput);

export { EnhancedComponent as PinCodeInput };
