import React, { useEffect, useRef, useState } from 'react';

import { useRecaptcha } from '@fd/customHooks/useRecaptcha';
import { SetPasswordWithPinModel } from '@flipdish/api-client-typescript';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';

import { Dialog } from '@fd/ui/molecules/Dialog';

import { accountActions } from '../../../actions/account.actions';
import { passwordValidationApiErrors, validatePassword } from '../../../helpers/validation';
import {
  getActiveLanguage,
  getTranslate,
  Translate,
} from '../../../overrides/react-localize-redux';
import { Button } from '../../../ui/atoms';
import { PinCodeInput } from '../../Auth/Login/PinCodeInput';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: '340px',
    maxWidth: '340px',
  },
  textFieldContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  newPassword: {
    marginBottom: '24px',
  },
  textField: {
    minWidth: '200px',
    margin: 0,
  },
  visibilityIcons: {
    marginLeft: '8px',
    opacity: 0.54,
  },
  newPasswordVisibilityIcon: {
    marginBottom: '20px',
  },
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
}));

type OuterProps = {
  open: boolean;
  account: any;
  onClose: () => void;
};

type InnerProps = MappedState & MappedDispatch;
type Props = OuterProps & InnerProps;

export const SetNewPassword = (props: Props) => {
  const { account, createNewPassword, language, onClose, open, translate } = props;

  const classes = useStyles({});

  const [pin, setPin] = useState<any>();
  const [pinErrorMessage, setPinErrorMessage] = useState<string | undefined>(undefined);
  const [pinHelperText, setPinHelperText] = useState<string | undefined>(undefined);
  const [pinError, setPinError] = useState<boolean>(false);

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordMasked, setNewPasswordMasked] = useState<boolean>(true);
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [currentPasswordError, setCurrentPasswordError] = useState<string | undefined>(undefined);
  const isAccountVerified = account && account.IsVerified;
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { isRecaptchaValid, recaptchaSiteKey, handleRecaptchaTest, handleRecaptchaExpired } =
    useRecaptcha();

  useEffect(() => {
    if (
      account?.error?.length > 0 &&
      account.IsVerified &&
      !passwordValidationApiErrors.includes(account.error)
    ) {
      setCurrentPasswordError(account.error.replace('.', '').replace(' ', '_'));
    } else {
      setCurrentPasswordError(undefined);
    }

    if (currentPasswordError === 'Pin_incorrect') {
      setPinError(true);
      setPinHelperText('Invalid_pin');
      setCurrentPasswordError(undefined);
    } else {
      setPinError(!!pinErrorMessage);
    }
  }, [account?.error, pinErrorMessage]);

  const onChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleChange = () => {
    let changePasswordModel = {} as SetPasswordWithPinModel;
    if (!isAccountVerified) {
      changePasswordModel = {
        Pin: 0,
        NewPassword: newPassword,
        RecaptchaToken: recaptchaRef.current?.getValue() ?? '',
      };
      if (!pin || pin.length < 6) {
        setPinErrorMessage('pin_must_be_6_digits'); // can't be empty
      } else {
        const pinInt = parseInt(pin, 10);

        if ((pinInt + '').length < 6) {
          setPinErrorMessage('pin_must_be_6_digits'); // can't be empty
        }
        changePasswordModel.Pin = pinInt;
      }
    } else {
      changePasswordModel.NewPassword = newPassword ? newPassword : '';
      changePasswordModel.RecaptchaToken = recaptchaRef.current?.getValue() ?? '';
    }

    const passwordErrorValidation = validatePassword(changePasswordModel.NewPassword, translate);

    setNewPasswordErrorMessage(passwordErrorValidation);

    !passwordErrorValidation && createNewPassword(changePasswordModel);
  };

  const handleClose = () => {
    onClose();
    setPin('');
    setPinErrorMessage(undefined);
    setNewPassword('');
    setNewPasswordErrorMessage(undefined);
    setNewPasswordMasked(true);
  };

  const setPinValue = (value) => {
    setPin(value);
    setPinErrorMessage(undefined);
  };

  return (
    <Dialog
      className={classes.dialog}
      title={translate('Set_a_new_password') as string}
      onClose={handleClose}
      open={open}
      actions={[
        <Button
          className={classes.cancelButton}
          key="btn-password-change-cancel"
          fdKey="btn-password-change-cancel"
          onClick={handleClose}
          variant="secondary"
        >
          <Translate id="Cancel" />
        </Button>,
        <Button
          autoFocus
          key="btn-password-change-agree"
          fdKey="btn-password-change-agree"
          onClick={handleChange}
          variant="primary"
          disabled={!isRecaptchaValid}
        >
          <Translate id="Confirm_password" />
        </Button>,
      ]}
    >
      <Typography variant="caption">
        <Translate id="Please_check_email_for_pin" />
      </Typography>
      <div className={clsx(classes.textFieldContainer, classes.newPassword)}>
        <TextField
          variant="standard"
          placeholder={translate('At_least_12_characters') as string}
          className={classes.textField}
          type={newPasswordMasked ? 'password' : 'text'}
          error={newPasswordErrorMessage != undefined}
          label={translate('Set_a_new_password')}
          value={newPassword}
          helperText={newPasswordErrorMessage || translate('Password_must_contain_two_of')}
          onChange={onChange}
          inputProps={{
            'data-fd': 'txt-password-change-new-password',
          }}
        />
        {newPasswordMasked ? (
          <Visibility
            className={clsx(classes.visibilityIcons, classes.newPasswordVisibilityIcon)}
            onClick={() => setNewPasswordMasked(false)}
            data-fd="txt-password-change-new-password-visibility-on"
          />
        ) : (
          <VisibilityOff
            className={clsx(classes.visibilityIcons, classes.newPasswordVisibilityIcon)}
            onClick={() => setNewPasswordMasked(true)}
            data-fd="txt-password-change-new-password-visibility-off"
          />
        )}
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        data-fd="set-new-password-recaptcha"
        hl={language}
        sitekey={recaptchaSiteKey ?? ''}
        onChange={handleRecaptchaTest}
        onExpired={handleRecaptchaExpired}
      />
      <PinCodeInput onChange={setPinValue} error={pinError} helperText={pinHelperText} />
    </Dialog>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp.AppId as string,
    translate: getTranslate(state.locale),
    language: getActiveLanguage(state.locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  createNewPassword: (password) => {
    dispatch(accountActions.createPasswordWithPin(password));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPassword);
