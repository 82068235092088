import React, { useEffect, useState } from 'react';

import { HydraDeviceDetails } from '@flipdish/api-client-typescript';
import { useQuery } from '@tanstack/react-query';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { compose } from 'recompose';

import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import Spacer from '../../../ui/Spacer';
import AddKioskForm, { HydraRegistrationForm } from '../Components/AddKioskForm';
import { createKiosk, updateKioskList } from '../Kiosks.actions';
import { kioskServices } from '../Kiosks.services';

type Props = MappedState & MappedDispatch & RouteComponentProps;

const KioskCreate = (props: Props) => {
  const { create, currentApp, updateList } = props;

  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [newKiosk, setNewKiosk] = useState<HydraRegistrationForm>();
  const [newKioskNotFound, setNewKioskNotFound] = useState<boolean>(false);

  const parentUrl = `/${currentApp.AppId}/kiosks`;

  const handleSubmit = async (kioskDetails: HydraRegistrationForm) => {
    await create(kioskDetails);
    setNewKiosk(kioskDetails);
  };

  const { data, isError, isSuccess } = useQuery({
    queryKey: ['kioskServices.getKioskList', currentApp.AppId, 'Kiosk', newKiosk?.KioskName],
    queryFn: () =>
      kioskServices.getKioskList(currentApp.AppId!, 'Kiosk', 1, 1, newKiosk?.KioskName),
    enabled: !!currentApp.AppId && isSubmitSuccess && !!newKiosk?.KioskName,
  });

  useEffect(() => {
    if (isSuccess && data) {
      if (newKiosk?.KioskName === data.Data[0]?.DeviceName) {
        updateList(data.Data[0]);
      } else {
        setNewKioskNotFound(true);
      }
    }
  }, [isSuccess, data]);

  if (isSubmitSuccess && data && newKiosk?.KioskName === data.Data[0]?.DeviceName) {
    return <Redirect to={`${parentUrl}/${data?.Data[0]?.DeviceId}`} />;
  }

  if (newKioskNotFound || (isSubmitSuccess && isError)) {
    return <Redirect to={parentUrl} />;
  }

  return (
    <PageLayout toParent={' '} title={<Translate id="New_kiosk" />} documentTitle="New_kiosk">
      <PaperContainer>
        <Spacer size={24} variant="horizontal">
          <AddKioskForm
            initialValues={{ PinCode: undefined, KioskName: '', StoreIds: [] }}
            submit={(kiosk) => handleSubmit(kiosk)}
            onSubmitSuccess={setSubmitSuccess}
          />
        </Spacer>
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    currentApp: state.currentApp,
    kiosks: state.kiosks.kiosks,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    create: (kiosk: HydraRegistrationForm) => {
      return dispatch(createKiosk(kiosk, true));
    },
    updateList: (kiosk: HydraDeviceDetails) => {
      dispatch(updateKioskList(kiosk));
    },
  };
};

export default compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(KioskCreate);
