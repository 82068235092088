import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { Button, Typography } from '@fd/ui/atoms';
import PageLayout from '@fd/ui/Layout';
import Spacer from '@fd/ui/Spacer';

import { appStoreService } from '../../../services/appstore.service';

enum OAuthStateEnum {
  'loading' = 'loading',
  'error' = 'error',
  'success' = 'success',
}

type Props = RouteComponentProps<{ appStoreAppId: string; appId: string }>;

const useStyles = makeStyles((theme: Theme) => ({
  paper: { overflow: 'hidden' },
  container: {
    padding: '1.4rem',
  },
  containerSuccess: {
    backgroundColor: '#20C26E',
  },
  containerError: {
    backgroundColor: '#FF395F',
  },
  textLoading: { color: theme.palette.primary.dark },
  textInfo: { color: theme.palette.primary.contrastText },
  closeTabButton: {
    backgroundColor: '#FF395F',
    marginTop: '1rem',
  },
}));
type AppStoreOAuthStatusProps = Props & MappedState;

const AppStoreOAuthStatus = ({
  appStoreAppId,
  appId,
  translate,
  location,
}: AppStoreOAuthStatusProps) => {
  const params = new URLSearchParams(location.search);
  const state = params.get('state');
  const code = params.get('code');
  const isCodeAndStateExist = !!code && !!state;

  const classes = useStyles();
  const [status, setStatus] = useState<OAuthStateEnum>(
    isCodeAndStateExist ? OAuthStateEnum.loading : OAuthStateEnum.error
  );
  const [info, setInfo] = useState(
    isCodeAndStateExist
      ? translate('OAuth_fetching_status')
      : translate('OAuth_Unexpectedly_failed_to_connect')
  );

  const { data: appData, isPending: isAppLoading } = useQuery({
    queryKey: [appStoreService.getAppStoreAppByIdKey, appStoreAppId],
    queryFn: () => appStoreService.getAppStoreAppById(appStoreAppId, appId),
  });

  const { data, isPending: isStatusLoading } = useQuery({
    queryKey: [appStoreService.getLightSpeedOAuthResponseStatusKey, appStoreAppId, state, code],
    queryFn: () => appStoreService.getLightSpeedOAuthResponseStatus(appStoreAppId, state, code),
    enabled: !!code && !!state && !isAppLoading,
  });

  const isLoading = isAppLoading || isStatusLoading;
  useEffect(() => {
    if (!isLoading && data) {
      if (data.Data.ErrorMessage) {
        setStatus(OAuthStateEnum.error);
        setInfo(data.Data.ErrorMessage);
      } else {
        setStatus(OAuthStateEnum.success);
        setInfo(translate('OAuth_You_can_close_page'));
      }
    }
  }, [data, isLoading]);

  const onCloseTab = () => {
    window.close();
  };

  const appName = appData?.Name ?? 'App';
  const getContainerClass = () => {
    if (status === OAuthStateEnum.loading) {
      return classes.container;
    }

    if (status === OAuthStateEnum.error) {
      return `${classes.container} ${classes.containerError}`;
    } else {
      return `${classes.container} ${classes.containerSuccess}`;
    }
  };

  const getMessage = () => {
    if (status === OAuthStateEnum.loading) {
      return translate('Loading');
    }

    if (status === OAuthStateEnum.error) {
      return translate('OAuth_Failed_connected_to_app_name', { appName });
    } else {
      return translate('OAuth_Successfully_connected_to_app_name', { appName });
    }
  };

  const getTextClass = () => {
    if (status !== OAuthStateEnum.loading) {
      return classes.textInfo;
    }
  };

  const renderContent = () => {
    return (
      <Grid container justifyContent={'center'}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid className={getContainerClass()}>
              <Spacer />
              <Typography align={'center'} variant={'h2'} className={getTextClass()}>
                {getMessage()}
              </Typography>
              <Spacer />
              <Typography align={'center'} variant={'body2'} className={getTextClass()}>
                {info}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        {status !== OAuthStateEnum.loading ? (
          <Grid item>
            <Button
              onClick={onCloseTab}
              fdKey={'fd-close-tab'}
              variant={'primary'}
              className={classes.closeTabButton}
            >
              {translate('Close')}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    );
  };

  return (
    <PageLayout documentTitle="App_Store" title={translate('App_Store')}>
      {renderContent()}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, props: Props) => {
  return {
    appStoreAppId: props.match.params.appStoreAppId,
    appId: props.match.params.appId,
    translate: getTranslate(state.locale),
  };
};

const AppStoreOAuthStatusConnected = compose<Props, any>(
  withRouter,
  connect(mapStateToProps)
)(AppStoreOAuthStatus);

export default AppStoreOAuthStatusConnected;
