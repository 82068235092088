import {
  Coordinates as FdCoordinates,
  CreateLocation,
  CreateLocationArea,
  LeadTime,
  LocationApiFactory,
  LocationAreasApiFactory,
  OrderBatchingConfiguration,
  OrderBatchingConfigurationApiFactory,
  ServiceCharge,
  StoreAddressBase,
  StoreAddressForm,
  StoreBase,
  StoreCloneSettings,
  StoreCreateBase,
  StoreOrderCapacityApiFactory,
  StoreOrderCapacityConfig,
  StoresApiFactory,
  TipConfiguration,
  TipsApiFactory,
  UpdateLocationArea,
} from '@flipdish/api-client-typescript';
import {
  StoreKioskSetting,
  StoreOrderNotificationContactDetails,
  StoresApiFactory as PrivateStoresApiFactory,
} from '@flipdish/api-client-typescript/private/api';
import axios from 'axios';

import { getApiEndPoint } from '../helpers/apibase';
import { createApi } from '../helpers/utilities';
import { KNOWN_ERRORS, mapServerError } from './utils/serverErrorMapper';

const apiEndpoint = getApiEndPoint();

const extraApi = axios.create({
  baseURL: apiEndpoint,
  withCredentials: true,
});

const storesApi = createApi(StoresApiFactory);

const storeOrderCapacityApi = createApi(StoreOrderCapacityApiFactory);

const privateStoresApi = createApi(PrivateStoresApiFactory);

const tipsApi = createApi(TipsApiFactory);

const orderBatchingConfigurationApi = createApi(OrderBatchingConfigurationApiFactory);

const locationAreaApi = createApi(LocationAreasApiFactory);

const locationApi = createApi(LocationApiFactory);

// #region load
export async function load(query?: string, page?: number, limit?: number, storeGroupId?: number) {
  try {
    const incomingMessage = await storesApi.getStores(query, page, limit, storeGroupId);
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion
// #region loadByAppId
export type LoadByAppIdProps = {
  appId: string;
  query?: string;
  page?: number;
  limit?: number;
};

export async function loadByAppId(props: LoadByAppIdProps) {
  if (!props.appId) {
    return;
  }
  try {
    const { appId, query, page, limit } = props;
    const incomingMessage = await storesApi.getStoresByAppId(appId, query, page, limit);
    return incomingMessage;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

export async function loadByAppIdHeaders(props: LoadByAppIdProps) {
  if (!props.appId) {
    return;
  }
  try {
    const { appId, query, page, limit } = props;
    const incomingMessage = await storesApi.getStoreHeadersByAppId(appId, query, page, limit);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

function setStoreAddress(storeId: number, address, latitude: number, longitude: number) {
  const addressBase = {} as StoreAddressBase;
  addressBase.Line1 = address.addressLine1;
  addressBase.Postcode = address.Line2;
  addressBase.CountryCode = address.addressCountryCode; // two letters
  addressBase.City = address.addressCity;

  const coordinates = {} as FdCoordinates;
  coordinates.Latitude = latitude;
  coordinates.Longitude = longitude;

  return Promise.all([
    storesApi.updateStoreAddress(storeId, addressBase),
    storesApi.updateStoreAddressCoordinates(storeId, coordinates),
  ]).catch((error) => {
    console.error(error);
    return Promise.reject(error);
  });
}

export function loadById(storeId: number) {
  return storesApi
    .getStoreById(storeId)
    .then((response, ...rest) => {
      return Promise.resolve(response.Data);
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
}

function create(storeGroupId: number, store: StoreCreateBase) {
  return storesApi
    .createStore(storeGroupId, store)
    .then((response, ...rest) => {
      return Promise.resolve(response.Data);
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
}

function update(storeId: number, store: StoreBase) {
  return storesApi
    .updateStore(storeId, store)
    .then((response, ...rest) => {
      return Promise.resolve(response.Data);
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
}

// #region updateAddress
export async function updateAddress(storeId: number, address: StoreAddressBase) {
  try {
    const incomingMessage = await storesApi.updateStoreAddress(storeId, address);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region updateAddress
export const updateAddressFormQueryKey = 'updateAddressForm';
export async function updateAddressForm(storeId: number, address: StoreAddressForm) {
  try {
    const incomingMessage = await storesApi.updateStoreAddressForm(storeId, address);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region updateAddressCoordinates
export async function updateAddressCoordinates(storeId: number, coordinates: FdCoordinates) {
  try {
    const incomingMessage = await storesApi.updateStoreAddressCoordinates(storeId, coordinates);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region clone
export async function clone(props: { storeGroupId: number; storeId: number }) {
  try {
    const payload: StoreCloneSettings = {
      TargetStoreGroupId: props.storeGroupId,
    };
    const incomingMessage = await storesApi.cloneStore(props.storeId, payload);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region getAttachedBankAccount
export async function getAttachedBankAccount(storeId: number) {
  try {
    const incomingMessage = await storesApi.getBankAccountForStore(storeId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region getStoreNetSales
export async function getStoreNetSales(apiId: string, storeId: number[]) {
  try {
    const incomingMessage = await storesApi.getStoreNetSales(apiId, storeId);
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
// #endregion

// #region assignMenu
export const assignMenu = async (storeId: number, menuId: number) => {
  try {
    await storesApi.assignMenu(storeId, menuId);
    return 200;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};
// #endregion

// #region  archive store
export const archiveStore = async (storeId: number) => {
  try {
    await storesApi.archiveStore(storeId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};
// #endregion

// #region publishStore
export const publishStore = async (storeId: number) => {
  try {
    await storesApi.publishStore(storeId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};
// #endregion

// #region unpublishStore
export const unpublishStore = async (storeId: number) => {
  try {
    await storesApi.unpublishStore(storeId);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};
// #endregion

// #region updatePreOrder
export const updatePreOrderEnabled = async (
  storeId: number,
  deliveryType: 'Delivery' | 'Pickup',
  enabled: boolean
) => {
  try {
    await storesApi.setPreOrdeEnabled(storeId, deliveryType, enabled);
  } catch (incomingError) {
    const err = await mapServerError(incomingError);
    throw err;
  }
};
// #endregion

// #region getStoreKioskSettings
export async function getStoreKioskSettings(storeId: number) {
  try {
    const incomingMessage = await privateStoresApi.getStoreKioskSettings(storeId);
    return incomingMessage.Data;
  } catch (incomingError) {
    const err = await mapServerError(incomingError);
    throw err;
  }
}
// #endregion

// #region updateKioskSettings
export async function updateKioskSettings(storeId: number, storeKioskSetting: StoreKioskSetting) {
  try {
    const incomingMessage = await privateStoresApi.updateStoreKioskSettings(
      storeId,
      storeKioskSetting.KioskSettingId!,
      storeKioskSetting
    );
    return incomingMessage.Data;
  } catch (incomingError) {
    const err = await mapServerError(incomingError);
    throw err;
  }
}
// #endregion

// #region addLogoImage
const addLogoImage = async (storeId: number, data: FormData) => {
  try {
    await extraApi.post(`/privateapi/v1.0/stores/${storeId}/logo`, data);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addLogoImage,
};
// #endregion

// #region getStoreKioskLogo
export async function getStoreLogoImage(storeId: number) {
  try {
    const incomingMessage = await privateStoresApi.getLogoImage(storeId);
    return incomingMessage.Data;
  } catch (incomingError) {
    const err = await mapServerError(incomingError);
    throw err;
  }
}
// #endregion

// #region deleteStoreKioskLogo
export async function deleteStoreLogoImage(storeId: number) {
  try {
    await privateStoresApi.deleteLogoImage(storeId);
  } catch (incomingError) {
    const err = await mapServerError(incomingError);
    throw err;
  }
}
// #endregion

//#region ORDER CAPACITY
export type GetStoreOrderCapacityProps = {
  appId: string;
  storeId: number;
  deliveryType: 'Delivery' | 'Pickup';
};
export async function getStoreOrderCapacity(props: GetStoreOrderCapacityProps) {
  try {
    const { appId, storeId, deliveryType } = props;
    const incomingMessage = await storeOrderCapacityApi.getStoreOrderCapacity(
      appId,
      storeId,
      deliveryType
    );
    return incomingMessage.Data;
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}

export type UpdateStoreOrderCapacityProps = {
  appId: string;
  storeId: number;
  orderCapacity: StoreOrderCapacityConfig;
  deliveryType: 'Delivery' | 'Pickup';
};
export async function updateStoreOrderCapacity(props: UpdateStoreOrderCapacityProps) {
  try {
    const { appId, storeId, orderCapacity, deliveryType } = props;
    await storeOrderCapacityApi.updateStoreOrderCapacityConfig(
      storeId,
      deliveryType,
      appId,
      orderCapacity
    );
  } catch (incomingMessage) {
    const err = await mapServerError(incomingMessage);
    throw err;
  }
}
//#endregion

//#region Order Notification Contacts
export const getOrderNotificationContacts = async (storeId: number) => {
  try {
    const incomingMessage = await privateStoresApi.getOrderNotificationContactDetails(storeId);
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const setOrderNotificationContacts = async (
  storeId: number,
  details: StoreOrderNotificationContactDetails
) => {
  try {
    const incomingMessage = await privateStoresApi.setOrderNotificationContactDetails(
      storeId,
      details
    );
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const deleteOrderNotificationContacts = async (
  storeId: number,
  storeOrderContactDetailId: number
) => {
  try {
    await privateStoresApi.deleteOrderNotificationContactDetails(
      storeId,
      storeOrderContactDetailId
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};
//#endregion

//#region Tipping
export async function getStoreTips(storeId: number) {
  try {
    const incomingMessage = await tipsApi.tipConfigGet(storeId);
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function updateStoreTips(storeId: number, tipConfig: TipConfiguration) {
  try {
    const incomingMessage = await tipsApi.tipConfigUpsert(storeId, tipConfig);
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
//#endregion

//#region Service Charge
export async function getStoreServiceCharge(storeId: number) {
  try {
    const incomingMessage = await storesApi.getStoreServiceCharge(storeId);
    return incomingMessage;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function updateStoreServiceCharge(storeId: number, serviceCharge: ServiceCharge) {
  try {
    const incomingMessage = await storesApi.configureStoreServiceCharge(storeId, serviceCharge);
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
//#endregion

//#region LocationArea
export async function getLocationAreasForStore(appId: string, storeId: number) {
  try {
    const incomingMessage = await locationAreaApi.getLocationAreasForStore(appId, storeId);
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function createLocationArea(
  appId: string,
  storeId: number,
  createLocationArea: CreateLocationArea
) {
  try {
    const incomingMessage = await locationAreaApi.createLocationArea(
      appId,
      storeId,
      createLocationArea
    );
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function updateLocationArea(
  appId: string,
  storeId: number,
  locationAreaId: string,
  updateLocationArea: UpdateLocationArea
) {
  try {
    const incomingMessage = await locationAreaApi.updateLocationArea(
      appId,
      storeId,
      locationAreaId,
      updateLocationArea
    );
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
//#endregion

//#region Location
export async function createLocations(
  locationAreaId: number,
  appId: string,
  storeId: number,
  createLocation: CreateLocation[]
) {
  try {
    const incomingMessage = await locationApi.createLocation(
      locationAreaId,
      appId,
      storeId,
      createLocation
    );
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function deleteLocation(
  locationId: number,
  locationAreaId: number,
  appId: string,
  storeId: number
) {
  try {
    const incomingMessage = await locationApi.deleteLocation(
      locationId,
      locationAreaId,
      appId,
      storeId
    );
    return incomingMessage;
  } catch (e) {
    if (KNOWN_ERRORS.includes(e?.message)) {
      return;
    }
    const err = await mapServerError(e);
    throw err;
  }
}

export async function updateLocation(
  locationAreaId: number,
  locationId: number,
  appId: string,
  storeId: number,
  createLocation: CreateLocation
) {
  try {
    const incomingMessage = await locationApi.updateLocation(
      locationAreaId,
      locationId,
      appId,
      storeId,
      createLocation
    );
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
//#endregion

//#region Order Batching
export async function getStoreOrderBatchConfig(storeId: number) {
  try {
    const incomingMessage = await orderBatchingConfigurationApi.get(storeId);
    return incomingMessage.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function updateStoreOrderBatchConfig(
  storeId: number,
  orderBatchConfig: OrderBatchingConfiguration
) {
  try {
    const setOrderBatchConfig = {
      BatchIntervalInSeconds: orderBatchConfig.BatchIntervalInSeconds,
      Enabled: orderBatchConfig.IsEnabled,
    };

    await orderBatchingConfigurationApi.post(storeId, setOrderBatchConfig);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
//#endregion

//#region Lead Times
export async function getStoreLeadTimes(storeId: number) {
  try {
    const response = await storesApi.getStoreLeadTimes(storeId);
    return response.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function updateStoreLeadTimes(storeId: number, leadTime: LeadTime) {
  try {
    const response = await storesApi.setStoreLeadTimes(storeId, leadTime);
    return response.Data;
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
//#endregion

export const storeService = {
  setStoreAddress,
  loadAll: load,
  load: loadById,
  create,
  update,
  updateStoreAddress: updateAddress,
  updateAddressFormQueryKey,
  updateAddressForm,
  updateStoreAddressCoordinates: updateAddressCoordinates,
  clone,
  getStoreKioskSettings,
  updateKioskSettings,
  getStoreLogoImage,
  deleteStoreLogoImage,

  getStoreOrderCapacity,
  updateStoreOrderCapacity,

  getStoreTips,
  updateStoreTips,

  getStoreServiceCharge,
  updateStoreServiceCharge,

  getStoreOrderBatchConfig,
  updateStoreOrderBatchConfig,

  getLocationAreasForStore,
  createLocationArea,
  updateLocationArea,

  createLocations,
  deleteLocation,
  updateLocation,

  getStoreLeadTimes,
  updateStoreLeadTimes,
};
