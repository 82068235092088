import React, { useEffect } from 'react';

import { OrderBatchingConfiguration } from '@flipdish/api-client-typescript';
import CircularProgress from '@mui/material/CircularProgress';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import { storeActions } from '../../../actions/store.actions';
import * as storeConstants from '../../../constants/store.constants';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../../selectors/loading.selector';
import { getSelectedStore } from '../../../selectors/store.selector';
import PageLayout from '../../../ui/Layout';
import OrderBatchingForm from './OrderBatchingForm';

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([storeConstants.STORE_LOAD]);

const orderBatchConfigLoadingSelector = createLoadingSelector([
  storeConstants.STORE_GET_ORDER_BATCH_CONFIG,
]);

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    margin: theme.spacing(1.25),
  },
}));

type Props = ReturnType<typeof mapStateProps> & ReturnType<typeof mapDispatchToProps>;

const StoreOrderBatching = (props: Props) => {
  const classes = useStyles();

  const {
    getOrderBatchConfig,
    isLoading,
    isLoadingError,
    isOrderBatchConfigLoading,
    loadStore,
    orderBatchConfig,
    selectedApp,
    store,
    storeId,
    updateOrderBatchConfig,
  } = props;

  useEffect(() => {
    getOrderBatchConfig(storeId);
  }, []);

  useEffect(() => {
    if (!store) {
      loadStore(storeId);
    }
  }, []);

  const onOrderBatchConfigChange = (
    newOrderBatchConfig: OrderBatchingConfiguration,
    enableChanged: boolean
  ) => {
    updateOrderBatchConfig(storeId, newOrderBatchConfig, enableChanged);
  };

  const isLoadingDependencies = isLoading || isOrderBatchConfigLoading || !store;

  if (isLoadingError) {
    return <Redirect to={'/' + selectedApp.AppId + '/storegroups'} />;
  }

  return (
    <PageLayout
      toParent={store && `/${selectedApp.AppId}/storegroups/${store.StoreGroupId}/${store.StoreId}`}
      title={<Translate id="Order_batching_title" />}
      caption={store && store.Name}
    >
      {isLoadingDependencies ? (
        <CircularProgress className={classes.loader} size={50} />
      ) : (
        <OrderBatchingForm
          orderBatchConfig={orderBatchConfig}
          onOrderBatchConfigChange={onOrderBatchConfigChange}
        />
      )}
    </PageLayout>
  );
};

const mapStateProps = (state: AppState, ownProps) => {
  const { storeId } = ownProps.match.params;

  const store = getSelectedStore(state);

  return {
    isLoading: initialLoadingSelector(state),
    isLoadingError: initialLoadingErrorSelector(state),
    isOrderBatchConfigLoading: orderBatchConfigLoadingSelector(state),
    orderBatchConfig: state.stores.orderBatchConfig,
    selectedApp: state.currentApp,
    store,
    storeId,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    loadStore: (storeId: number) => dispatch(storeActions.load(storeId)),
    getOrderBatchConfig: (storeId: number) => dispatch(storeActions.getOrderBatchConfig(storeId)),
    updateOrderBatchConfig: debounce(
      (storeId: number, orderBatchConfig: OrderBatchingConfiguration, enableChanged) =>
        dispatch(storeActions.updateOrderBatchConfig({ storeId, orderBatchConfig }, enableChanged)),
      1000
    ),
  };
};

const EnhancedComponent = compose<Props, {}>(
  setDisplayName('StoreOrderBatching'),
  connect(mapStateProps, mapDispatchToProps)
)(StoreOrderBatching);

export default EnhancedComponent;
