export const isSupported = () => {
  return !!window && !!window.navigator && !!window.navigator.geolocation;
};

//@ts-ignore
export type CurrentLocationResult = Coordinates;

export const getCurrentLocation = (options?: PositionOptions) =>
  new Promise<CurrentLocationResult>((resolve, reject) => {
    if (!isSupported()) {
      reject(new Error('Geolocation not supported!'));
    }

    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position.coords);
      },
      reject,
      options
    );
  });
