import React, { useEffect } from 'react';

import { AppExternalServices } from '@flipdish/api-client-typescript/private/api';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PageLayout from '../../../ui/Layout';
import { getTrackingCodes, setTrackingCodes } from '../actions';
import { getTrackingCodesSelector } from '../selectors';
import FacebookPixelCodeForm from './FacebookPixelCodeForm';
import GoogleAnalyticsForm from './GoogleAnalyticsForm';

type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {};
type Props = InnerProps & OuterProps;

const WebsiteSettings = (props: Props) => {
  const { createTrackingCodes, currentApp, getTrackingCodes, trackingCodes, translate } = props;
  useEffect(() => {
    getTrackingCodes();
  }, []);

  return (
    <PageLayout
      toParent={`/${currentApp.AppId}/sales-channels/website-settings`}
      strictToParent={true}
      documentTitle="Social_media_and_analytics"
      title={translate('Social_media_and_analytics')}
    >
      <GoogleAnalyticsForm
        trackingCodes={trackingCodes}
        submit={createTrackingCodes}
        isLoading={props.isLoading}
      />
      <FacebookPixelCodeForm
        trackingCodes={trackingCodes}
        submit={createTrackingCodes}
        isLoading={props.isLoading}
      />
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
  trackingCodes: getTrackingCodesSelector(state),
  translate: getTranslate(state.locale),
  isLoading: state.loading?.['GET_TRACKING_CODES'],
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  createTrackingCodes: (trackingCodes: AppExternalServices) =>
    dispatch(setTrackingCodes(trackingCodes)),
  getTrackingCodes: () => dispatch(getTrackingCodes()),
});

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  WebsiteSettings
);
