import React from 'react';

import Hidden from '@mui/material/Hidden';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import { sortBy } from 'lodash';
import { type DispatchProp, connect } from 'react-redux';
import { compose } from 'redux';

import { languageActions } from '../../actions/language.actions';
import { languageCodeToLanguage } from '../../helpers/language';
import { languagesSelector } from '../../selectors/locale.selector';

const styles = (theme) => ({
  languageLabel: {
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  languagesWrapper: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
    },
  },
  selectMobile: {
    'text-align': 'right',
    'text-align-last': 'right',
  },
});

type Props = {
  disabled?: boolean;
  wrapperClasses: string;
} & ReturnType<typeof mapStateToProps> &
  DispatchProp &
  WithStyles<typeof styles>;

class LanguageBar extends React.Component<Props, any> {
  private languages;

  public constructor(props) {
    super(props);
    const { locale, languages } = this.props;
    const languageInfo = languages.map((language) => ({
      code: language,
      name: languageCodeToLanguage(language),
    }));
    this.languages = sortBy(languageInfo, (l) => l.name);
    this.state = {
      languageCode: locale.activeLanguage,
    };
  }

  public handleChange = (event) => {
    const code = event.target.value;
    this.setState({ languageCode: code });
    // @ts-ignore
    this.props.dispatch(languageActions.setLanguage(code));
  };

  public render() {
    const { classes, wrapperClasses, disabled } = this.props;
    return (
      <div className={wrapperClasses ? wrapperClasses : classes.languagesWrapper}>
        <Hidden smUp>{this.createLanguageSelect(true, classes, disabled)}</Hidden>
        <Hidden smDown>{this.createLanguageSelect(false, classes, disabled)}</Hidden>
      </div>
    );
  }

  private createLanguageSelect(isMobile, classes, disabled) {
    return (
      <Select
        className={classes.languageLabel}
        disableUnderline={true}
        value={this.state.languageCode}
        native={isMobile}
        onChange={this.handleChange}
        disabled={disabled}
        variant="standard"
        inputProps={{
          name: 'languageCode',
          id: 'language-bar',
          className: isMobile ? clsx(classes.languageLabel, classes.selectMobile) : undefined,
          'data-fd': 'language-bar',
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
      >
        {this.languages.map((language) =>
          isMobile ? (
            <option
              key={language.code}
              value={language.code}
              disabled={language.code === this.state.languageCode}
            >
              {language.name}
            </option>
          ) : (
            <MenuItem
              key={language.code}
              value={language.code}
              disabled={language.code === this.state.languageCode}
            >
              {language.name}
            </MenuItem>
          )
        )}
      </Select>
    );
  }
}

function mapStateToProps(state) {
  const { account, locale } = state;

  return {
    account,
    locale,
    languages: languagesSelector(state),
  };
}

const EnhancedComponent = compose(
  withStyles(styles, {
    name: 'LanguageBar',
    withTheme: true,
  }),
  connect(mapStateToProps)
)(LanguageBar);

export { EnhancedComponent as LanguageBar };
