import React from 'react';

import List from '@mui/material/List';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import cookieSettingsIcon from '../../../assets/images/Cookie.svg';
import storesIcon from '../../../assets/images/storesIcon.svg';
import websiteDomainIcon from '../../../assets/images/website-domain.svg';
import websiteEditIcon from '../../../assets/images/website-edit.svg';
import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { getTranslate, Translate } from '../../../overrides/react-localize-redux';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import ListItemLinkButton from '../../../ui/List/ListItemLinkButton';
import { SET_HOSTNAME_APP_CONFIG } from '../../WebsiteAndApp/actions';

type Props = MappedState & MappedDispatch;

const getNextGenUrl = (appId: string) => {
  return window.location.host.includes('portal.flipdishdev.com')
    ? `https://editor.portal.flipdishdev.com/${appId}`
    : `https://editor.portal.flipdish.com/${appId}`;
};

const domaUpdateLoadingSelector = createLoadingSelector([SET_HOSTNAME_APP_CONFIG]);

const WebsiteSettings = (props: Props) => {
  const { currentApp, translate } = props;

  return (
    <PageLayout
      documentTitle="Website"
      title={<Translate id="Website" />}
      showActionButton={true}
      actionBtnTitle={'Contact_support'}
      hrefBtnLink={'https://support.flipdish.com/hc/en-us/requests/new?ticket_form_id=360003936037'}
      userPermissions="Owner"
      openLinkInNewTab
    >
      <Permissions allowed={['ViewSalesChannels', 'EditSalesChannels']}>
        <PaperContainer fluid>
          <List aria-label={translate('Website') as string}>
            <Permissions allowed={['EditSalesChannels']}>
              <ListItemLinkButton
                borderBottom="1px dashed #cecece"
                tagText="New"
                title="Edit_site_code"
                subtitle="Manage_your_new_site_code"
                icon={websiteEditIcon}
                onClick={() => {
                  currentApp.AppId &&
                    window.open(getNextGenUrl(currentApp.AppId), '_blank', 'noopener,noreferrer');
                }}
              />
            </Permissions>
            <ListItemLinkButton
              title="Domain_setup"
              subtitle="Configure_your_website_domain_by_changing"
              link={`/${currentApp.AppId}/sales-channels/website/domain-setup` as string}
              icon={websiteDomainIcon}
            />
            <ListItemLinkButton
              title="Cookie_management"
              subtitle="Select_type_of_cookie_setup_default"
              link={`/${currentApp.AppId}/sales-channels/website/cookie-management` as string}
              icon={cookieSettingsIcon}
            />
            <Permissions allowed={['EditSalesChannels']}>
              <ListItemLinkButton
                title={'Site_settings'}
                subtitle="Change_the_visibility_of_the_stores_on_your_website"
                link={`/${currentApp.AppId}/sales-channels/website/store-visibility` as string}
                icon={storesIcon}
                isLast
              />
            </Permissions>
          </List>
        </PaperContainer>
      </Permissions>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const getFlipdisherPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'FlipdishStaff',
  ]);
  return {
    translate: getTranslate(state.locale),
    currentApp: state.currentApp,
    isFlipdishStaff: getFlipdisherPermissionsSelector(state),
    domaUpdateLoading: domaUpdateLoadingSelector(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchCloseNotifySaving: () => dispatch(closeNotifySaving()),
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaving: () => dispatch(notifySaving({ persist: true })),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});
export default compose<Props, {}>(connect(mapStateToProps, mapDispatchToProps))(WebsiteSettings);
