import React, { useEffect } from 'react';

import Grid, { GridSize } from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import InfoIcon from '../Tooltip/InfoIcon';
import Tooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: Props) => ({
    padding: props.removeRootPaddings ? 0 : theme.spacing(4.75, 0, 2.5, 0),
    alignItems: props.alignItems ? props.alignItems : 'flex-start',
    flexWrap: props.noWrap ? 'nowrap' : 'wrap',
  }),
  label: ({ showTooltip, removeRootPaddings }: Props) => ({
    display: showTooltip ? 'inline-block' : 'block',
    paddingTop: removeRootPaddings ? theme.spacing(2) : 0,
  }),
  description: () => ({
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1.75),
  }),
  icon: {
    display: 'inline',
    marginLeft: theme.spacing(0.75),
  },
}));

export interface FormItemLayoutProps {
  alignItems?: 'stretch' | 'center' | 'flex-end' | 'flex-start' | 'baseline';
  bottomBorder?: boolean;
  children: React.ReactNode;
  description?: React.ReactNode;
  descriptionId?: TranslationId;
  label?: React.ReactNode;
  labelId?: TranslationId;
  mdProportion?: '4x8' | '8x4' | '10x2' | '12x12';
  noWrap?: boolean;
  removeRootPaddings?: boolean;
  showTooltip?: boolean;
  smProportion?: '4x8' | '8x4' | '10x2' | '12x12';
  tooltipOptions?: {
    fdKey: string;
    titleId?: TranslationId;
    messageId?: TranslationId;
    labelText?: string;
  };
}

type Props = FormItemLayoutProps;
const FormItemLayout = (props: Props) => {
  const classes = useStyles(props);
  const {
    bottomBorder,
    children,
    description,
    descriptionId,
    label,
    labelId,
    mdProportion,
    showTooltip,
    smProportion,
    tooltipOptions = { fdKey: '' },
  } = props;

  const [titleProportion, dataProportion] = mdProportion
    ? mdProportion.split('x').map((m) => parseInt(m, 10))
    : [4, 8];
  const [titleProportionSm, dataProportionSm] = smProportion
    ? smProportion.split('x').map((m) => parseInt(m, 10))
    : [];
  const castToGridSize = (data) => data as unknown as GridSize;

  useEffect(() => {
    if (typeof assert === 'undefined') {
      return;
    }
    assert(!(labelId && label), 'dont use labelId and label at same time');
    assert(!(descriptionId && description), "don't use descriptionId and description at same time");
  }, [labelId, label, descriptionId, description]);

  return (
    <Grid
      container
      className={classes.root}
      style={{
        borderBottom: bottomBorder ? '1px solid rgba(0,0,0,0.2)' : '',
      }}
    >
      <Grid
        item
        xs={12}
        sm={castToGridSize(titleProportionSm)}
        md={castToGridSize(titleProportion)}
      >
        <Typography
          variant="subtitle1"
          component="h3"
          color="textPrimary"
          className={classes.label}
        >
          {labelId ? <Translate id={labelId} /> : label || null}
        </Typography>

        {showTooltip && (
          <Tooltip {...tooltipOptions}>
            <div className={classes.icon} data-fd={tooltipOptions?.fdKey}>
              <InfoIcon size={13} verticalAlign="text-top" />
            </div>
          </Tooltip>
        )}

        {descriptionId || description ? (
          <div className={classes.description}>
            <Typography variant="caption" color="textSecondary">
              {descriptionId ? <Translate id={descriptionId} /> : description || null}
            </Typography>
          </div>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={castToGridSize(dataProportionSm)} md={castToGridSize(dataProportion)}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FormItemLayout;
