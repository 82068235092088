import React from 'react';

import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';

const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));

const SalesRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> = (
  props: RouteComponentProps
) => {
  const {
    match: { path },
  } = props;
  return (
    <ErrorBoundary identifier="sales">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Sales" />}
                url={`modules/${RMSModules.SALES}`}
                module={RMSModules.SALES}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default SalesRoutes;
